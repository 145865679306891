import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ListResult, UploadResult, UploadTask } from '@angular/fire/storage';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { Observable } from 'rxjs';
// Services
import { DataService } from 'app/core/database/data.service';
import { AuthService } from '../auth/auth.service';
import { FirebaseStorageService } from './firebase/firebase-storage.service';
// Types and enums
import { AcceptedTypes } from 'app/shared/enums/acceptedTypes';
import { Collection } from 'app/shared/enums/collection';
import { Storage } from 'app/shared/enums/storage';
import { FileData } from 'app/shared/types/fileData';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Injectable({
	providedIn: 'root',
})
export class StorageService {
	private _backend: Storage;
	private _translations;
	private _accountPath: string;
	private _fileAccountPath: string;

	constructor(
		private _firebaseStorageService: FirebaseStorageService,
		private _authService: AuthService,
		private _dataService: DataService,
		private http: HttpClient,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private _fuseConfirmationService: FuseConfirmationService,
		private _functions: Functions
	) {
		this._accountPath = `${Collection.ACCOUNTS}/${this._authService.accountId}`;
		this._fileAccountPath = `${Collection.FILES}/${this._authService.accountId}`;
		// TODO: Load storage from settings, for now, Firestore is fixed
		this._backend = Storage.firebase;
		this._translocoService.selectTranslateObject('common.fileTypeNotSupported', {}, this.scope).subscribe((result) => {
			this._translations = result;
		});
	}
	/**
	 *
	 * Uploads a file to Firestore storage
	 * @param {string} path path to upload to
	 * @param {File} file file to upload
	 * @param {{ [key: string]: string }} [metaData]
	 * @return Promise with the uploadresult
	 */
	uploadFile(path: string, file: File, metaData?: { [key: string]: string }): Promise<UploadResult> {
		if (this._backend === Storage.firebase) return this._firebaseStorageService.uploadFile(path, file, metaData);
	}
	/**
	 * Uploads file to storage and saves document for each in firestore
	 *
	 * @param {FileList} files
	 * @param {DocumentReference} linkedRecord
	 * @return Promise
	 */
	async uploadFiles(files: FileList, linkedRecord: DocumentReference): Promise<void> {
		const path = `${this._fileAccountPath}/${linkedRecord.parent.id}/${linkedRecord.id}`;
		const container = `${this._accountPath}/${linkedRecord.parent.id}/${linkedRecord.id}/${Collection.FILES}`;
		const documents = [];
		const accepted = this.checkFileType(files);
		if (!accepted) return;
		for (let i = 0; i < files.length; i++) {
			const contents = (files[i] as any).contents ? (files[i] as any).contents : null;
			const file: FileData = {
				name: files[i].name,
				createdBy: this._authService.userId,
				created: new Date(),
				size: files[i].size,
				type: files[i].type,
				parent: this._authService.accountId,
				linkedRecord: linkedRecord,
				filePath: '',
				contents: contents,
				processingProgress: {},
			};
			const uploadResult = await this.uploadFile(path, files[i], {
				accountId: this._authService.accountId,
			});
			file.filePath = uploadResult.ref.fullPath;
			documents.push(file);
		}
		return this._dataService.storeDocuments(documents, container);
	}

	/**
	 * Uploads file to storage at specific section & sub-section and save path of file in firestore
	 * @param { File } fileInput
	 * @param { string } section
	 * @param { string } sub_section
	 * @return { Promise<void> }
	 */
	async uploadFileAtPath(fileInput: File, section: string, sub_section: string): Promise<void> {
		const path = `${this._fileAccountPath}/${section}/${sub_section}`;

		// Generate a timestamp (current date and time)
		const timestamp = new Date().toISOString();

		// Split the original filename into name and extension
		const fileNameParts = fileInput.name.split('.');
		const nameWithoutExtension = fileNameParts.slice(0, -1).join('.');
		const fileExtension = fileNameParts.slice(-1)[0];

		// Create a new filename with the timestamp before the extension
		const newFileName = `${nameWithoutExtension}_${timestamp}.${fileExtension}`;
		// Create a new File object with the desired name
		const renamedFile = new File([fileInput], newFileName, { type: fileInput.type });

		const file: { [key: string]: string } = { logoPath: '' };

		// Upload file into storage
		const uploadResult = await this.uploadFile(path, renamedFile, {
			accountId: this._authService.accountId,
		});
		file.logoPath = uploadResult.ref.fullPath;

		// Write logo path into the accounts
		return this._dataService.updateDocument(file, Collection.ACCOUNTS, this._authService.accountId);
	}

	/**
	 * Uploads file to storage at specific section & sub-section and save path of file in firestore
	 * @param { File } fileInput
	 * @param { string } section
	 * @param { string } sub_section
	 * @return { Promise<{ [key: string]: string }> }
	 */
	async uploadTemplateFileAtPath(fileInput: File, section: string, sub_section: string): Promise<{ [key: string]: string }> {
		const path = `${this._fileAccountPath}/${section}/${sub_section}`;

		// Generate a timestamp (current date and time)
		const timestamp = new Date().toISOString();

		// Split the original filename into name and extension
		const fileNameParts = fileInput.name.split('.');
		const nameWithoutExtension = fileNameParts.slice(0, -1).join('.');
		const fileExtension = fileNameParts.slice(-1)[0];

		// Create a new filename with the timestamp before the extension
		const newFileName = `${nameWithoutExtension}_${timestamp}.${fileExtension}`;
		// Create a new File object with the desired name
		const renamedFile = new File([fileInput], newFileName, { type: fileInput.type });

		const file: { [key: string]: string } = { logoPath: '' };

		// Upload file into storage
		const uploadResult = await this.uploadFile(path, renamedFile, {
			accountId: this._authService.accountId,
		});
		file.logoPath = uploadResult.ref.fullPath;

		// Added template path & action name to file
		file.templatePath = uploadResult.ref.fullPath;
		file.actualName = nameWithoutExtension;

		return file;
	}

	/**
	 * Upload file to storage asynchronously as a resumable upload task
	 * @param {File} file The file that should be uploaded
	 * @param {DocumentReference} linkedRecord The linked record
	 * @return {UploadTask} The subscribeable upload task
	 */
	uploadFileResumable(file: File, linkedRecord: DocumentReference): UploadTask {
		const path = `${this._fileAccountPath}/${linkedRecord.parent.id}/${linkedRecord.id}`;
		return this._firebaseStorageService.uploadFileResumable(path, file, { accountId: this._authService.accountId });
	}

	/**
	 * Stores a file's meta data in firestore
	 * @param {File} file
	 * @param {DocumentReference} linkedRecord
	 * @return {Promise<void | DocumentReference>}
	 */
	storeFileMeta(file: File, linkedRecord: DocumentReference, filePath: string): Promise<void | DocumentReference> {
		const container = `${this._accountPath}/${linkedRecord.parent.id}/${linkedRecord.id}/${Collection.FILES}`;
		const fileData: FileData = {
			name: file.name,
			createdBy: this._authService.userId,
			created: new Date(),
			size: file.size,
			type: file.type,
			parent: this._authService.accountId,
			linkedRecord: linkedRecord,
			filePath: filePath,
			contents: null,
			processingProgress: {},
		};

		return this._dataService.storeDocument(fileData, container);
	}

	/**
	 * Returns list of file in given path
	 *
	 * @param {string} path
	 * @return {Promise<ListResult>} Promise with list of result
	 */
	getFiles(path: string): Promise<ListResult> {
		if (this._backend === Storage.firebase) return this._firebaseStorageService.getFiles(path);
	}

	/**
	 * Returns the download URL for the given path
	 *
	 * @param {string} path
	 * @return Promise with the url string
	 */
	getFileUrl(path: string) {
		if (this._backend === Storage.firebase) return this._firebaseStorageService.getUrl(path);
	}

	/**
	 * Downloads a file with dialog
	 *
	 * @param {string} path path to file
	 * @param {string} title title of file
	 * @return Promise
	 */
	async downloadFile(path: string, title: string): Promise<void> {
		const url = await this.getFileUrl(path).catch((err) => {
			console.error(err);
		});
		if (!url) throw new Error('File not found: ' + path);
		this.http
			.get(url, {
				responseType: 'blob',
			})
			.subscribe((blob) => {
				if (!blob) return;
				const a = document.createElement('a');
				const objectUrl = URL.createObjectURL(blob);
				a.href = objectUrl;
				a.download = title;
				a.click();
				URL.revokeObjectURL(objectUrl);
			});
	}
	/**
	 * Deletes a file from the storage and firestore
	 * @param {FileData} file
	 * @return {Promise<any[]>}
	 */
	deleteFile(file: FileData): Promise<any[]> {
		if (this._backend !== Storage.firebase) return;
		const promiseArr = [];
		// delete files in storage
		const filePaths: string[] = [];
		filePaths.push(file.filePath);
		if (file.filePathsProcessed) filePaths.push(...file.filePathsProcessed);
		const deleteFilesFromStorage = httpsCallable(this._functions, 'deleteFilesFromStorage');
		promiseArr.push(deleteFilesFromStorage({ filePaths: filePaths }));
		// delete files in database
		const container = `${this._accountPath}/${file.linkedRecord.parent.id}/${file.linkedRecord.id}/${Collection.FILES}`;
		promiseArr.push(this._dataService.deleteCollection(`${container}/${file.id}/${Collection.AUDITLOG}`));
		promiseArr.push(this._dataService.deleteData(container, file.id));
		return Promise.all(promiseArr);
	}

	/**
	 * Delete file at path
	 * @param { string } filePath
	 * @return { Promise<void> }
	 */
	deleteFileAtPath(filePath: string): Promise<void> {
		return this._firebaseStorageService.deleteFile(filePath);
	}

	/**
	 * Checks if all files have a accepted file type, returns false if not
	 *
	 * @param {FileList} files
	 * @return {boolean}
	 */
	checkFileType(files: FileList): boolean {
		const accepted = Array.from(files).every((file) => AcceptedTypes.includes(file.type));
		if (accepted) return accepted;
		// Filetype not supported
		this._fuseConfirmationService.open({
			title: this._translations['title'],
			message: this._translations['message'],
			actions: {
				confirm: {
					label: this._translations['label'],
				},
				cancel: {
					show: false,
				},
			},
		});
		return false;
	}

	/**
	 * Reads files of the given record
	 *
	 * @param {DocumentReference} linkedRecord
	 * @return {Observable<DataObserver>}
	 */
	readFileData(linkedRecord: DocumentReference): Observable<DataObserver> {
		const container = `${this._accountPath}/${linkedRecord.parent.id}/${linkedRecord.id}/${Collection.FILES}`;
		return this._dataService.loadAllEntriesObservable(container);
	}

	/**
	 * Reads files of multiple records and returns them as an array
	 *
	 * @param {DocumentReference[]} linkedRecords
	 * @return {Observable<any[]>}
	 */
	async readFileDataMultiple(linkedRecords: DocumentReference[]): Promise<any[]> {
		if (linkedRecords.length === 0) return [];
		const promises = [];
		linkedRecords.forEach(async (record) => {
			const container = `${this._accountPath}/${record.parent.id}/${record.id}/${Collection.FILES}`;
			promises.push(this._dataService.loadAllEntries(container));
		});

		const entries = await Promise.all(promises);
		return entries
			.map((data) =>
				data.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				})
			)
			.flat();
	}

	/**
	 * Reads files of multiple records and returns them as an array
	 *
	 * @param {string[]} paths
	 * @return {Observable<any[]>}
	 */
	async readFilesDataByPath(paths: string[]): Promise<any[]> {
		if (paths.length === 0) return [];
		const promises = [];
		paths.forEach((path) => promises.push(this._dataService.loadDataByPath(path)));
		const entries = await Promise.all(promises);
		return entries;
	}
}
