import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { BehaviorSubject, finalize, Observable, Subject, takeUntil } from 'rxjs';

// types & enums
import { Collection } from 'app/shared/enums/collection';
import { RiskCriterionType } from 'app/shared/enums/risk-criterion-type';
import { RiskEngineSettingsStatus } from 'app/shared/enums/risk-engine-setting-status';
import { Account } from 'app/shared/types/account';
import { AccountType } from 'app/shared/types/accountType';
import { CountrySetting } from 'app/shared/types/countrySetting';
import { RiskEngine, RiskEngineSettings } from 'app/shared/types/risk-engine-settings';
import { RiskLevel } from 'app/shared/types/risk-level';
import { RiskLevelSettings } from 'app/shared/types/risk-level-settings';
import { RiskCriterion } from 'app/shared/types/riskCriterion';
import { DataObserver } from 'app/shared/types/utilityTypes';

// services
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';

@Injectable({
	providedIn: 'root',
})
export class RiskEngineSettingsService {
	private _activeRiskEngineSettings: BehaviorSubject<RiskEngineSettings> = new BehaviorSubject(null);
	private _displayedRiskEngineSettings: BehaviorSubject<RiskEngineSettings> = new BehaviorSubject(null);
	private _riskEngines: BehaviorSubject<RiskEngine[]> = new BehaviorSubject(null);
	private _countrySettings: BehaviorSubject<{
		[key: string]: {
			[key: string]: CountrySetting;
		};
	}> = new BehaviorSubject(null);
	private _displayedRiskEngine: BehaviorSubject<RiskEngine> = new BehaviorSubject(null);
	private _riskEnginesObserver: DataObserver;
	private _countrySettingsObserver: DataObserver;
	unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _authService: AuthService,
		private _dataService: DataService
	) {}
	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	init() {
		// Prepare subcollection data before setting up riskEngineSettings
		this._dataService
			.loadAllEntriesObservable(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.COUNTRYSETTINGS}`)
			.pipe(
				takeUntil(this.unsubscribeAll),
				finalize(() => {
					this._countrySettingsObserver.unsubscribe();
				})
			)
			.subscribe((countrySettingsObserver) => {
				this._countrySettingsObserver = countrySettingsObserver;
				const countryData = {};
				this._countrySettingsObserver.data.forEach((setting) => {
					countryData[setting.id] = setting;
				});
				this._countrySettings.next(countryData);
				// If riskengine initialised setup object
				if (!this._activeRiskEngineSettings.value) return;
				const activeRiskObj = this.buildRiskSettingsObj(this._activeRiskEngineSettings.value.id);
				this._activeRiskEngineSettings.next(activeRiskObj);
				if (!this._displayedRiskEngineSettings.value) this._displayedRiskEngineSettings.next(activeRiskObj);
			});
		// Definition with ids to get
		this._dataService
			.loadAllEntriesObservable(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`)
			.pipe(
				takeUntil(this.unsubscribeAll),
				finalize(() => {
					this._riskEnginesObserver.unsubscribe();
				})
			)
			.subscribe((riskenginesObserver) => {
				this._riskEnginesObserver = riskenginesObserver;
				const riskEngineData: RiskEngine[] = riskenginesObserver.data;
				this._riskEngines.next(riskEngineData);
				// Active definition
				const activeSettings = riskEngineData.find((setting) => {
					return setting.status === RiskEngineSettingsStatus.ACTIVE;
				});
				// If countrysettings initialised setup object
				if (!activeSettings || !this._countrySettings) return;
				const activeRiskObj = this.buildRiskSettingsObj(activeSettings.id);
				this._activeRiskEngineSettings.next(activeRiskObj);
				if (!this._displayedRiskEngineSettings.value) return;
				const displayedSettings = riskEngineData.find((setting) => {
					return setting.id === this._displayedRiskEngineSettings.value.id;
				});
				if (!displayedSettings) return;
				const displayedSettingsObj = this.buildRiskSettingsObj(displayedSettings.id);
				this._displayedRiskEngineSettings.next(displayedSettingsObj);
			});
	}

	/**
	 * Builds the risksettingsobject according to the id
	 *
	 * @param {string} riskEngineId
	 * @return {RiskEngineSettings}
	 */
	buildRiskSettingsObj(riskEngineId: string): RiskEngineSettings {
		const riskEngines = this._riskEngines.value;
		const riskEngine = riskEngines.find((setting) => setting.id === riskEngineId);
		if (!riskEngine) return;
		// countrySettings
		const countriesData = this._countrySettings.value;
		const countriesSettings = countriesData[riskEngine.countrySettingsId] ?? {};
		// accountTypes
		const accountTypes = riskEngine.accountTypes ?? {};
		// riskCriteria
		const riskCriteria = riskEngine.riskCriteria ?? [];
		// riskTreshold
		const riskThreshold = riskEngine.riskThreshold ?? [];
		// riskLevels
		const riskLevels = riskEngine.riskLevels ?? {};
		const riskEngineSettings: RiskEngineSettings = {
			id: riskEngine.id,
			countrySettings: countriesSettings,
			accountTypes: accountTypes,
			riskCriteria: riskCriteria,
			riskThreshold: riskThreshold,
			riskLevels: riskLevels,
			created: riskEngine.created,
			edited: riskEngine.edited,
			createdBy: riskEngine.createdBy,
			editedBy: riskEngine.editedBy,
			version: riskEngine.version,
			status: riskEngine.status,
		};
		return riskEngineSettings;
	}

	/**
	 * Updates the risk engine settings
	 *
	 * @param {Partial<RiskEngineSettings>} changes
	 * @return {Promise<void>}
	 */
	updateSetting(changes: Partial<RiskEngineSettings>): Promise<void> {
		changes.edited = new Date();
		changes.editedBy = this._authService.userId;
		return this._dataService.updateDocument(
			changes,
			`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`,
			this.riskEngineSettingsId
		);
	}

	/**
	 * Returns the readable value to a customlist entry
	 * If it is a multiple value criterion the entry with the highest risk level is returned
	 *
	 * @param {string} criterionId
	 * @param {(string | string[])} value
	 * @return {string}
	 */
	getCustomListTitle(criterionId: string, value: string | string[]): string {
		if (!criterionId || !value) return '';
		const criterion = this.getRiskCriterion(criterionId);
		if (!criterion || criterion.type !== RiskCriterionType.CUSTOMLIST || !criterion.children) return '';
		const child = criterion.children.find((child) => child.id === value) || null;
		if (!criterion.multipleCustomerInput && child) return child.title;
		const values = [];
		for (const valueId of value) {
			const item = criterion.children.find((child) => child.id === valueId);
			if (item) values.push(item);
		}
		const lowestRiskLevel = Math.min(...values.map((value) => value.riskLevel));
		const highestRiskLevel = Math.max(...values.map((value) => value.riskLevel));
		if (lowestRiskLevel < 0) {
			const valuesLowest = values.find((value) => value.riskLevel.toString() === lowestRiskLevel.toString());
			return valuesLowest ? valuesLowest.title : '';
		}
		const valueHighest = values.find((value) => value.riskLevel.toString() === highestRiskLevel.toString());
		return valueHighest ? valueHighest.title : '';
	}

	/**
	 * Returns country name to iso code
	 *
	 * @param {string} countryISO
	 * @return {string}
	 */
	getCountryName(countryISO: string): string {
		if (!countryISO) return '';
		const settings = this._activeRiskEngineSettings.value.countrySettings[countryISO];
		return settings ? settings.title : '';
	}

	/**
	 * Gets the risk criterion
	 *
	 * @param {string} criterionId
	 * @return {RiskCriterion}
	 */
	getRiskCriterion(criterionId: string): RiskCriterion {
		const criteria = this._activeRiskEngineSettings.value.riskCriteria;
		return this._getCriterion(criterionId, criteria);
	}

	/**
	 * Creates a new risk settings version
	 *
	 * @return {(Promise<DocumentReference<any> | void>)}
	 */
	async createNewVersion(): Promise<DocumentReference<any> | void> {
		const newCountrySettings = this._activeRiskEngineSettings.value.countrySettings;
		/** Set created user and date time for each country */
		for (const key in newCountrySettings) {
			if (typeof newCountrySettings[key] !== 'object') continue;
			newCountrySettings[key].created = new Date();
			newCountrySettings[key].createdBy = this._authService.userId;
		}
		// Remove id in object
		delete newCountrySettings['id'];
		const countryRef = await this._dataService.storeDocument(
			newCountrySettings,
			`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.COUNTRYSETTINGS}`
		);
		if (!countryRef) return;
		// Get current riskengine to copy data into new riskengine
		const riskEngine = this._riskEngines.value.find((setting) => setting.id === this._activeRiskEngineSettings.value.id);
		const newVersion: RiskEngine = {
			countrySettingsId: countryRef.id,
			accountTypes: riskEngine ? riskEngine.accountTypes : null,
			riskCriteria: riskEngine ? riskEngine.riskCriteria : null,
			riskThreshold: riskEngine ? riskEngine.riskThreshold : null,
			riskLevels: riskEngine ? riskEngine.riskLevels : null,
			created: new Date(),
			createdBy: this._authService.userId,
			version: this.getLastestRiskEngineVersion.version + 1,
			status: RiskEngineSettingsStatus.DRAFT,
		};
		return this._dataService.storeDocument(newVersion, `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`);
	}

	/**
	 * Activates a risk settings version
	 *
	 * @param {string} riskSettingsId
	 * @return {Promise<any[]>}
	 */
	activateNewVersion(riskSettingsId: string): Promise<any[]> {
		const promiseArray = [];
		const updAcc: Partial<Account> = {
			currentRiskEngineSettings: riskSettingsId,
			modified: new Date(),
			modifiedBy: this._authService.userId,
		};
		const updRiskSet: Partial<RiskEngineSettings> = {
			status: RiskEngineSettingsStatus.ACTIVE,
			editedBy: this._authService.userId,
			edited: new Date(),
		};
		const updOldRiskSet: Partial<RiskEngineSettings> = {
			status: RiskEngineSettingsStatus.DISCONTINUED,
			editedBy: this._authService.userId,
			edited: new Date(),
		};
		const riskEnginePath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`;
		promiseArray.push(
			this._dataService.updateDocument(updAcc, Collection.ACCOUNTS, this._authService.accountId),
			this._dataService.updateDocument(updRiskSet, riskEnginePath, riskSettingsId),
			this._dataService.updateDocument(updOldRiskSet, riskEnginePath, this._activeRiskEngineSettings.value.id)
		);
		return Promise.all(promiseArray);
	}

	/**
	 * Deletes a risk settings version
	 *
	 * @param {string} riskSettingsId
	 * @return {Promise<void[]>}
	 */
	deleteVersion(riskSettingsId: string): Promise<void[]> {
		if (this._activeRiskEngineSettings.value.id === riskSettingsId) return;
		const riskengine = this._riskEngines.value.find((element) => element.id === riskSettingsId);
		this.displayedSettings = this._activeRiskEngineSettings.value.id;
		return Promise.all([
			this._dataService.deleteData(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`, riskSettingsId),
			this._dataService.deleteData(
				`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.COUNTRYSETTINGS}`,
				riskengine.countrySettingsId
			),
		]);
	}

	/**
	 * Returns the risk level according to the score
	 *
	 * @param {AccountType} accountType
	 * @param {number} score
	 * @return {(RiskLevel | null)}
	 */
	getRiskLevelByScore(accountType: AccountType, score: number): RiskLevel | null {
		const riskLevels = this._activeRiskEngineSettings.value && this._activeRiskEngineSettings.value.riskLevels;
		if (!riskLevels) return null;
		const accountLevels = riskLevels[accountType.id];
		if (!accountLevels) return null;
		const sorted = accountLevels.riskLevels.sort((a, b) => {
			if (a.rangeStart < b.rangeStart) return -1;
			if (a.rangeStart > b.rangeStart) return 1;
			return 0;
		});
		if (accountLevels.intervalStart === score) return sorted[0];
		const level = accountLevels.riskLevels.find((level) => level.rangeStart < score && level.rangeEnd >= score) || null;
		return level;
	}

	/**
	 * Returns the risk level settings for a given accounttype
	 *
	 * @param {AccountType} accountType
	 * @return {RiskLevelSettings}
	 */
	getRiskLevelSettingsForAccountType(accountType: AccountType): RiskLevelSettings {
		if (!this._activeRiskEngineSettings.value) return;
		const riskLevels = this._activeRiskEngineSettings.value.riskLevels;
		return riskLevels[accountType.id];
	}

	/**
	 * Returns the risk level settings for all accounttype
	 * @return {{ [key: string]: RiskLevelSettings; }}
	 */
	getRiskLevelsForAllAccountType(): { [key: string]: RiskLevelSettings } {
		if (!this._activeRiskEngineSettings.value || !this._activeRiskEngineSettings.value.riskLevels) return {};
		const riskLevels = this._activeRiskEngineSettings.value.riskLevels;
		return riskLevels;
	}

	/**
	 * Check for content type used in risk criteria
	 * @param { string } definitionTypeId
	 * @return { Promise<boolean> }
	 */
	async checkContentTypeUsedInRiskCriteria(definitionTypeId: string): Promise<boolean> {
		const riskEngineData = await this._dataService.loadAllEntries(
			`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.RISKENGINE}`
		);

		return riskEngineData.docs.find((riskEngine) => {
			if (!riskEngine.data().riskCriteria.length) return false;
			return riskEngine.data().riskCriteria.find((criterion: RiskCriterion) => {
				if (!criterion.fileContentTypes.length) return false;
				return criterion.fileContentTypes.includes(definitionTypeId);
			});
		})
			? true
			: false;
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the risk criterion recursively
	 *
	 * @param {string} criterionId
	 * @param {RiskCriterion[]} [scope]
	 * @return {RiskCriterion}
	 */
	private _getCriterion(criterionId: string, scope?: RiskCriterion[]): RiskCriterion {
		if (!scope) scope = this._activeRiskEngineSettings.value.riskCriteria;

		for (let criterion of scope) {
			if (criterion.id === criterionId) return criterion;
			if (criterion.children) {
				const searchedCriterion = this._getCriterion(criterionId, criterion.children);
				if (searchedCriterion) return searchedCriterion;
			}
		}
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {Observable<RiskEngineSettings>}
	 */
	get activeRiskEngineSettings$(): Observable<RiskEngineSettings> {
		return this._activeRiskEngineSettings.asObservable();
	}

	/**
	 * @type {Observable<RiskEngineSettings>}
	 */
	get displayedRiskEngineSettings$(): Observable<RiskEngineSettings> {
		return this._displayedRiskEngineSettings.asObservable();
	}

	/**
	 * @type {Observable<RiskEngine[]>}
	 */
	get riskEngineSettings$(): Observable<RiskEngine[]> {
		return this._riskEngines.asObservable();
	}

	/**
	 * @type {string}
	 */
	get riskEngineSettingsId(): string {
		return this._displayedRiskEngineSettings.value.id;
	}

	/**
	 * @type {RiskEngineSettingsStatus}
	 */
	get displayedRiskEngineSettingsStatus(): RiskEngineSettingsStatus {
		if (this._displayedRiskEngineSettings.value) return this._displayedRiskEngineSettings.value.status;
		return null;
	}

	/**
	 * @type {Observable<RiskEngine>}
	 */
	get displayedRiskEngine$(): Observable<RiskEngine> {
		return this._displayedRiskEngine.asObservable();
	}

	/**
	 * Sets the displayed setting
	 * @param { string } id
	 *
	 */
	set displayedSettings(id: string) {
		const value = this.buildRiskSettingsObj(id);
		this._displayedRiskEngineSettings.next(value);
		this._displayedRiskEngine.next(this._riskEngines.value.find((setting) => setting.id === id));
	}

	/**
	 * @type { RiskEngine }
	 */
	get getLastestRiskEngineVersion(): RiskEngine {
		return this._riskEngines.value.reduce((maxVersionObject, currentObject) => {
			const maxVersion = maxVersionObject ? maxVersionObject.version : 0;
			return currentObject.version >= maxVersion ? currentObject : maxVersionObject;
		}, null);
	}
}
