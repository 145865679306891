<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6">
		<mat-stepper #stepper linear="true" [selectedIndex]="stageOrder[case.stage]" class="stepper">
			<mat-step [editable]="false" state="first" [completed]="stepper.selectedIndex > 0" #matStep1>
				<ng-template matStepLabel>
					<div class="mat-step-custom-click" [matTooltip]="getInfo(caseStages.OPEN)">
						{{ t('platform.cases.dashboard.caseStatusElement.open') }}
					</div></ng-template
				>
			</mat-step>
			<mat-step [editable]="false" [completed]="stepper.selectedIndex > 1">
				<ng-template matStepLabel>
					<div class="mat-step-custom-click" [matTooltip]="getInfo(caseStages.INPROGRESS)">
						{{ t('platform.cases.dashboard.caseStatusElement.inprogress') }}
					</div></ng-template
				>
			</mat-step>
			<mat-step [editable]="false" [completed]="stepper.selectedIndex > 2">
				<ng-template matStepLabel
					><div class="mat-step-custom-click" [matTooltip]="getInfo(caseStages.APPROVAL)">
						{{ t('platform.cases.dashboard.caseStatusElement.approval') }}
					</div></ng-template
				>
			</mat-step>
			<mat-step [editable]="false">
				<ng-template matStepLabel
					><div class="mat-step-custom-click" [matTooltip]="getInfo(caseStages.APPROVED)">
						{{
							stepper.selectedIndex == 3
								? getTranslatedStageValue(case.stage)
								: t('platform.cases.dashboard.caseStatusElement.decision')
						}}
					</div></ng-template
				>
			</mat-step>
		</mat-stepper>
	</div>
</ng-container>
