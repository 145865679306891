import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
	TRANSLOCO_CONFIG,
	TRANSLOCO_LOADER,
	Translation,
	TranslocoModule,
	TranslocoService,
	getBrowserCultureLang,
	translocoConfig,
} from '@jsverse/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
/** Models */
import { environment } from 'environments/environment';
import { LanguageUtils } from './language.utils';

@NgModule({
	exports: [TranslocoModule],
	providers: [
		{
			// Provide the default Transloco configuration
			provide: TRANSLOCO_CONFIG,
			useValue: translocoConfig({
				availableLangs: LanguageUtils.getAvailableLanguages(),
				defaultLang: 'en-US',
				fallbackLang: 'en-US',
				reRenderOnLangChange: true,
				prodMode: environment.production,
				missingHandler: {
					useFallbackTranslation: true,
					logMissingKey: true,
				},
				failedRetries: 1,
			}),
		},
		{
			// Provide the default Transloco loader
			provide: TRANSLOCO_LOADER,
			useClass: TranslocoHttpLoader,
		},
		{
			// Preload the default language before the app starts to prevent empty/jumping content
			provide: APP_INITIALIZER,
			deps: [TranslocoService],
			useFactory:
				(translocoService: TranslocoService): any =>
				(): Promise<Translation> => {
					// Get the browser language
					const browserLang = getBrowserCultureLang();
					// Default language
					let defaultLang: string = '';
					if (LanguageUtils.getAvailableLanguages().some((lang) => lang.id === browserLang)) {
						defaultLang = browserLang;
					} else {
						defaultLang = translocoService.getDefaultLang();
					}
					translocoService.setActiveLang(defaultLang);
					return translocoService.load(defaultLang).toPromise();
				},
			multi: true,
		},
	],
})
export class TranslocoCoreModule {}
