import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { UserBackendService } from 'app/core/backend/user-backend.service';
@Injectable()
export class UserSettingsService {
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _userData: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

	constructor(
		private _authService: AuthService,
		private _userBackendService: UserBackendService,
		private _functions: Functions
	) {
		this._functions.region = 'europe-west1';
		this._getUsers();
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	createInvite(displayName: string, email: string, password: string, providerId: string) {
		let inviteUser = httpsCallable(this._functions, 'invite');

		return inviteUser({
			accountId: this._authService.accountId,
			displayName: displayName,
			email: email,
			password: password,
			providerId: providerId,
			createdBy: this._authService.userId,
		});
	}

	deleteUser(userData: User) {
		let deleteUserBackend = httpsCallable(this._functions, 'deleteUser');

		return deleteUserBackend({
			userData: userData,
			accountId: this._authService.accountId,
			currentUserId: this._authService.userId,
		});
	}

	storeUserRoles(userId: string, role: string) {
		let updateUserRole = httpsCallable(this._functions, 'updateUserRole');

		return updateUserRole({
			id: userId,
			role: role,
			accountId: this._authService.accountId,
			currentUserId: this._authService.userId,
		});
	}

	setUserStatus(userData: User): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			let setUserStatusUI = httpsCallable(this._functions, 'setUserStatusUI');

			setUserStatusUI({
				userData: userData,
				accountId: this._authService.accountId,
				currentUserId: this._authService.userId,
			})
				.then(() => {
					this._getUsers();
					resolve();
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	reloadUsers(): void {
		this._getUsers();
	}

	/**
	 * Transfer ownership of user
	 * @param oldUserId
	 * @param newUserId
	 * @returns
	 */
	transferOwnership(oldUserId: string, newUserId: string) {
		let transferOwnership = httpsCallable(this._functions, 'transferOwnership');

		return transferOwnership({
			oldUserId: oldUserId,
			newUserId: newUserId,
		});
	}

	/**
	 * Update user language
	 * @param userId
	 * @param langCode
	 * @returns
	 */
	updateUserLanguage(userId: string, langCode: string) {
		let updateUserLanguage = httpsCallable(this._functions, 'updateUserLanguage');
		return updateUserLanguage({
			id: userId,
			language: langCode,
			accountId: this._authService.accountId,
			displayName: this._authService.displayName,
		});
	}

	// -------------------------------------------------------------------------
	// @ Private functions
	// -------------------------------------------------------------------------

	private _getUsers() {
		this._userBackendService.getUsers().then((users: User[]) => {
			this._userData.next(users);
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	get userData$(): Observable<User[]> {
		return this._userData.asObservable();
	}
}
