import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApprovalService } from 'app/core/platform-data/approvals.service';
import { Approval } from 'app/shared/types/approval';

@Component({
	selector: 'request-changes-list',
	templateUrl: './request-changes-list.component.html',
	styleUrls: ['./request-changes-list.component.scss'],
})
export class RequestChangesList implements OnInit {
	@Input() approval: Approval;

	private _requestChangesForm: UntypedFormGroup;
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _approvalService: ApprovalService
	) {}

	ngOnInit(): void {
		if (!this.approval || !this.approval.requestedChanges) return;
		const requestChanges = this.approval.requestedChanges;
		const formGrp = {};
		for (let i = 0; i < requestChanges.length; i++) {
			formGrp[i] = requestChanges[i].done;
		}
		this._requestChangesForm = this._formBuilder.group(formGrp);
		this._requestChangesForm.valueChanges.subscribe((value) => {
			this.approval.requestedChanges = Object.keys(value).map((key) => {
				return {
					...requestChanges[key],
					done: value[key],
				};
			});
			this._approvalService.updateApproval(this.approval.id, this.approval);
		});
	}

	/**
	 *
	 * @type {FormGroup}
	 */
	get requestChangesForm(): FormGroup {
		return this._requestChangesForm;
	}
}
