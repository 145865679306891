import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CasesModule } from '../components/cases/cases.module';
import { DashboardModule } from '../components/dashboard/dashboard.module';
import { EntitiesModule } from '../components/entities/entities.module';
import { ApprovalModule } from './approval/approval.module';
import { AuditModule } from './audit/audit.module';
import { DocumentsModule } from './documents/documents.module';
import { FilesModule } from './files/files.module';
import { SearchModule } from './search/search.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, DashboardModule, EntitiesModule, CasesModule, DocumentsModule, SearchModule, AuditModule, ApprovalModule, FilesModule],
	exports: [DashboardModule, EntitiesModule, CasesModule, DocumentsModule, SearchModule, AuditModule, ApprovalModule, FilesModule],
})
export class ComponentsModule {}
