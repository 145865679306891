/* eslint-disable no-mixed-spaces-and-tabs */
import { AffectedCategory } from '../enums/affectedCategories';
import { Entity } from '../enums/entity';
import { Address } from './address';
import { GenericFormMap } from './genericForm';
import { NegativeNewsResults } from './negativeNewsResults';
import { SanctionListResults } from './sanctionListResults';

export interface BaseEntity {
	type: Entity;
	id: string | null;
	emails?:
		| {
				email: string;
				label: string;
		  }[]
		| null;
	phoneNumbers?:
		| {
				country: string;
				phoneNumber: string;
				label: string;
		  }[]
		| null;
	addresses?: Address[] | null;
	parent: string;
	owner: string;
	externalId?: string | null;
	tags: string[];
	notes?: string | null;
	latestSanctionListResult: { [key: string]: SanctionListResults } | null;
	latestNewsCheckResult: NegativeNewsResults | null;
	customData: { [key: string]: string | string[] };
	created: Date;
	createdBy: string;
	edited?: Date | null;
	editedBy?: string | null;
	continousCheck?: boolean;
}

export interface IContact extends BaseEntity {
	firstName: string;
	lastName: string;
	title?: string | null;
	company?: string | null;
	dateOfBirth?: Date | null;
	gender?: string | null;
	pep?: boolean;
}

export interface ICompany extends BaseEntity {
	name: string;
}

export class Contact implements Required<IContact> {
	type: Entity;
	firstName: string;
	lastName: string;
	title: string | null;
	company: string | null;
	dateOfBirth: Date | null;
	id: string | null;
	emails: { email: string; label: string }[] | null;
	phoneNumbers: { country: string; phoneNumber: string; label: string }[] | null;
	addresses: Address[] | null;
	parent: string;
	externalId: string | null;
	tags: string[];
	notes: string | null;
	latestSanctionListResult: { [key: string]: SanctionListResults } | null;
	latestNewsCheckResult: NegativeNewsResults | null;
	owner: string;
	customData: { [key: string]: string | string[] };
	created: Date;
	createdBy: string;
	edited: Date | null;
	editedBy: string | null;
	continousCheck: boolean;
	gender: string | null;
	pep: boolean;
	displayName: string;

	constructor(entity: IContact) {
		this.type = Entity.CONTACT;
		this.id = entity.id || null;
		this.firstName = entity.firstName;
		this.lastName = entity.lastName;
		this.title = entity.title || null;
		this.company = entity.company || null;
		this.dateOfBirth = entity.dateOfBirth || null;
		this.emails = entity.emails || null;
		this.phoneNumbers = entity.phoneNumbers || null;
		this.addresses = entity.addresses || null;
		this.parent = entity.parent;
		this.externalId = entity.externalId || null;
		this.tags = entity.tags || null;
		this.notes = entity.notes || null;
		this.latestSanctionListResult = entity.latestSanctionListResult || null;
		this.latestNewsCheckResult = entity.latestNewsCheckResult || null;
		this.owner = entity.owner;
		this.customData = entity.customData || {};
		this.created = entity.created;
		this.createdBy = entity.createdBy;
		this.edited = entity.edited || null;
		this.editedBy = entity.editedBy || null;
		this.continousCheck = entity.continousCheck || false;
		this.gender = entity.gender || null;
		this.pep = entity.pep || false;
		this.displayName = `${entity.lastName}, ${entity.firstName}`;
	}
}

export const mappableContactFields: GenericFormMap[] = [
	{
		internalReference: 'firstName',
		title: 'First Name',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'lastName',
		title: 'Last Name',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'name',
		title: 'Name',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'gender',
		title: 'Gender',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'title',
		title: 'Title',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'company',
		title: 'Company',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'dateOfBirth',
		title: 'Date of Birth',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'emails',
		title: 'Emails',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'phoneNumbers',
		title: 'Phone Numbers',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.line1',
		title: 'Address Line 1',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.line2',
		title: 'Address Line 2',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.city',
		title: 'Address City',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.country',
		title: 'Address Country',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.zip_code',
		title: 'Address Zip Code',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.state',
		title: 'Address State',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'notes',
		title: 'Notes',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'pep',
		title: 'Politically Exposed Person',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
];

export class Company implements Required<ICompany> {
	name: string;
	type: Entity;
	id: string | null;
	emails: { email: string; label: string }[] | null;
	phoneNumbers: { country: string; phoneNumber: string; label: string }[] | null;
	addresses: Address[] | null;
	parent: string;
	externalId: string | null;
	tags: string[];
	notes: string | null;
	latestSanctionListResult: { [key: string]: SanctionListResults } | null;
	latestNewsCheckResult: NegativeNewsResults | null;
	owner: string;
	customData: { [key: string]: string | string[] };
	created: Date;
	createdBy: string;
	edited: Date | null;
	editedBy: string | null;
	continousCheck: boolean;
	displayName: string;

	constructor(entity: ICompany) {
		this.type = Entity.COMPANY;
		this.id = entity.id || null;
		this.name = entity.name;
		this.emails = entity.emails || null;
		this.phoneNumbers = entity.phoneNumbers || null;
		this.addresses = entity.addresses || null;
		this.parent = entity.parent;
		this.externalId = entity.externalId || null;
		this.tags = entity.tags || null;
		this.notes = entity.notes || null;
		this.latestSanctionListResult = entity.latestSanctionListResult || null;
		this.latestNewsCheckResult = entity.latestNewsCheckResult || null;
		this.owner = entity.owner;
		this.customData = entity.customData || {};
		this.created = entity.created;
		this.createdBy = entity.createdBy;
		this.edited = entity.edited || null;
		this.editedBy = entity.editedBy || null;
		this.continousCheck = entity.continousCheck || false;
		this.displayName = entity.name;
	}
}

export const mappableCompanyFields: GenericFormMap[] = [
	{
		internalReference: 'name',
		title: 'Name',
		type: 'static',
		affectedCategory: AffectedCategory.COMPANY,
	},
	{
		internalReference: 'emails',
		title: 'Emails',
		type: 'static',
		affectedCategory: AffectedCategory.COMPANY,
	},
	{
		internalReference: 'phoneNumber',
		title: 'Phone Numbers',
		type: 'static',
		affectedCategory: AffectedCategory.COMPANY,
	},
	{
		internalReference: 'addresses.line1',
		title: 'Address Line 1',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.line2',
		title: 'Address Line 2',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.city',
		title: 'Address City',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.country',
		title: 'Address Country',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.zip_code',
		title: 'Address Zip Code',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'addresses.state',
		title: 'Address State',
		type: 'static',
		affectedCategory: AffectedCategory.CONTACT,
	},
	{
		internalReference: 'notes',
		title: 'Notes',
		type: 'static',
		affectedCategory: AffectedCategory.COMPANY,
	},
];
