<div>
	<form [formGroup]="searchForm">
		<mat-form-field class="w-full" [appearance]="'fill'" id="searchEntityField">
			<mat-label *ngIf="label">{{ label }}</mat-label>
			<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
			<input
				#searchInput
				matInput
				[matAutocomplete]="auto"
				[placeholder]="placeholder"
				(keyup)="handleChange($event)"
				[value]="presetValue"
				[formControlName]="'searchedValue'"
				(blur)="isBlurEventCheck && onBlurEvent($event)"
			/>
			<mat-error id="companyAlreadyExistsError" *ngIf="searchForm.get('searchedValue').hasError('customError')">{{ errorMessage }}</mat-error>
		</mat-form-field>
	</form>
	<mat-autocomplete #auto="matAutocomplete" [disableRipple]="true" class="max-h-max mx-2 rounded border-t rounded-b shadow-md">
		<ng-container *ngIf="showResults || showPreview">
			<div *ngFor="let index of state.indices || []">
				<mat-optgroup *ngIf="state.indices.length > 1 && index.indexName && index.hits.length > 0">{{
					index.indexName | titlecase
				}}</mat-optgroup>
				<ng-container *ngFor="let option of index.hits">
					<div *ngIf="option.type">
						<mat-option
							class="group mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
							*ngIf="option.type"
							[value]="option.type == entityType.CONTACT ? option.firstName + ' ' + option.lastName : option.name"
							(click)="
								onQuerySuggestionClick.emit({
									query: option.type == entityType.CONTACT ? option.firstName + option.lastName : option.name,
									object: option,
								})
							"
						>
							<ng-container
								*ngTemplateOutlet="
									entityResult;
									context: {
										$implicit: option,
									}
								"
							></ng-container>
						</mat-option>
					</div>
					<div *ngIf="option.title">
						<mat-option
							class="group mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
							*ngIf="!option.type"
							[value]="option.title"
							(click)="
								onQuerySuggestionClick.emit({
									query: option.title,
									object: option,
								})
							"
						>
							<ng-container
								*ngTemplateOutlet="
									caseResult;
									context: {
										$implicit: option,
									}
								"
							></ng-container>
						</mat-option>
					</div>
				</ng-container>
			</div>
		</ng-container>
	</mat-autocomplete>
</div>
<!-- Entity template -->
<ng-template #entityResult let-result>
	<div class="flex items-center">
		<div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
			<mat-icon
				*ngIf="result.type == entityType.COMPANY"
				class="m-0 icon-size-5 text-primary dark:text-primary-400"
				[svgIcon]="'heroicons_solid:building-office'"
			></mat-icon>
			<mat-icon
				*ngIf="result.type == entityType.CONTACT"
				class="m-0 icon-size-5 text-primary dark:text-primary-400"
				[svgIcon]="'heroicons_solid:user'"
			></mat-icon>
		</div>
		<div *ngIf="result.type == entityType.COMPANY" class="ml-3 truncate">
			<span>{{ result.name }}</span>
		</div>
		<div *ngIf="result.type == entityType.CONTACT" class="ml-3 truncate">
			<span>{{ result.firstName }} {{ result.lastName }}</span>
		</div>
	</div>
</ng-template>
<!-- Entity template -->
<ng-template #caseResult let-result>
	<div class="flex items-center">
		<div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
			<mat-icon class="m-0 icon-size-5 text-primary dark:text-primary-400" [svgIcon]="'heroicons_outline:briefcase'"></mat-icon>
		</div>
		<div class="ml-3 truncate">
			<span>{{ result.title }}</span>
		</div>
	</div>
</ng-template>
