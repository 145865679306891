import { Injectable } from '@angular/core';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';

// types & enums
import { Collection } from 'app/shared/enums/collection';
import { Template } from 'app/shared/enums/templates';

// enums
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable()
export class ReportsService {
	private _template: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(
		private _dataService: DataService,
		private _authService: AuthService
	) {}

	/**
	 * Get html template for id
	 * @param { string } templateId
	 * @return { void }
	 */
	getHTMLTemplateWithId(templateId: string): void {
		this._dataService.loadData(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.TEMPLATES}`, templateId).then((data) => {
			this._template.next(data);
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * Get custom fields
	 * @type { Observable<Template> }
	 */
	get template$(): Observable<Template> {
		return this._template.asObservable();
	}
}
