import { Injectable } from '@angular/core';
import {
	ListResult,
	Storage,
	UploadResult,
	UploadTask,
	deleteObject,
	getDownloadURL,
	listAll,
	ref,
	uploadBytes,
	uploadBytesResumable,
} from '@angular/fire/storage';

@Injectable({
	providedIn: 'root',
})
export class FirebaseStorageService {
	constructor(private _storage: Storage) {}

	/**
	 * Uploads file to given path in storage
	 * @param {string} path where file should be stored
	 * @param {File} file file to upload
	 * @param {{ [key: string]: string }} metaData
	 * @return {Promise<UploadResult>} with UploadResult
	 */
	uploadFile(path: string, file: File, metaData: { [key: string]: string }): Promise<UploadResult> {
		return uploadBytes(ref(this._storage, `${path}/${file.name}`), file, { customMetadata: metaData });
	}
	/** Creates a resumable uploadtask for the given file
	 * @param {string} path where file should be stored
	 * @param {File} file file to upload
	 * @param {{ [key: string]: string }} metaData for the file
	 * @return {UploadTask}
	 */
	uploadFileResumable(path: string, file: File, metaData: { [key: string]: string }): UploadTask {
		return uploadBytesResumable(ref(this._storage, `${path}/${file.name}`), file, {
			customMetadata: metaData,
		});
	}
	/**
	 * Gets all files from the path
	 * @param {string} path
	 * @return {Promise} with the list of files
	 */
	getFiles(path: string): Promise<ListResult> {
		return listAll(ref(this._storage, path));
	}
	/**
	 * Returns the download URL for the given path
	 * @param {string} path
	 * @return {Promise<string>} with the url string
	 */
	getUrl(path: string): Promise<string> {
		return getDownloadURL(ref(this._storage, path));
	}
	/**
	 * Deletes the file/folder at the given path
	 * @param {string} path
	 * @return {Promise<void>}
	 */
	deleteFile(path: string): Promise<void> {
		return deleteObject(ref(this._storage, path));
	}
}
