import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { FuseHorizontalNavigationComponent } from '@fuse/components/navigation/horizontal/horizontal.component';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'fuse-horizontal-navigation-branch-item',
	templateUrl: './branch.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	// standalone: true,
	// imports: [
	// 	NgClass,
	// 	MatMenuModule,
	// 	NgTemplateOutlet,
	// 	FuseHorizontalNavigationBasicItemComponent,
	// 	forwardRef(() => FuseHorizontalNavigationBranchItemComponent),
	// 	FuseHorizontalNavigationDividerItemComponent,
	// 	MatTooltipModule,
	// 	MatIconModule,
	// ],
})
export class FuseHorizontalNavigationBranchItemComponent implements OnInit, OnDestroy {
	/* eslint-disable @typescript-eslint/naming-convention */
	static ngAcceptInputType_child: BooleanInput;
	/* eslint-enable @typescript-eslint/naming-convention */

	private _changeDetectorRef = inject(ChangeDetectorRef);
	private _fuseNavigationService = inject(FuseNavigationService);

	@Input() child: boolean = false;
	@Input() item: FuseNavigationItem;
	@Input() name: string;
	@ViewChild('matMenu', { static: true }) matMenu: MatMenu;

	private _fuseHorizontalNavigationComponent: FuseHorizontalNavigationComponent;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Get the parent navigation component
		this._fuseHorizontalNavigationComponent = this._fuseNavigationService.getComponent(this.name);

		// Subscribe to onRefreshed on the navigation component
		this._fuseHorizontalNavigationComponent.onRefreshed.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			// Mark for check
			this._changeDetectorRef.markForCheck();
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Trigger the change detection
	 */
	triggerChangeDetection(): void {
		// Mark for check
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}
}
