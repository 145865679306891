import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { provideFuse } from '@fuse';
import { provideTransloco } from '@jsverse/transloco';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from './core/config/app.config';
import { LanguageUtils } from './core/transloco/language.utils';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { LayoutModule } from './layout/layout.module';

import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { environment } from '../environments/environment';
// firebase
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { connectFirestoreEmulator, enableIndexedDbPersistence, getFirestore, provideFirestore } from '@angular/fire/firestore';
import { connectFunctionsEmulator, FunctionsModule, getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';

// Algolia

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		// Core module of your application
		CoreModule,

		// Layout module of your application
		LayoutModule,

		FunctionsModule,
		// necessary Material imports
		MatNativeDateModule,
		MatDatepickerModule,
	],
	bootstrap: [AppComponent],
	providers: [
		// 3rd party modules that require global configuration via forRoot
		provideFirebaseApp(() => initializeApp(environment.firebase)),
		provideAuth(() => {
			const auth = getAuth();
			if (environment.useEmulators) {
				connectAuthEmulator(auth, 'http://localhost:9099');
			}
			return auth;
		}),
		provideFirestore(() => {
			const firestore = getFirestore();
			if (environment.useEmulators) {
				connectFirestoreEmulator(firestore, 'localhost', 8080);
			}
			enableIndexedDbPersistence(firestore);
			return firestore;
		}),
		provideFunctions(() => {
			const functions = getFunctions();
			if (environment.useEmulators) {
				connectFunctionsEmulator(functions, 'localhost', 5001);
			}
			return functions;
		}),
		provideStorage(() => {
			const storage = getStorage();
			if (environment.useEmulators) {
				connectStorageEmulator(storage, 'localhost', 9199);
			}
			return storage;
		}),
		provideFuse({ fuse: appConfig }),
		provideTransloco({
			config: {
				availableLangs: LanguageUtils.getAvailableLanguages(),
				defaultLang: 'en-US',
				fallbackLang: 'en-US',
				reRenderOnLangChange: true,
				prodMode: environment.production,
				missingHandler: {
					useFallbackTranslation: true,
					logMissingKey: true,
				},
				failedRetries: 1,
			},
			loader: TranslocoHttpLoader,
		}),
	],
})
export class AppModule {}
