<ng-container *transloco="let t">
	<div *ngIf="riskAssessmentData" class="flex flex-col bg-white rounded-2xl p-4 overflow-x-scroll">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center h-10">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.cases.caseDetails.riskAssessmentDetails.riskAssessmentDetails') }}
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<table class="w-full" mat-table [dataSource]="riskAssessmentData" [trackBy]="trackByFn" #recentTransactionsTable>
				<!-- Name -->
				<ng-container matColumnDef="name">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.caseDetails.riskAssessmentDetails.name') }}
					</th>
					<td mat-cell *matCellDef="let transaction">
						{{ transaction.title }}
					</td>
				</ng-container>
				<!-- Value -->
				<ng-container matColumnDef="value">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.caseDetails.riskAssessmentDetails.value') }}
					</th>
					<td mat-cell *matCellDef="let transaction">
						{{ transaction.value }}
					</td>
				</ng-container>
				<!-- Value -->
				<ng-container matColumnDef="verifiedValue">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.caseDetails.riskAssessmentDetails.verifiedValue') }}
					</th>
					<td mat-cell *matCellDef="let transaction">
						{{ transaction.verifiedValue }}
					</td>
				</ng-container>
				<!-- Weighting -->
				<ng-container matColumnDef="weighting">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.caseDetails.riskAssessmentDetails.weighting') }}
					</th>
					<td mat-cell *matCellDef="let transaction">
						{{ transaction.weighting }}
					</td>
				</ng-container>
				<!-- Risk Score -->
				<ng-container matColumnDef="riskScore">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.caseDetails.riskAssessmentDetails.riskScore') }}
					</th>
					<td mat-cell *matCellDef="let transaction">
						{{ (transaction.riskScoreVerified ? transaction.riskScoreVerified : transaction.riskScore) | number }}
					</td>
				</ng-container>
				<!-- Notifications -->
				<ng-container matColumnDef="notifications">
					<th mat-header-cell mat-sort-header *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let transaction">
						<mat-icon
							*ngIf="!transaction.riskScoreVerified && transaction.riskScore"
							class="mr-1 icon-size-5 text-red-500"
							[svgIcon]="'heroicons_solid:exclamation-triangle'"
							matTooltip="{{ t('platform.cases.caseDetails.riskAssessmentDetails.asThereIsNoVerifiedValue') }}"
						></mat-icon>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="riskAssessmentColumns; sticky: true"></tr>
				<tr mat-row *matRowDef="let row; columns: riskAssessmentColumns"></tr>
			</table>
		</div>
	</div>
</ng-container>
