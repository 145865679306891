import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// material
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

// Transloco module
import { TranslocoModule } from '@jsverse/transloco';

// components
import { FileUploadComponent } from './file-upload/file-upload.component';

@NgModule({
	declarations: [FileUploadComponent],
	imports: [CommonModule, MatProgressSpinnerModule, MatTooltipModule, MatIconModule, TranslocoModule, MatProgressBarModule],
	exports: [FileUploadComponent],
})
export class FilesModule {}
