import { Injectable } from '@angular/core';
// services
import { FirestoreService } from './firestore/firestore.service';
// enums
import { Database } from 'app/shared/enums/database';
// types
import { DocumentReference, DocumentSnapshot, OrderByDirection } from '@angular/fire/firestore';
import { Collection } from 'app/shared/enums/collection';
import { QueryCondition } from 'app/shared/types/query-condition';
import { TrashBinElement } from 'app/shared/types/trash-bin-element';

@Injectable({
	providedIn: 'root',
})
//This service could be used to implement multiple backends, for now, only Firestore is supported
export class DataService {
	private _backend: Database;

	constructor(private _firestoreService: FirestoreService) {
		// TODO: Load database from settings, for now, Firestore is fixed
		this._backend = Database.firestore;
	}

	storeDocument(data: any, container: Collection | string, id?: string): Promise<DocumentReference<any> | void> {
		let ret: Promise<DocumentReference<any> | void>;

		// add multiple cases here for the database selected by user
		switch (this._backend) {
			case Database.firestore:
				ret = this._firestoreService.storeDocument(data, container, id);
				break;
			default:
				ret = Promise.reject(new Error('Database not supported'));
				break;
		}

		return ret;
	}

	storeDocuments(data: any[], container: Collection | string) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.storeDocuments(container, data);
		} else throw new Error('Database not supported');
	}

	// TODO: extend return types for other databases
	loadData(path: string, id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			switch (this._backend) {
				case Database.firestore:
					this._firestoreService
						.getDocument(path, id)
						.then((data) => {
							resolve({ ...data.data(), id: data.id });
						})
						.catch((err) => {
							reject(err);
						});
					break;
				default:
					reject(new Error('Database not supported'));
					break;
			}
		});
	}

	loadDataObservable(path: string, id: string) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.getDocumentObserverable(path, id);
		} else throw new Error('Database not supported');
	}

	loadAllEntriesObservable(path, includeParentId?: boolean) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.loadCollectionDataObservable(path, includeParentId);
		} else throw new Error('Database not supported');
	}

	loadAllEntries(path) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.loadCollectionData(path);
		} else throw new Error('Database not supported');
	}

	queryData(path: string, conditions: QueryCondition[], orders?: OrderClause[], resultLimit?: number, resultStartAfter?: number): Promise<any[]> {
		const ret: any[] = [];

		return new Promise((resolve, reject) => {
			this._firestoreService
				.getDocuments(path, conditions, orders, resultLimit, resultStartAfter)
				.then((results) => {
					results.forEach((result) => {
						ret.push({ ...result.data(), id: result.id });
					});

					resolve(ret);
				})
				.catch((err) => reject(err));
		});
	}

	queryDataObservable(
		path: string,
		conditions: QueryCondition[],
		orders?: OrderClause[],
		resultLimit?: number,
		resultStartAfter?: DocumentSnapshot
	) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.queryChanges(path, conditions, orders, resultLimit, resultStartAfter);
		} else throw new Error('Database not supported');
	}

	updateDocument(data: any, path: string, id: string): Promise<void> {
		// add modified data & user

		if (this._backend === Database.firestore) {
			return this._firestoreService.updateDocument(data, path, id);
		} else throw new Error('Database not supported');
	}

	updateDocuments(data: any[], path: string): Promise<void> {
		if (this._backend === Database.firestore) {
			return this._firestoreService.updateDocuments(path, data);
		} else throw new Error('Database not supported');
	}

	writeMultipleDocuments(data: [any, string][]): Promise<void> {
		if (this._backend === Database.firestore) {
			return this._firestoreService.writeMultipleDocuments(data);
		} else throw new Error('Database not supported');
	}

	deleteData(path: string, id: string) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.deleteDocument(path, id);
		} else throw new Error('Database not supported');
	}

	deleteDocuments(path: string, ids: string[]) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.deleteDocuments(path, ids);
		} else throw new Error('Database not supported');
	}

	moveToTrash(element: TrashBinElement, accountId: string) {
		return new Promise<void>((resolve, reject) => {
			if (this._backend === Database.firestore) {
				this._firestoreService
					.storeDocument(element, `${Collection.ACCOUNTS}/${accountId}/${Collection.TRASHBIN}`)
					.then(() => {
						this._firestoreService
							.deleteDocument(element.origin, element.object.id)
							.then(() => {
								resolve();
							})
							.catch((err) => {
								reject(err);
							});
					})
					.catch((err) => {
						reject(err);
					});
			} else throw new Error('Database not supported');
		});
	}

	documentExists(path: string, id: string) {
		if (this._backend === Database.firestore) {
			return this._firestoreService.documentExists(path, id);
		} else throw new Error('Database not supported');
	}

	getFirestoreRef(collection: Collection | string, id: string) {
		return this._firestoreService.getDocRef(collection, id);
	}

	getFirestoreRefByPath(path: string) {
		return this._firestoreService.getDocRefByPath(path);
	}

	loadDocumentSnapShot(path: string, id: string): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			switch (this._backend) {
				case Database.firestore:
					this._firestoreService
						.getDocument(path, id)
						.then((data) => {
							resolve(data);
						})
						.catch((err) => {
							reject(err);
						});
					break;
				default:
					reject(new Error('Database not supported'));
					break;
			}
		});
	}

	async deleteCollection(path: string): Promise<void> {
		const data = await this.loadAllEntries(path);
		const ids = data.docs.map((doc) => {
			return doc.id;
		});
		return this.deleteDocuments(path, ids);
	}

	/**
	 * Returns the document at the specified path
	 *
	 * @param {string} path
	 * @return {Promise<any>}
	 */
	async loadDataByPath(path: string): Promise<any> {
		switch (this._backend) {
			case Database.firestore:
				// eslint-disable-next-line no-case-declarations
				const data = await this._firestoreService.getDocumentByPath(path);
				return { ...data.data(), id: data.id };
			default:
				throw new Error('Database not supported');
		}
	}
}

export interface OrderClause {
	field: string;
	direction: OrderByDirection;
}
