import { Injectable } from '@angular/core';

import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	DocumentData,
	documentId,
	DocumentSnapshot,
	Firestore,
	getDoc,
	getDocs,
	limit,
	onSnapshot,
	orderBy,
	OrderByDirection,
	query,
	QueryConstraint,
	setDoc,
	startAfter,
	updateDoc,
	where,
	writeBatch,
} from '@angular/fire/firestore';
import { QueryCondition } from 'app/shared/types/query-condition';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { Observable } from 'rxjs';
import { v4 } from 'uuid';

@Injectable({
	providedIn: 'root',
})
export class FirestoreService {
	constructor(private _firestore: Firestore) {}

	storeDocument(data: any, path: string, id?: string) {
		if (id) {
			return setDoc(doc(this._firestore, `${path}/${id}`), data);
		} else {
			return addDoc(collection(this._firestore, path), data);
		}
	}

	storeDocuments(path: string, data: any[]) {
		let batch = writeBatch(this._firestore);

		data.forEach((item) => {
			batch.set(doc(collection(this._firestore, path)), item);
		});

		return batch.commit();
	}

	updateDocument(data: any, path: string, id: string) {
		return updateDoc(doc(this._firestore, `${path}/${id}`), data);
	}

	updateDocuments(path: string, data: any[]) {
		let batch = writeBatch(this._firestore);

		data.forEach((item) => {
			batch.update(doc(this._firestore, `${path}/${item.id}`), item);
		});

		return batch.commit();
	}

	async writeMultipleDocuments(data: [any, string][]) {
		const batch = writeBatch(this._firestore);
		for (const [item, path] of data) {
			const id = item.id ?? v4();
			const ref = doc(this._firestore, `${path}/${id}`);
			const exists = (await getDoc(ref)).exists();
			if (exists) batch.update(ref, item);
			else batch.set(ref, item);
		}
		return batch.commit();
	}

	deleteDocument(path: string, id: string): Promise<void> {
		return deleteDoc(doc(this._firestore, `${path}/${id}`));
	}

	getDocument(path: string, id: string) {
		return getDoc(doc(this._firestore, `${path}/${id}`));
	}

	/**
	 * Returns the document at the specified path
	 *
	 * @param {string} path
	 * @return {Promise<DocumentSnapshot<DocumentData>>}
	 */
	getDocumentByPath(path: string): Promise<DocumentSnapshot<DocumentData>> {
		return getDoc(doc(this._firestore, path));
	}

	getDocumentObserverable(path: string, id: string) {
		return new Observable<DataObserver>((observer) => {
			let unsubscribe = onSnapshot(doc(this._firestore, `${path}/${id}`), (querySnapshot) => {
				if (!querySnapshot.exists()) {
					observer.next({ unsubscribe: unsubscribe });
					return;
				}
				observer.next({
					unsubscribe: unsubscribe,
					data: {
						...querySnapshot.data(),
						id: querySnapshot.id,
					},
				});
			});
		});
	}

	getDocuments(
		path: string,
		conditions: QueryCondition[],
		order?: {
			field: string;
			direction: OrderByDirection;
		}[],
		resultLimit?: number,
		resultStartAfter?: number
	) {
		let queryConstraints: QueryConstraint[] = [];

		conditions.forEach((condition) => {
			if (condition.field === 'id') {
				queryConstraints.push(where(documentId(), condition.operator, condition.value));
			} else {
				queryConstraints.push(where(condition.field, condition.operator, condition.value));
			}
		});

		if (order) {
			order.forEach((order) => {
				queryConstraints.push(orderBy(order.field, order.direction));
			});
		}

		if (resultLimit) queryConstraints.push(limit(resultLimit));
		if (resultStartAfter) queryConstraints.push(startAfter(resultStartAfter));

		const q = query(collection(this._firestore, path), ...queryConstraints);

		return getDocs(q);
	}

	queryChanges(
		path: string,
		conditions: QueryCondition[],
		order?: {
			field: string;
			direction: OrderByDirection;
		}[],
		resultLimit?: number,
		resultStartAfter?: DocumentSnapshot
	): Observable<DataObserver> {
		let queryConstraints: QueryConstraint[] = [];

		conditions.forEach((condition) => {
			if (condition.field === 'id') {
				queryConstraints.push(where(documentId(), condition.operator, condition.value));
			} else {
				queryConstraints.push(where(condition.field, condition.operator, condition.value));
			}
		});

		if (order) {
			order.forEach((order) => {
				queryConstraints.push(orderBy(order.field, order.direction));
			});
		}

		if (resultLimit) queryConstraints.push(limit(resultLimit));
		if (resultStartAfter) queryConstraints.push(startAfter(resultStartAfter));

		const q = query(collection(this._firestore, path), ...queryConstraints);

		let data;

		return new Observable<DataObserver>((observer) => {
			let unsubscribe = onSnapshot(q, (querySnapshot) => {
				data = [];
				querySnapshot.docs.forEach((doc) => {
					data.push({ ...doc.data(), id: doc.id });
				});

				observer.next({ unsubscribe: unsubscribe, data: data });
			});
		});
	}

	loadCollectionDataObservable(path: string, includeParentId?: boolean) {
		let data;
		if (!includeParentId) includeParentId = false;
		return new Observable<DataObserver>((observer) => {
			let unsubscribe = onSnapshot(collection(this._firestore, path), (querySnapshot) => {
				data = [];
				let tempData;
				querySnapshot.docs.forEach((doc) => {
					tempData = { ...doc.data(), id: doc.id };

					if (includeParentId) tempData['parentId'] = doc.ref.parent.parent.id;

					data.push(tempData);
				});

				observer.next({ unsubscribe: unsubscribe, data: data });
			});
		});
	}

	loadCollectionData(path: string) {
		return getDocs(collection(this._firestore, path));
	}

	documentExists(path: string, id: string): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			getDoc(doc(this._firestore, `${path}/${id}`))
				.then((doc) => {
					resolve(doc.exists());
				})
				.catch(() => {
					resolve(false);
				});
		});
	}

	getDocRef(path: string, id: string) {
		return doc(this._firestore, `${path}/${id}`);
	}

	getDocRefByPath(path: string) {
		return doc(this._firestore, path);
	}

	deleteDocuments(path: string, docIds: string[]) {
		let batch = writeBatch(this._firestore);

		docIds.forEach((id) => {
			batch.delete(doc(this._firestore, `${path}/${id}`));
		});

		return batch.commit();
	}
}
