<div
	class="fuse-vertical-navigation-item-wrapper"
	[class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
	[ngClass]="item.classes?.wrapper"
>
	<div
		class="fuse-vertical-navigation-item"
		[ngClass]="{
			'fuse-vertical-navigation-item-active': active,
			'fuse-vertical-navigation-item-disabled': item.disabled,
			'fuse-vertical-navigation-item-active-forced': item.active,
		}"
		[matTooltip]="item.tooltip || ''"
	>
		<!-- Icon -->
		@if (item.icon) {
			<mat-icon class="fuse-vertical-navigation-item-icon" [ngClass]="item.classes?.icon" [svgIcon]="item.icon"></mat-icon>
		}

		<!-- Title & Subtitle -->
		<div class="fuse-vertical-navigation-item-title-wrapper">
			<div class="fuse-vertical-navigation-item-title">
				<span [ngClass]="item.classes?.title">
					{{ item.title }}
				</span>
			</div>
			@if (item.subtitle) {
				<div class="fuse-vertical-navigation-item-subtitle">
					<span [ngClass]="item.classes?.subtitle">
						{{ item.subtitle }}
					</span>
				</div>
			}
		</div>

		<!-- Badge -->
		@if (item.badge) {
			<div class="fuse-vertical-navigation-item-badge">
				<div class="fuse-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
					{{ item.badge.title }}
				</div>
			</div>
		}
	</div>
</div>

@if (!skipChildren) {
	<div class="fuse-vertical-navigation-item-children">
		@for (item of item.children; track trackByFn($index, item)) {
			<!-- Skip the hidden items -->
			@if ((item.hidden && !item.hidden(item)) || !item.hidden) {
				<!-- Basic -->
				@if (item.type === 'basic') {
					<fuse-vertical-navigation-basic-item [item]="item" [name]="name"></fuse-vertical-navigation-basic-item>
				}

				<!-- Collapsable -->
				@if (item.type === 'collapsable') {
					<fuse-vertical-navigation-collapsable-item
						[item]="item"
						[name]="name"
						[autoCollapse]="autoCollapse"
					></fuse-vertical-navigation-collapsable-item>
				}

				<!-- Divider -->
				@if (item.type === 'divider') {
					<fuse-vertical-navigation-divider-item [item]="item" [name]="name"></fuse-vertical-navigation-divider-item>
				}

				<!-- Group -->
				@if (item.type === 'group') {
					<fuse-vertical-navigation-group-item [item]="item" [name]="name"></fuse-vertical-navigation-group-item>
				}

				<!-- Spacer -->
				@if (item.type === 'spacer') {
					<fuse-vertical-navigation-spacer-item [item]="item" [name]="name"></fuse-vertical-navigation-spacer-item>
				}
			}
		}
	</div>
}
