import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar/loading-bar.module';
import { EmptyLayoutComponent } from 'app/layout/layouts/empty/empty.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
	declarations: [EmptyLayoutComponent],
	imports: [RouterModule, SharedModule, FuseLoadingBarModule],
	exports: [EmptyLayoutComponent],
})
export class EmptyLayoutModule {}
