import { Component, Input, OnInit } from '@angular/core';
import {
	FormArray,
	FormGroup,
	UntypedFormArray,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { countries } from 'app/shared/enums/country-list';
import { Country } from 'app/shared/types/country';
import { Company, Contact } from 'app/shared/types/entityTypes';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';

@Component({
	selector: 'communication-details',
	templateUrl: './communication-details.component.html',
	styleUrls: ['./communication-details.component.scss'],
})
export class CommunicationDetailsComponent implements OnInit {
	@Input() entity: Contact | Company;

	private _editMode: boolean = false;
	private _communicationForm: UntypedFormGroup;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._communicationForm = this._formBuilder.group(
			{
				emails: this._formBuilder.array([], this._duplicateEmailFieldValueValidator()),
				phoneNumbers: this._formBuilder.array([]),
			},
			{ validators: this._requireEmailPhoneNumberValues() }
		);

		// Setup the emails form array
		const emailFormGroups = [];

		if (this.entity.emails && this.entity.emails.length > 0) {
			// Iterate through them
			this.entity.emails.forEach((email) => {
				// Create an email form group
				let formGroup = this._getEmailGroup();
				formGroup.patchValue(email);
				emailFormGroups.push(formGroup);
			});
		} else {
			// Create an email form group
			emailFormGroups.push(this._getEmailGroup());
		}

		// Add the email form groups to the emails form array
		emailFormGroups.forEach((emailFormGroup) => {
			(this._communicationForm.get('emails') as UntypedFormArray).push(emailFormGroup);
		});

		// Setup the phone numbers form array
		const phoneNumbersFormGroups = [];

		if (this.entity.phoneNumbers && this.entity.phoneNumbers.length > 0) {
			// Iterate through them
			this.entity.phoneNumbers.forEach((phoneNumber) => {
				// Create an phone form group
				let formGroup = this._getPhoneGroup();
				formGroup.patchValue(phoneNumber);
				phoneNumbersFormGroups.push(formGroup);
			});
		} else {
			// Create a phone number form group
			phoneNumbersFormGroups.push(this._getPhoneGroup());
		}

		// Add the phone numbers form groups to the phone numbers form array
		phoneNumbersFormGroups.forEach((phoneNumbersFormGroup) => {
			(this._communicationForm.get('phoneNumbers') as UntypedFormArray).push(phoneNumbersFormGroup);
		});
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Get country info by iso code
	 *
	 * @param iso
	 */
	getCountryByIso(iso: string): Country {
		return countries.find((country) => country.iso === iso);
	}

	toggleEditMode() {
		if ((this._communicationForm.get('emails') as UntypedFormArray).length === 0) {
			this.addEmailField();
		}
		if ((this._communicationForm.get('phoneNumbers') as UntypedFormArray).length === 0) {
			this.addPhoneNumberField();
		}
		this._editMode = !this._editMode;
	}

	saveChanges() {
		// Create an object to hold the updated data
		const commData: Partial<Contact | Company> = {
			...this._communicationForm.value, // Copy the form values to the updated data object
			editedBy: this._authService.userId, // Set the 'editedBy' property to the current user ID
			edited: new Date(), // Set the 'edited' property to the current date and time
			emails: this._getTrimmedValuesOfForm(this._communicationForm.value.emails).filter(
				(obj) => !this._hasAllEmptyValues(obj) // Trim and filter the 'emails' values to remove empty objects
			),
			// Trim and filter the 'phoneNumbers' values to remove empty objects and skip objects with empty 'phoneNumber' and 'label'
			phoneNumbers: this._getTrimmedValuesOfForm(this._communicationForm.value.phoneNumbers).filter(
				(obj) => !this._hasAllEmptyValues(obj) && (obj.phoneNumber !== '' || obj.label !== '')
			),
		};

		const updData: Contact | Company = { ...this.entity, ...commData };

		// Update the document using the updated data
		this._entityHelperService.updateEntity(updData, this.entity.id);

		// Set the edit mode to false
		this._editMode = false;
	}

	cancelEdit() {
		this._editMode = false;
		this._communicationForm.patchValue(this.entity);
	}

	/**
	 * Add the email field
	 */
	addEmailField(): void {
		// Add the email form group to the emails form array
		(this._communicationForm.get('emails') as UntypedFormArray).push(this._getEmailGroup());
	}

	/**
	 * Remove the email field
	 *
	 * @param index
	 */
	removeEmailField(index: number): void {
		// Get form array for emails
		const emailsFormArray = this._communicationForm.get('emails') as UntypedFormArray;

		// Remove the email field
		emailsFormArray.removeAt(index);
	}

	/**
	 * Add an empty phone number field
	 */
	addPhoneNumberField(): void {
		// Add the phone number form group to the phoneNumbers form array
		(this._communicationForm.get('phoneNumbers') as UntypedFormArray).push(this._getPhoneGroup());
	}

	/**
	 * Remove the phone number field
	 *
	 * @param index
	 */
	removePhoneNumberField(index: number): void {
		// Get form array for phone numbers
		const phoneNumbersFormArray = this._communicationForm.get('phoneNumbers') as UntypedFormArray;

		// Remove the phone number field
		phoneNumbersFormArray.removeAt(index);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Creates a form group for an email entry.
	 * @return { UntypedFormGroup } The form group for the email entry.
	 */
	private _getEmailGroup(): UntypedFormGroup {
		return this._formBuilder.group({
			email: ['', [Validators.email, Validators.pattern(/^[\w%]+(\.[\w%]+)*@[\w%]+(\.[\w%]+)*(\.\w{2,3})+$/)]],
			label: ['', [Validators.pattern(/^$|^\S+.*/)]],
		});
	}

	/**
	 * Creates a form group for an phone number entry.
	 * @return { UntypedFormGroup } The form group for the phone number entry.
	 */
	private _getPhoneGroup(): UntypedFormGroup {
		return this._formBuilder.group({
			country: ['USA'],
			phoneNumber: ['', [Validators.pattern(/^\d+$/)]],
			label: ['', [Validators.pattern(/^$|^\S+.*/)]],
		});
	}

	/**
	 * Get trimmed values of forms
	 * @param { Array<any[]> } formArray will be emails/phone numbers form array
	 * @return { Array<any[]> } return trimmed values of forms
	 */
	private _getTrimmedValuesOfForm(formArray: any[]): any[] {
		return formArray.map((obj: any) => {
			const trimmedObj: any = {};
			// Iterate over each key in the object
			for (const key in obj) {
				// eslint-disable-next-line no-prototype-builtins
				if (obj.hasOwnProperty(key)) {
					// Check if the value is a string
					if (typeof obj[key] === 'string') {
						// Trim the string value and assign it to the trimmedObj
						trimmedObj[key] = obj[key].trim();
					} else {
						// Assign non-string values as-is
						trimmedObj[key] = obj[key];
					}
				}
			}
			return trimmedObj;
		});
	}

	/**
	 * Duplicate email field value validator
	 * @return { ValidatorFn }
	 */
	private _duplicateEmailFieldValueValidator(): ValidatorFn {
		return (formArray: FormArray): ValidationErrors | null => {
			// Get the value of email field
			const values: string[] = formArray.controls.map((control) => control.get('email').value.toLowerCase()).filter((val) => val !== '');

			// Get the duplicate values
			const duplicates: string[] = values.filter((value, index, self) => self.indexOf(value.toLowerCase()) !== index);

			formArray.controls.forEach((control) => {
				const fieldName = control.get('email');
				if (fieldName.value && duplicates.includes(fieldName.value.toLowerCase())) {
					// Set duplicate value validation
					fieldName.setErrors({ duplicateValue: true });
				} else {
					// Remove duplicate value validation
					fieldName.value &&
						fieldName.errors &&
						fieldName.errors.duplicateValue &&
						!duplicates.includes(fieldName.value.toLowerCase()) &&
						fieldName.setErrors(null);
				}

				// Set the validators for the email field
				fieldName.setValidators([Validators.email, Validators.pattern(/^[\w%]+(\.[\w%]+)*@[\w%]+(\.[\w%]+)*(\.\w{2,3})+$/)]);
			});
			return duplicates.length > 0 ? { duplicateValue: true } : null;
		};
	}

	/**
	 * Custom validator that checks if email, phone number values are required.
	 * @return { ValidatorFn } return ValidationErrors or null
	 */
	private _requireEmailPhoneNumberValues(): ValidatorFn {
		// Custom validator function that checks if email, phone number values are required
		return (formGroup: FormGroup): ValidationErrors | null => {
			// Get the 'emails' FormArray control
			const emails = formGroup.get('emails');

			// Get the 'phoneNumbers' FormArray control
			const phoneNumbers = formGroup.get('phoneNumbers');

			// Check if every field value of the email controls have been filled
			const emailFilled = (emails as FormArray).controls.every((control) => control.value.email !== '');
			//(emails as FormArray).controls.some((control) => control.value.email !== '');

			// Check if every field value of the phone number controls have been filled
			const phoneNumberFilled = (phoneNumbers as FormArray).controls.every((control) => control.value.phoneNumber !== '');

			// If neither email nor phone number is filled, return the validation error otherwise return null if the validation passes
			// return !emailFilled && !phoneNumberFilled ? { requireEitherEmailOrPhoneNumber: true } : null;
			return !emailFilled || !phoneNumberFilled ? { requireEitherEmailOrPhoneNumber: true } : null;
		};
	}

	/**
	 * Checks if all values in an object are empty strings.
	 * @param { Object } obj The object to check.
	 * @return { boolean } True if all values are empty strings, false otherwise.
	 */
	private _hasAllEmptyValues(obj: Record<string, string>): boolean {
		return Object.values(obj).every((value) => value === '');
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get editMode(): boolean {
		return this._editMode;
	}

	get communicationForm(): UntypedFormGroup {
		return this._communicationForm;
	}

	get countryList() {
		return countries;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
	}
}
