import { Injectable } from '@angular/core';
import { Functions, HttpsCallableResult, httpsCallable } from '@angular/fire/functions';
import { AccountService } from 'app/core/account/account.service';
import {
	Actions,
	GraphCreateDataArray,
	GraphCreateRequest,
	GraphDeleteDataArray,
	GraphDeleteRequest,
	GraphInsertDataArray,
	GraphInsertRequest,
	GraphQueryResponse,
	GraphReadDataArray,
	GraphReadRequest,
	GraphUpdateDataArray,
	GraphUpdateRequest,
} from 'app/shared/types/database/graphRequest';

@Injectable({
	providedIn: 'root',
})
export class GraphdbService {
	private _graphQuery = httpsCallable(this._functions, 'graphQuery');

	constructor(
		private _functions: Functions,
		private _accountService: AccountService
	) {
		this._functions.region = 'europe-west1';
	}

	/**
	 * Creates a new model (edge or vertex) in GraphDB
	 * @param {GraphInsertDataArray} data - data that should be inserted into the graph DB
	 * @return {Promise<GraphQueryResponse>}
	 */
	async gCreate(data: GraphCreateDataArray): Promise<GraphQueryResponse> {
		const createReq: GraphCreateRequest = {
			space: this._accountService.graphSpace,
			action: Actions.CREATE,
			data: data,
		};

		const ret = (await this._graphQuery(createReq)) as HttpsCallableResult<GraphQueryResponse>;
		if (!ret.data.query_success) throw JSON.stringify(ret.data);
		return ret.data;
	}

	/**
	 * Creates a new Edge (Relationship Type) in GraphDB
	 * @param {GraphInsertDataArray} data - data that should be inserted into the graph DB
	 * @return {Promise<GraphQueryResponse>}
	 */
	async gInsert(data: GraphInsertDataArray): Promise<GraphQueryResponse> {
		const insertReq: GraphInsertRequest = {
			space: this._accountService.graphSpace,
			action: Actions.INSERT,
			data: data,
		};

		const ret = (await this._graphQuery(insertReq)) as HttpsCallableResult<GraphQueryResponse>;
		if (!ret.data.query_success) throw JSON.stringify(ret.data);
		return ret.data;
	}

	/**
	 * Updates existing Nodes and/or Edges - based on data - in GraphDB
	 * @param {GraphUpdateDataArray} data - data that should be updated in the graph DB
	 * @return {Promise<GraphQueryResponse>}
	 */
	async gUpdate(data: GraphUpdateDataArray): Promise<GraphQueryResponse> {
		const updReq: GraphUpdateRequest = {
			space: this._accountService.graphSpace,
			action: Actions.UPDATE,
			data: data,
		};

		const ret = (await this._graphQuery(updReq)) as HttpsCallableResult<GraphQueryResponse>;
		if (!ret.data.query_success) throw JSON.stringify(ret.data);
		return ret.data;
	}

	/**
	 * Reads Nodes and/or Edges from GraphDB
	 * @param {GraphReadDataArray} data - data that should be read from the graph DB
	 * @return {Promise<GraphQueryResponse>}
	 */
	async gRead(data: GraphReadDataArray): Promise<GraphQueryResponse> {
		const readReq: GraphReadRequest = {
			space: this._accountService.graphSpace,
			action: Actions.READ,
			data: data,
		};

		const ret = (await this._graphQuery(readReq)) as HttpsCallableResult<GraphQueryResponse>;
		if (!ret.data.query_success) throw JSON.stringify(ret.data);
		return ret.data;
	}

	/**
	 * Deletes Nodes and/or Edges from GraphDB
	 * @param {GraphDeleteDataArray} data - data that should be deleted from the graph DB
	 * @return {Promise<GraphQueryResponse>}
	 */
	async gDelete(data: GraphDeleteDataArray) {
		const deleteReq: GraphDeleteRequest = {
			space: this._accountService.graphSpace,
			action: Actions.DELETE,
			data: data,
		};

		const ret = (await this._graphQuery(deleteReq)) as HttpsCallableResult<GraphQueryResponse>;
		if (!ret.data.query_success) throw JSON.stringify(ret.data);
		return ret;
	}
}
