<ng-container *transloco="let t; read: 'platform.entity.customFieldDetails'">
	<div *ngIf="customFields && customFields.length" class="flex flex-col p-4">
		<div class="font-medium tracking-tight truncate pb-4">{{ t('title') }}</div>
		<div class="flex flex-row">
			<div id="leftCol" class="flex flex-col space-y-4 basis-1/2">
				<ng-container *ngFor="let field of customFieldData; let i = index">
					<ng-container *ngIf="i + 1 <= ceilNumber(customFields.length / 2)">
						<div class="flex flex-row space-x-4" *ngIf="field.response && field.response.value !== ''">
							<div class="min-w-50 font-light text-slate-600">{{ field.field.label }}</div>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.textbox">
								<div>{{ field.response.value }}</div>
							</ng-container>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.datePicker">
								<div>
									{{ field.response.value.toDate() | date: 'longDate' }}
								</div>
							</ng-container>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.checkbox">
								<div>{{ t(field.response.value) }}</div>
							</ng-container>
						</div>
					</ng-container>
				</ng-container>
			</div>
			<div id="rightCol" class="flex flex-col space-y-4 basis-1/2">
				<ng-container *ngFor="let field of customFieldData; let i = index">
					<ng-container *ngIf="i + 1 > ceilNumber(customFields.length / 2)">
						<div class="flex flex-row space-x-4" *ngIf="field.response && field.response.value !== ''">
							<div class="min-w-50 font-light text-slate-600">{{ field.field.label }}</div>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.textbox">
								<div>{{ field.response.value }}</div>
							</ng-container>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.datePicker">
								<div>
									{{ field.response.value }}
								</div>
							</ng-container>
							<ng-container *ngIf="field.field.fieldType == customFieldTypes.checkbox">
								<div>{{ t(field.response.value) }}</div>
							</ng-container>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
</ng-container>
