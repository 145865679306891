import { Injectable } from '@angular/core';
// firebase
import { Functions, httpsCallable } from '@angular/fire/functions';
import { User } from 'firebase/auth';

@Injectable({
	providedIn: 'root',
})
export class UserBackendService {
	constructor(private _functions: Functions) {
		this._functions.region = 'europe-west1';
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	linkAccount(accountId: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const linkAccount = httpsCallable(this._functions, 'linkAccount');

			linkAccount({ accountId: accountId })
				.then(() => {
					resolve();
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	initUser(accountId: string): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			let initUser = httpsCallable(this._functions, 'initUser');

			initUser({ accountId: accountId })
				.then(() => {
					resolve();
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	getUsers(): Promise<any> {
		return new Promise<User[]>((resolve, reject) => {
			let getUsers = httpsCallable(this._functions, 'getUsers');

			getUsers()
				.then((result) => {
					resolve(result.data as User[]);
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	getUsersData(): Promise<any> {
		return new Promise<User[]>((resolve, reject) => {
			let getUsersData = httpsCallable(this._functions, 'getUsersData');

			getUsersData()
				.then((result) => {
					resolve(result.data as User[]);
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}
}
