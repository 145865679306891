<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="entity && dataSource.length > 0" class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">
			{{ t('newsCheckResults.newsCheckResults') }}
		</div>
		<table mat-table class="w-full" [dataSource]="dataSource">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.date') }}</th>
				<td mat-cell *matCellDef="let newsResult">
					{{ newsResult.date ? (newsResult.date.toDate() | date: 'short') : '-' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.title') }}</th>
				<td mat-cell *matCellDef="let newsResult">
					{{ newsResult.title }}
				</td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.status') }}</th>
				<td mat-cell *matCellDef="let newsResult">
					<span
						class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
						[ngClass]="{
							'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': newsResult.averageScore < 0,
							'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': newsResult.averageScore > 0,
						}"
					>
						<span *ngIf="newsResult.averageScore === 0">{{ t('common.neutral') }}</span>
						<span *ngIf="newsResult.averageScore > 0">{{ t('common.positive') }}</span>
						<span *ngIf="newsResult.averageScore < 0">{{ t('common.negative') }}</span>
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="avgScore">
				<th mat-header-cell *matHeaderCellDef>{{ t('newsCheckResults.avgScore') }}</th>
				<td mat-cell *matCellDef="let newsResult">
					{{ newsResult.averageScore }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
		</table>
	</div>
</ng-container>
