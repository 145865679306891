import { Component, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations/public-api';
import { Entity } from 'app/shared/enums/entity';
import { Subject } from 'rxjs';

import { NavigationEnd, Router } from '@angular/router';
import { Collection } from 'app/shared/enums/collection';
import { connectConfigure } from 'instantsearch.js/es/connectors';

// Services
import { AuthService } from 'app/core/auth/auth.service';
import { InstantSearchService } from 'app/shared/components/search/instant-search.service';

@Component({
	selector: 'search',
	templateUrl: './search.component.html',
	encapsulation: ViewEncapsulation.None,
	exportAs: 'fuseSearch',
	animations: fuseAnimations,
})
export class SearchComponent implements OnChanges, OnDestroy {
	@Input() appearance: 'basic' | 'bar' = 'basic';
	@Input() debounce: number = 300;
	@Input() minLength: number = 2;
	@Output() search: EventEmitter<any> = new EventEmitter<any>();

	opened: boolean = false;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	showResults = false;
	/**
	 * Constructor
	 */
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _instantSearchService: InstantSearchService
	) {
		this._router.events.subscribe((val) => {
			if (val instanceof NavigationEnd) this.close();
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Host binding for component classes
	 */
	@HostBinding('class') get classList(): any {
		return {
			'search-appearance-bar': this.appearance === 'bar',
			'search-appearance-basic': this.appearance === 'basic',
			'search-opened': this.opened,
		};
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On changes
	 *
	 * @param changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		// Appearance
		if ('appearance' in changes) {
			// To prevent any issues, close the
			// search after changing the appearance
			this.close();
		}
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();

		this._instantSearchService.removeWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: `${this._authService.accountId}_${Collection.ENTITIES}`,
				} as any,
			}),
		]);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Open the search
	 * Used in 'bar'
	 */
	open(): void {
		// Return if it's already opened
		if (this.opened) return;

		// Add the widgets
		this._instantSearchService.addWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: `${this._authService.accountId}_${Collection.ENTITIES}`,
				} as any,
			}),
		]);

		// Open the search
		this.opened = true;
	}

	/**
	 * Close the search
	 * * Used in 'bar'
	 */
	close(): void {
		// Return if it's already closed
		if (!this.opened) return;

		// Close the search
		this.opened = false;
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	openObject(query: { query: string; object: any }) {
		this.close();
		if (query && query.object) {
			switch (query.object.type) {
				case Entity.COMPANY:
				case Entity.CONTACT:
					this._router.navigate(['entities', query.object.objectID]);
					break;
				default:
					this._router.navigate(['cases', 'view', query.object.objectID]);
					break;
			}
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Acessors
	// -----------------------------------------------------------------------------------------------------

	get entityType() {
		return Entity;
	}
}
