<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('platform.entity.caseList.cases') }}</div>
			<!-- Actions -->
			<div class="">
				<ng-container *ngIf="isAccountTypesExist">
					<div [matTooltip]="t('platform.entity.common.notPermission')" [matTooltipDisabled]="canCreate">
						<button
							mat-icon-button
							color="primary"
							(click)="addCase()"
							[matTooltip]="t('platform.entity.caseList.addNewCase')"
							[disabled]="!canCreate"
						>
							<mat-icon [svgIcon]="'heroicons_solid:plus-small'"></mat-icon>
						</button>
					</div>
				</ng-container>

				<ng-container *ngIf="!isAccountTypesExist">
					<button
						class="hidden sm:inline-flex"
						mat-icon-button
						color="warn"
						matTooltip="{{ t('platform.entity.caseList.noAccountTypesDefined') }}."
					>
						<mat-icon [svgIcon]="'heroicons_solid:exclamation-circle'"></mat-icon>
					</button>
				</ng-container>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<table class="w-full" mat-table [dataSource]="caseData">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.title') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.title }}
					</td>
				</ng-container>
				<ng-container matColumnDef="stage">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.caseList.stage') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.stage }}
					</td>
				</ng-container>
				<ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.owner') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ getUserName(caseData.owner) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.created') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.created ? (caseData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="modified">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.caseList.modified') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.edited ? (caseData.edited.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr class="cursor-pointer" mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="openCase(row.id)"></tr>
			</table>
		</div>
	</div>
</ng-container>
