import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DeclineDialogComponent } from '../decline-dialog/decline-dialog.component';

@Component({
	selector: 'request-changes-dialog',
	templateUrl: './request-changes-dialog.component.html',
	styleUrls: ['./request-changes-dialog.component.scss'],
})
export class RequestChangesDialog implements OnInit {
	changesForm: FormGroup;
	constructor(
		private _dialogRef: MatDialogRef<DeclineDialogComponent>,
		private _formBuilder: UntypedFormBuilder
	) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this.changesForm = this._formBuilder.group({
			requestedChanges: this._formBuilder.array([]),
		});

		this.add();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Save
	 * @return {void}
	 */
	save(): void {
		this._dialogRef.close(this.changesForm.get('requestedChanges').value);
	}

	/**
	 * Close
	 * @return {void}
	 */
	close(): void {
		this._dialogRef.close();
	}

	/**
	 * Add entry to requested changes
	 * @return {void}
	 */
	add(): void {
		let list = this.changesForm.get('requestedChanges') as UntypedFormArray;
		list.push(
			this._formBuilder.group({
				change: ['', [Validators.required, Validators.pattern(/^\S.*$/)]],
				done: false,
			})
		);
	}

	/**
	 * Delete entry from requested changes
	 * @param {number} index
	 * @return {void}
	 */
	delete(index: number) {
		let list = this.changesForm.get('requestedChanges') as UntypedFormArray;
		list.removeAt(index);
	}

	/**
	 * @type {UntypedFormArray}
	 */
	get requestedChanges(): UntypedFormArray {
		return this.changesForm.get('requestedChanges') as UntypedFormArray;
	}
}
