import { Injectable } from '@angular/core';
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { Collection } from 'app/shared/enums/collection';
import { CustomField } from 'app/shared/types/customFieldTypes';
import { QueryCondition } from 'app/shared/types/query-condition';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';
@Injectable({
	providedIn: 'root',
})
export class CustomFieldService {
	private _customFieldsPath: string;
	private _accountPath: string;

	constructor(
		private _authService: AuthService,
		private _dataService: DataService
	) {
		this._accountPath = `${Collection.ACCOUNTS}/${this._authService.accountId}`;
		this._customFieldsPath = `${this._accountPath}/${Collection.CUSTOMFIELDS}`;
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Get custom fields array
	 * @param { AffectedCategory } type
	 * @return { Promise<CustomField[]> }
	 */
	readCustomFields(type?: AffectedCategory, custom?: boolean): Promise<CustomField[]> {
		const condition: QueryCondition[] = [];
		if (type) condition.push({ field: 'entityType', operator: '==', value: type });
		if (custom !== undefined) condition.push({ field: 'custom', operator: '==', value: custom });
		return this._dataService.queryData(this._customFieldsPath, condition);
	}

	/**
	 * Get custom fields observable
	 * @return { Observable<DataObserver> }
	 */
	readCustomFieldsObservable(type?: AffectedCategory, custom?: boolean): Observable<DataObserver> {
		const condition: QueryCondition[] = [];
		if (type) condition.push({ field: 'custom', operator: '==', value: true });
		if (custom !== undefined) condition.push({ field: 'entityType', operator: '==', value: type });
		return this._dataService.queryDataObservable(this._customFieldsPath, condition);
	}

	/**
	 * Get custom field data observable of case or entity
	 *
	 * @param {AffectedCategory} type
	 * @param {string} id
	 * @return {Observable<DataObserver>}
	 */
	readCustomFieldDataObservable(col: Collection, id: string): Observable<DataObserver> {
		if (col !== Collection.ENTITIES && col !== Collection.CASES) return;
		const path = `${this._accountPath}/${col}/${id}/${Collection.CUSTOMFIELDDATA}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Get custom field data observable of case or entity
	 *
	 * @param {AffectedCategory} type
	 * @param {string} id
	 * @return {Observable<DataObserver>}
	 */
	readCustomFieldExtractionsObservable(col: Collection, parentId: string, customFieldId: string): Observable<DataObserver> {
		if (col !== Collection.ENTITIES && col !== Collection.CASES) return;
		const path = `${this._accountPath}/${col}/${parentId}/${Collection.CUSTOMFIELDDATA}/${customFieldId}/${Collection.EXTRACTIONRESULTS}`;
		return this._dataService.loadAllEntriesObservable(path);
	}
	/**
	 * Stores all changes to custom field data. Determines if object has to be created or updated.
	 *
	 * @param {[any, string, boolean][]} data
	 * @return {Promise<void>}
	 */
	storeChangesCustomFields(data: [any, string][]): Promise<void> {
		if (data.length === 0) return;
		data.forEach(([elemData, path]) => {
			// Update parent with valid entry
			if (!elemData.parent || !elemData.valid) return;
			const docRef = this._dataService.getFirestoreRef(path, elemData.id);
			const parentPath = path.split('/').slice(0, 5).join('/');
			data.push([{ id: elemData.parent, valid: docRef }, parentPath]);
		});
		return this._dataService.writeMultipleDocuments(data);
	}
}
