import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, Subject, takeUntil } from 'rxjs';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types and enums
import { Company, Contact, IContact } from 'app/shared/types/entityTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Component({
	selector: 'report-contact-list',
	templateUrl: './contact-list.component.html',
	styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {
	@Input() entity: Company;
	columnsToDisplay = ['title', 'owner', 'created'];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _contactListObservable: DataObserver;
	private _contactData: Contact[] = [];

	constructor(
		private _entityHelperService: EntityHelperService,
		private _platformInformationService: PlatformInformationService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		if (!this.entity) return;
		this._entityHelperService
			.getRelatedContacts(this.entity.id)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._contactListObservable.unsubscribe();
				})
			)
			.subscribe((entitiesObserver) => {
				this._contactListObservable = entitiesObserver;
				this._contactData = [];
				entitiesObserver?.data?.forEach((singleContact) => {
					this._contactData.push(new Contact(singleContact as IContact));
				});
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the users display name
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {Contact[]}
	 */
	get contactData(): Contact[] {
		return this._contactData;
	}
}
