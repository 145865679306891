import { Module } from '../types/module';
import { Actions } from './actions';
import { Collection } from './collection';

export enum Modules {
	ENTITIES = 'entities',
	CASES = 'cases',
	FORMS = 'forms',
	RISKENGINE = 'riskengine',
	ACCOUNTSETTINGS = 'account_settings',
	INTEGRATIONS = 'integrations',
	APPROVALS = 'approvals',
	FILES = 'files',
	WORKFLOWS = 'workflows',
	WORKFLOWEDITOR = 'workflow_editor',
	CUSTOMFIELDS = 'customFields',
	TEMPLATES = 'templates',
	RELATIONSHIPTYPES = 'relationshipTypes',
	USERS = 'users',
	GENERAL = 'general',
	DEFINITIONS = 'definitions',
}

export const ModulesMetadata: { [key: string]: Module } = {
	entities: {
		collection: Collection.ENTITIES,
		title: 'Entities',
		title_singular: 'Entity',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	cases: {
		collection: Collection.CASES,
		title: 'Cases',
		title_singular: 'Case',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	// forms: {
	// 	collection: Collection.FORMS,
	// 	title: 'Forms',
	// 	title_singular: 'Form',
	// 	actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	// },
	riskengine: {
		collection: Collection.RISKENGINE,
		title: 'Risk Settings',
		title_singular: 'Risk Setting',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	account_settings: {
		title: 'Account Settings',
		title_singular: 'Account Setting',
		collection: Collection.ACCOUNTS,
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	integrations: {
		title: 'Integrations',
		title_singular: 'Integration',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	approvals: {
		collection: Collection.APPROVALS,
		title: 'Approvals',
		title_singular: 'Approval',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.approve],
	},
	files: {
		collection: Collection.FILES,
		title: 'Files',
		title_singular: 'File',
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	workflow_editor: {
		title: 'Workflow Editor',
		title_singular: 'Workflow Editor',
		collection: Collection.WORKFLOWSNEW,
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	customFields: {
		title: 'Custom Field Settings',
		title_singular: 'Custom Field Setting',
		collection: Collection.CUSTOMFIELDS,
		actions: [Actions.view, Actions.create, Actions.edit],
	},
	templates: {
		title: 'Templates',
		title_singular: 'Template',
		collection: Collection.TEMPLATES,
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	relationshipTypes: {
		title: 'Relationship Types',
		title_singular: 'Relationship Type',
		collection: Collection.RELATIONSHIPTYPES,
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	users: {
		title: 'Users',
		title_singular: 'User',
		collection: Collection.USERS,
		actions: [Actions.view, Actions.create, Actions.edit, Actions.delete],
	},
	general: {
		title: 'General',
		title_singular: 'General',
		actions: [Actions.view, Actions.edit],
	},
	definitions: {
		collection: Collection.DEFINITIONS,
		title: 'definitions',
		title_singular: 'Definition',
		actions: [Actions.view, Actions.edit, Actions.create],
	},
};
