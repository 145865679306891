<ng-container *transloco="let t">
	<ng-container *ngFor="let res of passportResults">
		<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 mb-4 h-full">
			<div class="flex items-start justify-between mb-4">
				<div class="text-lg font-medium tracking-tight leading-6 truncate">
					{{ t('platform.cases.dashboard.passportElement.passportData') }}
				</div>
				<div *ngIf="res">
					<mat-icon
						*ngIf="passportValid(res.fields.expirationDate)"
						class="icon-size-5 text-green-500"
						[svgIcon]="'heroicons_solid:check-circle'"
						matTooltip="{{ t('platform.cases.dashboard.passportElement.passportIsValid') }}"
					></mat-icon>
					<mat-icon
						*ngIf="!passportValid(res.fields.expirationDate)"
						class="icon-size-5 text-warn"
						[svgIcon]="'heroicons_solid:exclamation-circle'"
						matTooltip="{{ t('platform.cases.dashboard.passportElement.passportExpired') }}"
					></mat-icon>
				</div>
			</div>
			<ng-container *ngIf="res; else entityLoading">
				<ng-container *ngTemplateOutlet="passportResult; context: { $implicit: res }"></ng-container>
			</ng-container>
		</div>
	</ng-container>

	<!-- <ng-container *ngIf="entity; else entityLoading">
  <ng-container [ngSwitch]="entity.type">
      <ng-container *ngSwitchCase="'contact'">
          <ng-container
              *ngTemplateOutlet="contact; context: { $implicit: entity }"
          ></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'company'">
          <ng-container
              *ngTemplateOutlet="company; context: { $implicit: entity }"
          ></ng-container>
      </ng-container>
  </ng-container>
</ng-container> -->

	<!-- Templates -->
	<ng-template #entityLoading>
		<div class="animate-pulse flex space-x-4">
			<div class="flex-1 space-y-6 py-1">
				<div class="h-2 bg-slate-700 rounded"></div>
				<div class="space-y-3">
					<div class="grid grid-cols-3 gap-4">
						<div class="h-2 bg-slate-700 rounded col-span-2"></div>
						<div class="h-2 bg-slate-700 rounded col-span-1"></div>
					</div>
					<div class="h-2 bg-slate-700 rounded"></div>
				</div>
			</div>
		</div>
	</ng-template>
	<ng-template #passportResult let-passport>
		<div class="flex flex-col">
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.passport') }} #: </span>
				{{ passport.fields.documentNumber }}
			</div>
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.firstName') }}: </span
				><span>{{ passport.fields.firstName }}</span>
			</div>
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.lastName') }}: </span
				><span>{{ passport.fields.lastName }}</span>
			</div>
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.nationality') }}: </span
				><span>{{ passport.fields.nationality }}</span>
			</div>
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.expirationDate') }}: </span>
				{{ formatDate(passport.fields.expirationDate) | date: 'mediumDate' }}
			</div>
			<div>
				<span class="font-medium">{{ t('platform.cases.dashboard.passportElement.dob') }}: </span>
				{{ passport.parsedBirthdate && passport.parsedBirthdate.toDate() | date: 'mediumDate' }}
			</div>
		</div>
	</ng-template>
</ng-container>
