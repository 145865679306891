export const countries = [
	{
		iso: 'AFG',
		name: 'Afghanistan',
		code: '+93',
		flagImagePos: '-1px -3180px',
	},
	{
		iso: 'ALB',
		name: 'Albania',
		code: '+355',
		flagImagePos: '-1px -1310px',
	},
	{
		iso: 'DZA',
		name: 'Algeria',
		code: '+213',
		flagImagePos: '-1px -681px',
	},
	{
		iso: 'ASM',
		name: 'American Samoa',
		code: '+1',
		flagImagePos: '-1px -2058px',
	},
	{
		iso: 'AND',
		name: 'Andorra',
		code: '+376',
		flagImagePos: '-1px -766px',
	},
	{
		iso: 'AGO',
		name: 'Angola',
		code: '+244',
		flagImagePos: '-1px -2636px',
	},
	{
		iso: 'AIA',
		name: 'Anguilla',
		code: '+1',
		flagImagePos: '-1px -2687px',
	},
	{
		iso: 'ATG',
		name: 'Antigua & Barbuda',
		code: '+1',
		flagImagePos: '-1px -1140px',
	},
	{
		iso: 'ARG',
		name: 'Argentina',
		code: '+54',
		flagImagePos: '-1px -3282px',
	},
	{
		iso: 'ARM',
		name: 'Armenia',
		code: '+374',
		flagImagePos: '-1px -205px',
	},
	{
		iso: 'ABW',
		name: 'Aruba',
		code: '+297',
		flagImagePos: '-1px -1021px',
	},
	{
		iso: 'SHN',
		name: 'Ascension Island',
		code: '+247',
		flagImagePos: '-1px -86px',
	},
	{
		iso: 'AUS',
		name: 'Australia',
		code: '+61',
		flagImagePos: '-1px -2279px',
	},
	{
		iso: 'AUT',
		name: 'Austria',
		code: '+43',
		flagImagePos: '-1px -1735px',
	},
	{
		iso: 'AZE',
		name: 'Azerbaijan',
		code: '+994',
		flagImagePos: '-1px -1599px',
	},
	{
		iso: 'BHS',
		name: 'Bahamas',
		code: '+1',
		flagImagePos: '-1px -460px',
	},
	{
		iso: 'BHR',
		name: 'Bahrain',
		code: '+973',
		flagImagePos: '-1px -1956px',
	},
	{
		iso: 'BGD',
		name: 'Bangladesh',
		code: '+880',
		flagImagePos: '-1px -2364px',
	},
	{
		iso: 'BRB',
		name: 'Barbados',
		code: '+1',
		flagImagePos: '-1px -2075px',
	},
	{
		iso: 'BLR',
		name: 'Belarus',
		code: '+375',
		flagImagePos: '-1px -1412px',
	},
	{
		iso: 'BEL',
		name: 'Belgium',
		code: '+32',
		flagImagePos: '-1px -1px',
	},
	{
		iso: 'BLZ',
		name: 'Belize',
		code: '+501',
		flagImagePos: '-1px -613px',
	},
	{
		iso: 'BEN',
		name: 'Benin',
		code: '+229',
		flagImagePos: '-1px -1684px',
	},
	{
		iso: 'BMU',
		name: 'Bermuda',
		code: '+1',
		flagImagePos: '-1px -2585px',
	},
	{
		iso: 'BTN',
		name: 'Bhutan',
		code: '+975',
		flagImagePos: '-1px -2483px',
	},
	{
		iso: 'BOL',
		name: 'Bolivia',
		code: '+591',
		flagImagePos: '-1px -2177px',
	},
	{
		iso: 'BIH',
		name: 'Bosnia & Herzegovina',
		code: '+387',
		flagImagePos: '-1px -2092px',
	},
	{
		iso: 'BWA',
		name: 'Botswana',
		code: '+267',
		flagImagePos: '-1px -3724px',
	},
	{
		iso: 'BRA',
		name: 'Brazil',
		code: '+55',
		flagImagePos: '-1px -1004px',
	},
	{
		iso: 'IOT',
		name: 'British Indian Ocean Territory',
		code: '+246',
		flagImagePos: '-1px -86px',
	},
	{
		iso: 'VGB',
		name: 'British Virgin Islands',
		code: '+1',
		flagImagePos: '-1px -1854px',
	},
	{
		iso: 'BRN',
		name: 'Brunei',
		code: '+673',
		flagImagePos: '-1px -2228px',
	},
	{
		iso: 'BGR',
		name: 'Bulgaria',
		code: '+359',
		flagImagePos: '-1px -3537px',
	},
	{
		iso: 'BFA',
		name: 'Burkina Faso',
		code: '+226',
		flagImagePos: '-1px -953px',
	},
	{
		iso: 'BDI',
		name: 'Burundi',
		code: '+257',
		flagImagePos: '-1px -2551px',
	},
	{
		iso: 'KHM',
		name: 'Cambodia',
		code: '+855',
		flagImagePos: '-1px -290px',
	},
	{
		iso: 'CMR',
		name: 'Cameroon',
		code: '+237',
		flagImagePos: '-1px -2806px',
	},
	{
		iso: 'CAN',
		name: 'Canada',
		code: '+1',
		flagImagePos: '-1px -1803px',
	},
	{
		iso: 'CPV',
		name: 'Cape Verde',
		code: '+238',
		flagImagePos: '-1px -3639px',
	},
	{
		iso: 'BES',
		name: 'Caribbean Netherlands',
		code: '+599',
		flagImagePos: '-1px -3741px',
	},
	{
		iso: 'CYM',
		name: 'Cayman Islands',
		code: '+1',
		flagImagePos: '-1px -375px',
	},
	{
		iso: 'CAF',
		name: 'Central African Republic',
		code: '+236',
		flagImagePos: '-1px -2466px',
	},
	{
		iso: 'TCD',
		name: 'Chad',
		code: '+235',
		flagImagePos: '-1px -1055px',
	},
	{
		iso: 'CHL',
		name: 'Chile',
		code: '+56',
		flagImagePos: '-1px -1752px',
	},
	{
		iso: 'CHN',
		name: 'China',
		code: '+86',
		flagImagePos: '-1px -1072px',
	},
	{
		iso: 'COL',
		name: 'Colombia',
		code: '+57',
		flagImagePos: '-1px -409px',
	},
	{
		iso: 'COM',
		name: 'Comoros',
		code: '+269',
		flagImagePos: '-1px -1871px',
	},
	{
		iso: 'COG',
		name: 'Congo - Brazzaville',
		code: '+242',
		flagImagePos: '-1px -2398px',
	},
	{
		iso: 'COD',
		name: 'Congo - Kinshasa',
		code: '+243',
		flagImagePos: '-1px -1990px',
	},
	{
		iso: 'COK',
		name: 'Cook Islands',
		code: '+682',
		flagImagePos: '-1px -3112px',
	},
	{
		iso: 'CRI',
		name: 'Costa Rica',
		code: '+506',
		flagImagePos: '-1px -2857px',
	},
	{
		iso: 'CIV',
		// prettier-ignore
		name: 'Côte d\'Ivoire',
		code: '+225',
		flagImagePos: '-1px -2194px',
	},
	{
		iso: 'HRV',
		name: 'Croatia',
		code: '+385',
		flagImagePos: '-1px -1174px',
	},
	{
		iso: 'CUB',
		name: 'Cuba',
		code: '+53',
		flagImagePos: '-1px -987px',
	},
	{
		iso: 'CUW',
		name: 'Curaçao',
		code: '+599',
		flagImagePos: '-1px -3758px',
	},
	{
		iso: 'CYP',
		name: 'Cyprus',
		code: '+357',
		flagImagePos: '-1px -732px',
	},
	{
		iso: 'CZE',
		name: 'Czechia',
		code: '+420',
		flagImagePos: '-1px -3095px',
	},
	{
		iso: 'DNK',
		name: 'Denmark',
		code: '+45',
		flagImagePos: '-1px -1820px',
	},
	{
		iso: 'DJI',
		name: 'Djibouti',
		code: '+253',
		flagImagePos: '-1px -2874px',
	},
	{
		iso: 'DMA',
		name: 'Dominica',
		code: '+1',
		flagImagePos: '-1px -3350px',
	},
	{
		iso: 'DOM',
		name: 'Dominican Republic',
		code: '+1',
		flagImagePos: '-1px -2007px',
	},
	{
		iso: 'ECU',
		name: 'Ecuador',
		code: '+593',
		flagImagePos: '-1px -1531px',
	},
	{
		iso: 'EGY',
		name: 'Egypt',
		code: '+20',
		flagImagePos: '-1px -3027px',
	},
	{
		iso: 'SLV',
		name: 'El Salvador',
		code: '+503',
		flagImagePos: '-1px -2160px',
	},
	{
		iso: 'GNQ',
		name: 'Equatorial Guinea',
		code: '+240',
		flagImagePos: '-1px -1973px',
	},
	{
		iso: 'ERI',
		name: 'Eritrea',
		code: '+291',
		flagImagePos: '-1px -936px',
	},
	{
		iso: 'EST',
		name: 'Estonia',
		code: '+372',
		flagImagePos: '-1px -3333px',
	},
	{
		iso: 'SWZ',
		name: 'Eswatini',
		code: '+268',
		flagImagePos: '-1px -3129px',
	},
	{
		iso: 'ETH',
		name: 'Ethiopia',
		code: '+251',
		flagImagePos: '-1px -3367px',
	},
	{
		iso: 'FLK',
		name: 'Falkland Islands (Islas Malvinas)',
		code: '+500',
		flagImagePos: '-1px -3809px',
	},
	{
		iso: 'FRO',
		name: 'Faroe Islands',
		code: '+298',
		flagImagePos: '-1px -1429px',
	},
	{
		iso: 'FJI',
		name: 'Fiji',
		code: '+679',
		flagImagePos: '-1px -2500px',
	},
	{
		iso: 'FIN',
		name: 'Finland',
		code: '+358',
		flagImagePos: '-1px -2568px',
	},
	{
		iso: 'FRA',
		name: 'France',
		code: '+33',
		flagImagePos: '-1px -324px',
	},
	{
		iso: 'GUF',
		name: 'French Guiana',
		code: '+594',
		flagImagePos: '-1px -324px',
	},
	{
		iso: 'PYF',
		name: 'French Polynesia',
		code: '+689',
		flagImagePos: '-1px -2262px',
	},
	{
		iso: 'GAB',
		name: 'Gabon',
		code: '+241',
		flagImagePos: '-1px -1157px',
	},
	{
		iso: 'GMB',
		name: 'Gambia',
		code: '+220',
		flagImagePos: '-1px -817px',
	},
	{
		iso: 'GEO',
		name: 'Georgia',
		code: '+995',
		flagImagePos: '-1px -1123px',
	},
	{
		iso: 'DEU',
		name: 'Germany',
		code: '+49',
		flagImagePos: '-1px -3452px',
	},
	{
		iso: 'GHA',
		name: 'Ghana',
		code: '+233',
		flagImagePos: '-1px -2891px',
	},
	{
		iso: 'GIB',
		name: 'Gibraltar',
		code: '+350',
		flagImagePos: '-1px -341px',
	},
	{
		iso: 'GRC',
		name: 'Greece',
		code: '+30',
		flagImagePos: '-1px -188px',
	},
	{
		iso: 'GRL',
		name: 'Greenland',
		code: '+299',
		flagImagePos: '-1px -2347px',
	},
	{
		iso: 'GRD',
		name: 'Grenada',
		code: '+1',
		flagImagePos: '-1px -3316px',
	},
	{
		iso: 'GLP',
		name: 'Guadeloupe',
		code: '+590',
		flagImagePos: '-1px -511px',
	},
	{
		iso: 'GUM',
		name: 'Guam',
		code: '+1',
		flagImagePos: '-1px -3265px',
	},
	{
		iso: 'GTM',
		name: 'Guatemala',
		code: '+502',
		flagImagePos: '-1px -1208px',
	},
	{
		iso: 'GIN',
		name: 'Guinea',
		code: '+224',
		flagImagePos: '-1px -3520px',
	},
	{
		iso: 'GNB',
		name: 'Guinea-Bissau',
		code: '+245',
		flagImagePos: '-1px -2602px',
	},
	{
		iso: 'GUY',
		name: 'Guyana',
		code: '+592',
		flagImagePos: '-1px -1038px',
	},
	{
		iso: 'HTI',
		name: 'Haiti',
		code: '+509',
		flagImagePos: '-1px -392px',
	},
	{
		iso: 'HND',
		name: 'Honduras',
		code: '+504',
		flagImagePos: '-1px -2959px',
	},
	{
		iso: 'HKG',
		name: 'Hong Kong',
		code: '+852',
		flagImagePos: '-1px -3707px',
	},
	{
		iso: 'HUN',
		name: 'Hungary',
		code: '+36',
		flagImagePos: '-1px -902px',
	},
	{
		iso: 'ISL',
		name: 'Iceland',
		code: '+354',
		flagImagePos: '-1px -2704px',
	},
	{
		iso: 'IND',
		name: 'India',
		code: '+91',
		flagImagePos: '-1px -2245px',
	},
	{
		iso: 'IDN',
		name: 'Indonesia',
		code: '+62',
		flagImagePos: '-1px -2653px',
	},
	{
		iso: 'IRN',
		name: 'Iran',
		code: '+98',
		flagImagePos: '-1px -2738px',
	},
	{
		iso: 'IRQ',
		name: 'Iraq',
		code: '+964',
		flagImagePos: '-1px -851px',
	},
	{
		iso: 'IRL',
		name: 'Ireland',
		code: '+353',
		flagImagePos: '-1px -2670px',
	},
	{
		iso: 'ISR',
		name: 'Israel',
		code: '+972',
		flagImagePos: '-1px -426px',
	},
	{
		iso: 'ITA',
		name: 'Italy',
		code: '+39',
		flagImagePos: '-1px -154px',
	},
	{
		iso: 'JAM',
		name: 'Jamaica',
		code: '+1',
		flagImagePos: '-1px -2296px',
	},
	{
		iso: 'JPN',
		name: 'Japan',
		code: '+81',
		flagImagePos: '-1px -528px',
	},
	{
		iso: 'JOR',
		name: 'Jordan',
		code: '+962',
		flagImagePos: '-1px -1905px',
	},
	{
		iso: 'KAZ',
		name: 'Kazakhstan',
		code: '+7',
		flagImagePos: '-1px -1565px',
	},
	{
		iso: 'KEN',
		name: 'Kenya',
		code: '+254',
		flagImagePos: '-1px -3605px',
	},
	{
		iso: 'KIR',
		name: 'Kiribati',
		code: '+686',
		flagImagePos: '-1px -477px',
	},
	{
		iso: 'XKX',
		name: 'Kosovo',
		code: '+383',
		flagImagePos: '-1px -3860px',
	},
	{
		iso: 'KWT',
		name: 'Kuwait',
		code: '+965',
		flagImagePos: '-1px -3435px',
	},
	{
		iso: 'KGZ',
		name: 'Kyrgyzstan',
		code: '+996',
		flagImagePos: '-1px -2143px',
	},
	{
		iso: 'LAO',
		name: 'Laos',
		code: '+856',
		flagImagePos: '-1px -562px',
	},
	{
		iso: 'LVA',
		name: 'Latvia',
		code: '+371',
		flagImagePos: '-1px -2619px',
	},
	{
		iso: 'LBN',
		name: 'Lebanon',
		code: '+961',
		flagImagePos: '-1px -1616px',
	},
	{
		iso: 'LSO',
		name: 'Lesotho',
		code: '+266',
		flagImagePos: '-1px -3010px',
	},
	{
		iso: 'LBR',
		name: 'Liberia',
		code: '+231',
		flagImagePos: '-1px -2823px',
	},
	{
		iso: 'LBY',
		name: 'Libya',
		code: '+218',
		flagImagePos: '-1px -137px',
	},
	{
		iso: 'LIE',
		name: 'Liechtenstein',
		code: '+423',
		flagImagePos: '-1px -1276px',
	},
	{
		iso: 'LTU',
		name: 'Lithuania',
		code: '+370',
		flagImagePos: '-1px -1446px',
	},
	{
		iso: 'LUX',
		name: 'Luxembourg',
		code: '+352',
		flagImagePos: '-1px -1922px',
	},
	{
		iso: 'MAC',
		name: 'Macao',
		code: '+853',
		flagImagePos: '-1px -3554px',
	},
	{
		iso: 'MDG',
		name: 'Madagascar',
		code: '+261',
		flagImagePos: '-1px -1667px',
	},
	{
		iso: 'MWI',
		name: 'Malawi',
		code: '+265',
		flagImagePos: '-1px -2942px',
	},
	{
		iso: 'MYS',
		name: 'Malaysia',
		code: '+60',
		flagImagePos: '-1px -2517px',
	},
	{
		iso: 'MDV',
		name: 'Maldives',
		code: '+960',
		flagImagePos: '-1px -800px',
	},
	{
		iso: 'MLI',
		name: 'Mali',
		code: '+223',
		flagImagePos: '-1px -3469px',
	},
	{
		iso: 'MLT',
		name: 'Malta',
		code: '+356',
		flagImagePos: '-1px -2041px',
	},
	{
		iso: 'MHL',
		name: 'Marshall Islands',
		code: '+692',
		flagImagePos: '-1px -1463px',
	},
	{
		iso: 'MTQ',
		name: 'Martinique',
		code: '+596',
		flagImagePos: '-1px -239px',
	},
	{
		iso: 'MRT',
		name: 'Mauritania',
		code: '+222',
		flagImagePos: '-1px -307px',
	},
	{
		iso: 'MUS',
		name: 'Mauritius',
		code: '+230',
		flagImagePos: '-1px -2993px',
	},
	{
		iso: 'MEX',
		name: 'Mexico',
		code: '+52',
		flagImagePos: '-1px -2755px',
	},
	{
		iso: 'FSM',
		name: 'Micronesia',
		code: '+691',
		flagImagePos: '-1px -2313px',
	},
	{
		iso: 'MDA',
		name: 'Moldova',
		code: '+373',
		flagImagePos: '-1px -3690px',
	},
	{
		iso: 'MCO',
		name: 'Monaco',
		code: '+377',
		flagImagePos: '-1px -1191px',
	},
	{
		iso: 'MNG',
		name: 'Mongolia',
		code: '+976',
		flagImagePos: '-1px -3503px',
	},
	{
		iso: 'MNE',
		name: 'Montenegro',
		code: '+382',
		flagImagePos: '-1px -2976px',
	},
	{
		iso: 'MSR',
		name: 'Montserrat',
		code: '+1',
		flagImagePos: '-1px -749px',
	},
	{
		iso: 'MAR',
		name: 'Morocco',
		code: '+212',
		flagImagePos: '-1px -3214px',
	},
	{
		iso: 'MOZ',
		name: 'Mozambique',
		code: '+258',
		flagImagePos: '-1px -834px',
	},
	{
		iso: 'MMR',
		name: 'Myanmar (Burma)',
		code: '+95',
		flagImagePos: '-1px -18px',
	},
	{
		iso: 'NAM',
		name: 'Namibia',
		code: '+264',
		flagImagePos: '-1px -2534px',
	},
	{
		iso: 'NRU',
		name: 'Nauru',
		code: '+674',
		flagImagePos: '-1px -2330px',
	},
	{
		iso: 'NPL',
		name: 'Nepal',
		code: '+977',
		flagImagePos: '-1px -120px',
	},
	{
		iso: 'NLD',
		name: 'Netherlands',
		code: '+31',
		flagImagePos: '-1px -1888px',
	},
	{
		iso: 'NCL',
		name: 'New Caledonia',
		code: '+687',
		flagImagePos: '-1px -1650px',
	},
	{
		iso: 'NZL',
		name: 'New Zealand',
		code: '+64',
		flagImagePos: '-1px -2024px',
	},
	{
		iso: 'NIC',
		name: 'Nicaragua',
		code: '+505',
		flagImagePos: '-1px -171px',
	},
	{
		iso: 'NER',
		name: 'Niger',
		code: '+227',
		flagImagePos: '-1px -715px',
	},
	{
		iso: 'NGA',
		name: 'Nigeria',
		code: '+234',
		flagImagePos: '-1px -3418px',
	},
	{
		iso: 'NIU',
		name: 'Niue',
		code: '+683',
		flagImagePos: '-1px -2840px',
	},
	{
		iso: 'NFK',
		name: 'Norfolk Island',
		code: '+672',
		flagImagePos: '-1px -256px',
	},
	{
		iso: 'PRK',
		name: 'North Korea',
		code: '+850',
		flagImagePos: '-1px -2415px',
	},
	{
		iso: 'MKD',
		name: 'North Macedonia',
		code: '+389',
		flagImagePos: '-1px -1769px',
	},
	{
		iso: 'MNP',
		name: 'Northern Mariana Islands',
		code: '+1',
		flagImagePos: '-1px -919px',
	},
	{
		iso: 'NOR',
		name: 'Norway',
		code: '+47',
		flagImagePos: '-1px -1089px',
	},
	{
		iso: 'OMN',
		name: 'Oman',
		code: '+968',
		flagImagePos: '-1px -3384px',
	},
	{
		iso: 'PAK',
		name: 'Pakistan',
		code: '+92',
		flagImagePos: '-1px -2772px',
	},
	{
		iso: 'PLW',
		name: 'Palau',
		code: '+680',
		flagImagePos: '-1px -273px',
	},
	{
		iso: 'PSE',
		name: 'Palestine',
		code: '+970',
		flagImagePos: '-1px -1548px',
	},
	{
		iso: 'PAN',
		name: 'Panama',
		code: '+507',
		flagImagePos: '-1px -1106px',
	},
	{
		iso: 'PNG',
		name: 'Papua New Guinea',
		code: '+675',
		flagImagePos: '-1px -1939px',
	},
	{
		iso: 'PRY',
		name: 'Paraguay',
		code: '+595',
		flagImagePos: '-1px -3231px',
	},
	{
		iso: 'PER',
		name: 'Peru',
		code: '+51',
		flagImagePos: '-1px -1225px',
	},
	{
		iso: 'PHL',
		name: 'Philippines',
		code: '+63',
		flagImagePos: '-1px -2432px',
	},
	{
		iso: 'POL',
		name: 'Poland',
		code: '+48',
		flagImagePos: '-1px -1514px',
	},
	{
		iso: 'PRT',
		name: 'Portugal',
		code: '+351',
		flagImagePos: '-1px -664px',
	},
	{
		iso: 'PRI',
		name: 'Puerto Rico',
		code: '+1',
		flagImagePos: '-1px -596px',
	},
	{
		iso: 'QAT',
		name: 'Qatar',
		code: '+974',
		flagImagePos: '-1px -579px',
	},
	{
		iso: 'REU',
		name: 'Réunion',
		code: '+262',
		flagImagePos: '-1px -324px',
	},
	{
		iso: 'ROU',
		name: 'Romania',
		code: '+40',
		flagImagePos: '-1px -885px',
	},
	{
		iso: 'RUS',
		name: 'Russia',
		code: '+7',
		flagImagePos: '-1px -868px',
	},
	{
		iso: 'RWA',
		name: 'Rwanda',
		code: '+250',
		flagImagePos: '-1px -3673px',
	},
	{
		iso: 'WSM',
		name: 'Samoa',
		code: '+685',
		flagImagePos: '-1px -3163px',
	},
	{
		iso: 'SMR',
		name: 'San Marino',
		code: '+378',
		flagImagePos: '-1px -2908px',
	},
	{
		iso: 'STP',
		name: 'São Tomé & Príncipe',
		code: '+239',
		flagImagePos: '-1px -3299px',
	},
	{
		iso: 'SAU',
		name: 'Saudi Arabia',
		code: '+966',
		flagImagePos: '-1px -52px',
	},
	{
		iso: 'SEN',
		name: 'Senegal',
		code: '+221',
		flagImagePos: '-1px -2925px',
	},
	{
		iso: 'SRB',
		name: 'Serbia',
		code: '+381',
		flagImagePos: '-1px -3401px',
	},
	{
		iso: 'SYC',
		name: 'Seychelles',
		code: '+248',
		flagImagePos: '-1px -1327px',
	},
	{
		iso: 'SLE',
		name: 'Sierra Leone',
		code: '+232',
		flagImagePos: '-1px -970px',
	},
	{
		iso: 'SGP',
		name: 'Singapore',
		code: '+65',
		flagImagePos: '-1px -35px',
	},
	{
		iso: 'MAF',
		name: 'Sint Maarten',
		code: '+1',
		flagImagePos: '-1px -3826px',
	},
	{
		iso: 'SVK',
		name: 'Slovakia',
		code: '+421',
		flagImagePos: '-1px -3044px',
	},
	{
		iso: 'SVN',
		name: 'Slovenia',
		code: '+386',
		flagImagePos: '-1px -1582px',
	},
	{
		iso: 'SLB',
		name: 'Solomon Islands',
		code: '+677',
		flagImagePos: '-1px -1361px',
	},
	{
		iso: 'SOM',
		name: 'Somalia',
		code: '+252',
		flagImagePos: '-1px -1786px',
	},
	{
		iso: 'ZAF',
		name: 'South Africa',
		code: '+27',
		flagImagePos: '-1px -3248px',
	},
	{
		iso: 'KOR',
		name: 'South Korea',
		code: '+82',
		flagImagePos: '-1px -3078px',
	},
	{
		iso: 'SSD',
		name: 'South Sudan',
		code: '+211',
		flagImagePos: '-1px -3775px',
	},
	{
		iso: 'ESP',
		name: 'Spain',
		code: '+34',
		flagImagePos: '-1px -1480px',
	},
	{
		iso: 'LKA',
		name: 'Sri Lanka',
		code: '+94',
		flagImagePos: '-1px -3622px',
	},
	{
		iso: 'BLM',
		name: 'St. Barthélemy',
		code: '+590',
		flagImagePos: '-1px -324px',
	},
	{
		iso: 'SHN',
		name: 'St. Helena',
		code: '+290',
		flagImagePos: '-1px -630px',
	},
	{
		iso: 'KNA',
		name: 'St. Kitts & Nevis',
		code: '+1',
		flagImagePos: '-1px -103px',
	},
	{
		iso: 'LCA',
		name: 'St. Lucia',
		code: '+1',
		flagImagePos: '-1px -1837px',
	},
	{
		iso: 'MAF',
		name: 'St. Martin',
		code: '+590',
		flagImagePos: '-1px -86px',
	},
	{
		iso: 'SPM',
		name: 'St. Pierre & Miquelon',
		code: '+508',
		flagImagePos: '-1px -1378px',
	},
	{
		iso: 'VCT',
		name: 'St. Vincent & Grenadines',
		code: '+1',
		flagImagePos: '-1px -3588px',
	},
	{
		iso: 'SDN',
		name: 'Sudan',
		code: '+249',
		flagImagePos: '-1px -443px',
	},
	{
		iso: 'SUR',
		name: 'Suriname',
		code: '+597',
		flagImagePos: '-1px -3656px',
	},
	{
		iso: 'SWE',
		name: 'Sweden',
		code: '+46',
		flagImagePos: '-1px -494px',
	},
	{
		iso: 'CHE',
		name: 'Switzerland',
		code: '+41',
		flagImagePos: '-1px -1718px',
	},
	{
		iso: 'SYR',
		name: 'Syria',
		code: '+963',
		flagImagePos: '-1px -2449px',
	},
	{
		iso: 'TWN',
		name: 'Taiwan',
		code: '+886',
		flagImagePos: '-1px -647px',
	},
	{
		iso: 'TJK',
		name: 'Tajikistan',
		code: '+992',
		flagImagePos: '-1px -222px',
	},
	{
		iso: 'TZA',
		name: 'Tanzania',
		code: '+255',
		flagImagePos: '-1px -3146px',
	},
	{
		iso: 'THA',
		name: 'Thailand',
		code: '+66',
		flagImagePos: '-1px -1242px',
	},
	{
		iso: 'TLS',
		name: 'Timor-Leste',
		code: '+670',
		flagImagePos: '-1px -3843px',
	},
	{
		iso: 'TGO',
		name: 'Togo',
		code: '+228',
		flagImagePos: '-1px -783px',
	},
	{
		iso: 'TKL',
		name: 'Tokelau',
		code: '+690',
		flagImagePos: '-1px -3792px',
	},
	{
		iso: 'TON',
		name: 'Tonga',
		code: '+676',
		flagImagePos: '-1px -1395px',
	},
	{
		iso: 'TTO',
		name: 'Trinidad & Tobago',
		code: '+1',
		flagImagePos: '-1px -545px',
	},
	{
		iso: 'TUN',
		name: 'Tunisia',
		code: '+216',
		flagImagePos: '-1px -698px',
	},
	{
		iso: 'TUR',
		name: 'Turkey',
		code: '+90',
		flagImagePos: '-1px -2126px',
	},
	{
		iso: 'TKM',
		name: 'Turkmenistan',
		code: '+993',
		flagImagePos: '-1px -3486px',
	},
	{
		iso: 'TCA',
		name: 'Turks & Caicos Islands',
		code: '+1',
		flagImagePos: '-1px -1701px',
	},
	{
		iso: 'TUV',
		name: 'Tuvalu',
		code: '+688',
		flagImagePos: '-1px -358px',
	},
	{
		iso: 'VIR',
		name: 'U.S. Virgin Islands',
		code: '+1',
		flagImagePos: '-1px -2381px',
	},
	{
		iso: 'UGA',
		name: 'Uganda',
		code: '+256',
		flagImagePos: '-1px -1497px',
	},
	{
		iso: 'UKR',
		name: 'Ukraine',
		code: '+380',
		flagImagePos: '-1px -2721px',
	},
	{
		iso: 'ARE',
		name: 'United Arab Emirates',
		code: '+971',
		flagImagePos: '-1px -3061px',
	},
	{
		iso: 'GBR',
		name: 'United Kingdom',
		code: '+44',
		flagImagePos: '-1px -86px',
	},
	{
		iso: 'USA',
		name: 'United States',
		code: '+1',
		flagImagePos: '-1px -69px',
	},
	{
		iso: 'URY',
		name: 'Uruguay',
		code: '+598',
		flagImagePos: '-1px -3571px',
	},
	{
		iso: 'UZB',
		name: 'Uzbekistan',
		code: '+998',
		flagImagePos: '-1px -1293px',
	},
	{
		iso: 'VUT',
		name: 'Vanuatu',
		code: '+678',
		flagImagePos: '-1px -1633px',
	},
	{
		iso: 'VAT',
		name: 'Vatican City',
		code: '+39',
		flagImagePos: '-1px -3197px',
	},
	{
		iso: 'VEN',
		name: 'Venezuela',
		code: '+58',
		flagImagePos: '-1px -1344px',
	},
	{
		iso: 'VNM',
		name: 'Vietnam',
		code: '+84',
		flagImagePos: '-1px -1259px',
	},
	{
		iso: 'WLF',
		name: 'Wallis & Futuna',
		code: '+681',
		flagImagePos: '-1px -324px',
	},
	{
		iso: 'YEM',
		name: 'Yemen',
		code: '+967',
		flagImagePos: '-1px -2211px',
	},
	{
		iso: 'ZMB',
		name: 'Zambia',
		code: '+260',
		flagImagePos: '-1px -2109px',
	},
	{
		iso: 'ZWE',
		name: 'Zimbabwe',
		code: '+263',
		flagImagePos: '-1px -2789px',
	},
];
