<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 w-full h-full">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.cases.dashboard.linkEntityElement.searchEntity') }}
			</div>
		</div>
		<div *ngIf="entities; else entityLoading" class="w-full sm:min-w-80">
			<mat-form-field class="fuse-mat-no-subscript w-full">
				<mat-icon matPrefix [svgIcon]="'heroicons_outline:search'"></mat-icon>
				<input
					matInput
					[formControl]="searchControl"
					[matAutocomplete]="matAutocomplete"
					[placeholder]="t('platform.cases.dashboard.linkEntityElement.searchForPageOrContact')"
				/>
			</mat-form-field>
			<mat-autocomplete class="max-h-128 mt-1 rounded" [disableRipple]="true" #matAutocomplete="matAutocomplete">
				<mat-option class="py-0 px-6 text-md pointer-events-none text-secondary bg-transparent" *ngIf="resultSet && !resultSet.length">
					{{ t('platform.cases.dashboard.linkEntityElement.noResultsFound') }}!
				</mat-option>

				<ng-container *ngFor="let result of resultSet; trackBy: trackByFn">
					<mat-option
						class="group relative mb-1 py-0 px-6 text-md rounded-md hover:bg-gray-100 dark:hover:bg-hover"
						(click)="entitySelected(result)"
					>
						<!-- Contacts -->
						<ng-container *ngIf="entityType === 'contact'">
							<ng-container *ngTemplateOutlet="contactResult; context: { $implicit: result }"></ng-container>
						</ng-container>
						<!-- Companies -->
						<ng-container *ngIf="entityType === 'company'">
							<ng-container *ngTemplateOutlet="companyResult; context: { $implicit: result }"></ng-container>
						</ng-container>
					</mat-option>
				</ng-container>
			</mat-autocomplete>
		</div>
	</div>

	<!-- Templates -->
	<ng-template #contactResult let-result>
		<div class="flex items-center">
			<div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
				<img *ngIf="result.avatar" [src]="result.avatar" />
				<mat-icon
					class="m-0 icon-size-5 text-primary dark:text-primary-400"
					*ngIf="!result.avatar"
					[svgIcon]="'heroicons_outline:user-circle'"
				></mat-icon>
			</div>
			<div class="ml-3 truncate">
				<span class="font-semibold">{{ result.lastName }}</span>
				<span>{{ result.firstName ? ', ' + result.firstName : '' }}</span>
			</div>
		</div>
	</ng-template>

	<ng-template #companyResult let-result>
		<div class="flex items-center">
			<div class="flex shrink-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden bg-primary-100 dark:bg-primary-800">
				<img *ngIf="result.avatar" [src]="result.avatar" />
				<mat-icon
					class="m-0 icon-size-5 text-primary dark:text-primary-400"
					*ngIf="!result.avatar"
					[svgIcon]="'heroicons_outline:building-office'"
				></mat-icon>
			</div>
			<div class="ml-3 truncate">
				<span>{{ result.name }}</span>
			</div>
		</div>
	</ng-template>

	<ng-template #entityLoading>
		<div class="animate-pulse flex space-x-4">
			<div class="flex-1 space-y-6 py-1">
				<div class="h-2 bg-slate-700 rounded"></div>
				<div class="space-y-3">
					<div class="grid grid-cols-3 gap-4">
						<div class="h-2 bg-slate-700 rounded col-span-2"></div>
						<div class="h-2 bg-slate-700 rounded col-span-1"></div>
					</div>
					<div class="h-2 bg-slate-700 rounded"></div>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
