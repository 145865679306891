import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'decline-dialog',
	templateUrl: './decline-dialog.component.html',
	styleUrls: ['./decline-dialog.component.scss'],
})
export class DeclineDialogComponent implements OnInit {
	reasonForm: FormGroup;
	constructor(
		private _dialogRef: MatDialogRef<DeclineDialogComponent>,
		private _formBuilder: UntypedFormBuilder
	) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this.reasonForm = this._formBuilder.group({
			reason: new FormControl('', [Validators.required, Validators.pattern(/^\S.*$/)]),
		});
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Save
	 */
	save(): void {
		this._dialogRef.close(this.reasonForm.get('reason').value);
	}

	close(): void {
		this._dialogRef.close();
	}
}
