<ng-container *transloco="let t">
	<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">{{ t('platform.approval.declineDialog.enterTheReasonForRejection') }}</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<form class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" [formGroup]="reasonForm">
			<!-- behavior field -->
			<mat-form-field class="w-full">
				<textarea
					matInput
					[formControlName]="'reason'"
					[placeholder]="t('platform.approval.declineDialog.giveReasonForDecliningTheApproval')"
					[spellcheck]="false"
				>
				</textarea>
				<mat-error *ngIf="reasonForm.get('reason').hasError('required')">
					{{ t('platform.approval.declineDialog.reasonIsRequired') }}
				</mat-error>
				<mat-error *ngIf="reasonForm.get('reason').hasError('pattern')"> {{ t('platform.approval.declineDialog.invalid') }}</mat-error>
			</mat-form-field>
			<!-- Actions -->
			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="flex items-center mt-4 sm:mt-0">
					<!-- Save as draft -->
					<button class="sm:mx-3" mat-stroked-button (click)="close()" type="button">
						<span>{{ t('platform.approval.declineDialog.cancel') }}</span>
					</button>
					<!-- Send -->
					<button
						class="order-first sm:order-last"
						mat-flat-button
						[color]="'primary'"
						(click)="save()"
						type="submit"
						[disabled]="!reasonForm.valid"
					>
						<span>{{ t('platform.approval.declineDialog.save') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-container>
