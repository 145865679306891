import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { countries } from 'app/shared/enums/country-list';
import { Address } from 'app/shared/types/address';
import { Country } from 'app/shared/types/country';
import { Company, Contact } from 'app/shared/types/entityTypes';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';

@Component({
	selector: 'address-details',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss'],
})
export class AddressDetailsComponent implements OnInit {
	@Input() entity: Contact | Company;

	private _editMode: boolean = false;
	private _addressesForm: UntypedFormGroup;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService
	) {}

	ngOnInit(): void {
		// Set the address form array
		this._resetAddressForm();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Get country info by iso code
	 *
	 * @param iso
	 */
	getCountryByIso(iso: string): Country {
		return countries.find((country) => country.iso === iso);
	}

	/**
	 * Toggle edit mode
	 * @return {void}
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
		this._resetAddressForm();
	}

	/**
	 * Save address changes for entity
	 * @return {void}
	 */
	saveChanges(): void {
		// Trim the addresses before save
		const trimmedAddresses = this._addressesForm.value.addresses.map((address: { [key: string]: string }) => {
			return Object.fromEntries(Object.entries(address).map(([key, value]) => [key, value.trim()]));
		});

		let updData: Contact | Company = { ...this.entity, addresses: trimmedAddresses };

		// Updated the trimmed values
		this._addressesForm.patchValue(updData);
		updData.editedBy = this._authService.userId;
		updData.edited = new Date();
		this._entityHelperService.updateEntity(updData, this.entity.id);
		this._editMode = false;
	}

	/**
	 * Cancel edit mode
	 * @return {void}
	 * */
	cancelEdit(): void {
		this._editMode = false;
		this._addressesForm.patchValue(this.entity);
	}

	/**
	 * Add address fields
	 */
	addAddressField(): void {
		// Add the address form group to the addresses form array
		(this._addressesForm.get('addresses') as UntypedFormArray).push(this._getAddressGroup());
	}

	/**
	 * Remove the address field
	 * @param { number } index
	 * @return { void }
	 */
	removeAddress(index: number): void {
		// Get form array for addresses
		const addressesFormArray = this._addressesForm.get('addresses') as UntypedFormArray;

		// Remove the address field
		addressesFormArray.removeAt(index);
	}

	formatAddress(address: Address) {
		let addressData = [];

		if (address.line1) addressData.push(address.line1);

		if (address.line2) addressData.push(address.line2);

		if (address.city) addressData.push(`${address.zip_code} ${address.city}`);

		if (address.state) addressData.push(address.state);

		if (address.country) this.getCountryByIso(address.country).name;

		return addressData.join(', ');
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private _getAddressGroup() {
		// Regex for allowing single white space in between words
		return this._formBuilder.group({
			line1: ['', [Validators.required, Validators.pattern(/\w+/)]],
			line2: ['', Validators.pattern(/\w+/)],
			zip_code: ['', [Validators.pattern(/\w+/), Validators.required]],
			city: ['', [Validators.pattern(/\w+/), Validators.required]],
			state: ['', Validators.pattern(/\w+/)],
			country: ['DEU'],
			label: ['', Validators.pattern(/\w+/)],
		});
	}

	/**
	 * Reset address form
	 * @return { void }
	 */
	private _resetAddressForm(): void {
		this._addressesForm = this._formBuilder.group({
			addresses: this._formBuilder.array([]),
		});

		// Setup the emails form array
		const addressFormGroups = [];

		if (this.entity.addresses && this.entity.addresses.length > 0) {
			// Iterate through them
			this.entity.addresses.forEach((address) => {
				// Create an email form group
				let formgroup = this._getAddressGroup();
				formgroup.patchValue(address);
				addressFormGroups.push(formgroup);
			});
		} else {
			// Create an email form group
			addressFormGroups.push(this._getAddressGroup());
		}

		// Add the email form groups to the emails form array
		addressFormGroups.forEach((addressFormGroup) => {
			(this._addressesForm.get('addresses') as UntypedFormArray).push(addressFormGroup);
		});
		return;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get editMode(): boolean {
		return this._editMode;
	}

	get addressesForm(): UntypedFormGroup {
		return this._addressesForm;
	}

	get countries(): Country[] {
		return countries;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
	}
}
