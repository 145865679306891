import { FuseNavigationItem } from '@fuse/components/navigation/navigation.types';
import { Collection } from 'app/shared/enums/collection';

export const mainMenu: FuseNavigationItem[] = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		type: 'basic',
		icon: 'heroicons_outline:chart-pie',
		link: '/dashboard',
	},
	{
		id: Collection.APPROVALS,
		title: 'My Approvals',
		type: 'basic',
		icon: 'heroicons_outline:inbox-arrow-down',
		link: '/approvals',
		meta: { useRights: 'cases' },
	},
	{
		type: 'divider',
	},
	{
		id: Collection.ENTITIES,
		title: 'Entities',
		type: 'basic',
		icon: 'heroicons_outline:user-group',
		link: '/entities',
	},
	{
		type: 'divider',
	},
	{
		id: Collection.CASES,
		title: 'Cases',
		type: 'basic',
		icon: 'heroicons_outline:briefcase',
		link: '/cases',
	},
	{
		type: 'divider',
	},
	{
		id: 'account_settings',
		title: 'Account Settings',
		type: 'basic',
		icon: 'heroicons_outline:cog',
		link: '/account-settings',
	},
	{
		id: 'customization',
		title: 'Customization',
		type: 'collapsable',
		icon: 'heroicons_outline:swatch',
		children: [
			{
				id: 'general',
				title: 'General',
				type: 'basic',
				link: 'customization/general',
				exactMatch: true,
				icon: 'heroicons_outline:wrench-screwdriver',
			},
			{
				id: Collection.CUSTOMFIELDS,
				title: 'Custom Field Settings',
				type: 'basic',
				link: 'customization/customFieldSettings',
				exactMatch: true,
				icon: 'heroicons_outline:square-2-stack',
			},
			{
				id: Collection.RELATIONSHIPTYPES,
				title: 'Relationship Types',
				type: 'basic',
				link: 'customization/relationshipTypes',
				icon: 'heroicons_outline:square-3-stack-3d',
			},
			{
				id: Collection.DEFINITIONS,
				title: 'Definitions',
				type: 'basic',
				link: 'customization/definitionSettings',
				icon: 'heroicons_outline:language',
			},
			{
				id: Collection.WORKFLOWSNEW,
				title: 'Workflow Editor',
				type: 'basic',
				icon: 'heroicons_outline:rectangle-group',
				link: '/workflow-editor',
			},

			{
				id: Collection.TEMPLATES,
				title: 'Templates',
				type: 'basic',
				link: 'customization/templateSettings',
				icon: 'heroicons_outline:document-chart-bar',
			},
		],
	},
	{
		id: Collection.RISKENGINE,
		title: 'Risk Settings',
		type: 'basic',
		icon: 'heroicons_outline:adjustments-horizontal',
		link: '/risk-settings',
	},
	{
		id: 'integrations',
		title: 'Integrations',
		type: 'basic',
		icon: 'heroicons_outline:share',
		link: '/integrations',
	},
];
