<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.entity.communicationDetails.communicationDetails') }}
			</div>
			<!-- Actions -->
			<div *ngIf="!editMode" class="">
				<div [matTooltip]="t('platform.entity.communicationDetails.notPermission')" [matTooltipDisabled]="canEdit">
					<button
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.communicationDetails.edit')"
						(click)="toggleEditMode()"
						[disabled]="!canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
					</button>
				</div>
			</div>
			<div *ngIf="editMode" class="space-x-2">
				<button
					mat-icon-button
					color="primary"
					[matTooltip]="t('platform.entity.common.saveChanges')"
					(click)="saveChanges()"
					[disabled]="!communicationForm.valid"
				>
					<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
				</button>
				<button mat-icon-button color="warn" [matTooltip]="t('platform.entity.common.cancel')" (click)="cancelEdit()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<ng-container *ngIf="!editMode; else editView">
				<div id="leftCol" class="flex flex-col space-y-4 basis-1/2">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.communicationDetails.email') }}
						</div>
						<div>
							<ng-container *ngIf="entity.emails">
								<div class="flex">
									<mat-icon [svgIcon]="'heroicons_outline:mail'"></mat-icon>
									<div class="min-w-0 ml-6 space-y-1">
										<ng-container *ngFor="let email of entity.emails; trackBy: trackByFn">
											<div class="flex items-center leading-6">
												<a class="hover:underline text-primary-500" [href]="'mailto:' + email.email" target="_blank">
													{{ email.email }}
												</a>
												<div class="text-md truncate text-secondary" *ngIf="email.label">
													<span class="mx-2">&bull;</span>
													<span class="font-medium">{{ email.label }}</span>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<div id="rightCol" class="flex flex-col space-y-4 basis-1/2">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.communicationDetails.phone') }}
						</div>
						<div>
							<ng-container *ngIf="entity.phoneNumbers">
								<div class="flex">
									<mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
									<div class="min-w-0 ml-6 space-y-1">
										<ng-container *ngFor="let phoneNumber of entity.phoneNumbers; trackBy: trackByFn">
											<div class="flex items-center leading-6">
												<div
													class="hidden sm:flex w-6 h-4 overflow-hidden"
													[matTooltip]="getCountryByIso(phoneNumber.country).name"
													[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
													[style.backgroundSize]="'24px 3876px'"
													[style.backgroundPosition]="getCountryByIso(phoneNumber.country).flagImagePos"
												></div>
												<div class="sm:ml-3 font-mono">
													{{ getCountryByIso(phoneNumber.country).code }}
												</div>
												<div class="ml-2.5 font-mono">
													{{ phoneNumber.phoneNumber }}
												</div>
												<div class="text-md truncate text-secondary" *ngIf="phoneNumber.label">
													<span class="mx-2">&bull;</span>
													<span class="font-medium">{{ phoneNumber.label }}</span>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<ng-template #editView>
		<form [formGroup]="communicationForm" class="flex flex-row w-full space-x-4">
			<div id="leftColEdit" class="flex flex-col space-y-4 basis-1/2">
				<div class="space-y-4">
					<div class="flex mb-[-12px] font-medium" *ngIf="communicationForm.get('emails')['controls']?.length > 0">
						<mat-label class="fuse-mat-no-subscript flex-auto">{{ t('platform.entity.communicationDetails.email') }}</mat-label>
						<mat-label class="fuse-mat-no-subscript flex-auto w-full max-w-30 sm:max-w-40 ml-2 sm:ml-4">{{
							t('platform.entity.common.label')
						}}</mat-label>
						<div class="flex items-center w-10 pl-2 mb-5"></div>
					</div>
					<ng-container
						*ngFor="
							let email of communicationForm.get('emails')['controls'];
							let i = index;
							let first = first;
							let last = last;
							trackBy: trackByFn
						"
					>
						<div class="flex">
							<mat-form-field class="fuse-mat-no-subscript flex-auto">
								<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
								<input
									matInput
									[formControl]="email.get('email')"
									[placeholder]="t('platform.entity.communicationDetails.emailAddress')"
									[spellcheck]="false"
								/>
								<mat-error *ngIf="email.get('email').hasError('required')"
									>{{ t('platform.entity.communicationDetails.required') }}
								</mat-error>
								<mat-error *ngIf="email.get('email').hasError('email') || email.get('email').hasError('pattern')">
									{{ t('platform.entity.communicationDetails.invalid') }}
								</mat-error>
								<mat-error *ngIf="email.get('email').hasError('duplicateValue')">{{
									t('platform.entity.communicationDetails.duplicateEmail')
								}}</mat-error>
							</mat-form-field>
							<mat-form-field class="fuse-mat-no-subscript flex-auto w-full max-w-24 sm:max-w-40 ml-2 sm:ml-4">
								<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:tag'"></mat-icon>
								<input matInput [formControl]="email.get('label')" [placeholder]="t('platform.entity.common.label')" maxlength="20" />
								<mat-error *ngIf="email.get('label').hasError('required')"
									>{{ t('platform.entity.communicationDetails.required') }}
								</mat-error>
								<mat-error *ngIf="email.get('label').hasError('pattern')">
									{{ t('platform.entity.communicationDetails.invalid') }}
								</mat-error>
							</mat-form-field>
							<!-- Remove email -->
							<ng-container>
								<div class="flex items-center w-10 pl-2 mb-5">
									<button
										class="w-8 h-8 min-h-8"
										mat-icon-button
										(click)="removeEmailField(i)"
										matTooltip="{{ t('platform.entity.communicationDetails.remove') }}"
									>
										<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
									</button>
								</div>
							</ng-container>
						</div>
					</ng-container>
				</div>
				<div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer" (click)="addEmailField()">
					<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
					<span class="ml-2 font-medium text-secondary group-hover:underline">{{
						t('platform.entity.communicationDetails.addEmailAddress')
					}}</span>
				</div>
			</div>
			<div id="rightColEdit" class="flex flex-col space-y-4 basis-1/2">
				<div class="space-y-4">
					<div class="flex mb-[-12px] font-medium" *ngIf="communicationForm.get('phoneNumbers')['controls']?.length > 0">
						<mat-label class="fuse-mat-no-subscript flex-auto">{{ t('platform.entity.communicationDetails.phone') }}</mat-label>
						<mat-label class="fuse-mat-no-subscript flex-auto w-full max-w-30 sm:max-w-40 ml-2 sm:ml-4">{{
							t('platform.entity.common.label')
						}}</mat-label>
						<div class="flex items-center w-10 pl-2 mb-5"></div>
					</div>
					<ng-container
						*ngFor="
							let phoneNumber of communicationForm.get('phoneNumbers')['controls'];
							let i = index;
							let first = first;
							let last = last;
							trackBy: trackByFn
						"
					>
						<div class="relative flex">
							<mat-form-field class="fuse-mat-no-subscript flex-auto">
								<input
									matInput
									[formControl]="phoneNumber.get('phoneNumber')"
									[placeholder]="t('platform.entity.communicationDetails.phone')"
									maxlength="11"
								/>
								<mat-select class="mr-1.5" [formControl]="phoneNumber.get('country')" matPrefix>
									<mat-select-trigger>
										<span class="flex items-center">
											<span
												class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
												[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
												[style.backgroundSize]="'24px 3876px'"
												[style.backgroundPosition]="getCountryByIso(phoneNumber.get('country').value).flagImagePos"
											></span>
											<span class="sm:mx-0.5 font-medium text-default">{{
												getCountryByIso(phoneNumber.get('country').value).code
											}}</span>
										</span>
									</mat-select-trigger>
									<ng-container *ngFor="let country of countryList; trackBy: trackByFn">
										<mat-option [value]="country.iso">
											<span class="flex items-center">
												<span
													class="w-6 h-4 overflow-hidden"
													[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
													[style.backgroundSize]="'24px 3876px'"
													[style.backgroundPosition]="country.flagImagePos"
												></span>
												<span class="ml-2">{{ country.name }}</span>
												<span class="ml-2 font-medium">{{ country.code }}</span>
											</span>
										</mat-option>
									</ng-container>
								</mat-select>
								<mat-error *ngIf="phoneNumber.get('phoneNumber').hasError('required')">
									{{ t('platform.entity.communicationDetails.required') }}
								</mat-error>
								<mat-error *ngIf="phoneNumber.get('phoneNumber').hasError('pattern')">
									{{ t('platform.entity.communicationDetails.invalid') }}
								</mat-error>
							</mat-form-field>
							<mat-form-field class="fuse-mat-no-subscript flex-auto w-full max-w-24 sm:max-w-40 ml-2 sm:ml-4">
								<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:tag'"></mat-icon>
								<input
									matInput
									[formControl]="phoneNumber.get('label')"
									[placeholder]="t('platform.entity.common.label')"
									maxlength="20"
								/>
								<mat-error *ngIf="phoneNumber.get('label').hasError('required')">
									{{ t('platform.entity.communicationDetails.required') }}
								</mat-error>
								<mat-error *ngIf="phoneNumber.get('label').hasError('pattern')">
									{{ t('platform.entity.communicationDetails.invalid') }}
								</mat-error>
							</mat-form-field>
							<!-- Remove phone number -->
							<ng-container>
								<div class="flex items-center w-10 pl-2 mb-5">
									<button
										class="w-8 h-8 min-h-8"
										mat-icon-button
										(click)="removePhoneNumberField(i)"
										matTooltip="{{ t('platform.entity.communicationDetails.remove') }}"
									>
										<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
									</button>
								</div>
							</ng-container>
						</div>
					</ng-container>
				</div>
				<div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer" (click)="addPhoneNumberField()">
					<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
					<span class="ml-2 font-medium text-secondary group-hover:underline">{{
						t('platform.entity.communicationDetails.addPhoneNumber')
					}}</span>
				</div>
			</div>
		</form>
	</ng-template>
</ng-container>
