import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { cloneDeep } from 'lodash-es';
import { Observable, ReplaySubject } from 'rxjs';
// services
// types & enums
import { Navigation } from 'app/core/navigation/navigation.types';
import { Actions } from 'app/shared/enums/actions';
import { ModulesMetadata } from 'app/shared/enums/modules';
import { AuthService } from '../auth/auth.service';
import { mainMenu } from './menus';
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

	/**
	 * Constructor
	 */
	constructor(private _authService: AuthService) {}

	init() {
		// For each element and its children, check if user has permission to view the element and if it is a module
		// Elements that are not modules can be seen by everyone e.g. dashboard, dividers
		const modules = Object.keys(ModulesMetadata);
		const setHidden = (item: FuseNavigationItem): boolean => {
			const canView = this._authService.checkRolePermission(item.id, Actions.view);
			return !canView && modules.includes(item.id);
		};
		// Set elements hidden
		mainMenu.forEach((element) => {
			element.hidden = setHidden;
			if (!element.children) return;
			element.children.forEach((child) => {
				child.hidden = setHidden;
			});
		});
		// Check for parents if all children are hidden
		mainMenu.forEach((element) => {
			if (!element.children) return;
			element.hidden = (item: FuseNavigationItem): boolean => {
				return element.children.every((child) => child.hidden(child));
			};
		});

		const navigation: Navigation = {
			default: cloneDeep(mainMenu),
			compact: cloneDeep(mainMenu),
			futuristic: cloneDeep(mainMenu),
			horizontal: cloneDeep(mainMenu),
		};

		this._navigation.next(navigation);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for navigation
	 */
	get navigation$(): Observable<Navigation> {
		return this._navigation.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------
}
