import { Injectable } from '@angular/core';
import { Collection } from 'app/shared/enums/collection';
// types
import { Account } from 'app/shared/types/account';
import { CustomField } from 'app/shared/types/customFieldTypes';
import { DomainSetting } from 'app/shared/types/domainSetting';
import { RoleData } from 'app/shared/types/roleData';
import { DataObserver } from 'app/shared/types/utilityTypes';
// rxjs
import { BehaviorSubject, finalize, Observable, Subject, takeUntil } from 'rxjs';
// services
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';
import { StorageService } from '../storage/storage.service';
@Injectable({
	providedIn: 'root',
})
export class AccountService {
	unsubscribeAll: Subject<any> = new Subject<any>();
	private _account: BehaviorSubject<Account> = new BehaviorSubject(null);
	private _accountRoles: BehaviorSubject<RoleData[]> = new BehaviorSubject(null);
	private _accountDataObserver: DataObserver;
	constructor(
		private _authService: AuthService,
		private _dataService: DataService,
		private _storageService: StorageService
	) {
		this.setLogoPath = 'assets/images/logo/logo.svg';
	}

	init() {
		this._dataService
			.loadDataObservable(Collection.ACCOUNTS, this._authService.accountId)
			.pipe(
				takeUntil(this.unsubscribeAll),
				finalize(() => {
					this._accountDataObserver.unsubscribe();
				})
			)
			.subscribe(async (data) => {
				this._accountDataObserver = data;
				if (!data.data) return;
				this.account = data.data;
				// Check for logo path
				if (!data.data.logoPath) return;
				const url = await this._storageService.getFileUrl(data.data.logoPath);
				// Set the logo into local storage with 'logo' key
				this.setLogoPath = url;
			});
	}
	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Updates account
	 * @param {Partial<Account>} accountData
	 * @return {Promise<void>}
	 */
	updateAccount(accountData: Partial<Account>): Promise<void> {
		accountData.modified = new Date();
		accountData.modifiedBy = this._authService.userId;
		return this._dataService.updateDocument(accountData, Collection.ACCOUNTS, this._account.value.id);
	}

	/**
	 * Updates connector settings
	 * @param {string} connectorId
	 * @param {*} settings
	 * @return {Promise<void>}
	 */
	updateConnectorSettings(connectorId: string, settings: any): Promise<void> {
		const updateObj = { [`connectors.${connectorId}`]: settings };
		return this.updateAccount(updateObj);
	}

	/**
	 * Deletes settings for specific connector
	 * @param {string} connectorId
	 * @return {Promise<void>}
	 */
	deleteConnectorSettings(connectorId: string): Promise<void> {
		const currConnectors = this._account.value.connectors;
		delete currConnectors[connectorId];
		return this.updateAccount({ connectors: currConnectors });
	}

	/**
	 * Updates account settings subobject
	 * @param {string[]} setting
	 * @param {(string | string[] | EntityField | DomainSetting[])} value
	 * @return {Promise<void>}
	 */
	updateSetting(setting: string[], value: string | string[] | CustomField | DomainSetting[]): Promise<void> {
		const updObj = { [`settings.${setting.join('.')}`]: value };
		return this.updateAccount(updObj);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Update logo from tab
	 * @param { string } logoPath
	 * @return { void }
	 */
	private _updateLogo(logoPath: string): void {
		// Get selector of each icon from document
		// prettier-ignore
		const links = document.querySelectorAll('link[rel=\'icon\']') as NodeListOf<HTMLLinkElement>;

		// If icons presents in DOM then replace href value of each link by logo url
		links.forEach((link: HTMLLinkElement) => {
			link.href = logoPath;
		});
	}

	/**
	 * Filters account roles
	 * @return {void}
	 */
	private _setAccountRoles(): void {
		if (!this._account.value || !this._account.value.settings.roleSettings) return;
		const settings = this._account.value.settings.roleSettings;
		const roleArr = Object.values(settings).filter((x) => x.type !== 'system');
		this._accountRoles.next(roleArr);
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	set account(account: Account) {
		this._account.next(account);
		this._setAccountRoles();
	}

	/**
	 * @type {Observable<Account>}
	 */
	get account$(): Observable<Account> {
		return this._account.asObservable();
	}

	/**
	 * @type {string}
	 */
	get accountName(): string {
		return this._account.value.name;
	}

	/**
	 * @type {Observable<RoleData[]>}
	 */
	get accountRoles$(): Observable<RoleData[]> {
		return this._accountRoles.asObservable();
	}

	/**
	 * @type {string}
	 */
	get graphSpace(): string {
		return this._account.value.graphSpace;
	}

	/**
	 * Get the active connectors
	 * @type {string}
	 */
	get activeConnectors(): { [key: string]: any } {
		return this._account.value.connectors;
	}

	/**
	 * Set logo path into the storage
	 * @param { string } logoPath
	 */
	set setLogoPath(logoPath: string) {
		localStorage.setItem('logo', logoPath);
		this._updateLogo(logoPath);
	}

	/**
	 * Get the logo path from storage
	 * @type { string }
	 */
	get logoPath(): string {
		return localStorage.getItem('logo') ?? '';
	}
}
