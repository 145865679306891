import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AccountService } from './core/account/account.service';
// services
import { AuthService } from './core/auth/auth.service';
import { NavigationService } from './core/navigation/navigation.service';
import { RiskEngineSettingsService } from './core/platform-data/risk-engine-settings.service';

@Injectable({
	providedIn: 'root',
})
export class InitialDataResolver {
	/**
	 * Constructor
	 */
	constructor(
		private _authService: AuthService,
		private _navigationService: NavigationService,
		private _riskEngineSettingsService: RiskEngineSettingsService,
		private _accountService: AccountService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Use this resolver to resolve initial data for the application
	 * Singleton services should not be initialized by the constructor as we need them at every login etc.
	 * Try not to create new singleton services
	 *
	 * @param route
	 * @param state
	 */
	resolve(): Promise<any> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve) => {
			await this._authService.init();
			this._navigationService.init();
			this._riskEngineSettingsService.init();
			this._navigationService.init();
			this._accountService.init();
			resolve(true);
		});
	}
}

@Injectable({
	providedIn: 'root',
})
export class SignOutResolver {
	/**
	 * Constructor
	 */
	constructor(
		private _riskEngineSettingsService: RiskEngineSettingsService,
		private _accountService: AccountService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Use this resolver to unsubscribe from singleton services as the subscribers would run endlessly
	 * Try not to create new singleton services
	 *
	 * @param route
	 * @param state
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
		return new Promise((resolve) => {
			// unsubscribe all data services in here
			this._accountService.unsubscribeAll.next(null);
			this._riskEngineSettingsService.unsubscribeAll.next(null);
			resolve(true);
		});
	}
}
