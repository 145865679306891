<ng-container *transloco="let t; read: 'platform.entity.addressDetails'">
	<div *ngIf="entity && entity.addresses && entity.addresses.length > 0" class="flex flex-col bg-white rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate pb-4">
			{{ t('addressDetails') }}
		</div>
		<div id="leftCol" class="flex flex-col space-y-4">
			<div class="flex flex-row space-x-4">
				<div class="min-w-20 font-light text-slate-600">
					{{ t('address') }}
				</div>
				<div class="flex">
					<mat-icon [svgIcon]="'heroicons_outline:map-pin'"></mat-icon>
					<div class="min-w-0 ml-6 space-y-1">
						<ng-container *ngFor="let address of entity.addresses">
							<div class="flex items-center leading-6">
								<div>{{ formatAddress(address) }}</div>
								<div class="text-md truncate text-secondary" *ngIf="address.label">
									<span class="mx-2">&bull;</span>
									<span class="font-medium">{{ address.label }}</span>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
