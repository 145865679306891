import { Component, Input } from '@angular/core';
// Types and enums
import { countries } from 'app/shared/enums/country-list';
import { Country } from 'app/shared/types/country';
import { Company, Contact } from 'app/shared/types/entityTypes';

@Component({
	selector: 'report-communication-details',
	templateUrl: './communication-details.component.html',
	styleUrls: ['./communication-details.component.scss'],
})
export class CommunicationDetailsComponent {
	@Input() entity: Contact | Company;

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	/**
	 * Get country info by iso code
	 *
	 * @param {string} iso
	 * @return {Country}
	 */
	getCountryByIso(iso: string): Country {
		return countries.find((country) => country.iso === iso);
	}
}
