<ng-container *transloco="let t; read: 'platform.entity.entitiesReport'">
	<div class="absolute inset-0 min-w-0 text-center overflow-auto print:overflow-visible" cdkScrollable>
		<!-- Main -->
		<div class="inline-block p-6 sm:p-10 text-left print:p-0 h-full">
			<!-- Actions -->
			<div class="flex w-full justify-center print:hidden pb-4">
				<button id="btnPrintReports" class="hidden sm:inline-flex ml-3" mat-flat-button [color]="'primary'" (click)="printReport()">
					<mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:printer'"></mat-icon>
					<span class="ml-2">{{ t('print') }}</span>
				</button>
			</div>
			<!-- Reportdata -->
			<div
				*ngIf="!isLoadingAllData"
				class="w-240 p-16 rounded-2xl shadow bg-card print:w-auto print:rounded-none print:shadow-none print:bg-transparent"
			>
				<div id="divReportHtml" #htmlContainer [innerHTML]="renderHTML"></div>
			</div>
			<div *ngIf="isLoadingAllData" class="flex flex-auto flex-col items-center justify-center bg-gray-100 dark:bg-transparent h-full">
				<mat-progress-spinner *ngIf="true" [diameter]="60" [mode]="'indeterminate'"></mat-progress-spinner>
			</div>
		</div>
	</div>
</ng-container>
