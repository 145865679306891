<ng-container *transloco="let t">
	<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">{{ t('platform.entity.searchEntity.title') }}</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<div class="p-4">
			<div class="flex flex-row w-full justify-evenly items-center">
				<div id="searchbox" class="basis-3/4 fuse-mat-no-subscript">
					<div class="ais-SearchBox-form">
						<mat-icon matPrefix class="search-icon" [svgIcon]="'mat_outline:search'"></mat-icon>
						<input
							#searchInput
							matInput
							[matAutocomplete]="auto"
							[placeholder]="'Search'"
							(keyup)="handleChange($event)"
							[value]="presetValue"
							class="ais-SearchBox-input"
						/>
					</div>
				</div>
				<button [matMenuTriggerFor]="addMenu" mat-stroked-button class="ml-2" [disabled]="!canCreate">
					<span class="ml-2">{{ t('platform.entity.searchEntity.addEntity') }} ...</span>
				</button>
				<mat-menu #addMenu="matMenu">
					<button mat-menu-item (click)="addCompany()" *ngIf="entityTypeFilter == entityType.COMPANY">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:building-office'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.searchEntity.company') }}</span>
					</button>
					<button mat-menu-item (click)="addContact()" *ngIf="entityTypeFilter == entityType.CONTACT">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.searchEntity.contact') }}</span>
					</button>
				</mat-menu>
			</div>
			<table class="w-full bg-transparent" mat-table matSort [dataSource]="hits" #resultsTable>
				<!-- Type -->
				<ng-container matColumnDef="type">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.entity.common.type') }}
					</th>
					<td mat-cell *matCellDef="let entity">
						<ng-container *ngIf="entity.type == entityType.COMPANY">
							<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:building-office'"></mat-icon>
						</ng-container>
						<ng-container *ngIf="entity.type == entityType.CONTACT">
							<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:user'"></mat-icon>
						</ng-container>
					</td>
				</ng-container>
				<!-- Name -->
				<ng-container matColumnDef="name">
					<th class="w-60 truncate" mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.entity.common.name') }}
					</th>
					<td mat-cell *matCellDef="let entity">
						<span class="pr-6 whitespace-nowrap truncate">
							{{ entity.displayName }}
						</span>
					</td>
				</ng-container>
				<!-- Owner -->
				<ng-container matColumnDef="owner">
					<th class="w-60 truncate" mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.entity.common.owner') }}
					</th>
					<td mat-cell *matCellDef="let entity">
						<span class="pr-6 whitespace-nowrap truncate">
							{{ entity.owner }}
						</span>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="resultTableDef"></tr>
				<tr class="order-row h-16" mat-row *matRowDef="let row; columns: resultTableDef" (click)="selectEntity(row)"></tr>
			</table>
		</div>
	</div>
</ng-container>
