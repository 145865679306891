import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Subject } from 'rxjs';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
// types & enums
import { MatDialog } from '@angular/material/dialog';
import { Entity } from 'app/shared/enums/entity';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { GraphLink, GraphNode } from 'app/shared/types/graph-interfaces';
import { EChartsOption } from 'echarts';

/** Component */
import { EntityRelationGraphViewerComponent } from '../entity-relation-graph-viewer/entity-relation-graph-viewer.component';

import { EntityRelation } from 'app/shared/types/entity-relation';
import { RelationshipType } from 'app/shared/types/relationshipType';

@Component({
	selector: 'entity-relation-graph',
	templateUrl: './entity-relation-graph.component.html',
	styleUrls: ['./entity-relation-graph.component.scss'],
})
export class EntityRelationGraphComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;
	@Input() externalRefresh: EventEmitter<void>;
	@Output() onRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	public options: EChartsOption;
	public mergeOptions: EChartsOption;
	public graphInitialised: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _entityRelations: EntityRelation[] = [];
	private _relationshipTypes: RelationshipType[] = [];

	constructor(
		private _dialog: MatDialog,
		private _entityHelperService: EntityHelperService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	async ngOnInit(): Promise<void> {
		await this._getRelations();
		await this._initGraph();

		if (!this.externalRefresh) return;
		this.externalRefresh.subscribe(async () => {
			await this._getRelations();
			await this._initGraph();
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Refreshes the graph
	 */
	refreshGraph() {
		this.onRefresh.emit(true);
	}

	/**
	 * Opens the graph in a fullscreen dialog
	 */
	fullScreenGraph() {
		this._dialog.open(EntityRelationGraphViewerComponent, {
			height: '95%',
			width: '95%',
			data: this.mergeOptions ?? this.options,
		});
	}

	// -------------------------------------------------------------------------
	// @ Private Methods
	// -------------------------------------------------------------------------
	/**
	 * Gets all relations of the entity
	 * @return {Promise<void>}
	 */
	private async _getRelations(): Promise<void> {
		this._relationshipTypes = await this._entityHelperService.getRelationTypes();
		this._entityRelations = await this._entityHelperService.getEntityRelationsGraphDB(this.entity.id);
	}

	/**
	 * Init Graph data from relations
	 * @return {Promise<void>}
	 */
	private async _initGraph(): Promise<void> {
		const relations = this._entityRelations;
		let options: EChartsOption;
		let links: GraphLink[] = [];

		const nodes = await this._getNodes();

		relations.forEach((relation) => {
			let value = '';
			const relType = this._relationshipTypes.find((type) => type.id === relation.type);

			if (relType) {
				if (relation.parent.id === this.entity.id) value = relType.reverseDescription;
				else value = relType.description;
			}
			links.push({
				source: relation.child.id,
				target: relation.parent.id,
				value: value, // relationdescription on links
				label: {
					position: 'middle',
					show: true,
					formatter: '{c}', // formats value to string as value is usually a number
				},
			});
		});

		options = {
			responsive: true,
			tooltip: {},
			series: [
				{
					center: ['50%', '50%'],
					roam: true, // make it zoomable
					type: 'graph',
					layout: 'force',
					data: nodes,
					links: links as any,
					autoCurveness: true,
					draggable: true,
					categories: [
						{
							// vector path from iconregistry does not work
							name: 'contact',
							symbol: 'path://M3065 5551 c-305 -76 -537 -261 -670 -536 -65 -134 -86 -217 -92 -376 -12 -296 75 -526 277 -730 140 -141 281 -224 460 -271 122 -32 358 -32 480 0 381 101 663 408 731 797 17 100 6 320 -21 415 -100 353 -385 621 -747 704 -99 22 -323 21 -418 -3z      M3007 2935 c-765 -93 -1448 -574 -1780 -1255 -149 -307 -220 -578 -236 -898 l-6 -132 2295 0 2295 0 -6 132 c-34 708 -406 1369 -994 1767 -464 315 -1022 452 -1568 386z',
							itemStyle: {
								color: 'rgba(70, 79, 229)',
							},
						},
						{
							name: 'company',
							symbol: 'path://M1903 5900 c-151 -16 -283 -80 -394 -190 -83 -82 -133 -163 -171 -275 l-23 -70 -3 -2026 -2 -2026 -46 -7 c-71 -9 -135 -43 -186 -97 -94 -100 -117 -245 -58 -365 51 -104 137 -166 258 -184 98 -16 1064 -8 1112 8 55 19 139 82 171 129 62 90 62 91 68 533 l6 405 27 52 c36 68 90 120 158 152 l55 26 405 0 405 0 55 -26 c68 -32 122 -84 158 -152 l27 -52 6 -405 c6 -442 6 -443 68 -533 32 -47 116 -110 171 -129 48 -16 1014 -24 1112 -8 121 18 207 80 258 184 95 193 -31 433 -244 462 l-46 7 -2 2026 -3 2026 -23 70 c-59 175 -181 319 -333 394 -167 82 -39 75 -1559 77 -745 1 -1387 -2 -1427 -6z m1047 -1308 l0 -328 -317 3 c-175 2 -322 5 -328 7 -6 2 -9 45 -8 107 1 57 2 202 2 322 l1 217 325 0 325 0 0 -328z m1311 111 c1 -299 1 -416 0 -428 -1 -7 -57 -10 -158 -9 -87 1 -233 2 -325 3 l-168 1 0 325 0 325 325 0 325 0 1 -217z m-1311 -1423 l0 -330 -325 0 c-253 0 -325 3 -326 13 -1 14 -1 131 0 430 l1 217 325 0 325 0 0 -330z m1311 113 c1 -299 1 -416 0 -430 -1 -10 -73 -13 -326 -13 l-325 0 0 330 0 330 325 0 325 0 1 -217z',
							itemStyle: {
								color: 'rgba(70, 79, 229)',
							},
						},
					],
					label: { position: 'bottom' },
					force: {
						initLayout: 'circular',
						repulsion: 500, // distance between 2 nodes becomes further as this value becomes larger
						edgeLength: 250,
						gravity: 0.05, // enforcing nodes approach to center, nodes will be closer to center as value becomes larger
					},
				},
			],
		};

		if (this.options) this.mergeOptions = options;
		else this.options = options;

		/** Update graph initialised flag */
		this.graphInitialised = true;
	}

	/**
	 * Gets all nodes from relations
	 * @return {Promise<GraphNode[]>}
	 */
	private async _getNodes(): Promise<GraphNode[]> {
		let nodes: GraphNode[] = [];

		this._entityRelations.forEach((relation) => {
			[relation.parent.id, relation.child.id].forEach((entityId, idx) => {
				if (nodes.find((node) => node.id === entityId)) return;
				let nodeObj: GraphNode = {
					id: entityId,
					value: '',
					name: idx === 0 ? relation.parentEntityName : relation.childEntityName,
					category:
						idx === 0 ? (relation.parentEntityType === Entity.CONTACT ? 0 : 1) : relation.childEntityType === Entity.CONTACT ? 0 : 1,
					label: { show: true },
					tooltip: {
						borderWidth: 0,
						valueFormatter: (value) => value.toString(),
						// would show a hyphen without that
					},
					symbolSize: 25,
					symbolRotate: 180,
				};
				if (entityId === this.entity.id) nodeObj.itemStyle = { color: 'rgba(100, 116, 139)' };
				nodes.push(nodeObj);
			});
		});

		return nodes;
	}

	// -------------------------------------------------------------------------
	// @ Public Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {boolean} hasRelations
	 */
	get hasRelations(): boolean {
		return this._entityRelations.length > 0;
	}
}
