<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
	class="dark bg-indigo-800 text-white print:hidden"
	[mode]="isScreenSmall ? 'over' : 'side'"
	[name]="'mainNavigation'"
	[navigation]="navigation.futuristic"
	[opened]="!isScreenSmall"
>
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationHeader>
		<!-- Logo -->
		<div class="flex items-center h-20 p-6 pb-0">
			<img class="w-30" src="assets/images/logo/logo-text-on-dark.svg" />
		</div>
	</ng-container>
	<!-- Navigation footer hook -->
	<ng-container fuseVerticalNavigationFooter>
		<!-- User -->
		<div class="flex items-center w-full px-6 py-8 border-t">
			<user></user>
			<div class="flex flex-col w-full ml-4 overflow-hidden">
				<div class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal text-current opacity-80">
					{{ displayName }}
				</div>
				<div class="w-full mt-0.5 whitespace-nowrap text-sm text-ellipsis overflow-hidden leading-normal text-current opacity-50">
					{{ email }}
				</div>
			</div>
		</div>
	</ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
	<!-- Header -->
	<div
		class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
	>
		<!-- Navigation toggle button -->
		<button class="mr-2" mat-icon-button (click)="toggleNavigation('mainNavigation')">
			<mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
		</button>
		<!-- Components -->
		<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
			<languages></languages>

			<search [appearance]="'bar'"></search>
			<shortcuts></shortcuts>
			<messages></messages>
			<notifications></notifications>
			<button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()">
				<mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
			</button>
		</div>
	</div>

	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
		<router-outlet *ngIf="true"></router-outlet>
	</div>

	<!-- Footer -->
	<div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
		<!-- <span class="font-medium text-secondary"
            >Fuse &copy; {{ currentYear }}</span
        > -->
	</div>
</div>

<!-- Quick chat -->
<quick-chat #quickChat="quickChat"></quick-chat>
