import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Functions } from '@angular/fire/functions';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';

// types & enums
import { AccountType } from 'app/shared/types/accountType';
import { Company, Contact } from 'app/shared/types/entityTypes';

// services
import { CaseService } from 'app/core/platform-data/case.service';
import { RiskEngineSettingsService } from 'app/core/platform-data/risk-engine-settings.service';
import { CaseStage } from 'app/shared/enums/case-stage';

@Component({
	selector: 'case-quick-create',
	templateUrl: './case-quick-create.component.html',
	styleUrls: ['./case-quick-create.component.scss'],
})
export class CaseQuickCreateComponent implements OnInit, OnDestroy {
	private _caseForm: UntypedFormGroup;
	private _accountTypes: AccountType[] = [];
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _dialogRef: MatDialogRef<CaseQuickCreateComponent>,
		@Inject(MAT_DIALOG_DATA) private _entity: Company | Contact,
		private _formBuilder: UntypedFormBuilder,
		private _riskEngineSettingsService: RiskEngineSettingsService,
		private _caseService: CaseService,
		private _functions: Functions
	) {
		this._functions.region = 'europe-west1';
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		this._caseForm = this._formBuilder.group({
			title: ['', Validators.required],
			accountType: ['', Validators.required],
		});

		this._riskEngineSettingsService.activeRiskEngineSettings$.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
			if (!settings || !settings.accountTypes) return;
			this._accountTypes = Object.values(settings.accountTypes).filter((accountType) => accountType.affectedEntity === this._entity.type);
			if (this._accountTypes.length === 1) {
				this._caseForm.patchValue({
					accountType: this._accountTypes[0],
					title: this._entity.displayName + ' ' + this._accountTypes[0].title,
				});
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Save
	 */
	async save(): Promise<void> {
		this._caseForm.disable();
		const caseData = this._caseForm.value;
		caseData.linkedRecord = this._entity.id;
		caseData.stage = CaseStage.OPEN;
		caseData.riskScore = 0;
		caseData.riskScoreVerified = 0;
		caseData.customData = {};
		caseData.notes = null;
		const caseID = await this._caseService.createCase(caseData);
		this._dialogRef.close(caseID);
	}

	close(): void {
		this._dialogRef.close();
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get caseForm(): UntypedFormGroup {
		return this._caseForm;
	}

	get accountTypes(): AccountType[] {
		return this._accountTypes;
	}

	get entityDisplayName(): string {
		return this._entity.displayName;
	}
}
