import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReportComponentsModule } from './report-components/report-components.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, ReportComponentsModule],
	exports: [ReportComponentsModule],
})
export class ReportsModule {}
