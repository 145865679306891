import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FuseAlertType } from '@fuse/components/alert';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { combineLatest, finalize, Subject, takeUntil } from 'rxjs';

// Material
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Modules } from 'app/shared/enums/modules';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { NegativeNewsResults } from 'app/shared/types/negativeNewsResults';
import { BaseRunningProcessesEntity, isBaseRunningProcessEntity } from 'app/shared/types/runningProcessesEntity';
import { DataObserver } from 'app/shared/types/utilityTypes';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';

@Component({
	selector: 'news-check-details',
	templateUrl: './news-check-details.component.html',
	styleUrls: ['./news-check-details.component.scss'],
	animations: [
		trigger('detailExpand', [
			state('collapsed', style({ height: '0px', minHeight: '0' })),
			state('expanded', style({ height: '*' })),
			transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
		]),
	],
})
export class NewsCheckDetailsComponent implements OnInit {
	@Input() entity: Contact | Company;
	@Input() canEditParent: boolean;
	@Input() relatedNC: any;
	@Input() externalRefresh: EventEmitter<void>;

	// Pagination variables
	@ViewChild('paginator') paginator: MatPaginator;
	dataSource: MatTableDataSource<any>;
	pageSize: number = 5;
	pageSizeOptions: Array<number> = [5, 10, 25];
	showFirstLastButtons: boolean = true;
	dataSourceLength = 0;
	pageIndex = 0;

	columnsToDisplay = ['date', 'title', 'status', 'avgScore'];
	columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
	subResultColumns = ['score', 'content', 'link'];
	expandedElement: NegativeNewsResults | null;
	showRelated = false;

	alert: { type: FuseAlertType; message: string } = {
		type: 'success',
		message: '',
	};
	showAlert: boolean = false;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _newsCheckResults: NegativeNewsResults[] = [];
	private _ongoingNewsChecks: number = 0;
	private _ongoingNewsChecksInitiated: boolean = false;
	private _allCheckResults: NegativeNewsResults[];
	private _commonTranslatedText = {};
	private _alertTranslatedText = {};
	private _newsCheckDataObservers: DataObserver[] = [];

	constructor(
		private _authService: AuthService,
		private _entityHelperService: EntityHelperService,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private matPaginatorIntl: MatPaginatorIntl,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadNewsCheckResults(false);
		this._initTranslations();

		if (!this.externalRefresh) return;
		this.externalRefresh.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this._loadNewsCheckResults(false);
			this._initTranslations();
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	initNewsCheck(): void {
		this._ongoingNewsChecksInitiated = true;
		this._entityHelperService.initNewsCheck(this.entity.id);
		this.showAlert = true;
		this.alert.message = this._alertTranslatedText['message'];
		setTimeout(() => {
			this.showAlert = false;
		}, 5000);
	}

	initNewsCheckRelated(): void {
		this._ongoingNewsChecksInitiated = true;
		this._entityHelperService.initNewsCheckRelated(this.entity.id);
		this.showAlert = true;
		this.alert.message = this._alertTranslatedText['message'];
		setTimeout(() => {
			this.showAlert = false;
		}, 5000);
	}

	toggleRow(element: { expanded: boolean }): void {
		element.expanded = !element.expanded;
	}

	getTranslatedScore(score: number): string {
		if (score === undefined) return this._commonTranslatedText['pending'];
		if (score === 0) return this._commonTranslatedText['neutral'];
		else if (score > 0) return this._commonTranslatedText['positive'];
		else return this._commonTranslatedText['negative'];
	}

	showRelatedToggle(e: MatSlideToggleChange): void {
		this.showRelated = e.checked;
		this._newsCheckDataObservers.forEach((ncDataObs) => {
			ncDataObs.unsubscribe();
		});
		// Reload results for related
		this._loadNewsCheckResults(this.showRelated);
	}

	/**
	 * Handling page event for pagination
	 * @param e
	 */
	handlePageEvent(e: PageEvent): void {
		this.pageSize = e.pageSize;
		this.dataSourceLength = e.length;
		this.pageIndex = e.pageIndex;
		this._setDataSourceForPagination();
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private async _loadNewsCheckResults(showRelated: boolean): Promise<void> {
		if (this._newsCheckDataObservers) this._newsCheckDataObservers.forEach((ncDataObs) => ncDataObs.unsubscribe());
		const { dataObs, relatedEntities } = await this._entityHelperService.loadNewsCheckResults(this.entity.id, showRelated);

		combineLatest(dataObs)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => this._newsCheckDataObservers.forEach((ncDataObs) => ncDataObs.unsubscribe()))
			)
			.subscribe((newsCheckDataObs) => {
				this._newsCheckDataObservers = newsCheckDataObs;
				const resArr: NegativeNewsResults[] = [];
				newsCheckDataObs.forEach((slcData) => resArr.push(...slcData.data));
				let hasPending = false;
				resArr.forEach((resData) => {
					if (isBaseRunningProcessEntity(resData)) {
						hasPending = true;
						const tempProcessData = { ...resData } as BaseRunningProcessesEntity;
						resData.title = tempProcessData.entityName;
						resData.date = tempProcessData.createdAt;
						resData.type = tempProcessData.type;
						resData.subType = tempProcessData.subType;
					} else {
						if (resData.parentId === null) return;
						if (resData.parentId === this.entity.id) resData.title = this.entity.displayName;
						if (!relatedEntities || relatedEntities.length === 0) return;
						const affEntity: Contact | Company = relatedEntities.find((entity) => entity.id === resData.parentId);
						if (!affEntity) return;
						resData.title = affEntity.displayName;
					}
				});
				this._ongoingNewsChecksInitiated = hasPending;
				this._newsCheckResults = resArr.sort((a, b) => Number(b.date) - Number(a.date));
				this._allCheckResults = this._newsCheckResults;

				// Set pagination data source
				this._setDataSourceForPagination();
			});
	}

	/**
	 * Set data source for pagination
	 * @return {void}
	 */
	private _setDataSourceForPagination(): void {
		this.dataSource = new MatTableDataSource(this._allCheckResults);
		this.changeDetectorRef.detectChanges();
		this.dataSourceLength = this._allCheckResults.length;
		this.dataSource.paginator = this.paginator;
		// Update the paginator labels initially
		this._updatePaginatorLabels();
	}

	/**
	 * Updates the paginator labels with translated text
	 * @return {void}
	 */
	private _updatePaginatorLabels(): void {
		// Set the translated text as the itemsPerPageLabel
		this.matPaginatorIntl.itemsPerPageLabel = this._commonTranslatedText['paginationLabels']['itemsPerPage'];
		// Trigger the changes to update the paginator labels
		this.matPaginatorIntl.changes.next();
		// Set the translated text as the range label
		this.matPaginatorIntl.getRangeLabel = this._getRangeLabel.bind(this, this._commonTranslatedText['paginationLabels']['of']);
		// Trigger the changes to update the paginator labels
		this.matPaginatorIntl.changes.next();
	}

	/**
	 * This function calculates and constructs the range label that indicates the current range of items being displayed.
	 * @param { string } ofLabel ofLabel The translated label for "of".
	 * @param { number } page  The current page number, starting from 0.
	 * @param { number } pageSize The number of items displayed per page
	 * @param { number } length The total number of items in the data set.
	 * @return { string } The range label string that represents the current range of items.
	 */
	private _getRangeLabel(ofLabel: string, page: number, pageSize: number, length: number): string {
		// If there are no items or pageSize is 0, return a label indicating there are no items.
		if (length === 0 || pageSize === 0) return `0 ${ofLabel} ${length}`;
		// Calculate the start and end index of the current range.
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		// Construct and return the range label string.
		return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
	}

	/**
	 * Initializes the translations
	 * @return {void}
	 */
	private _initTranslations(): void {
		this._translocoService
			.selectTranslateObject('entity.common', {}, this.scope)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((results) => {
				this._commonTranslatedText = results;
			});

		this._translocoService
			.selectTranslateObject('entity.newsCheckResults.alert', {}, this.scope)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((results) => {
				this._alertTranslatedText = results;
			});

		this._translocoService
			.selectTranslateObject('paginationLabels', {}, this.scope)
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((results) => {
				this._commonTranslatedText['paginationLabels'] = results;
			});
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {NegativeNewsResults[]}
	 */
	get newsCheckResults(): NegativeNewsResults[] {
		return this._newsCheckResults;
	}

	/**
	 * @type {NegativeNewsResults[]}
	 */
	get allCheckResults(): NegativeNewsResults[] {
		return this._allCheckResults;
	}

	/**
	 * @type {number}
	 */
	get ongoingNewsChecks(): number {
		return this._ongoingNewsChecks;
	}

	/**
	 * @type {boolean}
	 */
	get isOngoingNewsChecksInitiated(): boolean {
		return this._ongoingNewsChecksInitiated;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		const res = this._authService.checkRolePermission(Modules.ENTITIES, Actions.edit);
		if (this.canEditParent !== undefined) return res && this.canEditParent;
		return res;
	}
}
