import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
// services
import { AuthService } from '../auth/auth.service';
import { UserBackendService } from '../backend/user-backend.service';
// rxjs
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class PlatformInformationService {
	// getting called in onDestroy of platform.component
	unsubscribeAll: Subject<any> = new Subject<any>();

	// privates
	private _usersObserver;
	private _accountUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

	constructor(
		private _authService: AuthService,
		private _userBackendService: UserBackendService
	) {}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	init() {
		if (!this._authService.accountId || this._usersObserver) return;
		this._userBackendService.getUsersData().then((users) => {
			this._usersObserver = users;
			this._accountUsers.next(users);
		});
	}

	/**
	 * Returns the users displayname
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserNameById(userId: string): string {
		if (this._accountUsers.value === null) return '';
		// Find within the folders and files
		const userData = this._accountUsers.value.find((value) => value.uid === userId) || null;
		if (userData) return userData.displayName;
		return '';
	}

	/**
	 * Refresh the user list
	 * @return {Promise<void>}
	 */
	async refreshUserList(): Promise<void> {
		if (!this._authService.accountId) return;
		const users = await this._userBackendService.getUsersData();
		this._accountUsers.next(users);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {Observable<User[]>}
	 */
	get accountUsers$(): Observable<User[]> {
		return this._accountUsers.asObservable();
	}
}
