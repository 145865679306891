<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<fuse-alert *ngIf="showAlert" [appearance]="'outline'" [type]="alert.type" [showIcon]="false">
			<mat-icon *ngIf="alert.icon" class="icon-size-5" fuseAlertIcon [svgIcon]="alert.icon"> </mat-icon>
			<span *ngIf="alert.title" fuseAlertTitle>{{ alert.title }}</span>
			{{ alert.message }}
		</fuse-alert>
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.entity.sanctionList.sanctionListCheckResults') }}
			</div>
			<!-- Actions -->
			<div class="flex flex-row items-center">
				<div class="">
					<mat-slide-toggle [color]="'primary'" (change)="showRelatedToggle($event)" [checked]="showRelated">{{
						t('platform.entity.sanctionList.showRelated')
					}}</mat-slide-toggle>
				</div>
				<div [matTooltip]="t('platform.entity.sanctionList.notPermission')" [matTooltipDisabled]="canEdit">
					<button
						class="hidden sm:inline-flex"
						[matMenuTriggerFor]="slcMenu"
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.sanctionList.runSanctionListCheck')"
						[disabled]="!canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:clipboard-document-list'"></mat-icon>
					</button>
				</div>
				<mat-menu #slcMenu="matMenu">
					<button mat-menu-item (click)="initSanctionListCheck()">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:identification'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.common.currentEntity') }}</span>
					</button>
					<button mat-menu-item (click)="initSanctionListCheckRelated()">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:identification'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.common.currentRelatedEntities') }}</span>
					</button>
				</mat-menu>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-col">
			<div>
				<table class="w-full" mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="date">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.date') }}</th>
						<td mat-cell *matCellDef="let listResult">
							{{ listResult.date ? (listResult.date.toDate() | date: 'short') : '-' }}
						</td>
					</ng-container>
					<ng-container matColumnDef="title">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.title') }}</th>
						<td mat-cell *matCellDef="let listResult">
							{{ listResult.title }}
						</td>
					</ng-container>
					<ng-container matColumnDef="sanctionListCheckPassed">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.status') }}</th>
						<td mat-cell *matCellDef="let listResult">
							<span
								class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
								[ngClass]="{
									'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': listResult.sanctionListCheckPassed == false,
									'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': listResult.sanctionListCheckPassed == true,
								}"
								>{{
									listResult.sanctionListCheckStatus == 'pending' ? '-' : getTranslatedStatus(listResult.sanctionListCheckPassed)
								}}</span
							>
						</td>
					</ng-container>
					<ng-container matColumnDef="subType">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.type') }}</th>
						<td mat-cell *matCellDef="let listResult">
							{{ listResult.subType }}
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
					<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
				</table>
			</div>
			<div class="flex justify-end">
				<!-- Pagination -->
				<mat-paginator
					*ngIf="allCheckResults.length > 0"
					#paginator
					[pageSize]="pageSize"
					[pageSizeOptions]="pageSizeOptions"
					[length]="allCheckResults.length"
					(page)="handlePageEvent($event)"
					[showFirstLastButtons]="showFirstLastButtons"
				>
				</mat-paginator>
			</div>
		</div>
	</div>
</ng-container>
