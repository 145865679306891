import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, finalize, Observable, Subject, takeUntil } from 'rxjs';

// Material
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { BaseRunningProcessesEntity, isBaseRunningProcessEntity } from 'app/shared/types/runningProcessesEntity';
import { SanctionListResults } from 'app/shared/types/sanctionListResults';
import { DataObserver } from 'app/shared/types/utilityTypes';

// services
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { FuseAlertType } from '@fuse/components/alert';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';

@Component({
	selector: 'sanction-list-check-details',
	templateUrl: './sanction-list-check-details.component.html',
	styleUrls: ['./sanction-list-check-details.component.scss'],
})
export class SanctionListCheckDetailsComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;
	@Input() canEditParent: boolean;
	@Input() externalRefresh: EventEmitter<void>;

	// Pagination variables
	@ViewChild('paginator') paginator: MatPaginator;
	dataSource: MatTableDataSource<any>;
	pageSize: number = 5;
	pageSizeOptions: Array<number> = [5, 10, 25];
	showFirstLastButtons: boolean = true;
	dataSourceLength = 0;
	pageIndex = 0;

	columnsToDisplay = ['date', 'title', 'sanctionListCheckPassed', 'subType'];
	showRelated = false;
	alert: { type: FuseAlertType; message: string } = {
		type: 'success',
		message: '',
	};
	showAlert: boolean = false;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _slcDataObservers: DataObserver[] = [];
	private _sanctionListResults: SanctionListResults[];
	private _ongoingSanctionListChecks: number = 0;
	private _ongoingSanctionListChecksInitiated: boolean = false;
	private _allCheckResults: SanctionListResults[] = [];
	private _commonTranslatedText = {};
	private _alertTranslatedText = {};

	constructor(
		private _authService: AuthService,
		private _entityHelperService: EntityHelperService,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private matPaginatorIntl: MatPaginatorIntl,
		private changeDetectorRef: ChangeDetectorRef
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._loadSanctionListResults(false);

		// Get translated text for common text
		this._getTranslatedTextForCommon();
		// Get translated text for sanction list alert
		this._getTranslatedTextForSanctionListAlert();

		if (!this.externalRefresh) return;
		this.externalRefresh.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this._loadSanctionListResults(false);

			// Get translated text for common text
			this._getTranslatedTextForCommon();
			// Get translated text for sanction list alert
			this._getTranslatedTextForSanctionListAlert();
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	initSanctionListCheck() {
		this._ongoingSanctionListChecksInitiated = true;
		this._entityHelperService.initSanctionListCheck(this.entity.id);
		this.showAlert = true;
		this.alert.message = this._alertTranslatedText['message'];
		setTimeout(() => {
			this.showAlert = false;
		}, 5000);
	}

	initSanctionListCheckRelated() {
		this._ongoingSanctionListChecksInitiated = true;
		this._entityHelperService.initSanctionListCheckRelated(this.entity.id);
		this.showAlert = true;
		this.alert.message = this._alertTranslatedText['message'];
		setTimeout(() => {
			this.showAlert = false;
		}, 5000);
	}

	getTranslatedStatus(status: boolean): string {
		if (status) return this._commonTranslatedText['passed'];
		else return this._commonTranslatedText['failed'];
	}

	showRelatedToggle(e: MatSlideToggleChange) {
		this.showRelated = e.checked;

		this._slcDataObservers.forEach((slcDataObs) => {
			slcDataObs.unsubscribe();
		});
		// Reload results for related
		this._loadSanctionListResults(this.showRelated);
	}

	/**
	 * Handling page event for pagination
	 * @param e
	 */
	handlePageEvent(e: PageEvent) {
		this.pageSize = e.pageSize;
		this.dataSourceLength = e.length;
		this.pageIndex = e.pageIndex;
		this._setDataSourceForPagination();
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private async _loadSanctionListResults(showRelated: boolean): Promise<void> {
		if (this._slcDataObservers) this._slcDataObservers.forEach((slcDataObs) => slcDataObs.unsubscribe());
		let resArr: SanctionListResults[] = [];
		const { dataObs, relatedEntities } = await this._entityHelperService.loadSanctionListCheckResults(this.entity.id, showRelated);

		combineLatest(dataObs)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => this._slcDataObservers.forEach((slcDataObs) => slcDataObs.unsubscribe()))
			)
			.subscribe((slcDataObs) => {
				this._slcDataObservers = slcDataObs;
				slcDataObs.forEach((slcData) => resArr.push(...slcData.data));

				let hasPending = false;

				resArr.forEach((resData) => {
					if (isBaseRunningProcessEntity(resData)) {
						hasPending = true;
						const tempProcessData = { ...resData } as BaseRunningProcessesEntity;
						resData.title = tempProcessData.entityName;
						resData.date = tempProcessData.createdAt;
						resData.sanctionListCheckPassed = false;
						resData.sanctionListCheckStatus = 'pending';
						resData.type = tempProcessData.type;
						resData.subType = tempProcessData.subType;
						return;
					}
					if (resData.parentId === null) return;
					if (resData.parentId === this.entity.id) resData.title = this.entity.displayName;
					if (!relatedEntities || relatedEntities.length === 0) return;
					const affEntity: Contact | Company = relatedEntities.find((entity) => entity.id === resData.parentId);
					if (!affEntity) return;
					resData.title = affEntity.displayName;
				});

				this._ongoingSanctionListChecksInitiated = hasPending;
				this._sanctionListResults = resArr.sort((a, b) => Number(b.date) - Number(a.date));
				this._allCheckResults = this._sanctionListResults;

				// Set pagination data source
				this._setDataSourceForPagination();
			});
	}

	/**
	 * Set data source for pagination
	 */
	private _setDataSourceForPagination() {
		this.dataSource = new MatTableDataSource(this._allCheckResults);
		this.changeDetectorRef.detectChanges();
		this.dataSourceLength = this._allCheckResults.length;
		this.dataSource.paginator = this.paginator;
		// Update the paginator labels initially
		this._updatePaginatorLabels();
	}

	/**
	 * Get the translation of the object by using key
	 * @param { string } key
	 * @return { Observable }
	 */
	private _getTranslatedTextByKey(key: string): Observable<string> {
		return this._translocoService.selectTranslate(key, {}, this.scope);
	}

	/**
	 * Get translated text for common text
	 */
	private _getTranslatedTextForCommon() {
		// Keys of common object
		let keys = ['passed', 'failed'];

		// Get translated text of common text
		for (let i = 0; i < keys.length; i++) {
			this._getTranslatedTextByKey('entity.common.' + keys[i]).subscribe((result) => {
				this._commonTranslatedText[keys[i]] = result;
			});
		}
	}

	/**
	 * Get translated text for sanction list
	 */
	private _getTranslatedTextForSanctionListAlert() {
		// Keys of common object
		let keys = ['message'];

		// Get translated text of common text
		for (let i = 0; i < keys.length; i++) {
			this._getTranslatedTextByKey('entity.sanctionList.alert.' + keys[i]).subscribe((result) => {
				this._alertTranslatedText[keys[i]] = result;
			});
		}
	}

	/**
	 * Updates the paginator labels with translated text
	 */
	private _updatePaginatorLabels(): void {
		// Get the translated text for the itemsPerPageLabel key
		this._getTranslatedTextByKey('paginationLabels.itemsPerPage').subscribe((translatedText) => {
			// Set the translated text as the itemsPerPageLabel
			this.matPaginatorIntl.itemsPerPageLabel = translatedText;

			// Trigger the changes to update the paginator labels
			this.matPaginatorIntl.changes.next();
		});
		// Get the translated text for the itemsPerPageLabel key
		this._getTranslatedTextByKey('paginationLabels.of').subscribe((translatedText) => {
			// Set the translated text as the range label
			this.matPaginatorIntl.getRangeLabel = this._getRangeLabel.bind(this, translatedText);

			// Trigger the changes to update the paginator labels
			this.matPaginatorIntl.changes.next();
		});
	}

	/**
	 * This function calculates and constructs the range label that indicates the current range of items being displayed.
	 * @param { string } ofLabel ofLabel The translated label for "of".
	 * @param { number } page  The current page number, starting from 0.
	 * @param { number } pageSize The number of items displayed per page
	 * @param { number } length The total number of items in the data set.
	 * @return { string } The range label string that represents the current range of items.
	 */
	private _getRangeLabel(ofLabel: string, page: number, pageSize: number, length: number): string {
		// If there are no items or pageSize is 0, return a label indicating there are no items.
		if (length === 0 || pageSize === 0) {
			return `0 ${ofLabel} ${length}`;
		}
		// Calculate the start and end index of the current range.
		const startIndex = page * pageSize;
		const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
		// Construct and return the range label string.
		return `${startIndex + 1} - ${endIndex} ${ofLabel} ${length}`;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get sanctionListResults(): SanctionListResults[] {
		return this._sanctionListResults;
	}

	get allCheckResults(): SanctionListResults[] {
		return this._allCheckResults;
	}

	get ongoingSanctionListChecks(): number {
		return this._ongoingSanctionListChecks;
	}

	get isOngoingSanctionListChecksInitiated(): boolean {
		return this._ongoingSanctionListChecksInitiated;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		const res = this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
		if (this.canEditParent !== undefined) return res && this.canEditParent;
		return res;
	}
}
