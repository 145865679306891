import { CaseTaskList } from '../types/caseTaskList';

export const CaseTaskLists: { [key: string]: CaseTaskList } = {
	todo: {
		title: 'To Do',
		suffix: 'todo',
		position: 0,
	},
	inProgress: {
		title: 'In Progress',
		suffix: 'in-progress',
		position: 1,
	},
	done: {
		title: 'Done',
		suffix: 'done',
		position: 2,
	},
};

export enum TaskStages {
	TODO = 'To Do',
	INPROGRESS = 'In Progress',
	DONE = 'Done',
}
