<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 h-full">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.approval.requestChangesList.requestedChangesForApproval') }}
			</div>
		</div>
		<div>
			<ng-container *ngFor="let item of approval.requestedChanges; let i = index" [formGroup]="requestChangesForm">
				<div class="flex flex-row items-center w-full">
					<span>{{ item.change }}</span>
					<mat-checkbox [formControlName]="i" class="ml-auto"></mat-checkbox>
				</div>
			</ng-container>
		</div>
	</div>
</ng-container>
