import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { FuseFullscreenModule } from '@fuse/components/fullscreen/fullscreen.module';
import { FuseLoadingBarModule } from '@fuse/components/loading-bar/loading-bar.module';
import { FuseNavigationModule } from '@fuse/components/navigation/navigation.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { QuickChatModule } from 'app/layout/common/quick-chat/quick-chat.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { ThinLayoutComponent } from 'app/layout/layouts/vertical/thin/thin.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
	declarations: [ThinLayoutComponent],
	imports: [
		HttpClientModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		FuseFullscreenModule,
		FuseLoadingBarModule,
		FuseNavigationModule,
		LanguagesModule,
		MessagesModule,
		NotificationsModule,
		QuickChatModule,
		SearchModule,
		ShortcutsModule,
		UserModule,
		SharedModule,
	],
	exports: [ThinLayoutComponent],
})
export class ThinLayoutModule {}
