<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto md:w-160 md:min-w-160 max-h-240 -m-6 overflow-hidden">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">{{ t('platform.cases.tasks.details.task') }}</div>
			<button mat-icon-button (click)="cancel()" [tabIndex]="-1" type="button">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<div class="overflow-y-auto h-120 xl:h-full" cdkScrollable>
			<!-- Task form -->
			<form *ngIf="task" class="flex flex-col flex-0 items-start w-full p-6 sm:p-8 space-y-6 overflow-y-auto" [formGroup]="taskForm">
				<!-- Title -->
				<mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
					<mat-label>{{ t('platform.cases.tasks.details.title') }}</mat-label>
					<textarea matInput [formControlName]="'title'" [rows]="1" cdkTextareaAutosize [cdkAutosizeMinRows]="1"> </textarea>
				</mat-form-field>

				<!-- Description -->
				<div class="flex flex-col" *ngIf="description">
					<span class="font-medium"> {{ t('platform.cases.tasks.details.riskCriterionDescription') }}</span>
					<span>{{ description }}</span>
				</div>
				<!-- Value -->
				<mat-form-field class="w-full" *ngIf="!multipleCustomerInput">
					<mat-label class="flex items-center"
						>{{ t('platform.cases.tasks.details.customerInput') }}
						<mat-icon
							*ngIf="selectionMisMatched"
							class="text-red-500"
							[matTooltip]="t('platform.cases.tasks.mismatchInCustomerValueAndVerifiedValue')"
							[svgIcon]="'heroicons_solid:exclamation-triangle'"
						></mat-icon>
					</mat-label>
					<mat-select id="value" matSelect [formControlName]="'value'" (selectionChange)="valuesChanged()">
						<mat-option *ngFor="let item of pickList | keyvalue" [value]="item.key">
							{{ item.value }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="taskForm.get('value').hasError('required')">
						{{ t('platform.cases.tasks.details.valueIsRequired') }}
					</mat-error>
				</mat-form-field>

				<mat-form-field class="w-full" *ngIf="multipleCustomerInput">
					<mat-label class="flex items-center"
						>{{ t('platform.cases.tasks.details.customerInput') }}
						<mat-icon
							*ngIf="selectionMisMatched"
							class="text-red-500"
							[matTooltip]="t('platform.cases.tasks.details.mismatchInCustomerValueVerifiedValue')"
							[svgIcon]="'heroicons_solid:exclamation-triangle'"
						></mat-icon>
					</mat-label>
					<mat-select [formControlName]="'value'" multiple (selectionChange)="valuesChanged()">
						<mat-select-trigger>
							<mat-chip-listbox>
								<mat-chip-option
									*ngFor="let selectedItem of selectedValues"
									[removable]="true"
									(removed)="remove(selectedItem, 'value'); valuesChanged()"
								>
									{{ pickList[selectedItem] }}
									<mat-icon matChipRemove>cancel</mat-icon>
								</mat-chip-option>
							</mat-chip-listbox>
						</mat-select-trigger>

						<mat-option *ngFor="let selectedCustomerInput of pickList | keyvalue" [value]="selectedCustomerInput.key">{{
							selectedCustomerInput.value
						}}</mat-option>
					</mat-select>
				</mat-form-field>

				<!-- Value Verified -->
				<mat-form-field class="w-full" *ngIf="verifiable">
					<mat-label>{{ t('platform.cases.tasks.details.verifiedValue') }}</mat-label>
					<ng-container *ngIf="!multipleCustomerInput">
						<mat-select id="verifiedValue" matSelect [formControlName]="'verifiedValue'" (selectionChange)="valuesChanged()">
							<mat-option *ngFor="let item of pickList | keyvalue" [value]="item.key">
								{{ item.value }}
							</mat-option>
						</mat-select>
					</ng-container>
					<ng-container *ngIf="multipleCustomerInput">
						<mat-select [formControlName]="'verifiedValue'" multiple (selectionChange)="valuesChanged()">
							<mat-select-trigger>
								<mat-chip-listbox>
									<mat-chip-option
										*ngFor="let selectedItem of selectedVerifiedValues"
										[removable]="true"
										(removed)="remove(selectedItem, 'verifiedValue'); valuesChanged()"
									>
										{{ pickList[selectedItem] }}
										<mat-icon matChipRemove>cancel</mat-icon>
									</mat-chip-option>
								</mat-chip-listbox>
							</mat-select-trigger>

							<mat-option *ngFor="let selectedVerifiedValue of pickList | keyvalue" [value]="selectedVerifiedValue.key">{{
								selectedVerifiedValue.value
							}}</mat-option>
						</mat-select>
					</ng-container>
				</mat-form-field>

				<!-- Comment -->
				<mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript w-full">
					<mat-label>{{ t('platform.cases.tasks.details.comment') }}</mat-label>
					<textarea matInput [formControlName]="'comment'" [rows]="1" cdkTextareaAutosize [cdkAutosizeMinRows]="1"> </textarea>
				</mat-form-field>

				<!-- Source of proof -->
				<div class="flex flex-col w-full justify-between" *ngIf="files && files.length > 0">
					<label class="font-medium">{{ t('platform.cases.tasks.details.sourceOfProof') }}</label>
					<mat-selection-list #list [formControlName]="'sourceOfProof'">
						<mat-list-option *ngFor="let file of files" [value]="file.id">
							<div class="flex flex-row items-center">
								<button
									mat-icon-button
									[matTooltip]="t('platform.cases.tasks.details.showDocument')"
									(click)="showFile(file)"
									type="button"
								>
									<mat-icon [svgIcon]="'heroicons_solid:document-magnifying-glass'"></mat-icon>
								</button>
								<span>{{ file.name }}</span>
							</div>
						</mat-list-option>
					</mat-selection-list>
				</div>
				<!-- Due date -->
				<div>
					<div class="font-medium">{{ t('platform.cases.tasks.details.dueDate') }}</div>
					<div
						class="relative flex items-center mt-1.5 px-4 leading-9 rounded-full cursor-pointer"
						[ngClass]="{
							'text-gray-500 bg-gray-100 dark:text-gray-300 dark:bg-gray-700': !dueDate,
							'text-green-800 bg-green-200 dark:text-green-100 dark:bg-green-500': dueDate && !isOverdue(dueDate),
							'text-red-800 bg-red-200 dark:text-red-100 dark:bg-red-500': dueDate && isOverdue(dueDate),
						}"
						(click)="dueDatePicker.open()"
					>
						<mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:calendar'"></mat-icon>
						<span class="ml-2 text-md font-medium">
							<ng-container *ngIf="dueDate">{{ dueDate.toDate() | date: 'longDate' }} </ng-container>
							<ng-container *ngIf="!dueDate">{{ t('platform.cases.tasks.details.notSet') }}</ng-container>
						</span>
						<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense invisible absolute inset-0 -mt-2.5 opacity-0 pointer-events-none">
							<input matInput [formControlName]="'dueDate'" [matDatepicker]="dueDatePicker" />
							<mat-datepicker #dueDatePicker>
								<mat-datepicker-actions>
									<button mat-button (click)="taskForm.get('dueDate').setValue(null)" matDatepickerCancel type="button">
										{{ t('platform.cases.tasks.details.clear') }}
									</button>
									<button mat-flat-button [color]="'primary'" matDatepickerApply type="button" (click)="setDueDate()">
										{{ t('platform.cases.tasks.details.select') }}
									</button>
								</mat-datepicker-actions>
							</mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6 w-full">
					<!-- Save as draft -->
					<button class="sm:mx-3" mat-stroked-button (click)="cancel()" type="button">
						<span>{{ t('platform.cases.tasks.details.cancel') }}</span>
					</button>
					<!-- Send -->
					<button *ngIf="canEdit" class="order-first sm:order-last" mat-flat-button [color]="'primary'" (click)="save()" type="submit">
						<span>{{ t('platform.cases.tasks.details.save') }}</span>
					</button>
				</div>
			</form>
		</div>
	</div>
</ng-container>
