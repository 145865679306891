import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import algoliasearch from 'algoliasearch/lite';
import { Timestamp } from 'firebase/firestore';
import { connectConfigure } from 'instantsearch.js/es/connectors';
import { Subject, finalize, takeUntil } from 'rxjs';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { InstantSearchService } from '../../search/instant-search.service';

// enums & types
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Gender } from 'app/shared/enums/gender';
import { Company, Contact, IContact } from 'app/shared/types/entityTypes';
import { environment as env } from 'environments/environment';

const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);
@Component({
	selector: 'contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
	@Input() entity: Contact;
	@Input() canEditParent: boolean;
	@Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
	private _editMode: boolean = false;
	private _contactForm: UntypedFormGroup;
	private _entityObserver: any;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _companyName: string = '';
	private _duplicates: Partial<Contact>[] = [];
	private _currentDate: Date = new Date();
	private _isInvalidCompany: boolean = false;

	searchConfig = {
		indexName: '',
	};
	searchParameters = {
		hitsPerPage: 5,
		query: '',
		filters: 'type:COMPANY',
	};
	isDuplicateEntityName: boolean = false;
	constructor(
		private _platformInformationService: PlatformInformationService,
		private _formBuilder: UntypedFormBuilder,
		private _authService: AuthService,
		private _entityHelperService: EntityHelperService,
		private _router: Router,
		private _instantSearchService: InstantSearchService
	) {
		this.searchConfig.indexName = `${this._authService.accountId}_${Collection.ENTITIES}`;
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._instantSearchService.addWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: this.searchConfig.indexName,
					filters: this.searchParameters.filters,
					hitsPerPage: this.searchParameters.hitsPerPage,
				} as any,
			}),
		]);
		this._contactForm = this._formBuilder.group({
			id: [''],
			lastName: ['', [Validators.required, Validators.pattern(/\w+/)]],
			firstName: ['', [Validators.required, Validators.pattern(/\w+/)]],
			dateOfBirth: [new Date()],
			notes: [null],
			gender: [''],
			company: [''],
			pep: [false],
		});
		this._contactForm.patchValue(this.entity);
		this._resetDateOfBirth();
		if (!this.entity || !this.entity.company) return;
		this._entityHelperService
			.readEntity(this.entity.company)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._entityObserver.unsubscribe();
				})
			)
			.subscribe((entityObserver) => {
				this._entityObserver = entityObserver;
				this._companyName = entityObserver.data.name;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();

		this._instantSearchService.removeWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: this.searchConfig.indexName,
					filters: this.searchParameters.filters,
					hitsPerPage: this.searchParameters.hitsPerPage,
				} as any,
			}),
		]);
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns user displayname
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return userId ? this._platformInformationService.getUserNameById(userId) : '-';
	}

	/**
	 * Toggles edit mode
	 * @return {void}
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
	}

	/**
	 * Checks if duplicate exists and saves entity if not
	 * @return {Promise<void>}
	 */
	async saveChanges(): Promise<void> {
		// Reset the duplicate entity name flag
		this.isDuplicateEntityName = false;
		// Check for duplication entity
		const isDuplicate = await this._duplicateCheck();
		if (!isDuplicate) {
			const formValues = this._contactForm.value;
			formValues.editedBy = this._authService.userId;
			formValues.edited = new Date();
			formValues.lastName = formValues.lastName.replace(/\s{2,}/g, ' ');
			formValues.firstName = formValues.firstName.replace(/\s{2,}/g, ' ');
			formValues.displayName = formValues.lastName + ', ' + formValues.firstName;
			await this._entityHelperService.updateEntity(formValues, this.entity.id);
			this._contactForm.patchValue(this.entity);
			this._resetDateOfBirth();
			this._editMode = false;
			this.onRefresh.emit();
		} else {
			// Show duplicate entity name error message
			this.isDuplicateEntityName = true;
			// Set errors to first name and last name
			this._contactForm.get('firstName').setErrors({ invalid: true });
			this._contactForm.get('lastName').setErrors({ invalid: true });
			this._contactForm.get('firstName').markAsTouched();
			this._contactForm.get('lastName').markAsTouched();
		}
	}

	/**
	 * Toggles edit mode and resets to original values
	 * @return { Promise<void> }
	 */
	async cancelEdit(): Promise<void> {
		this._editMode = false;
		this._contactForm.patchValue(this.entity);
		this._resetDateOfBirth();
		// Reset company & invalid company flag
		this._isInvalidCompany = false;
		this._companyName = '';
		if (this.entity.company) {
			const companyDetails = (await this._entityHelperService.readEntityData(this.entity.company)) as Company;
			this._companyName = companyDetails.name;
		}
		return;
	}

	/**
	 * Sets the search query for algolia search
	 *
	 * @param {{ query: string; object: any }} query
	 * @return {void}
	 */
	setQuery(query: { query: string; object: any }): void {
		this._isInvalidCompany = false;
		if (!query) return;
		this._contactForm.get('company').setValue(query.object?.objectID ?? '');
		this._companyName = query.object?.name ?? '';
	}

	/**
	 * Check & set error for company
	 * @param {{ isError: boolean }} error
	 * @return { void }
	 */
	checkAndSetForError(error: { isError: boolean }): void {
		this._isInvalidCompany = error.isError ? true : false;
		return;
	}

	/**
	 * Opens the current entity
	 * @return {void}
	 */
	openEntity(): void {
		this._router.navigate(['entities', this.entity.id]);
	}

	/**
	 * On change of first name/ last name input field value
	 * @return {void}
	 */
	onChangeName(): void {
		// Reset duplicate entity name flag
		this.isDuplicateEntityName = false;
		// Clear the validations
		if (
			this._contactForm.get('firstName').value &&
			this._contactForm.get('firstName').value.trim() &&
			this._contactForm.get('firstName').value.trim().length
		)
			this._contactForm.get('firstName').setErrors(null);
		if (
			this._contactForm.get('lastName').value &&
			this._contactForm.get('lastName').value.trim() &&
			this._contactForm.get('lastName').value.trim().length
		)
			this._contactForm.get('lastName').setErrors(null);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Convert date format & Reset date of birth
	 */
	private _resetDateOfBirth(): void {
		// patch seperatly as DOB is saved as timestamp
		if (this.entity && this.entity.dateOfBirth)
			this._contactForm.patchValue({
				dateOfBirth: new Timestamp(this.entity.dateOfBirth['seconds'], this.entity.dateOfBirth['nanoseconds']).toDate(),
			});
	}

	/**
	 * Check the duplication of the entity name
	 * @returns { boolean }
	 */
	private async _duplicateCheck(): Promise<boolean> {
		// Clears the duplicates array
		this._duplicates = [];
		const displayName = [this._contactForm.value.lastName.trim(), this._contactForm.value.firstName.trim()].join(', ');
		const index = searchClient.initIndex(this.searchConfig.indexName);
		try {
			const { hits } = await index.search(displayName, {
				filters: 'type:CONTACT',
			});
			hits.forEach((hit) => {
				// Check id and display name of the edited record
				if (
					hit.objectID !== this._contactForm.value.id &&
					displayName.toLowerCase() === [hit['lastName'].trim().toLowerCase(), hit['firstName'].trim().toLowerCase()].join(', ')
				)
					this._duplicates.push(new Contact(hit as unknown as IContact));
			});
			return this._duplicates.length > 0;
		} catch (error) {
			console.error(error);
		}
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {boolean}
	 */
	get editMode(): boolean {
		return this._editMode;
	}
	/**
	 * @type {UntypedFormGroup}
	 */
	get contactForm(): UntypedFormGroup {
		return this._contactForm;
	}
	/**
	 * @type {typeof Gender}
	 */
	get Gender(): typeof Gender {
		return Gender;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		const res = this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
		if (this.canEditParent !== undefined) return res && this.canEditParent;
		return res;
	}
	/**
	 * @type {string}
	 */
	get companyName(): string {
		return this._companyName;
	}
	/**
	 * @type {boolean}
	 */
	get isOpen(): boolean {
		return this._router.url === `/entities/${this.entity.id}`;
	}
	/**
	 * @type {boolean}
	 */
	get canView(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.view);
	}

	/**
	 * @type {Date}
	 */
	get currentDate(): Date {
		return this._currentDate;
	}

	/**
	 * @type { boolean }
	 */
	get isInvalidCompany(): boolean {
		return this._isInvalidCompany;
	}
}
