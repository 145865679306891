<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('platform.entity.entityRelations.title') }}</div>
			<!-- Actions -->
			<div>
				<ng-container *ngIf="availableRelationships && availableRelationships.length > 0">
					<div [matTooltip]="t('platform.entity.common.notPermission')" [matTooltipDisabled]="canEdit">
						<button
							class="hidden sm:inline-flex"
							[matMenuTriggerFor]="relationsMenu"
							mat-icon-button
							color="primary"
							[disabled]="!canEdit"
							id="btnAddEntityRelation"
						>
							<mat-icon [svgIcon]="'heroicons_solid:plus-small'"></mat-icon>
						</button>
					</div>
					<mat-menu #relationsMenu="matMenu">
						<ng-container *ngFor="let relation of availableRelationships">
							<button mat-menu-item (click)="addEntityRelation(relation)">
								<span *ngIf="entity.type == relation.primaryEntity" class="ml-2">{{ relation.reverseDescription }}</span>
								<span *ngIf="entity.type != relation.primaryEntity" class="ml-2">{{ relation.description }}</span>
							</button></ng-container
						>
					</mat-menu>
				</ng-container>
				<ng-container *ngIf="!availableRelationships || availableRelationships.length == 0">
					<button
						class="hidden sm:inline-flex"
						mat-icon-button
						color="warn"
						matTooltip="{{ t('platform.entity.entityRelations.noRelationshipTypesDefined') }}."
					>
						<mat-icon [svgIcon]="'heroicons_solid:exclamation-circle'"></mat-icon>
					</button>
				</ng-container>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<table class="w-full" mat-table [dataSource]="entityDataSource">
				<ng-container>
					<ng-container matColumnDef="type">
						<th mat-header-cell *matHeaderCellDef>
							{{ t('platform.entity.entityRelations.description') }}
						</th>
						<td mat-cell *matCellDef="let entityRelation" (click)="openDetails(entityRelation)">
							{{ getRelationshipDescription(entityRelation) }}
						</td>
					</ng-container>
					<ng-container matColumnDef="parent">
						<th mat-header-cell *matHeaderCellDef>
							{{ t('platform.entity.entityRelations.linkedEntity') }}
						</th>
						<td mat-cell *matCellDef="let entityRelation" (click)="openDetails(entityRelation)">
							{{ getEntityDisplayName(entityRelation) }}
						</td>
					</ng-container>
					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let entityRelation">
							<dev class="flex flex-row justify-center">
								<button
									mat-icon-button
									color="warn"
									(click)="deleteRelation(entityRelation)"
									[matTooltip]="t('platform.entity.entityRelations.deleteRelation')"
									[disabled]="!canEdit"
								>
									<mat-icon [svgIcon]="'mat_solid:delete'"></mat-icon>
								</button>
							</dev>
						</td>
					</ng-container>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
