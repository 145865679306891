import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
// Services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
// Types & Enums
import { Entity } from 'app/shared/enums/entity';
import { Company, Contact } from 'app/shared/types/entityTypes';

import { debounceTime, filter, finalize, map, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'link-entity-element',
	templateUrl: './link-entity-element.component.html',
	styleUrls: ['./link-entity-element.component.scss'],
})
export class LinkEntityElementComponent implements OnInit, OnDestroy {
	@Input() entityType: Entity;
	@Input() debounce: number = 300;
	@Input() minLength: number = 2;
	@Output() selectedEntity: EventEmitter<Contact | Company> = new EventEmitter<Contact | Company>();

	searchControl: UntypedFormControl = new UntypedFormControl();
	resultSet: any[];

	private _entities: Array<Contact> | Array<Company> = [];
	private _entityObserver: any;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		this._entityHelperService
			.searchEntitiesObserable([
				{
					field: 'parent',
					operator: '==',
					value: this._authService.accountId,
				},
				{
					field: 'type',
					operator: '==',
					value: this.entityType,
				},
			])
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					if (this._entityObserver) this._entityObserver.unsubscribe();
				})
			)
			.subscribe((entityObs) => {
				this._entityObserver = entityObs;
				this._entities = entityObs.data;
			});

		// Subscribe to the search field value changes
		this.searchControl.valueChanges
			.pipe(
				debounceTime(this.debounce),
				takeUntil(this._unsubscribeAll),
				map((value) => {
					// Set the resultSets to null if there is no value or
					// the length of the value is smaller than the minLength
					// so the autocomplete panel can be closed
					if (!value || value.length < this.minLength) {
						this.resultSet = null;
					}

					// Continue
					return value;
				}),
				// Filter out undefined/null/false statements and also
				// filter out the values that are smaller than minLength
				filter((value) => value && value.length >= this.minLength)
			)
			.subscribe((value) => {
				this._filterResults(value);
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	entitySelected(result: Contact | Company) {
		this.selectedEntity.emit(result);
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private _filterResults(filterValue: string) {
		let entities: Contact[] | Company[];
		if (this.entityType === Entity.CONTACT) {
			entities = this._entities as Contact[];
			this.resultSet = entities.filter((entity) => {
				return (
					entity.firstName.toUpperCase().includes(filterValue.toUpperCase()) ||
					entity.lastName.toUpperCase().includes(filterValue.toUpperCase())
				);
			});
		} else {
			entities = this._entities as Company[];
			this.resultSet = entities.filter((entity) => {
				return entity.name.toUpperCase().includes(filterValue.toUpperCase());
			});
		}
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get entities(): Contact[] | Company[] {
		return this._entities;
	}
}
