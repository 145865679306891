import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
// components
import { CaseTaskDetailsComponent } from 'app/modules/platform/cases/view/tasks/details/details.component';
// types & enums
import { CaseTaskLists } from 'app/shared/enums/caseTaskLists';
import { TaskAnswer } from 'app/shared/types/taskAnswer';
import { Task } from 'app/shared/types/taskboardTypes';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { CaseService } from 'app/core/platform-data/case.service';
import { TasksService } from 'app/modules/platform/cases/view/tasks/tasks.service';

@Component({
	selector: 'task-preview-element',
	templateUrl: './task-preview-element.component.html',
	styleUrls: ['./task-preview-element.component.scss'],
	providers: [TasksService],
})
export class TaskPreviewElementComponent implements OnInit, OnDestroy {
	@Input() tasks$: Observable<Task[]>;

	recentTasksTableColumns: string[] = ['title', 'dueDate', 'status'];

	private _tasks: Task[];
	private _openTasks: Task[] = [];
	private _taskAnswers: TaskAnswer[];
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _tasksService: TasksService,
		private _matDialog: MatDialog,
		private _authService: AuthService,
		private _caseService: CaseService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this.tasks$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (tasks) => {
			if (!tasks) return;
			this._tasks = tasks;
			this._openTasks = tasks.filter((task) => !task.listId.includes('done'));
			if (tasks.length > 0) this._taskAnswers = await this._caseService.readTaskAnswersToCase(tasks[0].boardId, tasks);
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	/**
	 * Opens tasks tab
	 * @return {void}
	 */
	goToTasks(): void {
		this._router.navigate(['..', 'tasks'], {
			relativeTo: this._activatedRoute,
		});
	}

	/**
	 * Opens the task and updates if necessary
	 *
	 * @param {string} taskId
	 * @return {Promise<void>}
	 */
	async openTask(taskId: string): Promise<void> {
		const task = this._tasksService.getTask(taskId);
		const taskAnswer = this._taskAnswers.find((answer) => answer.taskId === taskId) || null;
		const copyAnswer = JSON.parse(JSON.stringify(taskAnswer));
		const dialogRef = this._matDialog.open(CaseTaskDetailsComponent, {
			autoFocus: false,
			data: {
				case$: this._tasksService.case$,
				task$: task,
				linkedEntity$: this._tasksService.linkedEntity$,
				files$: this._tasksService.files$,
				taskAnswer$: taskAnswer,
			},
		});
		const data = await lastValueFrom(dialogRef.afterClosed());
		if (!data) return;
		// set task automatically to 'in-progress' if value/verifiedValue is present
		if (data.verifiedValue || data.value) data.listId = `${task.boardId}${CaseTaskLists.inProgress.suffix}`;
		const oldAnswer = taskAnswer ? taskAnswer.value : null;
		const verifiedValChanged = !this.areEqual(oldAnswer, data.verifiedValue, task.riskCriterion.multipleCustomerInput);
		// create TaskAnswer if verifiedValue or verifiedValues changed
		if (!taskAnswer || verifiedValChanged) {
			const taskAnswerObj: TaskAnswer = {
				createdBy: this._authService.userId,
				created: new Date(),
				manual: true,
				value: data.verifiedValue,
				taskId: task.id,
			};
			data.verifiedValue = await this._caseService.createTaskAnswer(taskAnswerObj, task.boardId, task.id);
		} else data.verifiedValue = task.verifiedValue;
		data.editedBy = this._authService.userId;
		return await this._tasksService.updateTask(data.id, data);
	}

	/**
	 * Checks if strings or string arrays are eqal
	 *
	 * @param {(string[] | string)} arr1
	 * @param {(string[] | string)} arr2
	 * @param {boolean} multi
	 * @return {boolean}
	 */
	areEqual(arr1: string[] | string, arr2: string[] | string, multi: boolean): boolean {
		// values are strings
		if (!multi) return arr1 !== arr2;
		// both values are empty and therefor equal
		if (!arr1 && !arr2) return true;
		arr1 = arr1 as string[];
		arr2 = arr2 as string[];
		// array was empty before or is empty now
		if ((!arr1 && arr2) || (arr1 && !arr2)) return false;
		// check if arrays have same length and same elements
		return arr1.length === arr2.length && arr1.every((element) => arr2.includes(element));
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {Task[]}
	 */
	get tasks(): Task[] {
		return this._tasks;
	}

	/**
	 * @type {Task[]}
	 */
	get openTasks(): Task[] {
		return this._openTasks;
	}
}
