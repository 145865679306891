import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';

// Environment
import { environment as env } from 'environments/environment';

// Instantsearch
import type { IndexWidget, Widget } from 'instantsearch.js';
import instantsearch from 'instantsearch.js';
import InstantSearch from 'instantsearch.js/es/lib/InstantSearch';

const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);

@Injectable()
export class InstantSearchService {
	public instantSearchInstance: InstantSearch;

	constructor() {
		this.instantSearchInstance = instantsearch({
			searchClient,
			future: { preserveSharedStateOnUnmount: true },
		});
	}

	/**
	 * Start the instant search
	 * @return { void }
	 */
	start(): void {
		this.instantSearchInstance.start();
		return;
	}

	/**
	 * Add the widgets to the instant search
	 * @param { Array<IndexWidget | Widget> } widgets
	 * @return { void }
	 */
	addWidgets(widgets: Array<IndexWidget | Widget>): void {
		this.instantSearchInstance.addWidgets(widgets);
		return;
	}

	/**
	 * Remove the widgets to the instant search
	 * @param { Array<Widget | IndexWidget> } widgets
	 * @return { void }
	 */
	removeWidgets(widgets: Array<Widget | IndexWidget>): void {
		this.instantSearchInstance.removeWidgets(widgets);
		return;
	}

	/**
	 * Dispose the search
	 * @return { void }
	 */
	dispose(): void {
		this.instantSearchInstance.dispose();
		return;
	}
}
