<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="linkedRecord" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('documentList.title') }}</div>
			<!-- Actions -->
			<div *ngIf="editMode" class="space-x-2">
				<button mat-icon-button color="primary" [matTooltip]="t('common.saveChanges')" (click)="saveChanges()">
					<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
				</button>
				<button mat-icon-button color="warn" [matTooltip]="t('common.cancel')" (click)="toggleEditMode()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
			<div *ngIf="!editMode">
				<div class="flex">
					<div [matTooltip]="t('common.notPermission')" [matTooltipDisabled]="canEdit" *ngIf="fileList.length > 0">
						<button
							mat-icon-button
							color="primary"
							[matTooltip]="t('documentList.edit')"
							(click)="toggleEditMode()"
							[disabled]="!canEdit"
							id="btnEditDocumentList"
						>
							<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
						</button>
					</div>
					<div [matTooltip]="t('common.notPermission')" [matTooltipDisabled]="canCreate">
						<button
							mat-icon-button
							color="primary"
							(click)="fileInput.click()"
							[matTooltip]="t('common.uploadFiles')"
							[disabled]="!canCreate"
							id="btnCreateDocumentList"
						>
							<mat-icon [svgIcon]="'heroicons_solid:plus-small'"></mat-icon>
						</button>
					</div>
				</div>
				<input (change)="addFiles($event)" type="file" #fileInput id="fileInput" class="hidden" multiple />
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<table class="w-full" mat-table [dataSource]="fileList">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.name') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.name }}
					</td>
				</ng-container>
				<ng-container matColumnDef="contents">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.contents') }}</th>
					<td mat-cell *matCellDef="let fileData">
						<ng-container *ngIf="editMode">
							<mat-form-field class="mt-2">
								<mat-select
									[value]="fileData.contents"
									[placeholder]="t('documentList.contents')"
									(selectionChange)="changeFileContentType(fileData, $event)"
								>
									<mat-option *ngFor="let fileType of fileContentTypes | keyvalue" [value]="fileType.key">
										{{ fileType.value }}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</ng-container>
						<ng-container *ngIf="!editMode">
							<span *ngIf="fileContentTypes[fileData.contents]">
								{{ fileContentTypes[fileData.contents] }}
							</span>
							<span *ngIf="!fileContentTypes[fileData.contents]" class="flex justify-center">
								<mat-progress-spinner *ngIf="true" [diameter]="18" [mode]="'indeterminate'"></mat-progress-spinner>
							</span>
						</ng-container>
					</td>
				</ng-container>
				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.type') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.type }}
					</td>
				</ng-container>
				<ng-container matColumnDef="size">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.size') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ formatBytes(fileData.size) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="createdBy">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.createdBy') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ getUserName(fileData.createdBy) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.created') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.created ? (fileData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let fileData">
						<div class="flex flex-row justify-center">
							<button mat-icon-button color="primary" (click)="openFile(fileData)" [matTooltip]="t('common.openFile')">
								<mat-icon [svgIcon]="'mat_solid:search'"></mat-icon>
							</button>
							<button mat-icon-button (click)="downloadFile(fileData)" [matTooltip]="t('common.downloadFile')">
								<mat-icon [svgIcon]="'mat_solid:cloud_download'"></mat-icon>
							</button>
							<div [matTooltip]="t('common.notPermission')" [matTooltipDisabled]="canDelete">
								<button
									mat-icon-button
									color="warn"
									(click)="deleteFile(fileData)"
									[matTooltip]="t('common.deleteFile')"
									[disabled]="!canDelete"
								>
									<mat-icon [svgIcon]="'mat_solid:delete'"></mat-icon>
								</button>
							</div>
						</div>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
