import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import algoliasearch from 'algoliasearch/lite';
import { environment as env } from 'environments/environment';
import { Timestamp } from 'firebase/firestore';
import { connectConfigure } from 'instantsearch.js/es/connectors';
import { Subject, finalize, lastValueFrom, takeUntil } from 'rxjs';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { InstantSearchService } from '../../search/instant-search.service';

// types & enums
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { Collection } from 'app/shared/enums/collection';
import { Company, Contact, ICompany, IContact } from 'app/shared/types/entityTypes';
import { CompanyQuickCreateComponent } from '../company-quick-create/company-quick-create.component';

const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);
@Component({
	selector: 'contact-quick-create',
	templateUrl: './contact-quick-create.component.html',
	styleUrls: ['./contact-quick-create.component.scss'],
})
export class ContactQuickCreateComponent implements OnInit, OnDestroy {
	private _contactForm: UntypedFormGroup;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _entityObserver: any;
	private _companyName: string = '';
	private _duplicates: Partial<Contact>[] = [];
	columnsToDisplay = ['name', 'owner', 'createdBy', 'created'];
	searchConfig = {
		indexName: '',
	};
	showResults = false;

	isInCreationMode: boolean = false;
	constructor(
		private _dialogRef: MatDialogRef<ContactQuickCreateComponent>,
		private _formBuilder: UntypedFormBuilder,
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService,
		private _dialog: MatDialog,
		private _platformInformationService: PlatformInformationService,
		private _instantSearchService: InstantSearchService,
		@Inject(MAT_DIALOG_DATA) private _company: string
	) {
		this.searchConfig.indexName = `${this._authService.accountId}_${Collection.ENTITIES}`;
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		this._contactForm = this._formBuilder.group({
			lastName: ['', [Validators.required, Validators.pattern(/\w+/)]],
			firstName: ['', [Validators.required, Validators.pattern(/\w+/)]],
			company: [''],
		});

		this._instantSearchService.addWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: this.searchConfig.indexName,
					filters: 'type:Company',
					hitsPerPage: 5,
				} as any,
			}),
		]);
		if (!this._company) return;
		this._entityHelperService
			.readEntity(this._company)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._entityObserver.unsubscribe();
				})
			)
			.subscribe((entityObserver) => {
				this._entityObserver = entityObserver;
				this._companyName = entityObserver.data.displayName;
				this._contactForm.patchValue({ company: this._company });
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();

		this._instantSearchService.removeWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: this.searchConfig.indexName,
					filters: 'type:Company',
					hitsPerPage: 5,
				} as any,
			}),
		]);
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Save
	 * @param {boolean} [continueOn]
	 * @return {Promise<void>}
	 */
	async save(continueOn?: boolean): Promise<void> {
		this.isInCreationMode = true;
		const noDuplicate = await this._duplicateCheck();
		if (!noDuplicate && !continueOn) return;
		const newContact = await this._createContact().catch((err) => {
			console.error(err);
			this._dialogRef.close();
			this.isInCreationMode = false;
		});
		this._dialogRef.close(newContact);
		this.isInCreationMode = false;
	}

	/**
	 * Closes Dialog
	 * @return {void}
	 */
	close(): void {
		this._dialogRef.close();
	}

	/**
	 * Sets query for algolia search
	 * @param {{ query: string; object: any }} query
	 * @return {void}
	 */
	setQuery(query: { query: string; object: any }): void {
		if (!query) return;
		this._contactForm.get('company').setValue(query.object.objectID);
		this._companyName = query.object.displayName;
	}

	/**
	 * Returns displayname of user
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	/**
	 * Returns formatted date
	 * @param {Timestamp} date
	 * @return {Date}
	 */
	getDate(date: Timestamp): Date {
		return new Timestamp(date['_seconds'], date['_nanoseconds']).toDate();
	}

	/**
	 * Opens an entity out of found duplicates
	 * @param {any} entity
	 * @return {void}
	 */
	openEntity(entity: any): void {
		entity.id = entity.objectID;
		this._dialogRef.close(entity);
	}

	/**
	 * Creates a new company
	 *
	 * @return {Promise<void> }
	 */
	async addCompany(): Promise<void> {
		const dialogRef = this._dialog.open(CompanyQuickCreateComponent);
		const result: ICompany = await lastValueFrom(dialogRef.afterClosed());
		if (!result) return;
		this._companyName = new Company(result).displayName;
		this._contactForm.patchValue({ company: result.id });
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Creates a new contact and returns it
	 * @return {Promise<Contact>}
	 */
	private async _createContact(): Promise<Contact> {
		const newContact: Contact = new Contact(this._contactForm.value);
		// set owner and parent
		newContact.created = new Date();
		newContact.createdBy = this._authService.userId;
		newContact.parent = this._authService.accountId;
		newContact.owner = this._authService.userId;
		const ref = await this._entityHelperService.createEntity({ ...newContact } as Contact);
		if (ref) newContact.id = ref.id;
		return newContact;
	}

	/**
	 * Returns true/false if contact has potential duplicates
	 * @return {Promise<boolean>}
	 */
	private async _duplicateCheck(): Promise<boolean> {
		const formVal = this._contactForm.value;
		const displayName = [formVal.lastName.trim(), formVal.firstName.trim()].join(', ');
		const index = searchClient.initIndex(this.searchConfig.indexName);
		const hits = await index.search(displayName, {
			filters: 'type:CONTACT',
		});
		if (!hits) return;
		hits.hits.forEach((hit) => {
			this._duplicates.push(new Contact(hit as unknown as IContact));
		});
		return hits.hits.length === 0;
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {UntypedFormGroup}
	 */
	get contactForm(): UntypedFormGroup {
		return this._contactForm;
	}

	/**
	 * @type {string}
	 */
	get companyName(): string {
		return this._companyName ? this._companyName : '';
	}

	/**
	 * @type {Partial<Contact>[]}
	 */
	get duplicates(): Partial<Contact>[] {
		return this._duplicates;
	}
}
