<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('platform.entity.entityDetails.title') }}</div>
			<!-- Actions -->
			<div class="flex flex-row" *ngIf="!editMode">
				<ng-container *ngIf="!isOpen">
					<button
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.entityDetails.openEntity')"
						(click)="openEntity()"
						[disabled]="!canView"
					>
						<mat-icon [svgIcon]="'heroicons_solid:folder-open'"></mat-icon>
					</button>
				</ng-container>
				<div [matTooltip]="t('platform.entity.entityDetails.notPermission')" [matTooltipDisabled]="canEdit">
					<button
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.entityDetails.edit')"
						(click)="toggleEditMode()"
						[disabled]="!canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
					</button>
				</div>
			</div>
			<div *ngIf="editMode" class="space-x-2">
				<button
					mat-icon-button
					color="primary"
					[matTooltip]="t('platform.entity.common.saveChanges')"
					[disabled]="contactForm.invalid || isInvalidCompany"
					(click)="saveChanges()"
				>
					<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
				</button>
				<button mat-icon-button color="warn" [matTooltip]="t('platform.entity.common.cancel')" (click)="cancelEdit()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<ng-container *ngIf="!editMode; else editView">
			<div class="flex flex-row pt-6">
				<div id="leftCol" class="flex flex-col space-y-4 basis-1/2">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">{{ t('platform.entity.common.name') }}</div>
						<div>{{ entity.displayName }}</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">{{ t('platform.entity.common.owner') }}</div>
						<div>{{ getUserName(entity.owner) }}</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.dateOfBirth') }}
						</div>
						<div>
							{{ entity.dateOfBirth ? (entity.dateOfBirth.toDate() | date: 'longDate') : '-' }}
						</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.gender') }}
						</div>
						<div>{{ entity.gender }}</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.company') }}
						</div>
						<div>{{ companyName }}</div>
					</div>
				</div>
				<div id="rightCol" class="flex flex-col space-y-4 basis-1/2">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.createdBy') }}
						</div>
						<div>
							{{ getUserName(entity.createdBy) ? getUserName(entity.createdBy) : t('platform.entity.entityDetails.deletedUser') }}
						</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.createdAt') }}
						</div>
						<div>
							{{ entity.created ? (entity.created.toDate() | date: 'short') : '-' }}
						</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.modifiedBy') }}
						</div>
						<div>
							{{ getUserName(entity.editedBy) ? getUserName(entity.editedBy) : t('platform.entity.entityDetails.deletedUser') }}
						</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.modifiedAt') }}
						</div>
						<div>
							{{ entity.edited != undefined ? (entity.edited.toDate() | date: 'short') : '' }}
						</div>
					</div>
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.politicallyExposedPerson') }}
						</div>
						<div>{{ entity.pep }}</div>
					</div>
				</div>
			</div>
			<div class="flex flex-row pb-6">
				<div id="lastFullRow" class="flex flex-col mt-4">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.entityDetails.notes') }}
						</div>
						<div>{{ entity.notes }}</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<ng-template #editView>
		<form [formGroup]="contactForm">
			<div class="flex flex-row w-full space-x-4">
				<div id="leftColEdit" class="flex flex-col space-y-4 basis-1/2">
					<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense">
						<mat-label>{{ t('platform.entity.entityDetails.lastNames') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
						<input
							matInput
							[formControlName]="'lastName'"
							[placeholder]="t('platform.entity.entityDetails.lastName')"
							[spellcheck]="false"
							(input)="onChangeName()"
						/>
						<mat-error *ngIf="contactForm.get('lastName').hasError('required') || contactForm.get('lastName').hasError('pattern')">
							{{ t('platform.entity.common.required') }}
						</mat-error>
					</mat-form-field>
					<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense">
						<mat-label>{{ t('platform.entity.entityDetails.firstNames') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:user-circle'"></mat-icon>
						<input
							matInput
							[formControlName]="'firstName'"
							[placeholder]="t('platform.entity.entityDetails.firstName')"
							[spellcheck]="false"
							(input)="onChangeName()"
						/>
						<mat-error *ngIf="contactForm.get('firstName').hasError('required') || contactForm.get('firstName').hasError('pattern')">
							{{ t('platform.entity.common.required') }}
						</mat-error>
						<mat-error *ngIf="isDuplicateEntityName">{{ t('platform.entity.entityDetails.duplicatesWereFound') }}</mat-error>
					</mat-form-field>
					<div class="searchbox">
						<app-autocomplete
							(onQuerySuggestionClick)="setQuery($event)"
							[placeholder]="t('platform.entity.entityDetails.typeSearchCompany')"
							[presetValue]="companyName"
							[label]="t('platform.entity.entityDetails.company')"
							[showPreview]="true"
							[isBlurEventCheck]="true"
							(checkForError)="checkAndSetForError($event)"
							[errorMessage]="t('platform.entity.entityDetails.invalidCompanyName')"
						></app-autocomplete>
					</div>
				</div>
				<div id="rightColEdit" class="flex flex-col space-y-4 basis-1/2">
					<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense">
						<mat-label>{{ t('platform.entity.entityDetails.gender') }}</mat-label>
						<mat-select [formControlName]="'gender'">
							<mat-option *ngFor="let gender of Gender | keyvalue" [value]="gender.value">
								{{ gender.value }}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="fuse-mat-no-subscript w-full fuse-mat-dense">
						<mat-label>{{ t('platform.entity.entityDetails.dateOfBirth') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:cake'"></mat-icon>
						<input
							matInput
							[matDatepicker]="birthdayDatepicker"
							[formControlName]="'dateOfBirth'"
							[placeholder]="t('platform.entity.entityDetails.dateOfBirth')"
							[max]="currentDate"
						/>
						<mat-datepicker-toggle matSuffix [for]="birthdayDatepicker"> </mat-datepicker-toggle>
						<mat-datepicker #birthdayDatepicker startView="year"></mat-datepicker>

						<mat-error *ngIf="contactForm.get('dateOfBirth').invalid">
							{{ t('platform.entity.entityDetails.invalidDob') }}
						</mat-error>
					</mat-form-field>

					<mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript fuse-mat-dense">
						<mat-label>{{ t('platform.entity.entityDetails.politicallyExposedPerson') }}</mat-label>
						<input class="hidden" matInput [hidden]="true" [formControlName]="'pep'" />
						<mat-checkbox color="primary" [formControlName]="'pep'"></mat-checkbox>
					</mat-form-field>
				</div>
			</div>

			<div class="flex flex-row w-full mt-4 space-y-4 basis-1/2">
				<mat-form-field class="fuse-mat-textarea fuse-mat-no-subscript fuse-mat-dense flex-row w-full">
					<mat-label>{{ t('platform.entity.entityDetails.notes') }}</mat-label>
					<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:annotation'"></mat-icon>
					<textarea matInput [formControlName]="'notes'" [placeholder]="t('platform.entity.entityDetails.notes')" [spellcheck]="false">
					</textarea>
				</mat-form-field>
			</div>
		</form>
	</ng-template>
</ng-container>
