<ng-container *transloco="let t; read: 'platform.entity'">
	<div class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate pb-4">{{ t('entityDetails.title') }}</div>
		<div class="flex flex-row">
			<div id="leftCol" class="flex flex-col space-y-4 basis-1/2">
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">{{ t('common.name') }}</div>
					<div>{{ entity.displayName }}</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">{{ t('common.owner') }}</div>
					<div>{{ getUserName(entity.owner) }}</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.dateOfBirth') }}
					</div>
					<div>
						{{ entity.dateOfBirth ? (entity.dateOfBirth.toDate() | date: 'longDate') : '-' }}
					</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.gender') }}
					</div>
					<div>{{ entity.gender }}</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.company') }}
					</div>
					<div>{{ companyName }}</div>
				</div>
			</div>
			<div id="rightCol" class="flex flex-col space-y-4 basis-1/2">
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.createdBy') }}
					</div>
					<div>
						{{ getUserName(entity.createdBy) ? getUserName(entity.createdBy) : t('entityDetails.deletedUser') }}
					</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.createdAt') }}
					</div>
					<div>
						{{ entity.created ? (entity.created.toDate() | date: 'short') : '-' }}
					</div>
				</div>
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.politicallyExposedPerson') }}
					</div>
					<div>{{ entity.pep ? t('entityDetails.true') : t('entityDetails.false') }}</div>
				</div>
			</div>
		</div>
		<div class="flex flex-row">
			<div id="lastFullRow" class="flex flex-col mt-4 space-y-4">
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('entityDetails.notes') }}
					</div>
					<div>{{ entity.notes }}</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
