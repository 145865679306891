import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
// types
import { Task } from 'app/shared/types/taskboardTypes';
// rxjs
import { Observable, Subject } from 'rxjs';
//enums
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { ApexOptions } from 'ng-apexcharts';

@Component({
	selector: 'case-completion-element',
	templateUrl: './case-completion-element.component.html',
	styleUrls: ['./case-completion-element.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseCompletionElementComponent implements OnInit, OnChanges, OnDestroy {
	@Input() tasks: Task[];

	private _tasksDone = 0;
	private _tasksRemaining = 0;
	private _completionRate = 0;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _progressTranslatedText: string = '';

	caseCompletionChart: ApexOptions;

	constructor(
		private _translocoService: TranslocoService,
		@Inject(TRANSLOCO_SCOPE) private scope
	) {
		// Get translated text by key
		this._getTranslatedTextByKey('cases.dashboard.caseCompletionElement.progress').subscribe((result) => {
			this._progressTranslatedText = result;
		});

		this.caseCompletionChart = {
			chart: {
				height: 200,
				type: 'radialBar',
			},
			series: [this._completionRate],
			labels: [this._progressTranslatedText],
		};
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._initValues();
	}

	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.tasks) return;
		this._initValues();
	}

	// -------------------------------------------------------------------------
	// @ Private Methods
	// -------------------------------------------------------------------------
	private _initValues() {
		this._tasksDone = 0;
		this._tasksRemaining = 0;
		this._completionRate = 0;
		this.tasks.forEach((task) => {
			if (task.listId.includes('done')) this._tasksDone++;
			else this._tasksRemaining++;

			let completionRate = Math.round((this._tasksDone / this.tasks.length) * 100);

			this.caseCompletionChart.series = [completionRate];
			this._completionRate = completionRate;
		});
	}

	/**
	 * Get translated text by key
	 * @param { string } key
	 * @return { Observable }
	 */
	private _getTranslatedTextByKey(key: string): Observable<string> {
		return this._translocoService.selectTranslate(key, {}, this.scope);
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get completionRate() {
		return this._completionRate;
	}

	get tasksRemaining(): number {
		return this._tasksRemaining;
	}

	get tasksDone(): number {
		return this._tasksDone;
	}
}
