<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.entity.addressDetails.addressDetails') }}
			</div>
			<!-- Actions -->
			<div *ngIf="!editMode" class="">
				<div [matTooltip]="t('platform.entity.addressDetails.notPermission')" [matTooltipDisabled]="canEdit">
					<button
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.addressDetails.edit')"
						(click)="toggleEditMode()"
						[disabled]="!canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
					</button>
				</div>
			</div>
			<div *ngIf="editMode" class="space-x-2">
				<button
					mat-icon-button
					color="primary"
					[matTooltip]="t('platform.entity.common.saveChanges')"
					[disabled]="!addressesForm.valid"
					(click)="saveChanges()"
				>
					<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
				</button>
				<button mat-icon-button color="warn" [matTooltip]="t('platform.entity.common.cancel')" (click)="cancelEdit()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<ng-container *ngIf="!editMode; else editView">
				<div id="leftCol" class="flex flex-col space-y-4">
					<div class="flex flex-row space-x-4">
						<div class="min-w-20 font-light text-slate-600">
							{{ t('platform.entity.addressDetails.address') }}
						</div>
						<div>
							<ng-container *ngIf="entity.addresses">
								<div class="flex">
									<mat-icon [svgIcon]="'heroicons_outline:map-pin'"></mat-icon>
									<div class="min-w-0 ml-6 space-y-1">
										<ng-container *ngFor="let address of entity.addresses; trackBy: trackByFn">
											<div class="flex items-center leading-6">
												<div>{{ formatAddress(address) }}</div>
												<div class="text-md truncate text-secondary" *ngIf="address.label">
													<span class="mx-2">&bull;</span>
													<span class="font-medium">{{ address.label }}</span>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<ng-template #editView>
		<form [formGroup]="addressesForm" class="flex flex-col w-full space-y-2">
			<ng-container
				*ngFor="
					let address of addressesForm.get('addresses')['controls'];
					let i = index;
					let first = first;
					let last = last;
					trackBy: trackByFn
				"
			>
				<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense">
					<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.addressLine') }} 1</mat-label>
					<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
					<input
						matInput
						[formControl]="address.get('line1')"
						[placeholder]="t('platform.entity.addressDetails.addressLine') + ' 1'"
						[spellcheck]="false"
					/>
					<mat-error *ngIf="address.get('line1').hasError('required')">
						{{ t('platform.entity.common.required') }}
					</mat-error>
					<mat-error *ngIf="address.get('line1').hasError('pattern')">
						{{ t('platform.entity.common.invalid') }}
					</mat-error>
				</mat-form-field>
				<mat-form-field class="fuse-mat-no-subscript fuse-mat-dense">
					<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.addressLine') }} 2</mat-label>
					<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
					<input
						matInput
						[formControl]="address.get('line2')"
						[placeholder]="t('platform.entity.addressDetails.addressLine') + ' 2'"
						[spellcheck]="false"
					/>
					<mat-error *ngIf="address.get('line2').hasError('pattern')">
						{{ t('platform.entity.common.invalid') }}
					</mat-error>
				</mat-form-field>
				<div class="flex flex-row space-x-2">
					<mat-form-field class="fuse-mat-no-subscript flex-auto max-w-xs">
						<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.zipCode') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:envelope'"></mat-icon>
						<input
							matInput
							[formControl]="address.get('zip_code')"
							[placeholder]="t('platform.entity.addressDetails.zipCode')"
							[spellcheck]="false"
						/>
						<mat-error *ngIf="address.get('zip_code').hasError('required')">
							{{ t('platform.entity.common.required') }}
						</mat-error>
						<mat-error *ngIf="address.get('zip_code').hasError('pattern')">
							{{ t('platform.entity.common.invalid') }}
						</mat-error>
					</mat-form-field>
					<mat-form-field class="fuse-mat-no-subscript flex-auto">
						<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.city') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:map-pin'"></mat-icon>
						<input
							matInput
							[formControl]="address.get('city')"
							[placeholder]="t('platform.entity.addressDetails.city')"
							[spellcheck]="false"
						/>
						<mat-error *ngIf="address.get('city').hasError('required')">
							{{ t('platform.entity.common.required') }}
						</mat-error>
						<mat-error *ngIf="address.get('city').hasError('pattern')">
							{{ t('platform.entity.common.invalid') }}
						</mat-error>
					</mat-form-field>
				</div>
				<div class="flex flex-row space-x-2">
					<mat-form-field class="fuse-mat-no-subscript flex-auto">
						<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.state') }}</mat-label>
						<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:globe-alt'"></mat-icon>
						<input
							matInput
							[formControl]="address.get('state')"
							[placeholder]="t('platform.entity.addressDetails.state')"
							[spellcheck]="false"
						/>
						<mat-error *ngIf="address.get('state').hasError('pattern')">
							{{ t('platform.entity.common.invalid') }}
						</mat-error>
					</mat-form-field>
					<mat-form-field class="fuse-mat-no-subscript flex-auto">
						<mat-label *ngIf="first">{{ t('platform.entity.addressDetails.country') }}</mat-label>
						<mat-select id="type" matSelect [formControl]="address.get('country')">
							<mat-select-trigger>
								<span class="flex items-center">
									<span
										class="hidden sm:flex w-6 h-4 mr-1 overflow-hidden"
										[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
										[style.backgroundSize]="'24px 3876px'"
										[style.backgroundPosition]="getCountryByIso(address.get('country').value).flagImagePos"
									></span>
									<span class="sm:mx-0.5 font-medium text-default">{{ getCountryByIso(address.get('country').value).name }}</span>
								</span>
							</mat-select-trigger>
							<mat-option *ngFor="let country of countries" [value]="country.iso">
								<span class="flex items-center">
									<span
										class="w-6 h-4 overflow-hidden"
										[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
										[style.backgroundSize]="'24px 3876px'"
										[style.backgroundPosition]="country.flagImagePos"
									></span>
									<span class="ml-2">{{ country.name }}</span>
								</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<mat-form-field class="fuse-mat-no-subscript flex-auto w-full max-w-24 sm:max-w-40">
					<mat-label *ngIf="first">{{ t('platform.entity.common.label') }}</mat-label>
					<mat-icon matPrefix class="hidden sm:flex icon-size-5" [svgIcon]="'heroicons_solid:tag'"></mat-icon>
					<input matInput [formControl]="address.get('label')" [placeholder]="t('platform.entity.common.label')" />
					<mat-error *ngIf="address.get('label').hasError('pattern')">
						{{ t('platform.entity.common.invalid') }}
					</mat-error>
				</mat-form-field>
				<!-- Remove email -->
				<ng-container *ngIf="!(first && last)">
					<div class="flex items-center w-10 pl-2" [ngClass]="{ 'mt-6': first }">
						<button
							class="w-8 h-8 min-h-8"
							mat-icon-button
							(click)="removeAddress(i)"
							matTooltip="{{ t('platform.entity.addressDetails.remove') }}"
						>
							<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
						</button>
					</div>
				</ng-container>
			</ng-container>
			<div class="group inline-flex items-center mt-2 -ml-4 py-2 px-4 rounded cursor-pointer" (click)="addAddressField()">
				<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
				<span class="ml-2 font-medium text-secondary group-hover:underline">{{ t('platform.entity.addressDetails.addAddress') }}</span>
			</div>
		</form>
	</ng-template>
</ng-container>
