<ng-container *transloco="let t">
	<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">{{ t('platform.entity.createEntity.createContact') }}</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1" type="button">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<form class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" [formGroup]="contactForm">
			<ng-container *ngIf="!duplicates || duplicates.length == 0">
				<mat-form-field class="w-full">
					<mat-label>{{ t('platform.entity.entityDetails.lastName') }}</mat-label>
					<input id="name" matInput [formControlName]="'lastName'" />
					<mat-error *ngIf="contactForm.get('lastName').hasError('required') || contactForm.get('lastName').hasError('pattern')">
						{{ t('platform.entity.entityDetails.lastName') }}
						{{ t('platform.entity.createEntity.isRequired') }}
					</mat-error>
				</mat-form-field>
				<mat-form-field class="w-full">
					<mat-label>{{ t('platform.entity.entityDetails.firstName') }}</mat-label>
					<input id="name" matInput [formControlName]="'firstName'" />
					<mat-error *ngIf="contactForm.get('firstName').hasError('required') || contactForm.get('firstName').hasError('pattern')">
						{{ t('platform.entity.entityDetails.firstName') }}
						{{ t('platform.entity.createEntity.isRequired') }}
					</mat-error>
				</mat-form-field>

				<div class="searchbox flex flex-row w-full items-center">
					<app-autocomplete
						(onQuerySuggestionClick)="setQuery($event)"
						[placeholder]="t('platform.entity.createEntity.searchForCompany')"
						[presetValue]="companyName"
						[label]="t('platform.entity.entityDetails.company')"
						class="w-full h-full"
					></app-autocomplete>
					<button class="ml-2 mt-2" mat-icon-button color="primary" (click)="addCompany()" type="button">
						<mat-icon class="text-current" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
					</button>
				</div>
			</ng-container>
			<ng-container *ngIf="duplicates && duplicates.length > 0">
				<div>
					<ng-container *ngTemplateOutlet="duplicateList"></ng-container>
				</div>
			</ng-container>
			<!-- Actions -->
			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="flex items-center mt-4 sm:mt-0">
					<button class="sm:mx-3" mat-stroked-button (click)="close()" type="button">
						<span>{{ t('platform.entity.common.cancel') }}</span>
					</button>
					<!-- Save -->
					<button
						*ngIf="!duplicates || duplicates.length == 0"
						class="order-first sm:order-last"
						mat-flat-button
						[color]="'primary'"
						(click)="save()"
						type="submit"
						[disabled]="contactForm.invalid || isInCreationMode"
					>
						<mat-progress-spinner *ngIf="isInCreationMode" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
						<span *ngIf="!isInCreationMode">{{ t('platform.entity.createEntity.createContact') }}</span>
					</button>
					<button
						*ngIf="duplicates && duplicates.length > 0"
						class="order-first sm:order-last"
						mat-flat-button
						[color]="'primary'"
						(click)="save(true)"
						type="submit"
						[disabled]="contactForm.invalid"
					>
						<span>{{ t('platform.entity.createEntity.continue') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>

	<!-- Duplicate template -->
	<ng-template #duplicateList>
		<span class="text-secondary">{{ t('platform.entity.createEntity.potentialDuplicatesFound') }}?</span>
		<table class="w-full bg-transparent table-auto" mat-table matSort [dataSource]="duplicates" #resultsTable>
			<!-- Name -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.name') }}</th>
				<td mat-cell *matCellDef="let entity">
					<span class="pr-6 whitespace-nowrap truncate">
						{{ entity.displayName }}
					</span>
				</td>
			</ng-container>
			<!-- Owner -->
			<ng-container matColumnDef="owner">
				<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.owner') }}</th>
				<td mat-cell *matCellDef="let entity">
					<span class="pr-6 whitespace-nowrap truncate">
						{{ getUserName(entity.owner) }}
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="createdBy">
				<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.createEntity.creator') }}</th>
				<td mat-cell *matCellDef="let entity">
					{{ getUserName(entity.createdBy) }}
				</td>
			</ng-container>
			<ng-container matColumnDef="created">
				<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.entityDetails.createdAt') }}</th>
				<td mat-cell *matCellDef="let entity">
					{{ getDate(entity.created) | date: 'short' }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr class="order-row h-16 hover:bg-gray-50" mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="openEntity(row)"></tr>
		</table>
	</ng-template>
</ng-container>
