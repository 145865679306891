import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';

// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { EntityRelation } from 'app/shared/types/entity-relation';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { RelationshipBehaviors, RelationshipType } from 'app/shared/types/relationshipType';

// rxjs

// components
import { MatTableDataSource } from '@angular/material/table';
import { SearchEntitiesComponent } from 'app/shared/components/entities/search-entities/search-entities.component';

@Component({
	selector: 'entity-relations-list',
	templateUrl: './entity-relations-list.component.html',
	styleUrls: ['./entity-relations-list.component.scss'],
})
export class EntityRelationsListComponent implements OnInit {
	@Input() entity: Contact | Company;
	@Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
	columnsToDisplay = ['type', 'parent', 'actions'];

	private _entityRelations: EntityRelation[] = [];
	private _entityDataSource: MatTableDataSource<EntityRelation> = new MatTableDataSource<EntityRelation>();
	private _relationshipTypes: { [key: string]: RelationshipType } = {};
	private _availableRelationships: RelationshipType[] = [];

	constructor(
		private _authService: AuthService,
		private _entityHelperService: EntityHelperService,
		private _dialog: MatDialog,
		private _router: Router
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	async ngOnInit(): Promise<void> {
		await this._initRelations();
		return;
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Initializes entity relations and sets up datasource for displaying
	 * @return {Promise<void>}
	 */
	private async _initRelations(): Promise<void> {
		const relTypes = await this._entityHelperService.getRelationTypes();

		relTypes.forEach((doc) => {
			this._relationshipTypes[doc.id] = doc;
			this._availableRelationships.push(doc);
		});

		this._prepareRelationshipTypes();
		this._entityRelations = await this._entityHelperService.getEntityRelationsGraphDB(this.entity.id);
		this._prepareEntityDataSource(this._entityRelations, true);

		return;
	}

	/**
	 * Prepares availabel relationshiptypes
	 * Filter disabled, unrelated for current entitytype or already in use (1:N)
	 * Can not be filtered in database call as there is no or clause possible
	 *
	 * @return {void}
	 */
	private _prepareRelationshipTypes(): void {
		const entityType = this.entity.type;
		this._availableRelationships = Object.values(this._relationshipTypes).filter((relType) => {
			if (!relType.enabled) return false;
			if (relType.primaryEntity !== entityType && relType.relatedEntity !== entityType) return false;
			const isUsed = this._entityRelations.some((relation) => relation.type === relType.id);
			if (relType.behavior === RelationshipBehaviors.ONETOMANY && entityType === relType.relatedEntity && isUsed) return false;
			return true;
		});
	}

	/**
	 * Prepares datasource for entity relations
	 * @param {EntityRelation[]} entityRelations
	 * @param {boolean} onlyDirect - if true, extended relations will be filtered out
	 * @return {void}
	 */
	private _prepareEntityDataSource(entityRelations: EntityRelation[], onlyDirect: boolean): void {
		if (onlyDirect) {
			entityRelations = entityRelations.filter((relation) => {
				return relation.parent.id === this.entity.id || relation.child.id === this.entity.id;
			});
		}

		this._entityDataSource.data = entityRelations;
		this._prepareRelationshipTypes();
		return;
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	/**
	 * Adds a new relation with search dialog
	 * Prevents adding duplicates or invalid relations
	 *
	 * @param {EntityRelation} type
	 * @return {Promise<void>}
	 */
	async addEntityRelation(type: EntityRelation): Promise<void> {
		if (!this._relationshipTypes[type.id]) return;
		const relationshipType = this._relationshipTypes[type.id];
		const isParent = relationshipType.primaryEntity === this.entity.type;
		const typeFilter = isParent ? relationshipType.relatedEntity : relationshipType.primaryEntity;
		const excludedIds = await this._getExludedEntityIds(relationshipType);
		const dialogRef = this._dialog.open(SearchEntitiesComponent, {
			data: { excludeIds: excludedIds, typeFilter: typeFilter },
		});
		dialogRef.afterClosed().subscribe(async (result: Company | Contact) => {
			if (!result) return;
			const parentid = isParent ? this.entity.id : result.id;
			const childid = isParent ? result.id : this.entity.id;
			const parentEntityName = isParent ? this.entity.displayName : result.displayName;
			const parentEntityType = isParent ? this.entity.type : result.type;
			const childEntityName = isParent ? result.displayName : this.entity.displayName;
			const childEntityType = isParent ? result.type : this.entity.type;

			const newRelation = await this._entityHelperService.createEntityRelation(
				childid,
				childEntityName,
				parentid,
				parentEntityName,
				type.id,
				this._relationshipTypes[type.id].description,
				childEntityType,
				parentEntityType
			);

			this._entityRelations.push(newRelation);
			this._prepareEntityDataSource(this._entityRelations, true);
			this.onRefresh.emit();
		});
	}

	/**
	 * Deletes a relation
	 *
	 * @param {EntityRelation} relation
	 * @return {Promise<void>}
	 */
	async deleteRelation(relation: EntityRelation): Promise<void> {
		const relationObj = this._entityRelations.find(
			(r) => r.type === relation.type && r.childId === relation.childId && r.parentId === relation.parentId
		);
		await this._entityHelperService.deleteRelation(relationObj);
		await this._initRelations();
		this._prepareEntityDataSource(this._entityRelations, true);
		this.onRefresh.emit();
		return;
	}

	/**
	 * Opens the linked entity
	 * @param {EntityRelation} relation
	 */
	openDetails(relation: EntityRelation): void {
		const id = relation.child.id === this.entity.id ? relation.parent.id : relation.child.id;
		this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
			this._router.navigate(['entities', id]);
		});
	}

	/**
	 * Returns entityname of linked entity
	 *
	 * @param {EntityRelation} relation
	 * @return {string}
	 */
	getEntityDisplayName(relation: EntityRelation): string {
		const entityDisplayName = relation.child.id === this.entity.id ? relation.parentEntityName : relation.childEntityName;
		if (!entityDisplayName) return '';
		return entityDisplayName;
	}

	/**
	 * Returns the description for the relation
	 *
	 * @param {EntityRelation} relation
	 * @return {string}
	 */
	getRelationshipDescription(relation: EntityRelation): string {
		const type = this._relationshipTypes[relation.type];
		if (!type) return '';
		if (relation.parent.id === this.entity.id) return type.reverseDescription;
		return type.description;
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Returns ids of entities that have choosen relation with current or if behaviour is 1:N with different entity
	 *
	 * @param {RelationshipType} relationshipType
	 * @return {Promise<string[]>}
	 */
	async _getExludedEntityIds(relationshipType: RelationshipType): Promise<string[]> {
		const isParent = relationshipType.primaryEntity === this.entity.type;
		// get all relations with the choosen type
		const existingRelations = await this._entityHelperService.searchEntityRelations([
			{ field: 'owner', operator: '==', value: this._authService.accountId },
			{ field: 'type', operator: '==', value: relationshipType.id },
		]);
		if (!existingRelations) return [];
		// filter entities that already have current relation with entity or if behavior is 1:N filter entities that already have current relation
		const relatedIds = existingRelations
			.filter((relation) => {
				if (isParent) {
					return relation.parentId === this.entity.id || relationshipType.behavior === RelationshipBehaviors.ONETOMANY;
				}
				return relation.childId === this.entity.id;
			})
			.map((relation) => {
				return isParent ? relation.childId : relation.parentId;
			});
		return [...relatedIds, this.entity.id];
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {EntityRelation[]}
	 */
	get entityRelations(): EntityRelation[] {
		return this._entityRelations;
	}

	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
	}

	/**
	 * @type {{ [key: string]: RelationshipType }}
	 */
	get relationshipTypes(): { [key: string]: RelationshipType } {
		return this._relationshipTypes;
	}

	/**
	 * @type {RelationshipType[]}
	 */
	get availableRelationships(): RelationshipType[] {
		return this._availableRelationships;
	}

	/**
	 * @type {MatTableDataSource<EntityRelation>}
	 */
	get entityDataSource(): MatTableDataSource<EntityRelation> {
		return this._entityDataSource;
	}
}
