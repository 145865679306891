import { Injectable, OnDestroy } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';

/** rxjs */
import { BehaviorSubject, finalize, Observable, Subject, takeUntil } from 'rxjs';

/** Services */
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';

/** Types and enums */
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { Collection } from 'app/shared/enums/collection';
import { CustomField } from 'app/shared/types/customFieldTypes';
import { QueryCondition } from 'app/shared/types/query-condition';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Injectable()
export class CustomFieldSettingsService implements OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _customFieldsData: BehaviorSubject<CustomField[]> = new BehaviorSubject([]);
	private _customFieldsDataObserver: DataObserver;
	private _customFieldsPath: string;

	constructor(
		private _authService: AuthService,
		private _dataService: DataService
	) {
		this._customFieldsPath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CUSTOMFIELDS}`;
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Get custom fields depending on the entity type
	 * @param { string } entityType
	 * @return { void }
	 */
	getFields(entityType: string): void {
		if (this._customFieldsDataObserver) this._customFieldsDataObserver.unsubscribe();
		this._dataService
			.queryDataObservable(this._customFieldsPath, [{ field: 'entityType', operator: '==', value: entityType }])
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._customFieldsDataObserver.unsubscribe();
				})
			)
			.subscribe((customFieldsObserver) => {
				this._customFieldsDataObserver = customFieldsObserver;
				// Sort the custom fields on label
				const sortedData = customFieldsObserver.data
					.slice()
					.sort((a: any, b: any) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
				this._customFieldsData.next(sortedData);
			});
	}

	/**
	 * Get custom fields array
	 * @param { AffectedCategory } type
	 * @return { Promise<CustomField[]> }
	 */
	readCustomFieldsData(type?: AffectedCategory, custom?: boolean): Promise<CustomField[]> {
		const condition: QueryCondition[] = [];
		if (type) condition.push({ field: 'entityType', operator: '==', value: type });
		if (custom !== undefined) condition.push({ field: 'custom', operator: '==', value: custom });
		return this._dataService.queryData(this._customFieldsPath, condition);
	}

	/**
	 * Create custom fields
	 * @param { CustomField } customField
	 * @return { (Promise<DocumentReference<any> | void>) }
	 */
	createCustomField(customField: CustomField): Promise<DocumentReference<any> | void> {
		customField.createdBy = this._authService.userId;
		customField.created = new Date();
		return this._dataService.storeDocument({ ...customField }, this._customFieldsPath);
	}

	/**
	 * Update a custom field
	 * @param { Partial<CustomField> } changes
	 * @return { Promise<void> }
	 */
	updateCustomField(changes: Partial<CustomField>): Promise<void> {
		return this._dataService.updateDocument(changes, this._customFieldsPath, changes.id);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * Get custom fields
	 * @type { Observable<CustomField[]> }
	 */
	get customFields$(): Observable<CustomField[]> {
		return this._customFieldsData.asObservable();
	}
}
