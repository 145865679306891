import { AfterContentInit, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import algoliasearch from 'algoliasearch/lite';
import { environment as env } from 'environments/environment';
import { BaseHit } from 'instantsearch.js';
import { connectConfigure, connectHits, connectSearchBox } from 'instantsearch.js/es/connectors';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

// services
import { AuthService } from 'app/core/auth/auth.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { InstantSearchService } from '../../search/instant-search.service';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Entity } from 'app/shared/enums/entity';
import { Company, Contact, ICompany, IContact } from 'app/shared/types/entityTypes';
// components
import { CompanyQuickCreateComponent } from '../company-quick-create/company-quick-create.component';
import { ContactQuickCreateComponent } from '../contact-quick-create/contact-quick-create.component';

const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);
@Component({
	selector: 'search-entities',
	templateUrl: './search-entities.component.html',
	styleUrls: ['./search-entities.component.scss'],
})
export class SearchEntitiesComponent implements OnDestroy, AfterContentInit {
	resultTableDef = ['type', 'name', 'owner'];
	private _results: BehaviorSubject<(Contact | Company)[]> = new BehaviorSubject([]);
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	searchParameters = {
		hitsPerPage: 5,
		query: '',
		filters: '',
	};

	public hits: BaseHit[] = [];
	public refine: (query: string) => void;
	public query: string;
	public presetValue = '';

	constructor(
		private _dialogRef: MatDialogRef<SearchEntitiesComponent>,
		private _authService: AuthService,
		private _dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)
		private _data: {
			excludeIds: [];
			typeFilter: Entity;
		},
		private _platformInformationService: PlatformInformationService,
		private _instantSearchService: InstantSearchService
	) {
		searchClient.clearCache();
		this.searchParameters.filters = 'type:' + this._data.typeFilter;

		this._instantSearchService.addWidgets([
			connectConfigure(() => {})({
				searchParameters: {
					index: `${this._authService.accountId}_${Collection.ENTITIES}`,
					filters: this.searchParameters.filters,
					hitsPerPage: this.searchParameters.hitsPerPage,
				} as any,
			}),
			connectSearchBox(({ refine, query }) => {
				this.refine = refine;
				this.query = query;
			})({
				// ...widgetParams
			}),
			connectHits(({ hits }) => {
				this.hits = this.transformItems(hits) as any;
			})({}),
		]);
	}

	ngAfterContentInit() {
		// Start the instant search
		this._instantSearchService.start();
	}

	/**
	 * Handle change event
	 * @param { KeyboardEvent } $event
	 * @return { void }
	 */
	handleChange($event: KeyboardEvent): void {
		// Assign value to query
		const val = ($event.target as HTMLInputElement).value;
		this.query = val;

		// Clear cache
		searchClient.clearCache();

		// Updated query search
		this.refine!(this.query);
		return;
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
		this._instantSearchService.dispose();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	/**
	 * Returns choosen entity and closes dialog
	 *
	 * @param {(Company | Contact)} entity
	 */
	selectEntity(entity: Company | Contact): void {
		this._dialogRef.close(entity);
	}

	/**
	 * Opens dialog to create new company and closes current dialog after creation
	 *
	 */
	addCompany(): void {
		const dialogRef = this._dialog.open(CompanyQuickCreateComponent);

		dialogRef.afterClosed().subscribe((result: Company) => {
			this._dialogRef.close(result);
		});
	}

	/**
	 * Opens dialog to create new contact and closes current dialog after creation
	 *
	 */
	addContact(): void {
		const dialogRef = this._dialog.open(ContactQuickCreateComponent);

		dialogRef.afterClosed().subscribe((result: Contact) => {
			this._dialogRef.close(result);
		});
	}
	/**
	 * Receives found items and is called before displaying them
	 * Removes excluded entities and formats output
	 */
	transformItems = (items) => {
		let results: (Company | Contact)[] = [];
		const excludeIds = this._data.excludeIds;
		items.forEach((entity) => {
			if (excludeIds && excludeIds.findIndex((e) => e === entity.objectID) !== -1) return;
			entity.id = entity.objectID;
			entity.owner = this._platformInformationService.getUserNameById(entity.owner);
			switch (entity.type) {
				case Entity.CONTACT:
					results.push(new Contact(entity as IContact));
					break;
				case Entity.COMPANY:
					results.push(new Company(entity as ICompany));
					break;
			}
		});
		return results;
	};

	/**
	 * Closes the dialog
	 */
	close(): void {
		this._dialogRef.close();
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {(Observable<(Company | Contact)[]>)}
	 */
	get results$(): Observable<(Company | Contact)[]> {
		return this._results;
	}
	/**
	 * @type {typeof Entity}
	 */
	get entityType(): typeof Entity {
		return Entity;
	}
	/**
	 * @type {Entity}
	 */
	get entityTypeFilter(): Entity {
		return this._data.typeFilter;
	}
	/**
	 * @type {boolean}
	 */
	get canCreate(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.create);
	}
}
