<ng-container *transloco="let t">
	<!-- Button -->
	<button mat-icon-button [matMenuTriggerFor]="userActions">
		<span class="relative">
			<img class="w-7 h-7 rounded-full" *ngIf="showAvatar && avatarURL" [src]="avatarURL" />
			<mat-icon *ngIf="!showAvatar || !avatarURL" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
		</span>
	</button>

	<mat-menu [xPosition]="'before'" #userActions="matMenu">
		<button mat-menu-item>
			<span class="flex flex-col leading-none">
				<span>{{ t('layout.user.signedInAs') }}</span>
				<span *ngIf="email" class="mt-1.5 text-md font-medium">{{ email }}</span>
			</span>
		</button>
		<mat-divider class="my-2"></mat-divider>
		<button mat-menu-item (click)="openPersonalSettings()">
			<mat-icon [svgIcon]="'heroicons_outline:identification'"></mat-icon>
			<span>{{ t('layout.user.personalSettings') }}</span>
		</button>
		<button mat-menu-item (click)="openAccountSettings()" *ngIf="canViewAccount">
			<mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
			<span>{{ t('layout.user.accountSettings') }}</span>
		</button>
		<button mat-menu-item (click)="openRiskSettings()" *ngIf="canViewRisk">
			<mat-icon [svgIcon]="'heroicons_outline:adjustments-horizontal'"></mat-icon>
			<span>{{ t('layout.user.riskSettings') }}</span>
		</button>
		<mat-divider class="my-2"></mat-divider>
		<button mat-menu-item (click)="signOut()">
			<mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
			<span>{{ t('layout.user.signOut') }}</span>
		</button>
	</mat-menu>
</ng-container>
