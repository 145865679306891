<div class="flex flex-col max-w-11/12 h-full -m-6">
	<!-- Header -->
	<div class="flex flex-0 items-center justify-end h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
		<button mat-icon-button mat-dialog-close [tabIndex]="-1" type="button">
			<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
		</button>
	</div>
	<ng-container *ngIf="viewer != 'image'">
		<ngx-doc-viewer [viewerUrl]="viewerUrl" [url]="url" [viewer]="viewer" style="width: 100%; height: 100%"> </ngx-doc-viewer>
	</ng-container>
	<ng-container *ngIf="viewer == 'image'">
		<div class="flex flex-1 items-center justify-center">
			<div class="place-self-center"><img [src]="url" /></div>
		</div>
	</ng-container>
</div>
