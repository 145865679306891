<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="entity && contactData.length > 0" class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">{{ t('contacts.contacts') }}</div>
		<div class="flex flex-row">
			<table class="w-full" mat-table [dataSource]="contactData">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.name') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ contactData.displayName }}
					</td>
				</ng-container>
				<ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.owner') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ getUserName(contactData.owner) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.created') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ contactData.created ? (contactData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
