import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { ComponentsModule } from './components/components.module';
import { ReportsModule } from './reports/reports.module';

@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule, ComponentsModule, ReportsModule],
	exports: [CommonModule, FormsModule, ReactiveFormsModule, MatSelectModule, ComponentsModule, ReportsModule],
})
export class SharedModule {}
