export enum RunningProcessesType {
	OFAC = 'OFAC',
	NEWSCHECK = 'NEWSCHECK',
}

export enum RunningProcessesSubType {
	OCEANSYSTEMS = 'oceanSystems',
	XPECTO = 'xpecto',
	EASYOFAC = 'easyOfac',
}
