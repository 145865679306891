export enum Collection {
	ACCOUNTS = 'accounts',
	ENTITIES = 'entities',
	CASES = 'cases',
	TASKS = 'tasks',
	FILES = 'files',
	SANCTIONLISTRESULTS = 'sanctionlistresults',
	NOTIFICATIONS = 'notifications',
	RISKENGINE = 'riskengine',
	INVITES = 'invites',
	FORMS = 'forms',
	JOTFORMMAPPING = 'jotformmapping',
	ENTITYRELATIONS = 'entityRelations',
	RUNNINGPROCESSES = 'runningProcesses',
	ERROR = 'errors',
	NEWSCHECKRESULT = 'newscheckresult',
	TRASHBIN = 'trashbin',
	AUDITLOG = 'auditlog',
	SUBMISSIONS = 'submissions',
	RELATIONSHIPTYPES = 'relationshipTypes',
	APPROVALS = 'approvals',
	COUNTRYSETTINGS = 'countrySettings',
	USERS = 'users',
	NOTIFICATIONSETTINGS = 'notificationSettings',
	DEFINITIONS = 'definitions',
	TASKANSWERS = 'taskAnswers',
	WORKFLOWS = 'workflows',
	CUSTOMFIELDS = 'customFields',
	WORKFLOWSNEW = 'workflow_editor',
	WORKFLOWVERSIONS = 'workflowsVersions',
	TEMPLATES = 'templates',
	CUSTOMRELATIONDATA = 'customRelationData',
	CUSTOMFIELDDATA = 'customFieldData',
	EXTRACTIONRESULTS = 'extractionResults',
}
