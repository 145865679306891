import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';

// Types Enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Company, Contact, IContact } from 'app/shared/types/entityTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { ContactQuickCreateComponent } from '../contact-quick-create/contact-quick-create.component';

// Services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';

@Component({
	selector: 'contact-list',
	templateUrl: './contact-list.component.html',
	styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit {
	@Input() entity: Company;

	columnsToDisplay = ['title', 'owner', 'created'];

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _contactListObservable: DataObserver;
	private _contactData: Contact[] = [];

	constructor(
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService,
		private _platformInformationService: PlatformInformationService,
		private _router: Router,
		private _dialog: MatDialog
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		if (!this.entity) return;
		this._entityHelperService
			.getRelatedContacts(this.entity.id)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._contactListObservable.unsubscribe();
				})
			)
			.subscribe((entitiesObserver) => {
				this._contactListObservable = entitiesObserver;
				this._contactData = [];
				entitiesObserver?.data?.forEach((singleContact) => {
					this._contactData.push(new Contact(singleContact as IContact));
				});
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	getUserName(userId: string) {
		return this._platformInformationService.getUserNameById(userId);
	}

	openContact(id: string) {
		this._router.navigate(['entities', id]);
	}

	addContact() {
		this._dialog
			.open(ContactQuickCreateComponent, {
				data: this.entity.id,
			})
			.afterClosed()
			.subscribe(async (contactRef) => {
				if (!contactRef) return;
				await this._router.navigate(['entities', contactRef.id]);
			});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get contactData(): Contact[] {
		return this._contactData;
	}
	/**
	 * @type {boolean}
	 */
	get canCreate(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.create);
	}
}
