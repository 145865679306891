import { BooleanInput } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// Types and enums
import { Actions } from 'app/shared/enums/actions';
import { Modules } from 'app/shared/enums/modules';
// Services
import { AuthService } from 'app/core/auth/auth.service';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'user',
})
export class UserComponent implements OnDestroy {
	static ngAcceptInputType_showAvatar: BooleanInput;

	@Input() showAvatar: boolean = true;
	avatarURL: string;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _router: Router,
		private _authService: AuthService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Sign out
	 */
	signOut(): void {
		this._router.navigate(['/sign-out']);
	}

	openAccountSettings(): void {
		this._router.navigate(['/account-settings']);
	}

	openRiskSettings(): void {
		this._router.navigate(['/risk-settings']);
	}

	openPersonalSettings(): void {
		this._router.navigate(['/personal-settings']);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get email(): string {
		return this._authService.email;
	}
	/**
	 * @type {boolean}
	 */
	get canViewAccount(): boolean {
		return this._authService.checkRolePermission(Modules.ACCOUNTSETTINGS, Actions.view);
	}
	/**
	 * @type {boolean}
	 */
	get canViewRisk(): boolean {
		return this._authService.checkRolePermission(Modules.RISKENGINE, Actions.view);
	}
}
