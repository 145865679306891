import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { MatSelectChange } from '@angular/material/select';
import { Subject, finalize, lastValueFrom, takeUntil } from 'rxjs';
// services
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';
import { DefinitionsService } from 'app/core/platform-data/definitions.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types & enums
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from 'app/core/storage/storage.service';
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Modules } from 'app/shared/enums/modules';
import { Definition } from 'app/shared/types/definition';
import { FileData } from 'app/shared/types/fileData';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { DocumentsViewerComponent } from '../documents-viewer/documents-viewer.component';

@Component({
	selector: 'documents-list',
	templateUrl: './documents-list.component.html',
	styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, OnDestroy {
	@Input() linkedRecord: DocumentReference;

	columnsToDisplay = ['name', 'contents', 'type', 'size', 'created', 'createdBy', 'actions'];

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _fileListObservable: DataObserver;
	private _fileList: FileData[] = [];
	private _deleteConfirmationTranslatedText = {};
	private _fileContentTypes: Definition;
	private _definitionsObserver: DataObserver;
	private _editMode: boolean = false;
	private _editedFiles: { [key: string]: FileData } = {};

	constructor(
		private _platformInformationService: PlatformInformationService,
		private _storageService: StorageService,
		private _dialog: MatDialog,
		private _fuseConfirmationService: FuseConfirmationService,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private _definitionsService: DefinitionsService,
		private _authService: AuthService,
		private _dataService: DataService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._storageService
			.readFileData(this.linkedRecord)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._fileListObservable.unsubscribe();
				})
			)
			.subscribe((filesObserver) => {
				this._fileListObservable = filesObserver;
				this._fileList = filesObserver.data;
			});

		// Get translated text for delete file confirmation box
		this._translocoService.selectTranslateObject('entity.common.deleteFileConfirmation', {}, this.scope).subscribe((result) => {
			this._deleteConfirmationTranslatedText = result;
		});

		this._definitionsService
			.readDefinition('fileContentTypes')
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._definitionsObserver.unsubscribe();
				})
			)
			.subscribe((fileContentTypes) => {
				this._definitionsObserver = fileContentTypes;
				this._fileContentTypes = fileContentTypes.data;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the full name of the user
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	/**
	 * Opens a file
	 *
	 * @param {FileData} file file to open
	 * @returns {Promise} Promise
	 */
	openFile(file: FileData): Promise<void> {
		return lastValueFrom(
			this._dialog
				.open(DocumentsViewerComponent, {
					height: '95%',
					width: '100%',
					data: file,
				})
				.afterClosed()
		);
	}

	/**
	 * Uploads files to storage and database
	 * @return {Promise<void>}
	 */
	addFiles(event: any): Promise<void> {
		const target = event.target as HTMLInputElement;
		if (!target.files) return;

		// Filter the duplicate files w.r.t. name
		const filteredFileList: any = Object.values(target.files)?.filter(
			(inputFile: File) => !this.fileList.some((file: FileData) => file.name === inputFile.name)
		);

		if (!filteredFileList.length) return;

		return this._storageService.uploadFiles(filteredFileList as FileList, this.linkedRecord);
	}

	/**
	 * Deletes a file from storage and database with confirmation dialog
	 * @param {FileData} file
	 * @return {Promise<void>}
	 */
	async deleteFile(file: FileData): Promise<any[]> {
		// Open the confirmation dialog
		const confirmation = this._fuseConfirmationService.open({
			title: this._deleteConfirmationTranslatedText['title'],
			message: this._deleteConfirmationTranslatedText['message'],
			actions: {
				confirm: {
					label: this._deleteConfirmationTranslatedText['label'],
				},
			},
		});
		// Wait for confirmation dialog closed action
		const result = await lastValueFrom(confirmation.afterClosed());
		// If the confirm button pressed...
		if (result !== 'confirmed') return;
		return this._storageService.deleteFile(file);
	}

	/**
	 * Downloads a file without dialog to set path
	 * @param {FileData} file
	 * @return {Promise<void>}
	 */
	downloadFile(file: FileData): Promise<void> {
		return this._storageService.downloadFile(file.filePath, file.name);
	}

	/**
	 * Formats byte number to string
	 * @param {number} bytes
	 * @param {number} [decimals=2]
	 * @return {string}
	 */
	formatBytes(bytes: number, decimals = 2): string {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	/**
	 * Toggle edit mode
	 * @return {void}
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
	}

	/**
	 * Saves changed filedata
	 *
	 * @return {Promise<void>}
	 */
	async saveChanges(): Promise<void> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${this.linkedRecord.parent.id}/${this.linkedRecord.id}/${Collection.FILES}`;
		await this._dataService.updateDocuments(Object.values(this._editedFiles), path);
		this.toggleEditMode();
	}

	/**
	 * Prepares object with edited file to save
	 *
	 * @param {FileData} file
	 * @param {MatSelectChange} event
	 */
	changeFileContentType(file: FileData, event: MatSelectChange) {
		this._editedFiles[file.id] = {
			...file,
			contents: event.value,
			modified: new Date(),
			modifiedBy: this._authService.userId,
		};
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {FileData[]}
	 */
	get fileList(): FileData[] {
		return this._fileList;
	}

	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Modules.FILES, Actions.edit);
	}

	/**
	 * @type {boolean}
	 */
	get canCreate(): boolean {
		return this._authService.checkRolePermission(Modules.FILES, Actions.create);
	}

	/**
	 * @type {boolean}
	 */
	get canDelete(): boolean {
		return this._authService.checkRolePermission(Modules.FILES, Actions.delete);
	}

	/**
	 * @type {{ [key: string]: string }}
	 */
	get fileContentTypes(): { [key: string]: string } {
		return this._definitionsService.getDefinitionObj(this._fileContentTypes);
	}

	/**
	 * @type {boolean}
	 */
	get editMode(): boolean {
		return this._editMode;
	}
}
