import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
// services
import { AuthService } from 'app/core/auth/auth.service';

@NgModule({
	imports: [],
	providers: [
		AuthService,
		// Wait for initialization
		// Needed to check permissions in guards
		// Cannot use data from resolvers as they run after guards
		{
			provide: APP_INITIALIZER,
			useFactory: (authService: AuthService) => async () => await authService.init(),
			deps: [AuthService],
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AuthModule {}
