export interface ExtractionResults {
	type: string;
}

export interface IPassportExtractionResult extends ExtractionResults {
	details: [{}];
	fields: {
		birthDate?: string;
		birthDateCheckDigit?: string;
		compositeCheckDigit?: string;
		documentCode?: string;
		documentNumber?: string;
		documentNumberCheckDigit?: string;
		expirationDate?: string;
		expirationDateCheckDigit?: string;
		firstName?: string;
		issuingState?: string;
		lastName?: string;
		nationality?: string;
		personalNumber?: string;
		personalNumberCheckDigit?: string;
		sex?: string;
	};
	parsedBirthdate: Date;
	valid: boolean;
	format: string;
}

export class PassportExtractionResult implements Required<IPassportExtractionResult> {
	type: string;
	details: [{}];
	fields: {
		birthDate?: string;
		birthDateCheckDigit?: string;
		compositeCheckDigit?: string;
		documentCode?: string;
		documentNumber?: string;
		documentNumberCheckDigit?: string;
		expirationDate?: string;
		expirationDateCheckDigit?: string;
		firstName?: string;
		issuingState?: string;
		lastName?: string;
		nationality?: string;
		personalNumber?: string;
		personalNumberCheckDigit?: string;
		sex?: string;
	};
	parsedBirthdate: Date;
	valid: boolean;
	format: string;

	constructor(entity: IPassportExtractionResult) {
		this.type = 'passport';
		this.details = entity.details;
		this.fields = entity.fields;
		this.parsedBirthdate = entity.parsedBirthdate;
		this.valid = entity.valid;
		this.format = entity.format;
	}
}
