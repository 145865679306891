import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import algoliasearch from 'algoliasearch/lite';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Company, ICompany } from 'app/shared/types/entityTypes';
import { environment as env } from 'environments/environment';
const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);
@Component({
	selector: 'company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
	@Input() entity: Company;
	@Input() canEditParent: boolean;
	@Output() onRefresh: EventEmitter<void> = new EventEmitter<void>();
	private _editMode: boolean = false;
	private _companyForm: UntypedFormGroup;
	private _duplicates: Partial<Company>[] = [];
	isDuplicateEntityName: boolean = false;
	constructor(
		private _platformInformationService: PlatformInformationService,
		private _formBuilder: UntypedFormBuilder,
		private _entityHelperService: EntityHelperService,
		private _authService: AuthService,
		private _router: Router
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		// Create the organization form
		this._companyForm = this._formBuilder.group({
			id: [''],
			name: ['', [Validators.required, Validators.pattern(/\w+/)]],
			owner: ['', [Validators.required]],
			notes: [null],
		});
		this._companyForm.patchValue(this.entity);
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Gets users displayname
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return userId ? this._platformInformationService.getUserNameById(userId) : '-';
	}

	/**
	 * Toggles edit mode
	 * @return {void}
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
	}

	/**
	 * Checks if duplicate exists and saves entity if not
	 * @return {Promise<void>}
	 */
	async saveChanges(): Promise<void> {
		// Reset the duplicate entity name flag
		this.isDuplicateEntityName = false;
		// Check for duplication entity
		const isDuplicate = await this._duplicateCheck();
		if (!isDuplicate) {
			const formValues = this._companyForm.value;
			formValues.editedBy = this._authService.userId;
			formValues.edited = new Date();
			formValues.name = formValues.name.replace(/\s{2,}/g, ' ');
			formValues.displayName = formValues.name;

			await this._entityHelperService.updateEntity(formValues, this.entity.id);
			this._companyForm.patchValue(this.entity);
			this._editMode = false;
			this.onRefresh.emit();
		} else {
			// Show duplicate entity name error message
			this.isDuplicateEntityName = true;
			this._companyForm.get('name').setErrors({ invalid: true });
		}
	}

	/**
	 * Toggles edit mode and resets to original values
	 * @return {void}
	 */
	cancelEdit(): void {
		this._editMode = false;
		this._companyForm.patchValue(this.entity);
	}

	/**
	 * Opens current entity
	 * @return {void}
	 */
	openEntity(): void {
		this._router.navigate(['entities', this.entity.id]);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Check the duplication of the entity name
	 * @returns { boolean }
	 */
	private async _duplicateCheck(): Promise<boolean> {
		// Clear the duplicates array
		this._duplicates = [];
		searchClient.clearCache();
		const index = searchClient.initIndex(`${this._authService.accountId}_${Collection.ENTITIES}`);
		try {
			const { hits } = await index.search(this._companyForm.value.name, {
				filters: 'type:COMPANY',
			});
			hits.forEach((hit) => {
				// Check the id and name of the edited record
				if (
					hit.objectID !== this._companyForm.value.id &&
					hit['name'].toString().trim().toLowerCase() === this._companyForm.value.name.toString().trim().toLowerCase()
				)
					this._duplicates.push(new Company(hit as unknown as ICompany));
			});
			return this._duplicates.length > 0;
		} catch (error) {
			console.error(error);
		}
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {boolean}
	 */
	get editMode(): boolean {
		return this._editMode;
	}
	/**
	 * @type {UntypedFormGroup}
	 */
	get companyForm(): UntypedFormGroup {
		return this._companyForm;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		const res = this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
		if (this.canEditParent !== undefined) return res && this.canEditParent;
		return res;
	}
	/**
	 * @type {boolean}
	 */
	get isOpen(): boolean {
		return this._router.url === `/entities/${this.entity.id}`;
	}
	/**
	 * @type {boolean}
	 */
	get canView(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.view);
	}
}
