<ng-container *transloco="let t">
	<!-- Item wrapper -->
	<div
		class="fuse-vertical-navigation-item-wrapper"
		[class.fuse-vertical-navigation-item-has-subtitle]="!!item.subtitle"
		[ngClass]="item.classes?.wrapper"
	>
		<!-- Item with an internal link -->
		@if (item.link && !item.externalLink && !item.function && !item.disabled) {
			<a
				class="fuse-vertical-navigation-item"
				[ngClass]="{
					'fuse-vertical-navigation-item-active-forced': item.active,
				}"
				[routerLink]="[item.link]"
				[fragment]="item.fragment ?? null"
				[preserveFragment]="item.preserveFragment ?? false"
				[queryParams]="item.queryParams ?? null"
				[queryParamsHandling]="item.queryParamsHandling ?? null"
				[routerLinkActive]="'fuse-vertical-navigation-item-active'"
				[routerLinkActiveOptions]="isActiveMatchOptions"
				[matTooltip]="item.tooltip || ''"
			>
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</a>
		}

		<!-- Item with an external link -->
		@if (item.link && item.externalLink && !item.function && !item.disabled) {
			<a class="fuse-vertical-navigation-item" [href]="item.link" [target]="item.target || '_self'" [matTooltip]="item.tooltip || ''">
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</a>
		}

		<!-- Item with a function -->
		@if (!item.link && item.function && !item.disabled) {
			<div
				class="fuse-vertical-navigation-item"
				[ngClass]="{
					'fuse-vertical-navigation-item-active-forced': item.active,
				}"
				[matTooltip]="item.tooltip || ''"
				(click)="item.function(item)"
			>
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</div>
		}

		<!-- Item with an internal link and function -->
		@if (item.link && !item.externalLink && item.function && !item.disabled) {
			<a
				class="fuse-vertical-navigation-item"
				[ngClass]="{
					'fuse-vertical-navigation-item-active-forced': item.active,
				}"
				[routerLink]="[item.link]"
				[fragment]="item.fragment ?? null"
				[preserveFragment]="item.preserveFragment ?? false"
				[queryParams]="item.queryParams ?? null"
				[queryParamsHandling]="item.queryParamsHandling ?? null"
				[routerLinkActive]="'fuse-vertical-navigation-item-active'"
				[routerLinkActiveOptions]="isActiveMatchOptions"
				[matTooltip]="item.tooltip || ''"
				(click)="item.function(item)"
			>
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</a>
		}

		<!-- Item with an external link and function -->
		@if (item.link && item.externalLink && item.function && !item.disabled) {
			<a
				class="fuse-vertical-navigation-item"
				[href]="item.link"
				[target]="item.target || '_self'"
				[matTooltip]="item.tooltip || ''"
				(click)="item.function(item)"
			>
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</a>
		}

		<!-- Item with a no link and no function -->
		@if (!item.link && !item.function && !item.disabled) {
			<div
				class="fuse-vertical-navigation-item"
				[ngClass]="{
					'fuse-vertical-navigation-item-active-forced': item.active,
				}"
				[matTooltip]="item.tooltip || ''"
			>
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</div>
		}

		<!-- Item is disabled -->
		@if (item.disabled) {
			<div class="fuse-vertical-navigation-item fuse-vertical-navigation-item-disabled" [matTooltip]="item.tooltip || ''">
				<ng-container *ngTemplateOutlet="itemTemplate"></ng-container>
			</div>
		}
	</div>

	<!-- Item template -->
	<ng-template #itemTemplate>
		<!-- Icon -->
		@if (item.icon) {
			<mat-icon class="fuse-vertical-navigation-item-icon" [ngClass]="item.classes?.icon" [svgIcon]="item.icon"></mat-icon>
		}

		<!-- Title & Subtitle -->
		<div class="fuse-vertical-navigation-item-title-wrapper">
			<div class="fuse-vertical-navigation-item-title" [id]="item.id">
				<span [ngClass]="item.classes?.title">
					{{ t('layout.menus.' + item.title) }}
				</span>
			</div>
			@if (item.subtitle) {
				<div class="fuse-vertical-navigation-item-subtitle">
					<span [ngClass]="item.classes?.subtitle">
						{{ item.subtitle }}
					</span>
				</div>
			}
		</div>

		<!-- Badge -->
		@if (item.badge) {
			<div class="fuse-vertical-navigation-item-badge">
				<div class="fuse-vertical-navigation-item-badge-content" [ngClass]="item.badge.classes">
					{{ item.badge.title }}
				</div>
			</div>
		}

		@if (item.endIcon) {
			<div class="fuse-vertical-navigation-item-badge">
				<mat-icon
					[class]="'fuse-vertical-navigation-item-icon ' + item.endIcon.class"
					[ngClass]="item.classes?.endIcon"
					[svgIcon]="item.endIcon.icon"
					[id]="'icon_' + item.id"
					[matTooltip]="item.endIcon.tooltip"
				></mat-icon>
			</div>
		}
	</ng-template>
</ng-container>
