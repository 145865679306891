import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { AvailableLangs, TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs';
/** Services */
import { AuthService } from 'app/core/auth/auth.service';
import { UserSettingsService } from 'app/modules/platform/account-settings/user-settings/user-settings.service';
@Component({
	selector: 'languages',
	templateUrl: './languages.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'languages',
	providers: [UserSettingsService],
})
export class LanguagesComponent implements OnInit {
	availableLangs: AvailableLangs;
	activeLang: string;

	/** Active language flag code */
	activeLangFlagCode: string = '';

	/**
	 * Constructor
	 */
	constructor(
		private _fuseNavigationService: FuseNavigationService,
		private _translocoService: TranslocoService,
		private _userSettingsService: UserSettingsService,
		private _authService: AuthService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Get the available languages from transloco
		this.availableLangs = this._translocoService.getAvailableLangs();

		// Check user language and set it as active language
		if (this._authService.language) {
			// Set the active lang
			this._translocoService.setActiveLang(this._authService.language);
		} else {
			// If user language is not set then set the default language to 'en-US'
			this.setActiveLang('en-US');
		}

		// Subscribe to language changes
		this._translocoService.langChanges$.subscribe((activeLang) => {
			// Get the active lang
			this.activeLang = activeLang;
			// Update the navigation
			this._updateNavigation(activeLang);

			// Set activated language flag code
			this._setActivatedLangFlagCode(this.activeLang);
		});
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Set the active lang
	 * @param lang
	 */
	setActiveLang(lang: string): void {
		// Set the active lang
		this._translocoService.setActiveLang(lang);

		// Update user language
		this._updateUserLanguage(lang);
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Update the navigation
	 *
	 * @param lang
	 * @private
	 */
	private _updateNavigation(lang: string): void {
		// For the demonstration purposes, we will only update the Dashboard names
		// from the navigation but you can do a full swap and change the entire
		// navigation data.
		//
		// You can import the data from a file or request it from your backend,
		// it's up to you.

		// Get the component -> navigation data -> item
		const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

		// Return if the navigation component does not exist
		if (!navComponent) {
			return null;
		}

		// Get the flat navigation data
		const navigation = navComponent.navigation;

		// Get the Project dashboard item and update its title
		const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
		if (projectDashboardItem) {
			this._translocoService
				.selectTranslate('Project')
				.pipe(take(1))
				.subscribe((translation) => {
					// Set the title
					projectDashboardItem.title = translation;

					// Refresh the navigation component
					navComponent.refresh();
				});
		}

		// Get the Analytics dashboard item and update its title
		const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
		if (analyticsDashboardItem) {
			this._translocoService
				.selectTranslate('Analytics')
				.pipe(take(1))
				.subscribe((translation) => {
					// Set the title
					analyticsDashboardItem.title = translation;

					// Refresh the navigation component
					navComponent.refresh();
				});
		}
	}

	/**
	 * Set the activated language flag code
	 * @param langCode
	 */
	private _setActivatedLangFlagCode(langCode: string): void {
		/** Get the index of active language id in available language array */
		let index = this.availableLangs.findIndex((item: any) => item.id === langCode);

		// Set the flag code of active language
		this.activeLangFlagCode = this.availableLangs[index]['flagCode'];
	}

	/**
	 * Update user language
	 * @param langCode
	 */
	private _updateUserLanguage(langCode: string) {
		// Call the user service to update the user language
		this._userSettingsService
			.updateUserLanguage(this._authService.userId, langCode)
			.then(() => {})
			.catch((error) => {
				console.error(error);
			});
	}
}
