export enum GraphRecordType {
	NODE = 'node',
	RELATION = 'relation',
}

export enum GraphDataType {
	ENTITY = 'entity',
}

export enum GraphReadType {
	SUBGRAPH = 'subgraph',
	FETCHREL = 'fetch_rel',
	FETCHNODE = 'fetch_node',
}

export enum Actions {
	CREATE = 'CREATE',
	INSERT = 'INSERT',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
	READ = 'READ',
}

interface IGraphRequest {
	space: string;
	action: Actions;
	data: GraphCreateDataArray | GraphDeleteDataArray | GraphReadDataArray | GraphUpdateDataArray | GraphInsertDataArray;
}

export class GraphCreateRequest implements IGraphRequest {
	space: string;
	action: Actions.CREATE;
	data: GraphCreateDataArray;
}

export class GraphInsertRequest implements IGraphRequest {
	space: string;
	action: Actions.INSERT;
	data: GraphInsertDataArray;
}
export class GraphUpdateRequest implements IGraphRequest {
	space: string;
	action: Actions.UPDATE;
	data: GraphUpdateDataArray;
}

export class GraphReadRequest implements IGraphRequest {
	space: string;
	action: Actions.READ;
	data: GraphReadDataArray;
}

export class GraphDeleteRequest implements IGraphRequest {
	space: string;
	action: Actions.DELETE;
	data: GraphDeleteDataArray;
}

export type GraphCreateDataArray = {
	[GraphRecordType.NODE]?: NodeCreateData;
	[GraphRecordType.RELATION]?: RelationCreateData;
}[];

export type GraphInsertDataArray = {
	relationId: string;
}[];

export type GraphUpdateDataArray = {
	[GraphRecordType.NODE]?: NodeUpdateData;
	[GraphRecordType.RELATION]?: RelationUpdateData;
}[];

export type GraphReadDataArray = {
	[GraphReadType.FETCHNODE]?: NodeReadData;
	[GraphReadType.FETCHREL]?: RelationReadData;
	[GraphReadType.SUBGRAPH]?: SubgraphReadData;
}[];

export type GraphDeleteDataArray = {
	[GraphRecordType.NODE]?: NodeDeleteData;
	[GraphRecordType.RELATION]?: RelationDeleteData;
}[];

interface NodeCreateData {
	id: string;
	type: GraphDataType;
	meta: string;
}
interface NodeUpdateData {
	id: string;
	prop: string;
	value: string;
}

interface NodeDeleteData {
	id: string;
}

interface NodeReadData {
	id: string;
}

interface RelationCreateData {
	id: string;
	parent_id: string;
	child_id: string;
	meta: string;
}
interface RelationUpdateData {
	id: string;
	parent_id: string;
	child_id: string;
	prop: string;
	value: string;
}

interface RelationReadData {
	id: string;
	parent_id: string;
	child_id: string;
}

interface RelationDeleteData {
	id: string;
	parent_id: string;
	child_id: string;
}

interface SubgraphReadData {
	id: string;
	n_hops: number;
}

export interface GraphQueryResponse {
	query_success: boolean;
	rollback_success: boolean | null;
	query_payload: GraphQueryPayloadData[] | null;
	error?: { [key: string]: number }[];
}

interface GraphQueryPayloadData {
	result: GraphReadData[];
	query: string;
	success: boolean;
	error: null | { [key: string]: number }[];
}

interface GraphReadData {
	columns: string[];
	data: {
		meta: {
			type: string;
			id: string | { ranking: number; type: number; dst: string; src: string; name: string };
		}[][];
		row: {
			'module.meta'?: string;
			'module.type'?: string;
			meta?: string;
		}[][];
	}[];
	errors: { code: number };
	latencyInUs: number;
	spaceName: string;
}
