<ng-container *transloco="let t">
	<div *ngIf="linkedRecord" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.entity.identificationDocumentList.title') }}
			</div>
			<!-- Actions -->
			<div class="">
				<button
					mat-icon-button
					color="primary"
					(click)="fileInput.click()"
					[matTooltip]="t('platform.entity.common.uploadFiles')"
					[disabled]="!canCreate"
					id="btnAddIdentificationFiles"
				>
					<mat-icon [svgIcon]="'heroicons_solid:plus-small'"></mat-icon>
				</button>
				<input (change)="addFiles($event)" type="file" #fileInput id="fileInput" class="hidden" multiple />
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<table class="w-full" mat-table [dataSource]="fileList">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.name') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.name }}
					</td>
				</ng-container>
				<ng-container matColumnDef="expirationDate">
					<th mat-header-cell *matHeaderCellDef>
						{{ t('platform.entity.identificationDocumentList.expirationDate') }}
					</th>
					<td mat-cell *matCellDef="let fileData">
						{{ extractionResultVal(fileData.extractionResults, 'expirationDate', true) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="issuingState">
					<th mat-header-cell *matHeaderCellDef>
						{{ t('platform.entity.identificationDocumentList.issuingState') }}
					</th>
					<td mat-cell *matCellDef="let fileData">
						{{ extractionResultVal(fileData.extractionResults, 'issuingState') }}
					</td>
				</ng-container>
				<ng-container matColumnDef="nationality">
					<th mat-header-cell *matHeaderCellDef>
						{{ t('platform.entity.identificationDocumentList.nationality') }}
					</th>
					<td mat-cell *matCellDef="let fileData">
						{{ extractionResultVal(fileData.extractionResults, 'nationality') }}
					</td>
				</ng-container>
				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let fileData">
						<dev class="flex flex-row justify-center">
							<button mat-icon-button color="primary" (click)="openFile(fileData)" [matTooltip]="t('platform.entity.common.openFile')">
								<mat-icon [svgIcon]="'mat_solid:search'"></mat-icon>
							</button>
							<button mat-icon-button (click)="downloadFile(fileData)" [matTooltip]="t('platform.entity.common.downloadFile')">
								<mat-icon [svgIcon]="'mat_solid:cloud_download'"></mat-icon>
							</button>
							<button
								mat-icon-button
								color="warn"
								(click)="deleteFile(fileData)"
								[matTooltip]="t('platform.entity.common.deleteFile')"
								[disabled]="!canDelete"
							>
								<mat-icon [svgIcon]="'mat_solid:delete'"></mat-icon>
							</button>
						</dev>
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
