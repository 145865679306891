import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

// Transloco module
import { TranslocoModule } from '@jsverse/transloco';

// components
import { CaseQuickCreateComponent } from './case-quick-create/case-quick-create.component';
import { RiskAssessmentDetailsComponent } from './risk-assessment-details/risk-assessment-details.component';

// material
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
	declarations: [CaseQuickCreateComponent, RiskAssessmentDetailsComponent],
	imports: [
		CommonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
		MatSelectModule,
		MatTableModule,
		ReactiveFormsModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		TranslocoModule,
	],
	exports: [RiskAssessmentDetailsComponent],
})
export class CasesModule {}
