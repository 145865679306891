import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Types & Enums */
import { Collection } from 'app/shared/enums/collection';
import { DataObserver } from 'app/shared/types/utilityTypes';

/** Services */
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';

@Injectable({
	providedIn: 'root',
})
export class CustomRelationService {
	private _accountPath: string;

	constructor(
		private _authService: AuthService,
		private _dataService: DataService
	) {
		this._accountPath = `${Collection.ACCOUNTS}/${this._authService.accountId}`;
	}

	/**
	 * Get custom relation data observable of entity
	 *
	 * @param {AffectedCategory} type
	 * @param {string} id
	 * @return {Observable<DataObserver>}
	 */
	readCustomRelationDataObservable(col: Collection, id: string): Observable<DataObserver> {
		if (col !== Collection.ENTITIES) return;
		const path = `${this._accountPath}/${col}/${id}/${Collection.CUSTOMRELATIONDATA}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Get custom relation data observable of entity
	 * @param {Collection} col
	 * @param {string} parentId
	 * @param {string} customRelationId
	 * @return {Observable<DataObserver>}
	 */
	readCustomRelationExtractionsObservable(col: Collection, parentId: string, customRelationId: string): Observable<DataObserver> {
		if (col !== Collection.ENTITIES) return;
		const path = `${this._accountPath}/${col}/${parentId}/${Collection.CUSTOMRELATIONDATA}/${customRelationId}/${Collection.EXTRACTIONRESULTS}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Stores all changes to custom relation data. Determines if object has to be created or updated.
	 *
	 * @param {[any, string, boolean][]} data
	 * @return {Promise<void>}
	 */
	storeChangesCustomRelations(data: [any, string][]): Promise<void> {
		if (data.length === 0) return;
		data.forEach(([elemData, path]) => {
			// Update parent with valid entry
			if (!elemData.parent || !elemData.valid) return;
			const docRef = this._dataService.getFirestoreRef(path, elemData.id);
			const parentPath = path.split('/').slice(0, 5).join('/');
			data.push([{ id: elemData.parent, valid: docRef }, parentPath]);
		});
		return this._dataService.writeMultipleDocuments(data);
	}
}
