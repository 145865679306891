<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<fuse-alert *ngIf="showAlert" [appearance]="'outline'" [type]="alert.type" [showIcon]="false">
			<mat-icon *ngIf="alert.icon" class="icon-size-5" fuseAlertIcon [svgIcon]="alert.icon"> </mat-icon>
			<span *ngIf="alert.title" fuseAlertTitle>{{ alert.title }}</span>
			{{ alert.message }}
		</fuse-alert>
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">
				{{ t('platform.entity.newsCheckResults.newsCheckResults') }}
			</div>
			<div class="flex flex-row items-center">
				<div class="">
					<mat-slide-toggle [color]="'primary'" (change)="showRelatedToggle($event)" [checked]="showRelated">{{
						t('platform.entity.newsCheckResults.showRelated')
					}}</mat-slide-toggle>
				</div>
				<div [matTooltip]="t('platform.entity.newsCheckResults.notPermission')" [matTooltipDisabled]="canEdit">
					<button
						class="hidden sm:inline-flex"
						[matMenuTriggerFor]="ncMenu"
						mat-icon-button
						color="primary"
						[matTooltip]="t('platform.entity.newsCheckResults.runNewsCheck')"
						[disabled]="!canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_outline:newspaper'"></mat-icon>
					</button>
				</div>
				<mat-menu #ncMenu="matMenu">
					<button mat-menu-item (click)="initNewsCheck()">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:newspaper'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.common.currentEntity') }}</span>
					</button>
					<button mat-menu-item (click)="initNewsCheckRelated()">
						<mat-icon class="icon-size-5" [svgIcon]="'heroicons_outline:newspaper'"></mat-icon>
						<span class="ml-2">{{ t('platform.entity.common.currentRelatedEntities') }}</span>
					</button>
				</mat-menu>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-col py-6">
			<div>
				<table mat-table class="w-full grow-0" [dataSource]="dataSource" multiTemplateDataRows>
					<ng-container matColumnDef="date">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.date') }}</th>
						<td mat-cell *matCellDef="let newsResult">
							{{ newsResult.date ? (newsResult.date.toDate() | date: 'short') : '-' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="title">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.title') }}</th>
						<td mat-cell *matCellDef="let newsResult">
							{{ newsResult.title }}
						</td>
					</ng-container>

					<ng-container matColumnDef="status">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.common.status') }}</th>
						<td mat-cell *matCellDef="let newsResult">
							<span
								class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
								[ngClass]="{
									'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': newsResult.averageScore < 0,
									'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': newsResult.averageScore > 0,
								}"
								>{{ getTranslatedScore(newsResult.averageScore) }}</span
							>
						</td>
					</ng-container>

					<ng-container matColumnDef="avgScore">
						<th mat-header-cell *matHeaderCellDef class="w-1/4">{{ t('platform.entity.newsCheckResults.avgScore') }}</th>
						<td mat-cell *matCellDef="let newsResult">
							{{ newsResult.averageScore }}
						</td>
					</ng-container>

					<!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
					<ng-container matColumnDef="expandedDetail">
						<td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
							<div class="overflow-hidden w-full" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
								<div class="flex flex-col divide-y">
									<div class="flex flex-row space-x-4 items-center py-4" *ngFor="let subResult of element.results">
										<div class="min-w-1/6">
											<span
												class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
												[ngClass]="{
													'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': subResult.score < 0,
													'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': subResult.score > 0,
												}"
												>{{ getTranslatedScore(subResult.score) }}</span
											>
										</div>
										<div>
											<a target="_blank" href="{{ subResult.link }}">{{ subResult.content }}</a>
										</div>
									</div>
								</div>
							</div>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
					<tr
						mat-row
						*matRowDef="let element; columns: columnsToDisplay"
						class="example-element-row"
						[class.example-expanded-row]="element.expanded"
						(click)="toggleRow(element)"
					></tr>
					<tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
				</table>
			</div>
			<div class="flex justify-end">
				<!-- Pagination -->
				<mat-paginator
					*ngIf="newsCheckResults.length > 0"
					#paginator
					[pageSize]="pageSize"
					[pageSizeOptions]="pageSizeOptions"
					[length]="newsCheckResults.length"
					(page)="handlePageEvent($event)"
					[showFirstLastButtons]="showFirstLastButtons"
				>
				</mat-paginator>
			</div>
		</div>
	</div>
</ng-container>
