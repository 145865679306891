import { Component, Input, OnInit } from '@angular/core';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
// types and enums
import { EntityRelation } from 'app/shared/types/entity-relation';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { RelationshipType } from 'app/shared/types/relationshipType';

@Component({
	selector: 'report-enitity-relations-list',
	templateUrl: './enitity-relations-list.component.html',
	styleUrls: ['./enitity-relations-list.component.scss'],
})
export class EnitityRelationsListComponent implements OnInit {
	@Input() entity: Contact | Company;
	columnsToDisplay = ['type', 'parent'];
	private _entityDataSource: EntityRelation[] = [];
	private _relationshipTypes: { [key: string]: RelationshipType } = {};

	constructor(private _entityHelperService: EntityHelperService) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._initRelations();
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Initializes entity relations and sets up datasource for displaying
	 * @return {Promise<void>}
	 */
	private async _initRelations(): Promise<void> {
		const relTypes = await this._entityHelperService.getRelationTypes();
		relTypes.forEach((doc) => {
			this._relationshipTypes[doc.id] = doc;
		});
		const entityRelations = await this._entityHelperService.getEntityRelationsGraphDB(this.entity.id);
		this._entityDataSource = entityRelations.filter((relation) => {
			return relation.parent.id === this.entity.id || relation.child.id === this.entity.id;
		});
		return;
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Returns entityname of linked entity
	 *
	 * @param {EntityRelation} relation
	 * @return {string}
	 */
	getEntityDisplayName(relation: EntityRelation): string {
		const entityDisplayName = relation.child.id === this.entity.id ? relation.parentEntityName : relation.childEntityName;
		if (!entityDisplayName) return '';
		return entityDisplayName;
	}

	/**
	 * Returns the description for the relation
	 *
	 * @param {EntityRelation} relation
	 * @return {string}
	 */
	getRelationshipDescription(relation: EntityRelation): string {
		const type = this._relationshipTypes[relation.type];
		if (!type) return '';
		if (relation.parent.id === this.entity.id) return type.reverseDescription;
		return type.description;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {EntityRelation[]}
	 */
	get dataSource(): EntityRelation[] {
		return this._entityDataSource;
	}
}
