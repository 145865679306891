import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FuseAlertModule } from '@fuse/components/alert/alert.module';
import { TranslocoModule } from '@jsverse/transloco';

// material
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxEchartsModule } from 'ngx-echarts';

// components
import { SearchModule } from 'app/shared/components/search/search.module';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CommunicationDetailsComponent } from './communication-details/communication-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { CompanyQuickCreateComponent } from './company-quick-create/company-quick-create.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactQuickCreateComponent } from './contact-quick-create/contact-quick-create.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { CustomRelationsComponent } from './custom-relations/custom-relations.component';
import { EntityRelationGraphViewerComponent } from './entity-relation-graph-viewer/entity-relation-graph-viewer.component';
import { EntityRelationGraphComponent } from './entity-relation-graph/entity-relation-graph.component';
import { EntityRelationsListComponent } from './entity-relations-list/entity-relations-list.component';
import { NewsCheckDetailsComponent } from './news-check-details/news-check-details.component';
import { ReportsComponent } from './reports/reports.component';
import { SanctionListCheckDetailsComponent } from './sanction-list-check-details/sanction-list-check-details.component';
import { SearchEntitiesComponent } from './search-entities/search-entities.component';
@NgModule({
	declarations: [
		SearchEntitiesComponent,
		CompanyQuickCreateComponent,
		ContactQuickCreateComponent,
		SanctionListCheckDetailsComponent,
		CaseListComponent,
		EntityRelationGraphComponent,
		EntityRelationsListComponent,
		ContactDetailsComponent,
		CompanyDetailsComponent,
		CommunicationDetailsComponent,
		NewsCheckDetailsComponent,
		AddressDetailsComponent,
		ContactListComponent,
		EntityRelationGraphViewerComponent,
		CustomFieldsComponent,
		CustomRelationsComponent,
		ReportsComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatCheckboxModule,
		MatTableModule,
		MatButtonModule,
		MatMenuModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		NgxEchartsModule.forRoot({
			echarts: () => import('echarts'),
		}),
		MatDatepickerModule,
		MatSlideToggleModule,
		SearchModule,
		MatPaginatorModule,
		TranslocoModule,
		FuseAlertModule,
		MatListModule,
		FormsModule,
	],
	exports: [
		SanctionListCheckDetailsComponent,
		CaseListComponent,
		EntityRelationGraphComponent,
		EntityRelationsListComponent,
		ContactDetailsComponent,
		CompanyDetailsComponent,
		CommunicationDetailsComponent,
		NewsCheckDetailsComponent,
		AddressDetailsComponent,
		ContactListComponent,
		CustomFieldsComponent,
		CustomRelationsComponent,
		ReportsComponent,
	],
})
export class EntitiesModule {}
