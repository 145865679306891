<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between items-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('platform.entity.entityRelationGraph.title') }}</div>
			<!-- Actions -->
			<div class="">
				<button mat-icon-button color="primary" [matTooltip]="t('platform.entity.entityRelationGraph.refreshGraph')" (click)="refreshGraph()">
					<mat-icon [svgIcon]="'heroicons_solid:arrow-path'"></mat-icon>
				</button>

				<button
					mat-icon-button
					color="primary"
					[matTooltip]="t('platform.entity.entityRelationGraph.clickToEnlarge')"
					(click)="fullScreenGraph()"
				>
					<mat-icon svgIcon="heroicons_outline:arrows-pointing-out"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row py-6">
			<div class="w-full flex flex-col justify-center items-center space-y-3" *ngIf="!hasRelations">
				{{ t('platform.entity.entityRelationGraph.noRelationsFor') }} {{ entity.displayName }}
			</div>
			<div *ngIf="hasRelations && !graphInitialised" class="w-full flex flex-col justify-center items-center space-y-3">
				<mat-progress-spinner [diameter]="30" [mode]="'indeterminate'"></mat-progress-spinner>
				<div>{{ t('platform.entity.entityRelationGraph.loadingRelationsFor') }} {{ entity.displayName }}</div>
			</div>
			<div *ngIf="options && hasRelations" echarts [options]="options" [merge]="mergeOptions" class="w-full"></div>
		</div>
	</div>
</ng-container>
