import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subject, combineLatest, finalize, lastValueFrom, map, switchMap, takeUntil } from 'rxjs';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { CustomRelationService } from 'app/core/platform-data/custom-relation.service';
import { StorageService } from 'app/core/storage/storage.service';

// types and enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { CustomRelation } from 'app/shared/enums/customRelation';
import { CustomRelationData, RelationExtractionResult } from 'app/shared/types/customFieldTypes';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { FileData } from 'app/shared/types/fileData';
import { RelationshipType } from 'app/shared/types/relationshipType';
import { DataObserver } from 'app/shared/types/utilityTypes';

// components
import { DocumentsViewerComponent } from '../../documents/documents-viewer/documents-viewer.component';

@Component({
	selector: 'custom-relations',
	templateUrl: './custom-relations.component.html',
	styleUrls: ['./custom-relations.component.scss'],
})
export class CustomRelationsComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;

	private _editMode: boolean = false;
	private _customRelations: { [key: string]: RelationExtractionResult[] } = {};
	private _fileList: FileData[] = [];
	private _relationshipTypes: { [key: string]: RelationshipType } = {};
	private _customRelationsForm: FormGroup;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _dataObserver: DataObserver;
	private _customRelationExtractions: RelationExtractionResult[] = [];
	private _customRelationEnum = CustomRelation;

	constructor(
		private _entityHelperService: EntityHelperService,
		private _storageService: StorageService,
		private _dialog: MatDialog,
		private _formBuilder: FormBuilder,
		private _authService: AuthService,
		private _customRelationService: CustomRelationService
	) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit() {
		if (!this.entity) return;
		const parentCol = Collection.ENTITIES;
		const parentId = this.entity.id;
		this._customRelationService
			.readCustomRelationDataObservable(parentCol, parentId)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._dataObserver.unsubscribe();
				}),
				map((parentCollection) => {
					return parentCollection.data.map((parent) => {
						return this._customRelationService.readCustomRelationExtractionsObservable(parentCol, parentId, parent.id);
					});
				}),
				switchMap((observablesArray) => {
					return combineLatest(observablesArray);
				})
			)
			.subscribe(async (dataObsv: any[]) => {
				this._customRelationExtractions = dataObsv.flatMap((observer) => {
					return observer.data;
				});

				this._initData();
			});
	}

	/**
	 * Initialize data
	 * @return { Promise<void> }
	 */
	private async _initData(): Promise<void> {
		// Sort the custom fields on label
		const relTypes = await this._entityHelperService.getRelationTypes();
		relTypes.forEach((doc) => {
			this._relationshipTypes[doc.id] = doc;
		});
		this._customRelations = {} as { [key: string]: RelationExtractionResult[] };
		this._customRelationExtractions.forEach((value) => {
			if (!this._customRelations[value.parent]) this._customRelations[value.parent] = [];
			this._customRelations[value.parent].push(value);
		});

		const documentPaths = this._customRelationExtractions.filter((res) => res.origin !== 'Manual').map((response) => response.source.path);
		this._fileList = await this._storageService.readFilesDataByPath(documentPaths);
		this._prepareRelationsForm();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Saves the changes
	 * @return {Promise<void>}
	 */
	async save(): Promise<void> {
		this._customRelationsForm.disable();
		const formData = this._customRelationsForm.value;
		const parentCol = Collection.ENTITIES;
		const parentId = this.entity.id;
		const parentPath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${parentCol}/${parentId}/${Collection.CUSTOMRELATIONDATA}`;
		let dataArr: [any, string][] = [];
		// for every source
		for (const [fieldId, responses] of Object.entries(formData)) {
			// Insert customFieldData
			const parentData: CustomRelationData = {
				id: fieldId,
				valid: null,
				created: new Date(),
				createdBy: this._authService.userId,
			};
			dataArr.push([parentData, parentPath]);
			const path = `${parentPath}/${fieldId}/${Collection.EXTRACTIONRESULTS}`;
			let isUpdated = false;
			for (const [resId, value] of Object.entries(responses)) {
				const extraction = this._customRelationExtractions.find((extraction) => extraction.id === resId);
				const extractedValue = Object.keys(extraction.value)[0];
				const updatedExtractedValue = Object.values(extraction.value)[0];
				// Update Extraction when changed
				if (extraction && (extractedValue !== value[extractedValue] || extraction.valid !== value.valid)) {
					const updObj: Partial<RelationExtractionResult> = { id: resId, valid: value.valid, parent: fieldId };
					const temp = value[extractedValue]?.trim().length ? value[extractedValue] : this.customRelationsEnum.EMPTY;
					if (extraction.origin === 'Manual') updObj['value'] = { [temp]: updatedExtractedValue };
					dataArr.push([updObj, path]);
					if (extraction.valid !== value.valid) isUpdated = true;
				} else if (!extraction) {
					// Get updated value
					const keyUpdated = Object.values(responses[resId])[0] as string;
					const updatedValue: { [key: string]: any } = this._customRelations[fieldId].find((rel) => rel.id === resId)?.value
						? Object.values(this._customRelations[fieldId].find((rel) => rel.id === resId)?.value)[0]
						: {};
					const newVal = { [keyUpdated]: updatedValue };

					// Create new extractionresult
					const insertObj: RelationExtractionResult = {
						id: resId,
						value: newVal,
						origin: 'Manual',
						created: new Date(),
						createdBy: this._authService.userId,
						parent: fieldId,
						valid: value.valid,
						source: null,
					};
					dataArr.push([insertObj, path]);
					isUpdated = true;
				}
			}

			// If not added any extraction result change for given custom relation then we have to remove the entries which has id is not equal to field id.
			if (!isUpdated) dataArr = dataArr.filter((item: { [key: string]: any }) => item[0].id !== fieldId);
		}
		this._editMode = false;
		await this._customRelationService.storeChangesCustomRelations(dataArr);
		this._customRelationsForm.enable();
		this._prepareRelationsForm();
	}

	/**
	 * Toggles edit mode
	 * @return { void }
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
		this._prepareRelationsForm();
	}

	/**
	 * Toggles edit mode and resets to original values
	 * @return { void }
	 */
	cancel(): void {
		this._editMode = false;
	}

	/**
	 * Opens the sourcefile
	 *
	 * @param {RelationExtractionResult} res
	 * @return {void}
	 */
	openSource(res: RelationExtractionResult): void {
		if (!this._fileList || !res || !res.source.id) return;
		const file = this._fileList.find((file) => file.id === res.source.id);
		if (!file) return;
		lastValueFrom(
			this._dialog
				.open(DocumentsViewerComponent, {
					height: '95%',
					width: '100%',
					data: file,
				})
				.afterClosed()
		);
	}

	/**
	 * Returns the filename out of the source path where the data was found
	 *
	 * @param {string} id
	 * @return {string}
	 */
	getFileName(id: string): string {
		if (!this._fileList) return '';
		const file = this._fileList.find((file) => file.id === id);
		return file ? file.name : '';
	}

	/**
	 * Returns the description for the relation
	 *
	 * @param {string} relationTypeId
	 * @return {string}
	 */
	getRelationshipDescription(relationTypeId: string): string {
		if (!relationTypeId || !this._relationshipTypes[relationTypeId]) return '';
		const type = this._relationshipTypes[relationTypeId];
		if (this.entity.type === type.primaryEntity) return type.reverseDescription;
		return type.description;
	}

	/**
	 * Handles valid toggle change; sets all other toggles to false if value was set to valid; otherwise prevents toggle
	 *
	 * @param {string} relationId
	 * @param {string} fileId
	 * @param {MatSlideToggleChange} event
	 * @return {void}
	 */
	toggleValid(relationId: string, fileId: string, event: MatSlideToggleChange): void {
		// Toggle cannot be unchecked, so that always one toggle is checked
		if (!event.source.checked) {
			event.source.checked = true;

			// Reset to true in form
			const parentGroup = this._customRelationsForm.get(relationId) as UntypedFormGroup;
			const formGroup = parentGroup.get(fileId) as UntypedFormGroup;
			if (formGroup) formGroup.get('valid').setValue(true);
			return;
		}
		const parentGroup = this._customRelationsForm.get(relationId) as UntypedFormGroup;
		// Set all other values for that customfield to invalid
		for (const file of Object.keys(parentGroup.controls)) {
			// Added the validation to valid input only
			parentGroup.controls[file]
				.get(Object.keys(parentGroup.controls[file].value)[0])
				.setValidators(file === fileId ? [Validators.required, Validators.pattern('^(?!\\s*$).+')] : null);

			parentGroup.controls[file].get(Object.keys(parentGroup.controls[file].value)[0]).updateValueAndValidity();
			if (file === fileId) continue;
			const formGroup = parentGroup.get(file) as UntypedFormGroup;
			if (formGroup) formGroup.get('valid').setValue(false);
		}
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Builds the form generic depending on the customRelations
	 *
	 * @return {void}
	 */
	private _prepareRelationsForm(): void {
		const customs = {};
		for (const rel in this._customRelations) {
			const relGroup = {};
			for (const i of this._customRelations[rel]) {
				const parentGroup = {};
				for (const entity in i.value as any) {
					parentGroup[entity] = i.valid
						? new FormControl(entity === this.customRelationsEnum.EMPTY ? '' : entity, [
								Validators.required,
								Validators.pattern('^(?!\\s*$).+'),
							])
						: new FormControl(entity === this.customRelationsEnum.EMPTY ? '' : entity);
					if (i.origin !== 'Manual') parentGroup[entity]?.disable();
				}
				parentGroup['valid'] = new FormControl(i.valid);
				relGroup[i.id] = this._formBuilder.group(parentGroup);
			}
			customs[rel] = this._formBuilder.group(relGroup);
		}
		this._customRelationsForm = this._formBuilder.group(customs);
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {boolean}
	 */
	get editMode(): boolean {
		return this._editMode;
	}

	/**
	 * @type {{ [key: string]: RelationExtractionResult[] }}
	 */
	get customRelations(): { [key: string]: RelationExtractionResult[] } {
		return this._customRelations;
	}

	/**
	 * @type { number }
	 */
	get customRelationsLength(): number {
		return Object.keys(this._customRelations).length ?? 0;
	}

	/**
	 * @type {FormGroup}
	 */
	get customRelationsForm(): FormGroup {
		return this._customRelationsForm;
	}

	/**
	 * @type {{ [key: string]: string }}
	 */
	get customRelationsEnum(): { [key: string]: string } {
		return this._customRelationEnum;
	}

	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
	}
}
