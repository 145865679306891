<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="linkedRecord && fileList.length > 0" class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">{{ t('documentList.title') }}</div>
		<div class="flex flex-row">
			<table class="w-full" mat-table [dataSource]="fileList">
				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.name') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.name }}
					</td>
				</ng-container>
				<ng-container matColumnDef="contents">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.contents') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.contents }}
					</td>
				</ng-container>
				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.type') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.type }}
					</td>
				</ng-container>
				<ng-container matColumnDef="size">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.size') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ formatBytes(fileData.size) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="createdBy">
					<th mat-header-cell *matHeaderCellDef>{{ t('documentList.createdBy') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ getUserName(fileData.createdBy) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.created') }}</th>
					<td mat-cell *matCellDef="let fileData">
						{{ fileData.created ? (fileData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
