export const environment = {
	firebase: {
		apiKey: 'AIzaSyCF3AyXqqTUmLY3GJiFxAgaS6EhwgHDoho',
		appId: '1:880889463490:web:61c6f128847af8b573b15b',
		authDomain: 'skynet-devt.firebaseapp.com',
		messagingSenderId: '880889463490',
		measurementId: 'G-XVMRDW0PPR',
		projectId: 'skynet-devt',
		storageBucket: 'skynet-devt.appspot.com',
	},
	production: false,
	useEmulators: false,
	isLocal: false,
	algolia: {
		appId: 'CRM6S4YH5T',
		apiKey: '433e5af2bf7a465358b5195725867187',
	},
};
