<ng-container *transloco="let t; read: 'platform.entity.communicationDetails'">
	<div
		*ngIf="entity && (entity.emails || entity.phoneNumbers) && (entity.emails.length > 0 || entity.phoneNumbers.length > 0)"
		class="flex flex-col bg-white rounded-2xl p-4"
	>
		<div class="font-medium tracking-tight truncate pb-4">
			{{ t('communicationDetails') }}
		</div>
		<div class="flex flex-row">
			<div id="leftCol" class="flex flex-col space-y-4 basis-1/2">
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('email') }}
					</div>
					<div>
						<ng-container *ngIf="entity.emails">
							<div class="flex">
								<mat-icon [svgIcon]="'heroicons_outline:mail'"></mat-icon>
								<div class="min-w-0 ml-6 space-y-1">
									<ng-container *ngFor="let email of entity.emails; trackBy: trackByFn">
										<div class="flex items-center leading-6">
											<a class="hover:underline text-primary-500" [href]="'mailto:' + email.email" target="_blank">
												{{ email.email }}
											</a>
											<div class="text-md truncate text-secondary" *ngIf="email.label">
												<span class="mx-2">&bull;</span>
												<span class="font-medium">{{ email.label }}</span>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
			<div id="rightCol" class="flex flex-col space-y-4 basis-1/2">
				<div class="flex flex-row space-x-4">
					<div class="min-w-20 font-light text-slate-600">
						{{ t('phone') }}
					</div>
					<div>
						<ng-container *ngIf="entity.phoneNumbers">
							<div class="flex">
								<mat-icon [svgIcon]="'heroicons_outline:phone'"></mat-icon>
								<div class="min-w-0 ml-6 space-y-1">
									<ng-container *ngFor="let phoneNumber of entity.phoneNumbers; trackBy: trackByFn">
										<div class="flex items-center leading-6">
											<div
												class="hidden sm:flex w-6 h-4 overflow-hidden"
												[matTooltip]="getCountryByIso(phoneNumber.country).name"
												[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
												[style.backgroundSize]="'24px 3876px'"
												[style.backgroundPosition]="getCountryByIso(phoneNumber.country).flagImagePos"
											></div>
											<div class="sm:ml-3 font-mono">
												{{ getCountryByIso(phoneNumber.country).code }}
											</div>
											<div class="ml-2.5 font-mono">
												{{ phoneNumber.phoneNumber }}
											</div>
											<div class="text-md truncate text-secondary" *ngIf="phoneNumber.label">
												<span class="mx-2">&bull;</span>
												<span class="font-medium">{{ phoneNumber.label }}</span>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
