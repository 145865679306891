import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';
// rxjs
import { combineLatest, Observable, Subject, takeUntil } from 'rxjs';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { RiskEngineSettingsService } from 'app/core/platform-data/risk-engine-settings.service';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { CaseStage } from 'app/shared/enums/case-stage';
import { Collection } from 'app/shared/enums/collection';
import { RiskCriterionType } from 'app/shared/enums/risk-criterion-type';
import { Case } from 'app/shared/types/case';
import { CountrySetting } from 'app/shared/types/countrySetting';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { FileData } from 'app/shared/types/fileData';
import { RiskCriterion } from 'app/shared/types/riskCriterion';
import { TaskAnswer } from 'app/shared/types/taskAnswer';
import { Label, Task } from 'app/shared/types/taskboardTypes';
// Components
import { DocumentsViewerComponent } from 'app/shared/components/documents/documents-viewer/documents-viewer.component';

@Component({
	selector: 'scrumboard-task-details',
	templateUrl: './details.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class CaseTaskDetailsComponent implements OnInit, OnDestroy {
	@ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
	taskForm: UntypedFormGroup;
	labels: Label[];
	filteredLabels: Label[];
	selectedProofOfSource: string[] = [];

	// Private
	private _case: Case;
	private _task: Task;

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _pickList: { [key: string]: string } = {};
	private _riskCriterion: RiskCriterion;
	private _linkedEntity: Company | Contact;
	private _files: FileData[] = [];
	private _selectionMismatched: boolean = false;

	/**
	 * Constructor
	 */
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			case$: Observable<Case>;
			task$: Task;
			linkedEntity$: Observable<Contact | Company>;
			files$: Observable<FileData[]>;
			taskAnswer$: TaskAnswer;
		},
		public matDialogRef: MatDialogRef<CaseTaskDetailsComponent>,
		private _changeDetectorRef: ChangeDetectorRef,
		private _formBuilder: UntypedFormBuilder,
		private _riskEngineSettingsService: RiskEngineSettingsService,
		private _entityHelperService: EntityHelperService,
		private _dialog: MatDialog,
		private _authService: AuthService
	) {
		this._task = data.task$;
	}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Prepare the task form
		this.taskForm = this._formBuilder.group({
			id: [''],
			title: ['', Validators.required],
			labels: [[]],
			dueDate: [null],
			value: [],
			sourceOfProof: [[]],
			verifiedValue: [[]],
			comment: [''],
		});

		// Fill the form
		this.taskForm.patchValue(this.data.task$);
		if (this.data.taskAnswer$) this.taskForm.get('verifiedValue').setValue(this.data.taskAnswer$.value);
		combineLatest([this.data.case$, this.data.linkedEntity$, this.data.files$, this._riskEngineSettingsService.activeRiskEngineSettings$])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(([caseData, linkedEntity, files, riskEngineSettings]) => {
				this._case = caseData;
				this._linkedEntity = linkedEntity;
				this._files = files;

				// set the riskCriterion that belongs to the task
				this._riskCriterion = this._task.riskCriterion;

				// set picklist values
				this._setPicklist(caseData.accountType.id, this._riskCriterion, riskEngineSettings.countrySettings);
				if (!this.canEdit) this.taskForm.disable();
			});
		this.valuesChanged();
		this._changeDetectorRef.markForCheck();
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Check if the given date is overdue
	 */
	isOverdue(dateStr: string): boolean {
		const date = new Timestamp(dateStr['seconds'], dateStr['nanoseconds']).toDate();
		return dayjs(date).isBefore(dayjs().startOf('day'), 'days');
	}

	/**
	 * Track by function for ngFor loops
	 *
	 * @param index
	 * @param item
	 */
	trackByFn(index: number, item: any): any {
		return item.id || index;
	}

	/**
	 * Saves the task and updates non-casespecific values in entities
	 * @return {void}
	 */
	save(): void {
		this._saveValueNonSpecific()
			.then(() => {
				this.matDialogRef.close(this.taskForm.value);
			})
			.catch((err) => {
				console.error(err);
			});
	}

	/**
	 * Close dialog
	 * @return {void}
	 */
	cancel(): void {
		this.matDialogRef.close();
	}

	/**
	 * Opens a file
	 *
	 * @param {FileData} file
	 * @return {void}
	 */
	showFile(file: FileData): void {
		this._dialog
			.open(DocumentsViewerComponent, { height: '95%', width: '100%', data: file })
			.afterClosed()
			.subscribe(() => {});
	}

	/**
	 * Sets the due date
	 * @return {void}
	 */
	setDueDate(): void {
		// Firestore saves date as timestamp but datepicker provides date
		// duedate has to be set to timestamp as all functions/frontend stuff is implemented for timestamp
		const val = Timestamp.fromDate(this.taskForm.get('dueDate').value);
		this.taskForm.get('dueDate').setValue(val);
	}

	/**
	 * Remove selected customer input
	 * @param id id of the element which needs to be removed
	 * @param from list name from which needs to be removed
	 * @return {void}
	 */
	remove(id: string, controlName: string): void {
		const values = this.taskForm.get(controlName).value;
		this.removeFirst(values, id);
		this.taskForm.get(controlName).setValue(values);
	}

	/**
	 * Handle values changed event to show alert
	 * @return {void}
	 */
	valuesChanged(): void {
		this._selectionMismatched = false;
		const customerInputs = this.taskForm.get('value').value;
		const verifiedValues = this.taskForm.get('verifiedValue').value;
		if (!verifiedValues || verifiedValues.length === 0) return;
		this._selectionMismatched = JSON.stringify(customerInputs) !== JSON.stringify(verifiedValues);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Find and remove first element.
	 * @param array
	 * @param toRemove
	 * @return {void}
	 */
	private removeFirst<T>(array: T[], toRemove: T): void {
		const index = array.indexOf(toRemove);
		if (index !== -1) array.splice(index, 1);
	}

	/**
	 * Saves verified value in entity if risk criterion is not casespecific
	 * @return {Promise<void>}
	 */
	async _saveValueNonSpecific(): Promise<void> {
		const valueVerified = this.taskForm.value.verifiedValue;
		if (!valueVerified || valueVerified.toString() === '' || this._riskCriterion.caseSpecific) return;
		const updData: Contact | Company = { ...this._linkedEntity };
		const updId = this._linkedEntity.id;
		const customData = this._linkedEntity.customData;
		customData[this._riskCriterion.id] = valueVerified;
		updData.customData = customData;
		return this._entityHelperService.updateEntity(updData, updId);
	}

	/**
	 * Sets the picklists depending on the riskcriteriontype
	 *
	 * @param {string} accountTypeId
	 * @param {RiskCriterion} riskCriterion
	 * @param {{ [key: string]: CountrySetting }} countrySettings
	 * @return {void}
	 */
	private _setPicklist(accountTypeId: string, riskCriterion: RiskCriterion, countrySettings: { [key: string]: CountrySetting }): void {
		let pickList: { [key: string]: string } = {};

		switch (riskCriterion.type) {
			case RiskCriterionType.CUSTOMLIST:
			case RiskCriterionType.SANCTIONLISTCHECK:
				if (riskCriterion.children)
					riskCriterion.children.forEach((child) => {
						if (child.accountTypes.includes(accountTypeId)) pickList[child.id] = child.title;
					});
				break;
			case RiskCriterionType.COUNTRY:
				for (let country in countrySettings) pickList[country] = countrySettings[country].title;
				break;
			default:
				pickList = {};
		}

		this._pickList = pickList;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {Task}
	 */
	get task(): Task {
		return this._task;
	}

	/**
	 * @type {{
	 * 		[key: string]: string;
	 * 	}}
	 */
	get pickList(): {
		[key: string]: string;
	} {
		return this._pickList;
	}

	/**
	 * @type {FileData[]}
	 */
	get files(): FileData[] {
		return this._files;
	}

	/**
	 * @type {string}
	 */
	/**
	 * @type {string}
	 */
	get description(): string {
		return this._riskCriterion.description;
	}

	/**
	 * @type {boolean}
	 */
	/**
	 * @type {boolean}
	 */
	get multipleCustomerInput(): boolean {
		return this._riskCriterion.multipleCustomerInput;
	}

	/**
	 * @type {any}
	 */
	get dueDate(): any {
		return this.taskForm.get('dueDate').value;
	}

	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		const hasPermission = this._authService.checkRolePermission(Collection.CASES, Actions.edit);
		if (hasPermission && this._case) {
			const a = this._case.stage;
			const b = [CaseStage.APPROVAL, CaseStage.APPROVED, CaseStage.DECLINED];
			return b.indexOf(a) === -1;
		} else return hasPermission;
	}

	/**
	 * @type {boolean}
	 */
	get verifiable(): boolean {
		return this.data.task$.riskCriterion.verifiable;
	}

	/**
	 * @type {string[]}
	 */
	get selectedValues(): string[] {
		return this.taskForm.get('value').value;
	}

	/**
	 * @type {string[]}
	 */
	get selectedVerifiedValues(): string[] {
		return this.taskForm.get('verifiedValue').value;
	}

	/**
	 * @type {boolean}
	 */
	get selectionMisMatched(): boolean {
		return this._selectionMismatched;
	}
}
