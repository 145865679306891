import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@jsverse/transloco';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CommunicationDetailsComponent } from './communication-details/communication-details.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { CustomFieldsDetailsComponent } from './custom-fields-details/custom-fields-details.component';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { EnitityRelationsListComponent } from './enitity-relations-list/enitity-relations-list.component';
import { NewsCheckListComponent } from './news-check-list/news-check-list.component';
import { SanctionListCheckListComponent } from './sanction-list-check-list/sanction-list-check-list.component';
@NgModule({
	declarations: [
		AddressDetailsComponent,
		CaseListComponent,
		CommunicationDetailsComponent,
		CompanyDetailsComponent,
		ContactDetailsComponent,
		EnitityRelationsListComponent,
		DocumentsListComponent,
		ContactListComponent,
		NewsCheckListComponent,
		SanctionListCheckListComponent,
		CustomFieldsDetailsComponent,
	],
	imports: [CommonModule, TranslocoModule, MatTableModule],
	exports: [
		AddressDetailsComponent,
		CaseListComponent,
		CommunicationDetailsComponent,
		CompanyDetailsComponent,
		ContactDetailsComponent,
		EnitityRelationsListComponent,
		DocumentsListComponent,
		ContactListComponent,
		NewsCheckListComponent,
		SanctionListCheckListComponent,
		CustomFieldsDetailsComponent,
	],
})
export class ReportComponentsModule {}
