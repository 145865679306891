import { Component, Input } from '@angular/core';

// Types and enums
import { countries } from 'app/shared/enums/country-list';
import { Address } from 'app/shared/types/address';
import { Country } from 'app/shared/types/country';
import { Company, Contact } from 'app/shared/types/entityTypes';

@Component({
	selector: 'report-address-details',
	templateUrl: './address-details.component.html',
	styleUrls: ['./address-details.component.scss'],
})
export class AddressDetailsComponent {
	@Input() entity: Contact | Company;

	/**
	 * Returns the address as a formatted string
	 *
	 * @param {Address} address
	 * @return {string}
	 */
	formatAddress(address: Address): string {
		const addressData = [];
		if (address.line1) addressData.push(address.line1);
		if (address.line2) addressData.push(address.line2);
		if (address.city) addressData.push(`${address.zip_code} ${address.city}`);
		if (address.state) addressData.push(address.state);
		if (address.country) this.getCountryByIso(address.country).name;
		return addressData.join(', ');
	}

	/**
	 * Get country info by iso code
	 *
	 * @param {string} iso
	 * @return {Country}
	 */
	getCountryByIso(iso: string): Country {
		return countries.find((country) => country.iso === iso);
	}
}
