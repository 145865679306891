<ng-container *transloco="let t">
	<!-- Bar search -->
	<ng-container *ngIf="appearance === 'bar'">
		<button mat-icon-button *ngIf="!opened" (click)="open()">
			<mat-icon [svgIcon]="'heroicons_outline:magnifying-glass'"></mat-icon>
		</button>
		<div class="absolute inset-0 flex items-center shrink-0 z-99 bg-card mb-2" *ngIf="opened" @slideInTop @slideOutTop>
			<div class="mt-6 w-full">
				<div class="searchbox">
					<app-autocomplete
						(onQuerySuggestionClick)="openObject($event)"
						[appearance]="'standard'"
						[placeholder]="t('layout.search.searchForAnEntityOrCase')"
						class="w-full"
						[isGlobalSearch]="true"
					></app-autocomplete>
				</div>
			</div>
			<button class="absolute top-1/2 right-5 sm:right-7 shrink-0 w-10 h-10 -mt-5" mat-icon-button (click)="close()">
				<mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
	</ng-container>
</ng-container>
