<ng-container *transloco="let t">
	<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">
				{{ t('platform.approval.requestChangesDialog.requestChangesForTheApproval') }}
			</div>
			<button mat-icon-button mat-dialog-close [tabIndex]="-1">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<div class="flex flex-col px-6 space-y-2 mt-4 w-full">
			<ng-container *ngFor="let child of requestedChanges.controls; let i = index">
				<form [formGroup]="child">
					<div class="flex flex-row w-full">
						<mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full">
							<input
								id="title"
								#title
								matInput
								[formControlName]="'change'"
								[placeholder]="t('platform.approval.requestChangesDialog.requestedChanges')"
							/>
							<mat-error *ngIf="child.get('change').hasError('required')">{{
								t('platform.approval.requestChangesDialog.required')
							}}</mat-error>
							<mat-error *ngIf="child.get('change').hasError('pattern')">
								{{ t('platform.approval.requestChangesDialog.invalid') }}</mat-error
							>
						</mat-form-field>
						<button mat-icon-button color="warn" (click)="delete(i)" class="ml-auto">
							<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
						</button>
					</div>
				</form>
			</ng-container>
			<div class="flex flex-row items-center justify-between pb-2">
				<button mat-stroked-button (click)="add()">
					<mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:plus'"></mat-icon>
					<span class="ml-2">{{ t('platform.approval.requestChangesDialog.addAnotherItem') }}</span>
				</button>
				<button
					class="order-first sm:order-last"
					mat-flat-button
					[color]="'primary'"
					[mat-dialog-close]="changesForm.get('requestedChanges').value"
					[disabled]="!changesForm.valid || changesForm.get('requestedChanges').value.length === 0"
				>
					<span>{{ t('platform.approval.requestChangesDialog.save') }}</span>
				</button>
			</div>
		</div>
	</div>
</ng-container>
