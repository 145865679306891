import { Component, ViewEncapsulation, inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseConfirmationConfig } from '@fuse/services/confirmation/confirmation.types';

@Component({
	selector: 'fuse-confirmation-dialog',
	templateUrl: './dialog.component.html',
	styles: [
		`
			.fuse-confirmation-dialog-panel {
				@screen md {
					@apply w-128;
				}

				.mat-mdc-dialog-container {
					.mat-mdc-dialog-surface {
						padding: 0 !important;
					}
				}
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
	// standalone: true,
	// imports: [MatButtonModule, MatDialogModule, MatIconModule, NgClass],
})
export class FuseConfirmationDialogComponent {
	data: FuseConfirmationConfig = inject(MAT_DIALOG_DATA);
}
