<ng-container *transloco="let t">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('platform.entity.contacts.contacts') }}</div>
			<!-- Actions -->
			<div class="">
				<div [matTooltip]="t('platform.entity.contacts.notPermission')" [matTooltipDisabled]="canCreate">
					<button
						mat-icon-button
						color="primary"
						(click)="addContact()"
						[matTooltip]="t('platform.entity.contacts.addNewContact')"
						[disabled]="!canCreate"
					>
						<mat-icon [svgIcon]="'heroicons_solid:plus-small'"></mat-icon>
					</button>
				</div>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row pb-3">
			<table class="w-full" mat-table [dataSource]="contactData">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.name') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ contactData.displayName }}
					</td>
				</ng-container>
				<ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.owner') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ getUserName(contactData.owner) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('platform.entity.common.created') }}</th>
					<td mat-cell *matCellDef="let contactData">
						{{ contactData.created ? (contactData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr class="cursor-pointer" mat-row *matRowDef="let row; columns: columnsToDisplay" (click)="openContact(row.id)"></tr>
			</table>
		</div>
	</div>
</ng-container>
