import { Component, Input, OnInit } from '@angular/core';
import { PassportExtractionResult } from 'app/shared/types/extraction-results';
import { FileData } from 'app/shared/types/fileData';

import dayjs from 'dayjs';
@Component({
	selector: 'passports-element',
	templateUrl: './passports-element.component.html',
	styleUrls: ['./passports-element.component.scss'],
})
export class PassportsElementComponent implements OnInit {
	@Input() files: FileData[];

	private _passportResults: PassportExtractionResult[] = [];
	private _indexDisplayed: number = 0;

	constructor() {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		this.files.forEach((file) => {
			if (file.extractionResults) {
				this._passportResults.push(new PassportExtractionResult(file.extractionResults as PassportExtractionResult));
			}
		});
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	formatDate(date: string) {
		return dayjs(date, 'YYMMDD').toDate();
	}

	passportValid(expirationDate: string): boolean {
		if (expirationDate) {
			return this.formatDate(expirationDate) > new Date();
		} else return false;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get passportResults(): PassportExtractionResult[] {
		return this._passportResults;
	}

	get indexDisplayed(): number {
		return this._indexDisplayed;
	}
}
