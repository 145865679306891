import { Component, Inject, Input, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { finalize, lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
// services
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { StorageService } from 'app/core/storage/storage.service';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Modules } from 'app/shared/enums/modules';
import { PassportExtractionResult } from 'app/shared/types/extraction-results';
import { FileData } from 'app/shared/types/fileData';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { DocumentsViewerComponent } from '../documents-viewer/documents-viewer.component';

@Component({
	selector: 'identification-documents-list',
	templateUrl: './identification-documents-list.component.html',
	styleUrls: ['./identification-documents-list.component.scss'],
})
export class IdentificationDocumentsListComponent implements OnInit {
	@Input() linkedRecord: DocumentReference;

	columnsToDisplay = ['name', 'expirationDate', 'issuingState', 'nationality', 'actions'];

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _fileListObservable: DataObserver;
	private _fileList: FileData[] = [];
	private _deleteConfirmationTranslatedText = {};

	constructor(
		private _platformInformationService: PlatformInformationService,
		private _storageService: StorageService,
		private _dialog: MatDialog,
		private _fuseConfirmationService: FuseConfirmationService,
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService,
		private _authService: AuthService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._storageService
			.readFileData(this.linkedRecord)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._fileListObservable.unsubscribe();
				})
			)
			.subscribe((filesObserver) => {
				this._fileListObservable = filesObserver;
				this._fileList = filesObserver.data.filter((file) => file.contents === 'passport');
			});

		// Get translated text for delete file confirmation box
		this._getTranslatedObjectByKey('entity.common.deleteFileConfirmation').subscribe((result) => {
			this._deleteConfirmationTranslatedText = result;
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the full name of the user
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	/**
	 * Opens a file
	 *
	 * @param {FileData} file file to open
	 * @returns {Promise} Promise
	 */
	openFile(file: FileData): Promise<void> {
		return lastValueFrom(
			this._dialog
				.open(DocumentsViewerComponent, {
					height: '95%',
					width: '100%',
					data: file,
				})
				.afterClosed()
		);
	}

	/**
	 * Uploads files to storage and database
	 *
	 * @return {Promise} Promise
	 */
	addFiles(event: any): Promise<void> {
		const target = event.target as HTMLInputElement;
		if (!target.files) return;
		for (let i = 0; i < target.files.length; i++) (target.files[i] as any).contents = 'passport';
		return this._storageService.uploadFiles(target.files, this.linkedRecord);
	}

	/**
	 * Deletes a file from storage and database with confirmation dialog
	 *
	 * @param {FileData} file
	 * @return {Promise}
	 */
	async deleteFile(file: FileData): Promise<any[]> {
		// Open the confirmation dialog
		const confirmation = this._fuseConfirmationService.open({
			title: this._deleteConfirmationTranslatedText['title'],
			message: this._deleteConfirmationTranslatedText['message'],
			actions: {
				confirm: {
					label: this._deleteConfirmationTranslatedText['label'],
				},
			},
		});
		// Subscribe to the confirmation dialog closed action
		const result = await lastValueFrom(confirmation.afterClosed());
		// If the confirm button pressed...
		if (result !== 'confirmed') return Promise.resolve([]);
		return this._storageService.deleteFile(file);
	}

	/**
	 * Downloads a file without dialog to set path
	 *
	 * @param {FileData} file
	 * @return {Promise} Promise
	 */
	downloadFile(file: FileData): Promise<void> {
		return this._storageService.downloadFile(file.filePath, file.name);
	}

	/**
	 * Formats bytes to string
	 *
	 * @param {number} bytes
	 * @param {number} [decimals=2]
	 * @return {string} formated string
	 */
	formatBytes(bytes, decimals = 2): string {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	/**
	 * Gets value for extractionResults, if value is a date it can be formatted as dates in passports have format YYMMDD
	 *
	 * @param extractionRes
	 * @param key
	 * @param formatDate
	 */
	extractionResultVal(extractionRes: PassportExtractionResult, key: string, formatDate: boolean): string {
		if (!extractionRes || !extractionRes.fields[key]) return '';
		const res = extractionRes.fields[key];
		if (!formatDate) return res;
		const arr = res.match(/.{1,2}/g);
		return arr[1] + '/' + arr[2] + '/' + arr[0];
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------
	/**
	 * Get the translation of the object by using key
	 * @param { string } key
	 * @return { Observable }
	 */
	private _getTranslatedObjectByKey(key: string): Observable<string> {
		return this._translocoService.selectTranslateObject(key, {}, this.scope);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {FileData[]}
	 */
	get fileList(): FileData[] {
		return this._fileList;
	}

	/**
	 * @type {boolean}
	 */
	get canCreate(): boolean {
		return this._authService.checkRolePermission(Modules.FILES, Actions.create);
	}

	/**
	 * @type {boolean}
	 */
	get canDelete(): boolean {
		return this._authService.checkRolePermission(Modules.FILES, Actions.delete);
	}
}
