import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { InstantSearchService } from './instant-search.service';

@NgModule({
	declarations: [AutocompleteComponent],
	imports: [CommonModule, MatInputModule, MatAutocompleteModule, MatIconModule, ReactiveFormsModule],
	exports: [AutocompleteComponent],
	providers: [InstantSearchService],
})
export class SearchModule {}
