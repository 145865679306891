import { ActivatedRouteSnapshot, Route, RouterStateSnapshot } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';

import { AuthGuard, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { ParsedToken } from 'firebase/auth';
import { map, pipe } from 'rxjs';

const redirectUnauthorizedToLogin = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	if (state.url !== '/sign-out') return redirectUnauthorizedTo(`sign-in?redirectURL=${state.url}`);
	else return redirectUnauthorizedTo('sign-in');
};
const redirectLoggedInToHome = () => redirectLoggedInTo(['dashboard']);
const hasAccount = () =>
	pipe(
		customClaims,
		map((claims) => {
			if ((claims as ParsedToken).accountId) return true;
			else return ['set-account-details'];
		})
	);

export const appRoutes: Route[] = [
	// Redirect empty path to '/dashboard'
	{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },

	// After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
	// path. Below is another redirection for that path to redirect the user to the desired
	// location. This is a small convenience to keep all main routes together here on this file.
	{ path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

	// Auth routes for guests
	{
		path: '',
		canActivate: [AuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
			authGuardPipe: redirectLoggedInToHome,
		},
		children: [
			{
				path: 'forgot-password',
				loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then((m) => m.AuthForgotPasswordModule),
			},
			{
				path: 'sign-in',
				loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then((m) => m.AuthSignInModule),
			},
		],
	},
	// Error routes
	{
		path: '',
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'error',
				loadChildren: () => import('app/modules/error/error.module').then((m) => m.ErrorModule),
			},
		],
	},

	// Platform routes for authenticated users
	{
		path: '',
		canActivate: [AuthGuard],
		component: LayoutComponent,
		data: { authGuardPipe: redirectUnauthorizedToLogin },
		children: [
			{
				path: '',
				loadChildren: () => import('app/modules/platform/platform.module').then((m) => m.PlatformModule),
				canActivate: [AuthGuard],
				data: { authGuardPipe: hasAccount },
				resolve: { InitialDataResolver },
			},
			{
				path: 'sign-out',
				loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then((m) => m.AuthSignOutModule),
				canActivate: [AuthGuard],
				data: {
					layout: 'empty',
				},
			},
		],
	},
	{ path: '**', redirectTo: 'dashboard' },
];
