<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="entity && caseData.length > 0" class="flex flex-col bg-white rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">{{ t('caseList.cases') }}</div>
		<div class="flex flex-row">
			<table class="w-full" mat-table [dataSource]="caseData">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.title') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.title }}
					</td>
				</ng-container>
				<ng-container matColumnDef="stage">
					<th mat-header-cell *matHeaderCellDef>{{ t('caseList.stage') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.stage }}
					</td>
				</ng-container>
				<ng-container matColumnDef="owner">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.owner') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ getUserName(caseData.owner) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="created">
					<th mat-header-cell *matHeaderCellDef>{{ t('common.created') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.created ? (caseData.created.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="modified">
					<th mat-header-cell *matHeaderCellDef>{{ t('caseList.modified') }}</th>
					<td mat-cell *matCellDef="let caseData">
						{{ caseData.edited ? (caseData.edited.toDate() | date: 'short') : '-' }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>
</ng-container>
