import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
// Transloco module
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
	declarations: [ShortcutsComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		RouterModule,
		OverlayModule,
		PortalModule,
		MatButtonModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatSlideToggleModule,
		MatTooltipModule,
		TranslocoModule,
	],
	exports: [ShortcutsComponent],
})
export class ShortcutsModule {}
