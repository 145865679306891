import { Entity } from '../enums/entity';

export interface RelationshipType {
	id?: string;
	enabled: boolean;
	slcRelevant: boolean;
	behavior: RelationshipBehaviors;
	primaryEntity: Entity;
	relatedEntity: Entity;
	description: string;
	embedding: number[];
	reverseDescription: string;
	reverseEmbedding: number[];
	created: Date;
	createdBy: string;
	edited?: Date;
	editedBy?: string;
}
export enum RelationshipBehaviors {
	ONETOMANY = 'oneToMany',
	MANYTOMANY = 'manyToMany',
}
