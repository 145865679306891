import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { countries } from 'app/shared/enums/country-list';
import { Entity } from 'app/shared/enums/entity';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { PassportExtractionResult } from 'app/shared/types/extraction-results';
import { FileData } from 'app/shared/types/fileData';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
@Component({
	selector: 'entity-element',
	templateUrl: './entity-element.component.html',
	styleUrls: ['./entity-element.component.scss'],
})
export class EntityElementComponent implements OnInit, OnChanges, OnDestroy {
	@Input() entity: Company | Contact;
	@Input() passportFiles: FileData[];

	private _passportResult: PassportExtractionResult;
	private _entitySubject: BehaviorSubject<Company | Contact> = new BehaviorSubject<Company | Contact>(null);
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor() {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		if (this.entity && this.entity.type === Entity.CONTACT) {
			this._matchPassport(this.entity as Contact);
		}

		this._entitySubject.pipe(takeUntil(this._unsubscribeAll)).subscribe((entity) => {
			if (entity && entity.type === Entity.CONTACT) this._matchPassport(this.entity as Contact);
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.entity && changes.entity.currentValue) {
			this._entitySubject.next(changes.entity.currentValue);
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Private Methods
	// -------------------------------------------------------------------------

	private _matchPassport(entity: Contact) {
		this.passportFiles.forEach((passportFile) => {
			if (passportFile.extractionResults) {
				let result = new PassportExtractionResult(passportFile.extractionResults as PassportExtractionResult);

				if (
					result.fields.lastName.toUpperCase() === entity.lastName.toUpperCase() &&
					result.fields.firstName.toUpperCase() === entity.firstName.toUpperCase()
				) {
					this._passportResult = result;
				}
			}
		});
	}

	private _formatAddress(entity: Company | Contact) {
		let finalAddressData = {};
		if (entity && entity.addresses && entity.addresses[0]) {
			finalAddressData = entity.addresses[0];
		}
		return finalAddressData;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get passportResult(): PassportExtractionResult {
		return this._passportResult;
	}

	get passportAvailable(): boolean {
		let result = false;

		if (this.entity && this.passportFiles) {
			if (this.entity.type === Entity.CONTACT) {
				let contact = this.entity as Contact;
				this.passportFiles.forEach((passportFile) => {
					if (passportFile && passportFile.extractionResults) {
						let passport = new PassportExtractionResult(passportFile.extractionResults as PassportExtractionResult);

						if (
							passport.fields.lastName.toUpperCase() === contact.lastName.toUpperCase() &&
							passport.fields.firstName.toUpperCase() === contact.firstName.toUpperCase()
						) {
							result = true;
						}
					}
				});
			}
		}

		return result;
	}

	get address(): any {
		return this._formatAddress(this.entity);
	}

	/**
	 * Get country info by iso code
	 * @param iso iso code
	 * @returns country name
	 */
	getCountryByIso(iso: string): string {
		let country = countries.find((country) => country.iso === iso);
		return country && country.name ? country.name : '';
	}
}
