import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

// types & enums
import { FileData } from 'app/shared/types/fileData';
// services
import { StorageService } from 'app/core/storage/storage.service';
import { DocumentsViewerService } from './documents-viewer.service';

@Component({
	selector: 'documents-viewer',
	templateUrl: './documents-viewer.component.html',
	styleUrls: ['./documents-viewer.component.scss'],
	providers: [DocumentsViewerService],
})
export class DocumentsViewerComponent implements OnInit {
	private _url;
	private _type: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) private _file: FileData,
		private _storageService: StorageService
	) {
		this._type = this._file.type;
	}

	ngOnInit(): void {
		this._storageService.getFileUrl(this._file.filePath).then((url) => {
			this._url = url;
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get url(): string {
		return this._url;
	}

	get viewer(): string {
		let viewer = 'google';
		switch (this._type) {
			case 'application/pdf':
				viewer = 'pdf';
				break;
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				viewer = 'office';
				break;
			case 'image/png':
			case 'image/jpeg':
			case 'image/gif':
				viewer = 'image';
				break;
		}

		return viewer;
	}

	get viewerUrl(): string {
		return 'https://docs.google.com/gview?url=%URL%&embedded=true';
	}
}
