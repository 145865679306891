import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
// types
import { Case } from 'app/shared/types/case';
// enums
import { MatStepper } from '@angular/material/stepper';
import { CaseStage } from 'app/shared/enums/case-stage';
import { Task } from 'app/shared/types/taskboardTypes';
import { Observable, Subject } from 'rxjs';
// Services
import { TRANSLOCO_SCOPE, TranslocoService } from '@jsverse/transloco';

@Component({
	selector: 'case-status-element',
	templateUrl: './case-status-element.component.html',
	styleUrls: ['./case-status-element.component.scss'],
})
export class CaseStatusElementComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild('stepper') stepper: MatStepper;
	@Input() case: Case;
	@Input() tasks: Task[];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _translatedText = {};

	caseStages = CaseStage;
	constructor(
		@Inject(TRANSLOCO_SCOPE) private scope,
		private _translocoService: TranslocoService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit() {
		// Get translated text
		this._setTranslatedTextCaseStatusElement();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if ((!changes.tasks && !changes.case) || !this.stepper) return;
		//prevents setting current step
		this.stepper.linear = false;
		this.stepper.selectedIndex = this.stageOrder[this.case.stage] ? this.stageOrder[this.case.stage] : 0;
		setTimeout(() => {
			this.stepper.linear = true;
		});
	}

	ngOnDestroy() {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Private Methods
	// -------------------------------------------------------------------------

	/**
	 * Get the translation of the object by using key
	 * @param { string } key
	 * @return { Observable }
	 */
	private _getTranslatedTextByKey(key: string): Observable<string> {
		return this._translocoService.selectTranslate(key, {}, this.scope);
	}

	/**
	 * Sets translations object
	 * @return {void}
	 */
	private _setTranslatedTextCaseStatusElement(): void {
		let keys = [
			'open',
			'approved',
			'declined',
			'approval',
			'inprogress',
			'decision',
			'startWorkingOnTask',
			'submitCaseForApproval',
			'waitForCaseToBeApproved',
		];
		// Get translated text of common text
		for (let i = 0; i < keys.length; i++) {
			this._getTranslatedTextByKey('cases.dashboard.caseStatusElement.' + keys[i]).subscribe((result) => {
				this._translatedText[keys[i]] = result;
			});
		}
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns translated infos for stepper component
	 * @param {CaseStage} stage
	 * @return {string}
	 */
	getInfo(stage: CaseStage): string {
		switch (stage) {
			case CaseStage.OPEN:
				break;
			case CaseStage.INPROGRESS:
				if (this.case.stage === CaseStage.OPEN) return this._translatedText['startWorkingOnTask'];
				break;
			case CaseStage.APPROVAL:
				return this._translatedText['submitCaseForApproval'];
			case CaseStage.APPROVED:
				return this._translatedText['waitForCaseToBeApproved'];
			default:
				break;
		}
	}

	/**
	 * Get translated stage value
	 * @param { CaseStage } stage
	 * @return { string }
	 */
	getTranslatedStageValue(stage: CaseStage): string {
		switch (stage) {
			case CaseStage.OPEN:
				return this._translatedText['open'];
			case CaseStage.INPROGRESS:
				return this._translatedText['inprogress'];
			case CaseStage.APPROVAL:
				return this._translatedText['approval'];
			case CaseStage.APPROVED:
				return this._translatedText['approved'];
			default:
				return stage;
		}
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {{ [key: string]: number }}
	 */
	get stageOrder(): { [key: string]: number } {
		const stageOrder = {};
		stageOrder[CaseStage.OPEN] = 0;
		stageOrder[CaseStage.INPROGRESS] = 1;
		stageOrder[CaseStage.APPROVAL] = 2;
		stageOrder[CaseStage.APPROVED] = 3;
		stageOrder[CaseStage.DECLINED] = 3;
		return stageOrder;
	}
}
