<ng-container *transloco="let t">
	<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">
		<!-- Header -->
		<div class="flex flex-0 items-center justify-between h-16 pr-3 sm:pr-5 pl-6 sm:pl-8 bg-primary text-on-primary">
			<div class="text-lg font-medium">{{ t('platform.cases.caseQuickCreate.addCaseFor') }} {{ entityDisplayName }}</div>
			<button mat-icon-button (click)="close()" [tabIndex]="-1" type="button">
				<mat-icon class="text-current" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
			</button>
		</div>
		<form class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto" [formGroup]="caseForm">
			<mat-form-field class="w-full">
				<mat-label>{{ t('platform.cases.caseQuickCreate.title') }}</mat-label>
				<input id="title" matInput [formControlName]="'title'" />
				<mat-error *ngIf="caseForm.get('title').hasError('required')">
					{{ t('platform.cases.caseQuickCreate.titleIsRequired') }}
				</mat-error>
			</mat-form-field>
			<mat-form-field class="fuse-mat-no-subscript">
				<mat-label>{{ t('platform.cases.caseQuickCreate.accountType') }}</mat-label>
				<mat-select [formControlName]="'accountType'">
					<mat-option *ngFor="let accountType of accountTypes" [value]="accountType">
						{{ accountType.title }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<!-- Actions -->
			<div class="flex flex-col sm:flex-row sm:items-center justify-between mt-4 sm:mt-6">
				<div class="flex items-center mt-4 sm:mt-0">
					<!-- Save as draft -->
					<button class="sm:mx-3" mat-stroked-button (click)="close()" type="button">
						<span>{{ t('platform.cases.caseQuickCreate.cancel') }}</span>
					</button>
					<!-- Send -->
					<button
						class="order-first sm:order-last"
						mat-flat-button
						[color]="'primary'"
						(click)="save()"
						[disabled]="caseForm.invalid"
						type="submit"
					>
						<span *ngIf="!caseForm.disabled"> {{ t('platform.cases.caseQuickCreate.createCase') }}</span>
						<mat-progress-spinner *ngIf="caseForm.disabled" [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
					</button>
				</div>
			</div>
		</form>
	</div>
</ng-container>
