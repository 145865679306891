import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
// types
import { Case } from 'app/shared/types/case';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { FileData } from 'app/shared/types/fileData';
import { Board, List, Task } from 'app/shared/types/taskboardTypes';
// enums
import { CaseStage } from 'app/shared/enums/case-stage';
import { CaseTaskLists, TaskStages } from 'app/shared/enums/caseTaskLists';
import { Collection } from 'app/shared/enums/collection';
import { TaskAnswer } from 'app/shared/types/taskAnswer';
// rxjs
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';
import { CaseService } from 'app/core/platform-data/case.service';
import { Actions } from 'app/shared/enums/actions';
import { CaseViewService } from '../case-view.service';

@Injectable()
export class TasksService {
	private _case: BehaviorSubject<Case> = new BehaviorSubject(null);
	private _board: BehaviorSubject<Board> = new BehaviorSubject(null);
	private _tasks: BehaviorSubject<Task[]> = new BehaviorSubject([]);
	private _task: BehaviorSubject<Task> = new BehaviorSubject(null);
	private _linkedEntity: BehaviorSubject<Company | Contact> = new BehaviorSubject(null);
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _taskAnswers: BehaviorSubject<TaskAnswer[]> = new BehaviorSubject([]);

	constructor(
		private _caseViewService: CaseViewService,
		private _dataService: DataService,
		private _authService: AuthService,
		private _caseService: CaseService
	) {
		combineLatest([this._caseViewService.case$, this._caseViewService.tasks$, this._caseViewService.linkedEntity$, this._caseViewService.files$])
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(async ([caseData, tasks, linkedEntity]) => {
				if (!caseData) return;
				this._case.next(caseData);
				if (tasks) {
					this._tasks.next(tasks);
					this._setBoard(tasks);
				}
				if (linkedEntity) this._linkedEntity.next(linkedEntity);
				this._taskAnswers.next(await this._caseService.readTaskAnswersToCase(caseData.id, tasks));
			});
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Creates a new task
	 *
	 * @param {Task} task
	 * @return {(Promise<DocumentReference<any> | void>)}
	 */
	createTask(task: Task): Promise<DocumentReference<any> | void> {
		const caseData = this._case.value;
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}/${caseData.id}/${Collection.TASKS}`;
		return this._dataService.storeDocument({ ...task }, path);
	}

	/**
	 * Update the tasks
	 *
	 * @param tasks
	 * @return {Promise<void>}
	 */
	updateTasks(tasks: Task[]): Promise<void> {
		const taskObjects = tasks.map((task) => {
			return { ...task };
		});
		const caseData = this._case.value;
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}/${caseData.id}/${Collection.TASKS}`;
		return this._dataService.updateDocuments(taskObjects, path);
	}

	/**
	 * Update the task
	 *
	 * @param id
	 * @param task
	 * @return {Promise<void>}
	 */
	updateTask(id: string, task: Partial<Task>): Promise<void> {
		const taskObject = { ...task };
		const caseData = this._case.value;
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}/${caseData.id}/${Collection.TASKS}`;
		return this._dataService.updateDocument(taskObject, path, id);
	}

	/**
	 * Get Task
	 *
	 * @param {string} id
	 * @return {Task}
	 */
	getTask(id: string): Task {
		// Find the task
		const task = this._board.value.lists.find((list) => list.tasks.some((item) => item.id === id)).tasks.find((item) => item.id === id);

		if (!task) {
			new Error('Task not found');
			return null;
		}
		// Update the task
		this._task.next(task);
		return task;
	}

	/**
	 * Updates the case stage depending on the tasks
	 *
	 * @return {Promise<void>}
	 */
	updateCaseStage(): Promise<void> {
		if (!this._board) return Promise.reject(new Error('No board'));
		const currentVal = this._case.value.stage;
		if (currentVal === CaseStage.APPROVAL || currentVal === CaseStage.APPROVED) return Promise.resolve();
		const listObj = this._board.value.lists.reduce((map, obj) => {
			map[obj.title] = obj.tasks;
			return map;
		}, {});
		const numberTasks = this._tasks.value.length;
		const numberTodo = listObj[TaskStages.TODO].length;
		const setStage = function (): CaseStage {
			if (numberTodo === numberTasks) return CaseStage.OPEN;
			return CaseStage.INPROGRESS;
		};
		const newStage = setStage();
		if (!newStage || newStage === currentVal) return Promise.resolve();
		return this._caseViewService.updateCase({ stage: newStage });
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Prepares the taskboard
	 * @private
	 * @param {Task[]} tasks
	 * @return {void}
	 */
	private _setBoard(tasks: Task[]): void {
		const caseData = this._case.value;
		const boardId = caseData.id;
		const lists: { [key: string]: List } = {};
		// prepare lists
		for (let listDefinition in CaseTaskLists) {
			lists[`${boardId}${CaseTaskLists[listDefinition].suffix}`] = {
				id: `${boardId}${CaseTaskLists[listDefinition].suffix}`,
				boardId: boardId,
				tasks: [],
				position: CaseTaskLists[listDefinition].position,
				title: CaseTaskLists[listDefinition].title,
			};
		}
		// sort tasks first
		tasks
			.sort((a, b) => a.position - b.position)
			.forEach((task) => {
				if (lists[task.listId]) lists[task.listId].tasks.push(task);
			});
		const board: Board = {
			id: caseData.id,
			title: caseData.title,
			lists: Object.values(lists),
			description: '',
			icon: '',
			labels: [],
			lastActivity: '',
			members: [],
		};
		this._board.next(board);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {Observable<Board>}
	 */
	get board$(): Observable<Board> {
		return this._board.asObservable();
	}

	/**
	 * @type {Observable<Task>}
	 */
	get task$(): Observable<Task> {
		return this._task.asObservable();
	}

	/**
	 * @type {Observable<Case>}
	 */
	get case$(): Observable<Case> {
		return this._case.asObservable();
	}

	/**
	 * @type {(Observable<Contact | Company>)}
	 */
	get linkedEntity$(): Observable<Contact | Company> {
		return this._linkedEntity.asObservable();
	}

	/**
	 * @type {Observable<FileData[]>}
	 */
	get files$(): Observable<FileData[]> {
		return this._caseViewService.files$;
	}

	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return !this._caseViewService.isSetForApproval && this._authService.checkRolePermission(Collection.CASES, Actions.edit);
	}

	/**
	 * @type {Observable<Task[]>}
	 */
	get tasks$(): Observable<Task[]> {
		return this._tasks.asObservable();
	}

	/**
	 * @type {Observable<Task[]>}
	 */
	get taskAnswers$(): Observable<TaskAnswer[]> {
		return this._taskAnswers.asObservable();
	}
}
