import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import algoliasearch from 'algoliasearch/lite';
import { environment as env } from 'environments/environment';
// types & enums
import { Collection } from 'app/shared/enums/collection';
import { Company, ICompany } from 'app/shared/types/entityTypes';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { DataService } from 'app/core/database/data.service';
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { Timestamp } from 'firebase/firestore';

const searchClient = algoliasearch(env.algolia.appId, env.algolia.apiKey);
@Component({
	selector: 'company-quick-create',
	templateUrl: './company-quick-create.component.html',
	styleUrls: ['./company-quick-create.component.scss'],
})
export class CompanyQuickCreateComponent implements OnInit {
	private _companyForm: UntypedFormGroup;
	private _duplicates: Partial<Company>[] = [];
	columnsToDisplay = ['name', 'owner', 'createdBy', 'created'];
	isInCreationMode: boolean = false;

	constructor(
		private _dialogRef: MatDialogRef<CompanyQuickCreateComponent>,
		private _formBuilder: UntypedFormBuilder,
		private _dataService: DataService,
		private _authService: AuthService,
		private _platformInformationService: PlatformInformationService,
		private _entityHelperService: EntityHelperService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		this._companyForm = this._formBuilder.group({
			name: ['', [Validators.required, Validators.pattern(/\w+/)]],
		});
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Save
	 */
	async save(continueOn?: boolean): Promise<void> {
		this.isInCreationMode = true;
		const isDuplicate = await this._duplicateCheck();

		if (isDuplicate || continueOn) {
			const companyData = new Company(this._companyForm.value);

			const newCompany = await this._entityHelperService.createEntity(companyData);

			this._dialogRef.close(newCompany);
			this.isInCreationMode = false;
		}
	}

	close(): void {
		this._dialogRef.close();
	}

	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	getDate(date) {
		return new Timestamp(date['_seconds'], date['_nanoseconds']).toDate();
	}

	openEntity(entity: any) {
		entity.id = entity.objectID;
		this._dialogRef.close(entity);
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private _createCompany() {
		let newCompany: Company = new Company(this._companyForm.value);
		// set owner and parent
		newCompany.created = new Date();
		newCompany.createdBy = this._authService.userId;
		newCompany.parent = this._authService.accountId;
		newCompany.owner = this._authService.userId;

		return new Promise<Company>((resolve, reject) => {
			this._dataService
				.storeDocument({ ...newCompany }, Collection.ENTITIES)
				.then((ref) => {
					if (ref) newCompany.id = ref.id;

					resolve(newCompany);
				})
				.catch((err) => {
					reject(err);
				});
		});
	}

	private _duplicateCheck(): Promise<boolean> {
		searchClient.clearCache();
		const index = searchClient.initIndex(`${this._authService.accountId}_${Collection.ENTITIES}`);
		return new Promise<boolean>((resolve, reject) => {
			index
				.search(this._companyForm.value.name, {
					filters: 'type:COMPANY',
				})
				.then(({ hits }) => {
					hits.forEach((hit) => {
						this._duplicates.push(new Company(hit as unknown as ICompany));
					});
					resolve(hits.length === 0);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get companyForm(): UntypedFormGroup {
		return this._companyForm;
	}

	get duplicates(): Partial<Company>[] {
		return this._duplicates;
	}
}
