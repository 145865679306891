<ng-container *transloco="let t; read: 'platform.entity.entityRelations'">
	<div *ngIf="entity && dataSource.length > 0" class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">{{ t('title') }}</div>
		<table class="w-full" mat-table [dataSource]="dataSource">
			<ng-container>
				<ng-container matColumnDef="type">
					<th mat-header-cell *matHeaderCellDef>
						{{ t('description') }}
					</th>
					<td mat-cell *matCellDef="let entityRelation" (click)="openDetails(entityRelation)">
						{{ getRelationshipDescription(entityRelation) }}
					</td>
				</ng-container>
				<ng-container matColumnDef="parent">
					<th mat-header-cell *matHeaderCellDef>
						{{ t('linkedEntity') }}
					</th>
					<td mat-cell *matCellDef="let entityRelation" (click)="openDetails(entityRelation)">
						{{ getEntityDisplayName(entityRelation) }}
					</td>
				</ng-container>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
		</table>
	</div>
</ng-container>
