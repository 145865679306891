/** Models */
import { IAvailableLanguages } from 'app/shared/types/language';

/** Static language class */
export class LanguageUtils {
	/**
	 * Get available languages list
	 * @returns
	 */
	static getAvailableLanguages(): Array<IAvailableLanguages> {
		return [
			{
				id: 'en-US',
				label: 'English-US',
				flagCode: 'us',
			},
			{
				id: 'de',
				label: 'German',
				flagCode: 'de',
			},
			// {
			// 	id: 'es-CAT',
			// 	label: 'Spanish',
			// 	flagCode: 'es',
			// },
		];
	}
}
