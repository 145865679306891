<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 h-full">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.cases.dashboard.caseCompletionElement.completion') }}
			</div>
		</div>
		<div class="flex flex-col items-center mt-2">
			<apx-chart
				class="flex flex-auto items-center justify-center w-full h-full"
				[chart]="caseCompletionChart.chart"
				[series]="caseCompletionChart.series"
				[labels]="caseCompletionChart.labels"
			></apx-chart>
			<div class="flex flex-row space-x-2">
				<div class="flex items-baseline justify-center w-full mt-5 text-secondary">
					<div class="text-md font-medium truncate">{{ t('platform.cases.dashboard.caseCompletionElement.tasksRemaining') }}:</div>
					<div class="ml-1.5 text-lg font-semibold">
						{{ tasksRemaining }}
					</div>
				</div>
				<div class="flex items-baseline justify-center w-full mt-5 text-secondary">
					<div class="text-md font-medium truncate">{{ t('platform.cases.dashboard.caseCompletionElement.completed') }}:</div>
					<div class="ml-1.5 text-lg font-semibold">{{ tasksDone }}</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
