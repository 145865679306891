import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { TranslocoService } from '@jsverse/transloco';
import { Collection } from 'app/shared/enums/collection';
import { Definition } from 'app/shared/types/definition';
import { DataObserver } from 'app/shared/types/utilityTypes';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { DataService } from '../database/data.service';

@Injectable({
	providedIn: 'root',
})
export class DefinitionsService {
	constructor(
		private _dataService: DataService,
		private _authService: AuthService,
		private _translocoService: TranslocoService
	) {}

	/**
	 * Loads all the accounts definitions
	 * @return {Observable<DataObserver>}
	 */
	readDefinitions(): Observable<DataObserver> {
		return this._dataService.loadAllEntriesObservable(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.DEFINITIONS}`);
	}

	/**
	 * Loads a specific definition
	 * @param {string} title
	 * @return {Observable<DataObserver>}
	 */
	readDefinition(title: string): Observable<DataObserver> {
		return this._dataService.loadDataObservable(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.DEFINITIONS}`, title);
	}

	/**
	 * Creates a new definiton
	 *
	 * @param {Definition} definition
	 * @return {(Promise<DocumentReference<any> | void>)}
	 */
	createDefinition(definition: Definition): Promise<DocumentReference<any> | void> {
		definition.createdBy = this._authService.userId;
		definition.created = new Date();
		return this._dataService.storeDocument({ ...definition }, `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.DEFINITIONS}`);
	}

	/**
	 * Updates an existing definition
	 *
	 * @param {Partial<Definition>} definition
	 * @return {Promise<void>}
	 */
	updateDefinition(definition: Partial<Definition>): Promise<void> {
		return this._dataService.updateDocument(
			definition,
			`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.DEFINITIONS}`,
			definition.id
		);
	}

	/**
	 * Returns a key value obj with the definition in the clients language
	 *
	 * @param {Definition} definition
	 * @return {[key: string]: string;}
	 */
	getDefinitionObj(definition: Definition): {
		[key: string]: string;
	} {
		if (!definition || !definition.entries) return {};
		const languageCode = this._translocoService.getActiveLang();
		if (!definition.entries['en-US'] && !definition.entries[languageCode]) return {};
		if (!definition.entries[languageCode]) return definition.entries['en-US'];
		return definition.entries[languageCode];
	}
}
