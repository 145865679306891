<ng-container *transloco="let t">
	<!-- Loading bar -->
	<fuse-loading-bar></fuse-loading-bar>

	<!-- Navigation -->
	<fuse-vertical-navigation
		class="bg-slate-100 print:hidden"
		[appearance]="navigationAppearance"
		[mode]="isScreenSmall ? 'over' : 'side'"
		[name]="'mainNavigation'"
		[navigation]="navigation.default"
		[opened]="!isScreenSmall"
	>
		<!-- Navigation header hook -->
		<ng-container fuseVerticalNavigationContentHeader>
			<!-- Logo -->
			<div class="flex items-center justify-center h-18">
				<img class="w-12" [src]="logo.length ? logo : 'assets/images/logo/logo.svg'" alt="Logo image" />
			</div>
		</ng-container>
	</fuse-vertical-navigation>

	<!-- Wrapper -->
	<div class="flex flex-col flex-auto w-full min-w-0">
		<!-- Header -->
		<div
			class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 dark:shadow-none dark:border-b bg-slate-100 dark:bg-transparent print:hidden"
		>
			<div class="flex items-center pr-2 space-x-2">
				<!-- Navigation toggle button -->
				<button mat-icon-button (click)="toggleNavigation('mainNavigation')">
					<mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
				</button>
				<!-- Navigation appearance toggle button -->
				<button class="hidden md:inline-flex" mat-icon-button (click)="toggleNavigationAppearance()">
					<mat-icon [svgIcon]="'heroicons_outline:arrows-right-left'"></mat-icon>
				</button>
			</div>
			<!-- Components -->
			<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
				<languages></languages>
				<search [appearance]="'bar'"></search>
				<shortcuts></shortcuts>
				<!-- <messages></messages> -->
				<notifications></notifications>
				<!-- <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()"
            >
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
				<user></user>
			</div>
		</div>

		<!-- Content -->
		<div class="flex flex-col flex-auto">
			<!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
			<router-outlet *ngIf="true"></router-outlet>
		</div>

		<!-- Footer -->
		<div
			class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-slate-100 dark:bg-transparent print:hidden"
		>
			<span class="font-medium text-secondary"
				>Synapze AXON - {{ t('layout.dense.version') }}: {{ productVersion }} | Synapze GmbH - &copy; {{ currentYear }}</span
			>
		</div>
	</div>

	<!-- Quick chat -->
	<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
</ng-container>
