import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
// material
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
// Transloco module
import { TranslocoModule } from '@jsverse/transloco';

// components
import { CaseCompletionElementComponent } from './case-completion-element/case-completion-element.component';
import { CaseStatusElementComponent } from './case-status-element/case-status-element.component';
import { EntityElementComponent } from './entity-element/entity-element.component';
import { LinkEntityElementComponent } from './link-entity-element/link-entity-element.component';
import { PassportsElementComponent } from './passports-element/passports-element.component';
import { RiskScoreElementComponent } from './risk-score-element/risk-score-element.component';
import { TaskPreviewElementComponent } from './task-preview-element/task-preview-element.component';

@NgModule({
	declarations: [
		EntityElementComponent,
		CaseStatusElementComponent,
		CaseCompletionElementComponent,
		TaskPreviewElementComponent,
		PassportsElementComponent,
		RiskScoreElementComponent,
		LinkEntityElementComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		NgApexchartsModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatTableModule,
		MatTooltipModule,
		MatAutocompleteModule,
		MatInputModule,
		MatFormFieldModule,
		MatStepperModule,
		TranslocoModule,
	],
	exports: [
		EntityElementComponent,
		CaseStatusElementComponent,
		CaseCompletionElementComponent,
		TaskPreviewElementComponent,
		PassportsElementComponent,
		RiskScoreElementComponent,
		LinkEntityElementComponent,
	],
})
export class DashboardModule {}
