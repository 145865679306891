import { Injectable } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RequestChangesDialog } from 'app/shared/components/approval/request-changes-dialog/request-changes-dialog.component';
import { lastValueFrom, Observable } from 'rxjs';
// services
import { DataService } from 'app/core/database/data.service';
import { AuthService } from '../auth/auth.service';
// enums & types
import { ApprovalStatus } from 'app/shared/enums/approvalStatus';
import { Collection } from 'app/shared/enums/collection';
import { Approval } from 'app/shared/types/approval';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Injectable({
	providedIn: 'root',
})
export class ApprovalService {
	/**
	 * Constructor
	 */
	constructor(
		private _authService: AuthService,
		private _dataService: DataService,
		private _functions: Functions,
		private _router: Router,
		private _dialog: MatDialog
	) {
		this._functions.region = 'europe-west1';
	}
	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Returns observables for approvals where the user is either the approver or the creator
	 *
	 * @return {Observable<DataObserver>[]}
	 */
	readApprovals(): Observable<DataObserver>[] {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.APPROVALS}`;
		const userId = this._authService.userId;
		return [
			this._dataService.queryDataObservable(path, [
				{
					field: 'approvers',
					operator: 'array-contains',
					value: userId,
				},
			]),
			this._dataService.queryDataObservable(path, [
				{
					field: 'createdBy',
					operator: '==',
					value: userId,
				},
			]),
		];
	}

	/**
	 * Returns observables for open approvals where the user is either the approver or the creator
	 *
	 * @return {Observable<DataObserver>[]}
	 */
	readOpenApprovals(): Observable<DataObserver>[] {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.APPROVALS}`;
		const userId = this._authService.userId;
		return [
			this._dataService.queryDataObservable(path, [
				{
					field: 'approvers',
					operator: 'array-contains',
					value: userId,
				},
				{
					field: 'status',
					operator: '==',
					value: ApprovalStatus.OPEN,
				},
			]),
			this._dataService.queryDataObservable(path, [
				{
					field: 'createdBy',
					operator: '==',
					value: userId,
				},
				{
					field: 'status',
					operator: '==',
					value: ApprovalStatus.OPEN,
				},
			]),
		];
	}

	/**
	 * Approves item, deletes Approval and updates linked record
	 *
	 * @param {Approval} approval
	 * @return {Promise<void>}
	 */
	async approve(approval: Approval): Promise<void> {
		const handleApproval = httpsCallable(this._functions, 'handleApproval');
		await handleApproval({ status: ApprovalStatus.APPROVED, approvalId: approval.id });
		return;
	}

	/**
	 * Declines approval, deletes approval and updates linked record
	 *
	 * @param {Approval} approval
	 * @param {string} declineReason
	 * @return {Promise<void>}
	 */
	async decline(approval: Approval, declineReason: string): Promise<void> {
		const handleApproval = httpsCallable(this._functions, 'handleApproval');
		await handleApproval({ status: ApprovalStatus.DECLINED, approvalId: approval.id, message: declineReason });
		return;
	}

	/**
	 * Creates approval for given document
	 *
	 * @param {DocumentReference} docRef
	 * @param {string} title
	 * @return {Promise<void>}
	 */
	async submittingForApproval(docRef: DocumentReference, title: string): Promise<void> {
		const submitForApproval = httpsCallable(this._functions, 'submitForApproval');
		await submitForApproval({ docPath: docRef.path, title: title });
		return;
	}

	/**
	 * Opens the linked record
	 *
	 * @param {Approval} approval
	 */
	openLinkedRecord(approval: Approval): Promise<boolean> {
		const doc = this._dataService.getFirestoreRefByPath(approval.linkedRecordPath);
		let link: string[];
		switch (doc.parent.id) {
			case Collection.CASES:
				link = ['cases', 'view', doc.id];
				break;
			default:
				link = ['dashboard', 'personal'];
				break;
		}
		return this._router.navigate(link);
	}

	/**
	 * Request changes to the approval with dialog
	 *
	 * @param {Approval} approval
	 * @return {Promise<void>}
	 */
	async requestChanges(approval: Approval): Promise<void> {
		const dialogRef = this._dialog.open(RequestChangesDialog);
		const result = await lastValueFrom(dialogRef.afterClosed());
		if (!result) return;
		const handleApproval = httpsCallable(this._functions, 'handleApproval');
		// Get the trimmed values by removing whitespaces at start and end of the string
		const resTrimmed = JSON.stringify(this._getTrimmedValuesOfForm(result));
		await handleApproval({ status: ApprovalStatus.REQUESTEDCHANGES, approvalId: approval.id, changes: resTrimmed });
		return;
	}

	/**
	 * Withdraw the approval, deletes it
	 *
	 * @param {Approval} approval
	 * @return {Promise<void>}
	 */
	async withdraw(approval: Approval): Promise<void> {
		const handleApproval = httpsCallable(this._functions, 'handleApproval');
		await handleApproval({ status: ApprovalStatus.WITHDRAWN, approvalId: approval.id });
		return;
	}

	/**
	 * Resubmits the approval, sets it to state OPEN, notifies approvers, updates linkedRecord
	 *
	 * @param {Approval} appr
	 * @return {Promise<void>}
	 */
	async resubmit(appr: Approval): Promise<void> {
		const handleApproval = httpsCallable(this._functions, 'handleApproval');
		await handleApproval({ status: ApprovalStatus.OPEN, approvalId: appr.id, message: 'Approval was resubmitted' });
		return;
	}

	/**
	 * Updates the approval in the database
	 *
	 * @param {string} approvalId
	 * @param {Partial<Approval>} updData
	 * @return {Promise<void>}
	 */
	updateApproval(approvalId: string, updData: Partial<Approval>): Promise<void> {
		return this._dataService.updateDocument(updData, `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.APPROVALS}`, approvalId);
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------
	/**
	 * Get trimmed values of forms
	 * @param { Array<any[]> } formArray will be emails/phone numbers form array
	 * @return { Array<any[]> } return trimmed values of forms
	 */
	private _getTrimmedValuesOfForm(formArray: any[]): any[] {
		return formArray.map((obj: any) => {
			const trimmedObj: any = {};
			// Iterate over each key in the object
			for (const key in obj) {
				// eslint-disable-next-line no-prototype-builtins
				if (!obj.hasOwnProperty(key)) continue;
				// Check if the value is a string
				if (typeof obj[key] === 'string') {
					// Trim the string value and assign it to the trimmedObj
					trimmedObj[key] = obj[key].trim();
				} else {
					// Assign non-string values as-is
					trimmedObj[key] = obj[key];
				}
			}
			return trimmedObj;
		});
	}
}
