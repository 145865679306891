import { AfterContentInit, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { BaseHit } from 'instantsearch.js';
import { connectHits, connectSearchBox } from 'instantsearch.js/es/connectors';
import { AutocompleteWidgetDescription } from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import index from 'instantsearch.js/es/widgets/index/index';

// types & enums
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { Collection } from 'app/shared/enums/collection';
import { Entity } from 'app/shared/enums/entity';

// Services
import { AuthService } from 'app/core/auth/auth.service';
import { InstantSearchService } from '../instant-search.service';

@Component({
	selector: 'app-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements AfterContentInit {
	state: AutocompleteWidgetDescription['renderState'] = {
		currentRefinement: '',
		refine: () => null,
		indices: [],
	};
	showResults = false;
	private _selectedIndex: number = -1;
	private _searchForm: UntypedFormGroup;

	@Output() onQuerySuggestionClick = new EventEmitter<{
		query: string;
		object: any;
	}>();

	@Output() checkForError = new EventEmitter<{
		isError: boolean;
	}>();
	@Input() appearance = '';
	@Input() placeholder = '';
	@Input() presetValue = '';
	@Input() label = '';
	@Input() showPreview;
	@Input() isBlurEventCheck = '';
	@Input() errorMessage = '';
	@Input() isGlobalSearch;

	public hits: BaseHit[] = [];
	public query: string;

	constructor(
		@Optional()
		private _formBuilder: UntypedFormBuilder,
		private _instantSearchService: InstantSearchService,
		private _authService: AuthService
	) {
		this.state.indices = [];
		this._instantSearchService.addWidgets([
			connectSearchBox(({ refine, query }) => {
				this.state.refine = refine;
				this.query = query;
			})({
				// ...widgetParams
			}),
			connectHits(({ hits }) => {
				this.hits = hits;
				this.state.indices[0] = { hits: this.hits as any, indexId: 'entities', indexName: 'entities' } as any;
			})({}),
		]);
	}

	ngAfterContentInit() {
		if (this.isGlobalSearch) {
			this._instantSearchService.addWidgets([
				index({ indexName: `${this._authService.accountId}_${Collection.CASES}` }).addWidgets([
					connectHits(({ hits }) => {
						this.hits = [...this.hits, ...hits];

						this.state.indices[1] = { hits: hits as any, indexId: 'cases', indexName: 'cases' } as any;
					})({}),
				]),
			]);
		}

		this._instantSearchService.start();
	}

	ngOnDestroy() {
		this._instantSearchService.dispose();
	}

	/**
	 * Call arrow up
	 * @return { void }
	 */
	private _handleArrowUp(): void {
		this._selectedIndex = Math.max(0, this._selectedIndex - 1);
		return;
	}

	/**
	 * Call arrow down
	 * @return { void }
	 */
	private _handleArrowDown(): void {
		let totalResultsLenght: number = 0;
		// Get toatl indices length
		this.state.indices.forEach((indice) => {
			totalResultsLenght += indice.hits.length;
		});
		this._selectedIndex = Math.min(totalResultsLenght - 1, this._selectedIndex + 1);
		return;
	}

	/**
	 * On change event
	 * @param { any } $event
	 * @return { void }
	 */
	onBlurEvent($event: any): void {
		if (!$event.target.value?.trim().length)
			return this.onQuerySuggestionClick.emit({
				query: '',
				object: null,
			});
		if ($event.target.value?.trim().length && !this.state.indices[0]?.hits?.find((item) => item.displayName === $event.target.value?.trim())) {
			this._searchForm.get('searchedValue').setErrors({ customError: true });
			return this.checkForError.emit({ isError: true });
		}
		// Set selected option & searched query string after enter
		let selectedOption: any = null,
			searchedQueryString: string = '';

		// Get selected option
		selectedOption = this.state.indices[0].hits?.find((item) => item.displayName === $event.target.value?.trim());
		searchedQueryString = selectedOption.name;

		this.onQuerySuggestionClick.emit({
			query: searchedQueryString,
			object: selectedOption,
		});
		return;
	}
	/**
	 * Handle event after updown array in search list
	 * @param $event
	 * @return { void }
	 */
	handleChange($event: KeyboardEvent): void {
		if (this.isBlurEventCheck) this.checkForError.emit({ isError: false });
		if ($event.code === 'Escape') {
			// Close the search
			this.onQuerySuggestionClick.emit(null);
		}
		const val = ($event.target as HTMLInputElement).value;

		// Set selected option & searched query string after enter
		let selectedOption: any = null,
			searchedQueryString: string = '';

		switch ($event.code) {
			case 'ArrowUp':
				this._handleArrowUp();
				break;
			case 'ArrowDown':
				this._handleArrowDown();
				break;
			case 'Enter':
				// Get selected option
				selectedOption =
					this._selectedIndex > this.state.indices[0].hits.length - 1
						? this.state.indices[1].hits[this._selectedIndex - this.state.indices[0].hits.length]
						: this.state.indices[0].hits[this._selectedIndex];

				if (selectedOption.type === AffectedCategory.CASE) {
					searchedQueryString = selectedOption.displayName;
				} else {
					searchedQueryString =
						selectedOption.type === this.entityType.CONTACT ? selectedOption.firstName + selectedOption.lastName : selectedOption.name;
				}
				this.onQuerySuggestionClick.emit({
					query: searchedQueryString,
					object: selectedOption,
				});
				break;
			default:
				if (val.length || this.showPreview) {
					this.showResults = true;
				} else {
					this.showResults = false;
				}
				this.state.refine!(val);
		}
		return;
	}

	ngOnInit() {
		// Set searchForm validators
		this._searchForm = this._formBuilder.group({
			searchedValue: ['', Validators.nullValidator],
		});
		this._searchForm.get('searchedValue').setValue(this.presetValue);
	}

	/**
	 * @type {UntypedFormGroup}
	 */
	get searchForm(): UntypedFormGroup {
		return this._searchForm;
	}

	get entityType() {
		return Entity;
	}
}
