import { Component, Input, OnInit } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';

// types
import { RiskLevel } from 'app/shared/types/risk-level';
import { RiskLevelSettings } from 'app/shared/types/risk-level-settings';
import { Task } from 'app/shared/types/taskboardTypes';

@Component({
	selector: 'risk-score-element',
	templateUrl: './risk-score-element.component.html',
	styleUrls: ['./risk-score-element.component.scss'],
})
export class RiskScoreElementComponent implements OnInit {
	riskScoreChart: ApexOptions;
	@Input() riskScore: number;
	@Input() tasks: Task[];
	@Input() riskLevel: RiskLevel;
	@Input() riskLevelSettings: RiskLevelSettings;
	tasksRemaining: number = 0;
	private percentage: number = 0;

	constructor() {}

	ngOnInit(): void {
		this._calculateValues();
		this.riskScoreChart = {
			series: [this.percentage],
			chart: {
				type: 'radialBar',
				offsetY: -20,
				sparkline: {
					enabled: true,
				},
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					track: {
						background: '#e7e7e7',
						strokeWidth: '97%',
						margin: 5, // margin is in pixels
						dropShadow: {
							enabled: false,
							top: 2,
							left: 0,
							color: '#999',
							opacity: 1,
							blur: 2,
						},
					},
					dataLabels: {
						name: {
							show: true,
							offsetY: 12,
							color: '#888',
							fontSize: '13px',
							fontWeight: 'normal',
						},
						value: {
							offsetY: -22,
							fontSize: '18px',
							formatter: () => {
								return this.percentage.toString() + '%';
							},
						},
					},
				},
			},
			labels: [this.riskLevel ? this.riskLevel.title : ''],
		};
	}

	ngOnChanges(): void {
		this._calculateValues();
		this.riskScoreChart = {
			series: [this.percentage],
			chart: {
				type: 'radialBar',
				offsetY: -20,
				sparkline: {
					enabled: true,
				},
			},
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					track: {
						background: '#e7e7e7',
						strokeWidth: '97%',
						margin: 5, // margin is in pixels
						dropShadow: {
							enabled: false,
							top: 2,
							left: 0,
							color: '#999',
							opacity: 1,
							blur: 2,
						},
					},
					dataLabels: {
						name: {
							show: true,
							offsetY: 12,
							color: '#888',
							fontSize: '13px',
							fontWeight: 'normal',
						},
						value: {
							offsetY: -22,
							fontSize: '18px',
							formatter: () => {
								return this.percentage.toString() + '%';
							},
						},
					},
				},
			},
			labels: [this.riskLevel ? this.riskLevel.title : ''],
		};
	}

	private _calculateValues() {
		// Counter for remaining tasks
		this.tasksRemaining = 0;
		this.tasks.forEach((task) => {
			if (!task.listId.includes('done')) this.tasksRemaining++;
		});

		if (this.riskLevelSettings) {
			// Numerical value needs to be in percentage of whole range to be displayed
			const total = this.riskLevelSettings.intervalEnd - this.riskLevelSettings.intervalStart;

			if (this.riskScore < this.riskLevelSettings.intervalStart) {
				this.percentage = 0;
			} else if (this.riskScore > this.riskLevelSettings.intervalEnd) {
				this.percentage = 100;
			} else {
				let riskSc = this.riskScore - this.riskLevelSettings.intervalStart;
				this.percentage = Number(((100 * riskSc) / total).toFixed(3));
			}
		}
	}
}
