import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

// data types & enums
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { CustomFieldTypes } from 'app/shared/enums/customFieldTypes';

// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { CaseService } from 'app/core/platform-data/case.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { StorageService } from 'app/core/storage/storage.service';
import { CaseViewService } from 'app/modules/platform/cases/view/case-view.service';
import { CustomFieldSettingsService } from 'app/modules/platform/customization/custom-field-settings/custom-field-settings.service';
import { ReportsService } from './reports.service';

// rxjs
import { CustomFieldService } from 'app/core/platform-data/custom-field.service';
import { Collection } from 'app/shared/enums/collection';
import { ExtractionResult } from 'app/shared/types/customFieldTypes';
import { Timestamp } from 'firebase/firestore';
import { combineLatest, firstValueFrom, map, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
	providers: [ReportsService, CaseViewService, CustomFieldSettingsService],
})
export class ReportsComponent implements OnInit {
	// Variable for render html & store all entity/ case details
	renderHTML: SafeHtml = null;
	allEntityDetails: any = {};
	isLoadingAllData: boolean = true;
	@ViewChild('htmlContainer') htmlContainer: ElementRef;

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _rawHtml: string = '';

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _entityHelperService: EntityHelperService,
		private _caseService: CaseService,
		private _storageService: StorageService,
		private _reportsService: ReportsService,
		private _customFieldService: CustomFieldService,
		private sanitizer: DomSanitizer,
		private _platformInformationService: PlatformInformationService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit() {
		this._activatedRoute.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (params) => {
			if (!params.id) return;
			await this._setEntityDetails(params.id, params.type);
			// Get the html template
			this._getHTMLTemplate(params.templateId, params.type);
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	private async _setEntityDetails(id: string, type: AffectedCategory) {
		let data;
		if (type === AffectedCategory.CASE) data = await this._caseService.readCaseData(id);
		else data = await this._entityHelperService.readEntityData(id);
		const customfields = await this._customFieldService.readCustomFields(type);
		const customFieldData = await this._getCustomFieldData(id, type);
		this.allEntityDetails[type] = {
			details: this._getUpdatedDetails(data),
			customFields: customfields,
			customFieldData: customFieldData,
		};
		// Contact is linked to company then add company details
		if (type === AffectedCategory.CONTACT && data.company) this._setEntityDetails(data.company, AffectedCategory.COMPANY);
		if (type === AffectedCategory.CASE && data.linkedRecord) this._setEntityDetails(data.linkedRecord, data.accountType.affectedEntity);
	}
	/**
	 * Update Object
	 * @param { { [key: string]: any } } inputObject
	 * @return { void }
	 */
	private _updatedObject(inputObject: { [key: string]: any }): void {
		// Check for timestamp key value & update to display date format
		for (const key in inputObject) if (this._isTimestamp(inputObject[key])) inputObject[key] = this._getDate(inputObject, key);
		return;
	}

	/**
	 * Get date in specific format
	 * @param { { [key: string]: any } } getBasicDetails
	 * @param { string } key
	 * @return { any }
	 */
	private _getDate(getBasicDetails: { [key: string]: any }, key: string): any {
		return getBasicDetails[key] ? new DatePipe('en-US').transform(new Date(getBasicDetails[key]?.seconds * 1000), 'MM/dd/yy, h:mm a') : '-';
	}

	/**
	 * Check for timestamp
	 * @param { any } value
	 * @return { boolean }
	 */
	private _isTimestamp(value: any): boolean {
		return typeof value === 'object' && value !== null && typeof value.seconds === 'number' && typeof value.nanoseconds === 'number';
	}

	/**
	 * Gets users display name
	 * @param {string} userId
	 * @return {string}
	 */
	private _getUserName(userId: string): string {
		return userId ? this._platformInformationService.getUserNameById(userId) : '-';
	}

	/**
	 * Get updated details
	 * @param { { [key: string]: any } } getBasicDetails
	 * @return { { [key: string]: any } }
	 */
	private _getUpdatedDetails(getBasicDetails: { [key: string]: any }): { [key: string]: any } {
		// Deep copy
		const newDetails = JSON.parse(JSON.stringify(getBasicDetails));
		const basicKeys = Object.keys(getBasicDetails);
		// Update id's to actual values against id's
		if (basicKeys.includes('owner')) newDetails.owner = this._getUserName(newDetails.owner);
		if (basicKeys.includes('createdBy')) newDetails.createdBy = this._getUserName(newDetails.createdBy);
		if (basicKeys.includes('editedBy')) newDetails.editedBy = this._getUserName(newDetails.editedBy);

		// Delete some fields like companyId, id, parentId, etc. from new object
		if (basicKeys.includes('company')) delete newDetails.company;
		if (basicKeys.includes('id')) delete newDetails.id;
		if (basicKeys.includes('parent')) delete newDetails.parent;
		if (basicKeys.includes('customData')) delete newDetails.customData;
		if (basicKeys.includes('customRelExtResponse')) delete newDetails.customRelExtResponse;
		if (basicKeys.includes('externalId')) delete newDetails.externalId;
		if (basicKeys.includes('tags')) delete newDetails.tags;
		if (basicKeys.includes('linkedRecord')) delete newDetails.linkedRecord;

		// Update the account type values
		if (getBasicDetails.accountType) {
			const accountTypeKeys = Object.keys(getBasicDetails.accountType);
			if (accountTypeKeys.includes('createdBy')) newDetails.accountType.createdBy = this._getUserName(newDetails.accountType.createdBy);
			if (accountTypeKeys.includes('editedBy')) newDetails.accountType.editedBy = this._getUserName(newDetails.accountType.editedBy);
			if (accountTypeKeys.includes('id')) delete newDetails.accountType.id;
			if (accountTypeKeys.includes('parent')) delete newDetails.accountType.parent;
		}

		// Update sanction list results
		if (newDetails.latestSanctionListResult) {
			const keysSLC = Object.keys(newDetails.latestSanctionListResult);
			newDetails.latestSanctionListResult = newDetails.latestSanctionListResult[keysSLC[0]];
			if (keysSLC.includes('output')) delete newDetails.latestSanctionListResult.output;
		}

		// Updated dates from latest sanction list check results object
		this._updatedObject(newDetails.latestSanctionListResult);
		// Updated dates from latest news check results object
		this._updatedObject(newDetails.latestNewsCheckResult);
		// Updated dates from account type object
		this._updatedObject(newDetails.accountType);
		// // Updated dates from details object
		this._updatedObject(newDetails);

		return newDetails;
	}

	/**
	 * Get html template
	 * @param { string } templateId
	 * @param { string } type
	 * @return { void }
	 */
	private _getHTMLTemplate(templateId: string, type: string): void {
		this._reportsService.getHTMLTemplateWithId(templateId);
		this._reportsService.template$.pipe(takeUntil(this._unsubscribeAll)).subscribe(async (template: any) => {
			if (!template) return;
			const url: string = await this._getURL(template.htmlFilePath);

			if (!url.length) return;
			this._fetchHtmlContent(url, type);
			return;
		});
	}

	/**
	 * Get the value of date
	 * @param { string } fieldId
	 * @param { any } typeData
	 * @return { any }
	 */
	private _formatDateValue(data: ExtractionResult): any {
		return data && data.value
			? new DatePipe('en-US').transform(new Timestamp(data.value['seconds'], data.value['nanoseconds']).toDate(), 'MMMM d, y')
			: null;
	}

	/**
	 * Make the value object
	 * @return {{ [key: string]: any } }
	 */
	private _makeCustomFieldLabelAndValue(): { [key: string]: any } {
		const allCustomFieldLabelAndValue: { [key: string]: any } = {};
		// Create costum field label & values also add basic details of company or contact
		Object.values(this.allEntityDetails).forEach((typeData: any, index) => {
			const tempCustomFieldLabelValue: Array<any> = typeData.customFields.map((field: any) => {
				const extraction = typeData.customFieldData.find((data: ExtractionResult) => data.parent === field.id);
				return {
					apiName: field.apiName,
					value: field.fieldType === CustomFieldTypes.datePicker ? this._formatDateValue(extraction) : extraction ? extraction.value : '',
				};
			});
			const parentName = Object.keys(this.allEntityDetails)[index];
			// Add entity & case data with there values
			allCustomFieldLabelAndValue[parentName] = {
				...typeData.details,
				customFields: tempCustomFieldLabelValue,
			};
		});
		return allCustomFieldLabelAndValue;
	}

	/**
	 * Fetch the html content
	 * @param { string } url
	 * @param { string } type
	 * @return { void }
	 */
	private async _fetchHtmlContent(url: string, type: string): Promise<void> {
		const response = await fetch(url);
		if (!response) return;
		const htmlContent = await response.text();
		if (!htmlContent) return;
		// Get the sanitized html content & after sanitization store it in rawHtml
		const sanitizedHtmlContent: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(htmlContent);
		this._rawHtml = this.sanitizer.sanitize(SecurityContext.HTML, sanitizedHtmlContent);
		// Get the details & custom fields label & values
		const customFieldAndDetailsValues = this._makeCustomFieldLabelAndValue();
		// Data used for placeholder
		const data = { data: customFieldAndDetailsValues };
		// Replace the placeholder
		this._rawHtml = this._replacePlaceholders(this._rawHtml, data, type);
		// Render the placeholder
		this.renderHTML = this.sanitizer.bypassSecurityTrustHtml(this._rawHtml);
		this.isLoadingAllData = false;
	}

	/**
	 * Get data at path
	 * @param { { [key: string]: any } } replacements
	 * @param { string } actualListName
	 * @return { { [key: string]: any } }
	 */
	private _getDataAtPath(replacements: { [key: string]: any }, actualListName: string): { [key: string]: any } {
		if (actualListName.split('.').length === 1) return replacements[actualListName];
		return this._getDataAtPath(replacements[actualListName.split('.')[0]], actualListName.split('.').slice(1).join('.'));
	}

	/**
	 * Replace the table data where for loop exist in html
	 * @param { string } html
	 * @param { { [key: string]: any } } replacements
	 * @param { string } type
	 * @return { string }
	 */
	private _replaceTableData(html: string, replacements: { [key: string]: any }, type: string): string {
		// Regex for table replacement syntax
		const regexForLoop = /{%\s*for\s*([^%]+)\s*in\s*([^%]+)%}([\s\S]*?){%\s*end\s*for\s*%}/g;
		// Match all occurrences of the for loop in the html
		let match;
		while ((match = regexForLoop.exec(html)) !== null) {
			// Variable used for for loop iteration
			const loopVariable = match[1].trim();
			// Variable consist of what should be iterated
			const loopData = match[2].trim();
			const loopBody = match[3].trim();
			// Split loopData into parts
			const dataParts = loopData.split('.');
			// Get the actual name of the list mentioned in the loop
			const actualListName = dataParts.slice(2).join('.');
			// Get the type in for loop
			const finalCaseOrEntityType = Object.values(AffectedCategory).includes(dataParts[1]) ? dataParts[1] : type;
			if (!replacements.data[finalCaseOrEntityType]) continue;
			// Get table data add to the html
			const tableData = this._getDataAtPath(replacements.data[finalCaseOrEntityType], actualListName);
			if (!tableData) continue;
			// Replace the table rows with actual values
			const replacedHtml = tableData
				?.map((estimation: any, index: number) => {
					estimation.index = index;
					const replacedRow = loopBody.replace(/\${([^}]+)}/g, (match, property) => {
						const [loopVar, prop] = property.split('.');
						if (loopVar === loopVariable) return estimation[prop] ?? match;
						return match;
					});
					return replacedRow;
				})
				.join('');
			// Replace with actual html
			html = html.substring(0, match.index) + replacedHtml + html.substring(match.index + match[0].length);
			// Reset the lastIndex property to continue searching from the correct position
			regexForLoop.lastIndex = match.index + replacedHtml.length;
		}

		return html;
	}

	/**
	 * Replace placeholders
	 * @param { string } html
	 * @param { { [key: string]: any } } replacements
	 * @return { string }
	 */
	private _replacePlaceholders(html: string, replacements: { [key: string]: any }, type: string): string {
		// Replace the table data
		html = this._replaceTableData(html, replacements, type);
		// Regular expression to match placeholders
		const regex = /\${(.*?)}/g;
		// Replace each placeholder with its corresponding value
		return html.replace(regex, (match: any, placeholder: any) => {
			const keys = placeholder.trim().split('.');
			let value = replacements;
			// Traverse the object keys to get the final value
			for (const [index, key] of keys.entries()) {
				if (keys[index - 1] === 'customFields') value = value?.find((item) => item.apiName === key)?.value ?? '-';
				else if (Object.prototype.hasOwnProperty.call(value, key)) value = value[key] ?? '-';
				else return match; // Return original placeholder if key not found
			}
			return value;
		});
	}

	/**
	 * Get file url
	 * @param { string } logoPath
	 * @return { Promise<string> }
	 */
	private async _getURL(logoPath?: string): Promise<string> {
		if (!logoPath) return '';
		const url = await this._storageService.getFileUrl(logoPath);
		return url;
	}

	/**
	 *
	 * @param {string} id
	 * @param {AffectedCategory} type
	 * @return {Promise<any[]>}
	 */
	private async _getCustomFieldData(id: string, type: AffectedCategory): Promise<any[]> {
		const collection = type === AffectedCategory.CASE ? Collection.CASES : Collection.ENTITIES;
		const data: any = await firstValueFrom(
			this._customFieldService.readCustomFieldDataObservable(collection, id).pipe(
				map((parentCollection) => {
					return parentCollection.data.map((parent) => {
						return this._customFieldService.readCustomFieldExtractionsObservable(collection, id, parent.id);
					});
				}),
				switchMap((observablesArray) => {
					return combineLatest(observablesArray);
				})
			)
		);

		return data
			.flatMap((observer) => {
				return observer.data;
			})
			.filter((elem) => elem.valid);
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Opens the reports print view
	 * @return {void}
	 */
	printReport(): void {
		window.print();
		return;
	}
}
