import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, Subject, takeUntil } from 'rxjs';
// services
import { CaseService } from 'app/core/platform-data/case.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types and enums
import { Case } from 'app/shared/types/case';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Component({
	selector: 'report-case-list',
	templateUrl: './case-list.component.html',
	styleUrls: ['./case-list.component.scss'],
})
export class CaseListComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;
	columnsToDisplay = ['title', 'stage', 'owner', 'created', 'modified'];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _caseListObservable: DataObserver;
	private _caseData: Case[] = [];

	constructor(
		private _caseService: CaseService,
		private _platformInfoService: PlatformInformationService
	) {}
	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._caseService
			.readCasesToEntity(this.entity.id)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._caseListObservable.unsubscribe();
				})
			)
			.subscribe((caseData) => {
				this._caseListObservable = caseData;
				if (!caseData.data) return;
				this._caseData = caseData.data;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the users displayname
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInfoService.getUserNameById(userId);
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {Case[]}
	 */
	get caseData(): Case[] {
		return this._caseData;
	}
}
