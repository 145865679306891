import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { CaseService } from 'app/core/platform-data/case.service';
import { RiskEngineSettingsService } from 'app/core/platform-data/risk-engine-settings.service';
// types & enums
import { RiskCriterionType } from 'app/shared/enums/risk-criterion-type';
import { Case } from 'app/shared/types/case';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { RiskCriterion } from 'app/shared/types/riskCriterion';
import { TaskAnswer } from 'app/shared/types/taskAnswer';
import { Task } from 'app/shared/types/taskboardTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

import { combineLatest, finalize, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'risk-assessment-details',
	templateUrl: './risk-assessment-details.component.html',
	styleUrls: ['./risk-assessment-details.component.scss'],
})
export class RiskAssessmentDetailsComponent implements OnInit, OnDestroy {
	@Input() private _caseData: Case;

	riskAssessmentColumns: string[] = ['name', 'value', 'verifiedValue', 'weighting', 'riskScore', 'notifications'];
	private _riskAssessmentData: RiskAssessmentData[] = [];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _tasksObserver: DataObserver;
	private _entityObserver: DataObserver;
	private _taskAnswers: TaskAnswer[];

	constructor(
		private _riskSettingsService: RiskEngineSettingsService,
		private _caseService: CaseService,
		private _entityHelperService: EntityHelperService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		combineLatest([
			this._entityHelperService.readEntity(this._caseData.linkedRecord).pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._entityObserver.unsubscribe();
				})
			),
			this._caseService.readTasks(this._caseData.id).pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._tasksObserver.unsubscribe();
				})
			),
		]).subscribe(async ([entityObserver, tasksObserver]) => {
			this._entityObserver = entityObserver;
			this._tasksObserver = tasksObserver;
			this._taskAnswers = await this._caseService.readTaskAnswersToCase(this._caseData.id, tasksObserver.data);
			this._setRiskAssessmentData(tasksObserver.data, entityObserver.data);
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Private Methods
	// -------------------------------------------------------------------------

	private _setRiskAssessmentData(tasks: Task[], entity: Contact | Company): void {
		if (!tasks || !entity) return;
		const riskAssessmentData: RiskAssessmentData[] = [];
		tasks.forEach((task) => {
			if (!task.riskCriterion) return;
			const criterion: RiskCriterion = task.riskCriterion;
			const riskObj: RiskAssessmentData = {
				title: task.title,
				weighting: task.weighting,
				riskScore: task.riskScore,
				riskScoreVerified: task.riskScoreVerified,
				verifiedValue: '-',
				value: '-',
			};
			const rcId = criterion.id;
			let taskAnswer;
			if (task.verifiedValue && task.verifiedValue.id)
				taskAnswer = this._taskAnswers.find((taskAnswer) => taskAnswer.id === task.verifiedValue.id);
			const verifiedValue = taskAnswer ? taskAnswer.value : '';
			const customerInput = task.value ? task.value.toString() : '';
			if (criterion.type === RiskCriterionType.CUSTOMLIST) {
				riskObj.value = this._riskSettingsService.getCustomListTitle(rcId, customerInput);
				riskObj.verifiedValue = this._riskSettingsService.getCustomListTitle(rcId, verifiedValue);
				if (!criterion.caseSpecific && entity.customData[rcId])
					riskObj.verifiedValue = this._riskSettingsService.getCustomListTitle(rcId, entity.customData[rcId]);
			}
			if (criterion.type === RiskCriterionType.COUNTRY) {
				riskObj.value = this._riskSettingsService.getCountryName(customerInput);
				riskObj.verifiedValue = this._riskSettingsService.getCountryName(verifiedValue.toString());
			}
			riskAssessmentData.push(riskObj);
		});
		this._riskAssessmentData = riskAssessmentData;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {RiskAssessmentData[]}
	 */
	get riskAssessmentData(): RiskAssessmentData[] {
		return this._riskAssessmentData;
	}
}

interface RiskAssessmentData {
	title: string;
	value: string;
	verifiedValue: string;
	weighting?: number;
	riskScore?: number;
	riskScoreVerified?: number;
}
