<ng-container *transloco="let t; read: 'platform.entity.customFieldDetails'">
	<div *ngIf="(entity || case) && customFields" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div class="font-medium tracking-tight truncate">{{ t('title') }}</div>
			<!-- Actions -->
			<div *ngIf="!editMode">
				<div
					[matTooltip]="!canEdit ? t('notPermission') : t('notHaveAnyCustomField')"
					[matTooltipDisabled]="canEdit && !!customFields.length"
				>
					<button
						id="btnEditCustomFields"
						mat-icon-button
						color="primary"
						[matTooltip]="t('edit')"
						(click)="toggleEditMode()"
						[disabled]="!(customFields && customFields.length) || !canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
					</button>
				</div>
			</div>
			<div *ngIf="editMode" class="space-x-2 flex flex-row">
				<div [matTooltip]="t('requiredMissing')" [matTooltipDisabled]="!customPlaceholderForm.invalid">
					<button
						id="btnSaveCustomFields"
						mat-icon-button
						color="primary"
						[matTooltip]="t('saveChanges')"
						(click)="saveChanges()"
						[disabled]="customPlaceholderForm.invalid"
					>
						<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
					</button>
				</div>
				<button id="btnCancelCustomFields" mat-icon-button color="warn" [matTooltip]="t('cancel')" (click)="cancelEdit()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row">
			<ng-container *ngIf="!customFields || customFields.length == 0">
				<div id="noCustomFields" class="w-full flex flex-col justify-center items-center space-y-4">
					{{ t('noCustomFields') }} {{ caseData ? affectedCategory.CASE : entity.type }}
				</div>
			</ng-container>
			<ng-container *ngIf="customFields && customFields.length">
				<ng-container *ngIf="!editMode; else editView">
					<div class="flex flex-col space-y-4 w-full">
						<ng-container *ngFor="let customField of customFieldData">
							<div class="space-x-4" *ngIf="(customField.field && customField.field.enabled) || hasValue(customField.responses)">
								<div class="min-w-fit font-medium text-slate-600">{{ customField.field.label }}</div>
								<div *ngIf="customField.responses" class="flex flex-col w-full space-y-1">
									<ng-container *ngFor="let response of customField.responses; let i = index">
										<div class="flex flex-row p-1 items-center space-x-2">
											<mat-icon
												*ngIf="response.valid"
												class="icon-size-6 text-green-500"
												[svgIcon]="'heroicons_outline:check-circle'"
											></mat-icon>
											<div class="flex flex-col" [ngClass]="{ 'pl-8': !response.valid }">
												<ng-container *ngIf="customField.field.fieldType == customFieldTypes.textbox">
													<div [id]="'valueCustomFieldTextResponse_' + i">
														{{ response.value }}
													</div>
												</ng-container>
												<ng-container *ngIf="customField.field.fieldType == customFieldTypes.datePicker">
													<div [id]="'valueCustomFieldDateResponse_' + i">
														{{ response.value ? (response.value.toDate() | date: 'longDate') : '' }}
													</div>
												</ng-container>
												<ng-container *ngIf="customField.field.fieldType == customFieldTypes.checkbox">
													<div [id]="'valueCustomFieldCheckboxResponse_' + i">
														{{ response.value }}
													</div>
												</ng-container>
												<div [id]="'sourceCustomFieldResponse_' + i" class="justify-self-end font-light text-slate-600">
													<ng-container *ngIf="response.origin != 'Manual'"
														>{{ t('source') }} {{ getFileName(response.source) }}</ng-container
													>
													<ng-container *ngIf="response.origin == 'Manual' && response.value != null"
														>{{ t('source') }} {{ t('manualInput') }}</ng-container
													>
												</div>
											</div>
											<button
												[id]="'btnOpenSourceCustomFieldResponse_' + i"
												*ngIf="response.origin != 'Manual'"
												mat-icon-button
												color="primary"
												(click)="openSource(response)"
												[matTooltip]="t('openFile')"
											>
												<mat-icon [svgIcon]="'mat_solid:search'"></mat-icon>
											</button>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<ng-template #editView>
		<div class="flex flex-col space-y-4 w-full">
			<div>
				<ng-container *ngFor="let customField of customFieldData; let i = index">
					<div class="flex flex-row" *ngIf="customField.field.enabled">
						<div class="w-1/3 font-medium text-slate-600">{{ customField.field.label }}</div>
						<div class="flex flex-col w-full">
							<form [formGroup]="customPlaceholderForm">
								<ng-container *ngFor="let response of customField.responses">
									<div class="flex flex-row w-5/6 mb-2 items-center">
										<ng-container *ngIf="response.origin != 'Manual'">
											<div class="items-center w-5/6">
												<div class="flex flex-col">
													<ng-container *ngIf="customField.field.fieldType == customFieldTypes.textbox">
														<div [id]="'valueTextEditCustomFieldResponse_' + i">
															{{ response.value }}
														</div>
													</ng-container>
													<ng-container *ngIf="customField.field.fieldType == customFieldTypes.datePicker">
														<div [id]="'valueDateEditCustomFieldResponse_' + i">
															{{ response.value ? (response.value.toDate() | date: 'longDate') : '' }}
														</div>
													</ng-container>
													<ng-container *ngIf="customField.field.fieldType == customFieldTypes.checkbox">
														<div [id]="'valueCheckboxEditCustomFieldResponse_' + i">
															{{ response.value }}
														</div>
													</ng-container>
													<div
														[id]="'sourceEditCustomFieldResponse_' + i"
														class="justify-self-end font-light text-slate-600"
														*ngIf="response.origin != 'Manual'"
													>
														{{ t('source') }} {{ getFileName(response.source) }}
													</div>
												</div>
											</div>
										</ng-container>
										<ng-container [formGroupName]="customField.field.id">
											<ng-container [formGroupName]="response.id">
												<ng-container *ngIf="response.origin == 'Manual'">
													<div class="w-5/6 mt-1">
														<mat-form-field
															appearance="fill"
															*ngIf="customField.field.fieldType == customFieldTypes.textbox"
															class="w-full"
														>
															<input
																[id]="'inputManualTextboxEditCustomFieldResponse_' + i"
																matInput
																formControlName="data"
															/>
															<mat-label [id]="'lblManualInputTextboxEditCustomFieldResponse_' + i">{{
																t('manualInput')
															}}</mat-label>
															<mat-error
																[id]="'requiredErrorInputManualTextboxEditCustomFieldResponse_' + i"
																*ngIf="
																	customPlaceholderForm
																		.get([customField.field.id, response.id, 'data'])
																		.hasError('required')
																"
															>
																{{ customField.field.label }} {{ t('isRequired') }}
															</mat-error>
															<mat-error
																[id]="'patternErrorInputManualTextboxEditCustomFieldResponse_' + i"
																*ngIf="
																	customPlaceholderForm
																		.get([customField.field.id, response.id, 'data'])
																		.hasError('pattern')
																"
															>
																{{ t('isInvalid') }} {{ customField.field.label }}
															</mat-error>
														</mat-form-field>
														<mat-form-field
															appearance="fill"
															*ngIf="customField.field.fieldType == customFieldTypes.datePicker"
															class="w-full"
														>
															<mat-label [id]="'lblManualDatePickerEditCustomFieldResponse_' + i">{{
																t('manualInput')
															}}</mat-label>
															<input
																[id]="'inputManualDatePickerEditCustomFieldResponse_' + i"
																matInput
																[placeholder]="t('manualInput')"
																[matDatepicker]="customDatepicker"
																formControlName="data"
															/>
															<mat-datepicker-toggle
																[id]="'inputManualDatePickerToggleEditCustomFieldResponse_' + i"
																matSuffix
																[for]="customDatepicker"
															>
															</mat-datepicker-toggle>
															<mat-datepicker
																[id]="'inputManualDatePickerYearEditCustomFieldResponse_' + i"
																#customDatepicker
																startView="year"
															></mat-datepicker>
															<mat-error
																[id]="'patternErrorManualDatePickerEditCustomFieldResponse_' + i"
																*ngIf="
																	customPlaceholderForm
																		.get([customField.field.id, response.id, 'data'])
																		.hasError('pattern')
																"
															>
																{{ customField.field.label }} {{ t('isRequired') }}
															</mat-error>
														</mat-form-field>
														<mat-form-field
															appearance="fill"
															*ngIf="customField.field.fieldType == customFieldTypes.checkbox"
															class="w-full"
														>
															<mat-label [id]="'lblManualCheckboxEditCustomFieldResponse_' + i">{{
																t('manualInput')
															}}</mat-label>
															<input
																[id]="'inputManualCheckboxEditCustomFieldResponse_' + i"
																class="hidden"
																[placeholder]="t('manualInput')"
																matInput
																[hidden]="true"
															/>
															<mat-checkbox
																[id]="'checkboxManualCheckboxEditCustomFieldResponse_' + i"
																color="primary"
																formControlName="data"
															></mat-checkbox>
															<mat-error
																[id]="'requiredErrorCheckboxManualEditCustomFieldResponse_' + i"
																*ngIf="
																	customPlaceholderForm
																		.get([customField.field.id, response.id, 'data'])
																		.hasError('required')
																"
															>
																{{ customField.field.label }} {{ t('isRequired') }}
															</mat-error>
														</mat-form-field>
													</div>
												</ng-container>
												<mat-slide-toggle
													[id]="'toggleValidEditCustomFieldResponse_' + i"
													(change)="toggleValid(response, customField.field.id, $event)"
													[color]="'primary'"
													*ngIf="customField.responses.length > 1"
													class="w-1/6 mt-0.5 ml-1"
													formControlName="valid"
												>
													{{ t('valid') }}
												</mat-slide-toggle>
											</ng-container>
										</ng-container>
									</div>
								</ng-container>
							</form>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-template>
</ng-container>
