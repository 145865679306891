import { DocumentReference } from '@angular/fire/firestore';
import { Entity } from '../enums/entity';
import { RunningProcessesSubType, RunningProcessesType } from '../enums/runningProcessesTypes';
import { tranStatusStatusCode } from './connectors/oceanSystems';

export interface BaseRunningProcessesEntity {
	type: RunningProcessesType;
	subType: RunningProcessesSubType;
	entityType: Entity;
	entity: DocumentReference;
	account: DocumentReference;
	createdAt: Date;
	updatedAt: Date;
	entityName: string;
}

export interface OFACPollingEntity extends BaseRunningProcessesEntity {
	status: tranStatusStatusCode;
}

export interface OceanSystemsOFACPollingEntity extends OFACPollingEntity {
	oceanSytemsRefId: string;
}

export const isBaseRunningProcessEntity = (obj: any): obj is BaseRunningProcessesEntity => {
	const tempObj = obj as BaseRunningProcessesEntity;
	return Object.keys(tempObj).includes('type') && Object.keys(tempObj).includes('subType') && Object.keys(tempObj).includes('entityType');
};
