import { Component, Input } from '@angular/core';
// services
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types and enums
import { Company } from 'app/shared/types/entityTypes';

@Component({
	selector: 'report-company-details',
	templateUrl: './company-details.component.html',
	styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent {
	@Input() entity: Company;
	constructor(private _platformInformationService: PlatformInformationService) {}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Gets users displayname
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}
}
