<ng-container *transloco="let t; read: 'platform.entity.customRelations'">
	<div *ngIf="entity" class="flex flex-col bg-white rounded-2xl p-4">
		<!-- Header Row -->
		<div class="flex flex-row justify-between content-center">
			<!-- Title -->
			<div id="customRelationTitle" class="font-medium tracking-tight truncate">{{ t('title') }}</div>
			<!-- Actions -->
			<div *ngIf="!editMode">
				<div
					[matTooltip]="!canEdit ? t('notPermission') : t('notHaveAnyCustomRelation')"
					[matTooltipDisabled]="canEdit && !!customRelationsLength"
				>
					<button
						id="btnEditCustomRelation"
						mat-icon-button
						color="primary"
						[matTooltip]="t('edit')"
						(click)="toggleEditMode()"
						[disabled]="!customRelationsLength || !canEdit"
					>
						<mat-icon [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
					</button>
				</div>
			</div>
			<div *ngIf="editMode" class="space-x-2">
				<button
					id="btnSaveCustomRelation"
					mat-icon-button
					color="primary"
					[matTooltip]="t('save')"
					(click)="save()"
					[disabled]="customRelationsForm.invalid"
				>
					<mat-icon [svgIcon]="'heroicons_solid:check'"></mat-icon>
				</button>
				<button id="btnCancelCustomRelation" mat-icon-button color="warn" [matTooltip]="t('cancel')" (click)="cancel()">
					<mat-icon [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
				</button>
			</div>
		</div>
		<!-- Content -->
		<div class="flex flex-row">
			<ng-container *ngIf="!customRelationsLength">
				<div id="txtNoRecordsCustomRelation" class="w-full flex flex-col justify-center items-center space-y-4">{{ t('noData') }}</div>
			</ng-container>
			<ng-container *ngIf="customRelationsLength">
				<ng-container *ngIf="!editMode; else editView">
					<div id="customRelationView" class="flex flex-col space-y-4 w-full">
						<ng-container *ngFor="let customRel of customRelations | keyvalue; let i = index">
							<div class="space-x-4">
								<div class="min-w-fit font-medium text-slate-600" [id]="'customRelationViewTitle_' + i">
									{{ getRelationshipDescription(customRel.key) }}
								</div>
								<div class="flex flex-col w-full space-y-1">
									<ng-container *ngFor="let sourceObj of customRel.value; let j = index">
										<div class="flex flex-row p-1 items-center space-x-2" [id]="'customRelationView_' + i + j">
											<mat-icon
												*ngIf="sourceObj.valid && sourceObj.value"
												class="icon-size-6 text-green-500"
												[svgIcon]="'heroicons_outline:check-circle'"
												[id]="'customRelationViewIcon_' + i + j"
											></mat-icon>
											<div
												class="flex flex-col"
												[ngClass]="{ 'pl-8': !sourceObj.valid }"
												[id]="'customRelationViewTotalValue_' + i + j"
											>
												<ng-container *ngFor="let entity of sourceObj.value">
													<div [id]="'customRelationViewValue_' + i + j">
														{{ entity }}
													</div>
												</ng-container>
												<div class="justify-self-end font-light text-slate-600" [id]="'customRelationViewSubValue_' + i + j">
													<ng-container *ngIf="sourceObj.origin != 'Manual'"
														>{{ t('source') }} {{ getFileName(sourceObj.source.id) }}</ng-container
													>
													<ng-container *ngIf="sourceObj.origin == 'Manual' && sourceObj.value"
														>{{ t('source') }} {{ t('manualInput') }}</ng-container
													>
												</div>
											</div>
											<button
												*ngIf="sourceObj.origin != 'Manual'"
												mat-icon-button
												color="primary"
												(click)="openSource(sourceObj)"
												[matTooltip]="t('openFile')"
												[id]="'btnSerachCustomRelationNonManualValue_' + i + j"
											>
												<mat-icon [svgIcon]="'mat_solid:search'"></mat-icon>
											</button>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>
	<ng-template #editView>
		<div id="customRelationEdit" class="flex flex-col space-y-4 w-full">
			<ng-container *ngFor="let customRel of customRelations | keyvalue; let i = index">
				<div class="space-x-4">
					<div [id]="'customRelationEditTitle_' + i" class="min-w-fit font-medium text-slate-600">
						{{ getRelationshipDescription(customRel.key) }}
					</div>
					<div class="flex flex-col w-full">
						<form [formGroup]="customRelationsForm">
							<ng-container [formGroupName]="customRel.key">
								<ng-container *ngFor="let relation of customRel.value; let j = index">
									<div class="flex flex-row w-5/6 mb-2 items-center">
										<ng-container [formGroupName]="relation.id">
											<div
												[id]="'customRelationNonManual_' + i + j"
												class="flex flex-col w-full"
												*ngIf="relation.origin != 'Manual'"
											>
												<div class="items-center w-5/6">
													<div class="flex flex-col">
														<div [id]="'customRelationNonManualValue_' + i + j">
															<ng-container *ngFor="let val of relation.value">
																{{ val }}
															</ng-container>
														</div>

														<div
															class="justify-self-end font-light text-slate-600"
															*ngIf="relation.origin != 'Manual'"
															[id]="'customRelationNonManualSubValue_' + i + j"
														>
															{{ t('source') }} {{ getFileName(relation.source.id) }}
														</div>
													</div>
												</div>
											</div>

											<div
												[id]="'customRelationManual_' + i + j"
												class="flex flex-col w-full"
												*ngIf="relation.origin == 'Manual'"
											>
												<ng-container *ngFor="let val of relation.value | keyvalue">
													<div class="w-5/6 mt-1">
														<mat-form-field
															class="fuse-mat-dense w-full"
															[id]="'fieldCustomRelationManualValue_' + i + j"
														>
															<mat-label [id]="'lblCustomRelationManualValue_' + i + j">{{
																t('manualInput')
															}}</mat-label>

															<input [id]="'customRelationManualValue_' + i + j" matInput [formControlName]="val.key" />
															<mat-error
																[id]="'errorRequiredCustomRelationManualValue_' + i + j"
																*ngIf="
																	customRelationsForm.get([customRel.key, relation.id, val.key]) &&
																	customRelationsForm
																		.get([customRel.key, relation.id, val.key])
																		.hasError('required')
																"
															>
																{{ t('isRequired') }}
															</mat-error>
															<mat-error
																[id]="'errorPatternCustomRelationManualValue_' + i + j"
																*ngIf="
																	customRelationsForm.get([customRel.key, relation.id, val.key]) &&
																	customRelationsForm.get([customRel.key, relation.id, val.key]).hasError('pattern')
																"
															>
																{{ t('isInvalid') }}
															</mat-error>
														</mat-form-field>
													</div>
												</ng-container>
											</div>
											<mat-slide-toggle
												(change)="toggleValid(customRel.key, relation.id, $event)"
												[color]="'primary'"
												*ngIf="customRel.value.length > 1"
												class="w-1/6 mt-0.5 ml-1"
												formControlName="valid"
												[id]="'btnToggleCustomRelationValue_' + i + j"
											>
												{{ t('valid') }}
											</mat-slide-toggle>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</form>
					</div>
				</div>
			</ng-container>
		</div>
	</ng-template>
</ng-container>
