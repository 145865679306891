<ng-container *transloco="let t">
	<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">
		<!-- Main -->
		<div class="flex flex-col flex-auto px-6 sm:px-16 sm:pb-20">
			<!-- Activity feed -->
			<div class="w-full max-w-3xl">
				<ng-container *ngIf="auditLogs$ | async as auditLogs; else loading">
					<ng-container *ngIf="auditLogs.length; else noActivity">
						<div>
							<ol>
								<!-- auditLogs -->
								<ng-container
									*ngFor="let auditLog of auditLogs; let i = index; let first = first; let last = last; trackBy: trackByFn"
								>
									<!-- Date separator -->
									<ng-container *ngIf="first || !isSameDay(auditLog.date.toDate(), auditLogs[i - 1].date.toDate())">
										<li class="relative flex py-7">
											<div class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
												{{ getRelativeFormat(auditLog.date.toDate()) }}
											</div>
										</li>
									</ng-container>

									<!-- Activity -->
									<li class="relative flex py-7">
										<!-- Line -->
										<ng-container *ngIf="!last && isSameDay(auditLog.date.toDate(), auditLogs[i + 1].date.toDate())">
											<div class="absolute top-7 left-5 w-0.5 h-full -ml-px bg-gray-300 dark:bg-gray-600"></div>
										</ng-container>

										<div class="relative flex flex-auto">
											<div class="flex shrink-0 items-center justify-center w-10 h-10 mr-4 rounded-full bg-gray-400">
												<mat-icon class="icon-size-5 text-white" [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
											</div>
											<!-- Content -->
											<div class="flex flex-col flex-auto items-start">
												<!-- Description -->
												<ng-container>
													<div [innerHTML]="getDescription(auditLog)"></div>
												</ng-container>
												<div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
													<!-- Date -->
													<div class="text-secondary">
														{{ auditLog.date.toDate() | date: 'MMM dd, h:mm a' }}
													</div>
													<!-- Linked content -->
													<ng-container *ngIf="auditLog.linkedContent">
														<div class="hidden sm:block">&bull;</div>
														<!-- Internal link -->
														<ng-container *ngIf="auditLog.useRouter">
															<a class="cursor-pointer text-primary" [routerLink]="auditLog.link">
																{{ auditLog.linkedContent }}
															</a>
														</ng-container>
														<!-- External link -->
														<ng-container *ngIf="!auditLog.useRouter">
															<a class="cursor-pointer text-primary" [href]="auditLog.link" target="_blank">
																{{ auditLog.linkedContent }}
															</a>
														</ng-container>
													</ng-container>
												</div>
												<!-- Extra content - only available for updates. -->
												<ng-container
													*ngIf="
														auditLog.action == 'update' ||
														((auditLog.action == 'create' || auditLog.action == 'delete') && auditLog.subModule != null)
													"
												>
													<div
														class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800"
														[innerHTML]="getExtraContent(auditLog)"
													></div>
												</ng-container>
											</div>
										</div>
									</li>
								</ng-container>
							</ol>
						</div>
					</ng-container>
				</ng-container>

				<!-- Loading template -->
				<ng-template #loading> {{ t('platform.entity.auditLog.loading') }}... </ng-template>

				<!-- No Activity template -->
				<ng-template #noActivity> {{ t('platform.entity.auditLog.noActivities') }}... </ng-template>
			</div>
		</div>
	</div>
</ng-container>
