import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
// services
import { AuthService } from 'app/core/auth/auth.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types & enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Case } from 'app/shared/types/case';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { DataObserver } from 'app/shared/types/utilityTypes';

// Dialogs
import { MatDialog } from '@angular/material/dialog';
import { CaseService } from 'app/core/platform-data/case.service';
import { RiskEngineSettingsService } from 'app/core/platform-data/risk-engine-settings.service';
import { CaseQuickCreateComponent } from 'app/shared/components/cases/case-quick-create/case-quick-create.component';

@Component({
	selector: 'case-list',
	templateUrl: './case-list.component.html',
	styleUrls: ['./case-list.component.scss'],
})
export class CaseListComponent implements OnInit, OnDestroy {
	@Input() entity: Contact | Company;
	@Input() canEditParent: boolean;

	columnsToDisplay = ['title', 'stage', 'owner', 'created', 'modified'];

	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _caseListObservable: DataObserver;
	private _caseData: Case[];
	private _isAccountTypesExist: boolean = false;

	constructor(
		private _authService: AuthService,
		private _platformInformationService: PlatformInformationService,
		private _router: Router,
		private _dialog: MatDialog,
		private _riskEngineSettingsService: RiskEngineSettingsService,
		private _caseService: CaseService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._caseService
			.readCasesToEntity(this.entity.id)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._caseListObservable.unsubscribe();
				})
			)
			.subscribe((caseData) => {
				this._caseListObservable = caseData;
				if (!caseData.data) return;
				this._caseData = caseData.data;
				this._checkAccountTypesExistsOrNot();
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	getUserName(userId: string) {
		return this._platformInformationService.getUserNameById(userId);
	}

	openCase(caseId: string) {
		this._router.navigate(['cases', 'view', caseId]);
	}

	addCase() {
		this._dialog
			.open(CaseQuickCreateComponent, {
				data: this.entity,
			})
			.afterClosed()
			.subscribe((caseRef) => {
				if (caseRef) {
					this._router.navigate(['cases', 'view', caseRef]);
				}
			});
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Check the account type is exist or not for given entity type
	 */
	private _checkAccountTypesExistsOrNot(): void {
		this._riskEngineSettingsService.activeRiskEngineSettings$.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
			if (!settings || !settings.accountTypes) return;
			// Set account type exist flag
			this._isAccountTypesExist = Object.values(settings.accountTypes).some((accountType) => accountType.affectedEntity === this.entity.type);
		});
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get caseData(): Case[] {
		return this._caseData;
	}
	/**
	 * @type {boolean}
	 */
	get canCreate(): boolean {
		return this._authService.checkRolePermission(Collection.CASES, Actions.create);
	}

	/**
	 * @type {boolean}
	 */
	get isAccountTypesExist(): boolean {
		return this._isAccountTypesExist;
	}
}
