import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Timestamp } from 'firebase/firestore';
import { Subject, combineLatest, finalize, lastValueFrom, map, switchMap, takeUntil } from 'rxjs';
import { v4 } from 'uuid';

// Services
import { AuthService } from 'app/core/auth/auth.service';
import { CustomFieldService } from 'app/core/platform-data/custom-field.service';
import { StorageService } from 'app/core/storage/storage.service';

// Enums & Types
import { Actions } from 'app/shared/enums/actions';
import { AffectedCategory } from 'app/shared/enums/affectedCategories';
import { Collection } from 'app/shared/enums/collection';
import { CustomFieldTypes } from 'app/shared/enums/customFieldTypes';
import { Case } from 'app/shared/types/case';
import { CustomData, CustomField, ExtractionResult } from 'app/shared/types/customFieldTypes';
import { Company, Contact } from 'app/shared/types/entityTypes';
import { FileData } from 'app/shared/types/fileData';
import { DataObserver } from 'app/shared/types/utilityTypes';

// components
import { DocumentsViewerComponent } from '../../documents/documents-viewer/documents-viewer.component';

@Component({
	selector: 'custom-fields',
	templateUrl: './custom-fields.component.html',
	styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit, OnDestroy {
	@Input() customFields: CustomField[];
	@Input() entity: Contact | Company;
	@Input() caseData: Case;

	private _editMode: boolean = false;
	private _customFieldObj: { field: CustomField; responses: ExtractionResult[] }[];
	private _customFieldExtractions: ExtractionResult[] = [];
	private _fileList: FileData[] = [];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _dataObserver: DataObserver;
	private _customPlaceholderForm: FormGroup;

	constructor(
		private _storageService: StorageService,
		private _formBuilder: UntypedFormBuilder,
		private _dialog: MatDialog,
		private _authService: AuthService,
		private _customFieldService: CustomFieldService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------
	ngOnInit(): void {
		const parentCol = this.caseData ? Collection.CASES : Collection.ENTITIES;
		const parentId = this.caseData ? this.caseData.id : this.entity.id;
		this._initData();
		this._customFieldService
			.readCustomFieldDataObservable(parentCol, parentId)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._dataObserver.unsubscribe();
				}),
				map((parentCollection) => {
					return parentCollection.data.map((parent) => {
						return this._customFieldService.readCustomFieldExtractionsObservable(parentCol, parentId, parent.id);
					});
				}),
				switchMap((observablesArray) => {
					return combineLatest(observablesArray);
				})
			)
			.subscribe(async (dataObsv: any[]) => {
				this._customFieldExtractions = dataObsv.flatMap((observer) => {
					return observer.data;
				});
				this._initData();
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Toggles edit mode
	 * @return { void }
	 */
	toggleEditMode(): void {
		this._editMode = !this._editMode;
		if (this._editMode) this._prepareCustomFieldsForm();
	}

	/**
	 * Toggles edit mode and resets to original values
	 * @return { void }
	 */
	cancelEdit(): void {
		this._editMode = false;
	}

	/**
	 * Get ceil of of given number
	 * @param { number } num
	 * @return { number }
	 */
	ceilNumber(num: number): number {
		return Math.ceil(num);
	}

	/**
	 * Save the custom fields form changes
	 * @return { void }
	 */
	async saveChanges(): Promise<void> {
		this._customPlaceholderForm.disable();
		const formData = this._customPlaceholderForm.value;
		const parentCol = this.caseData ? Collection.CASES : Collection.ENTITIES;
		const parentId = this.caseData ? this.caseData.id : this.entity.id;
		const parentPath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${parentCol}/${parentId}/${Collection.CUSTOMFIELDDATA}`;
		const dataArr: [any, string][] = [];
		// for every source
		for (const [fieldId, responses] of Object.entries(formData)) {
			// Insert customFieldData
			const parentData: CustomData = {
				id: fieldId,
				valid: null,
				created: new Date(),
				createdBy: this._authService.userId,
			};
			dataArr.push([parentData, parentPath]);
			const path = `${parentPath}/${fieldId}/${Collection.EXTRACTIONRESULTS}`;
			for (const [resId, value] of Object.entries(responses)) {
				const extraction = this._customFieldExtractions.find((extraction) => extraction.id === resId);
				if (value.data && typeof value.data === 'object') value.data = Timestamp.fromDate(value.data);
				// Update Extraction when changed
				if (extraction && (extraction.value !== value.data || extraction.valid !== value.valid)) {
					const updObj: Partial<ExtractionResult> = { id: resId, valid: value.valid, parent: fieldId };
					if (extraction.origin === 'Manual') updObj['value'] = value.data;
					dataArr.push([updObj, path]);
				} else if (!extraction) {
					// Create new extractionresult
					const insertObj: ExtractionResult = {
						id: resId,
						value: value.data,
						origin: 'Manual',
						created: new Date(),
						createdBy: this._authService.userId,
						parent: fieldId,
						valid: value.valid,
						source: null,
						processed: false,
					};
					dataArr.push([insertObj, path]);
				}
			}
		}
		await this._customFieldService.storeChangesCustomFields(dataArr);
		this._editMode = false;
		this._customPlaceholderForm.enable();
	}

	/**
	 * Returns the filename out of the source path where the data was found
	 *
	 * @param {string} id
	 * @return {string}
	 */
	getFileName(docRef: DocumentReference): string {
		if (!this._fileList) return '';
		const file = this._fileList.find((file) => file.id === docRef.id);
		return file ? file.name : '';
	}

	/**
	 * Opens the sourcefile
	 *
	 * @param {ExtractionResult} res
	 * @return {void}
	 */
	openSource(res: ExtractionResult): void {
		if (!this._fileList || !res || !res.parent) return;
		const file = this._fileList.find((file) => file.id === res.source.id);
		if (!file) return;
		lastValueFrom(
			this._dialog
				.open(DocumentsViewerComponent, {
					height: '95%',
					width: '100%',
					data: file,
				})
				.afterClosed()
		);
	}

	/**
	 * Handles valid toggle change; sets all other toggles to false if value was set to valid; otherwise prevents toggle
	 *
	 * @param {string} parentId
	 * @param {string} fieldId
	 * @param {MatSlideToggleChange} event
	 * @return {void}
	 */
	toggleValid(response: ExtractionResult, fieldId: string, event: MatSlideToggleChange): void {
		// Toggle cannot be unchecked, so that always one toggle is checked
		if (!event.source.checked) {
			event.source.checked = true;
			this._customPlaceholderForm.get([fieldId, response.id, 'valid']).setValue(true);
			return;
		}
		const customField = this.customFields.find((field) => field.id === fieldId);
		const dataControl = this._customPlaceholderForm.get([fieldId, response.id, 'data']);
		if (customField && customField.fieldRequired && dataControl) {
			dataControl.addValidators(Validators.required);
			dataControl.updateValueAndValidity();
		}
		// Set all other values for that customfield to invalid´
		const controls = (this._customPlaceholderForm.get([fieldId]) as UntypedFormGroup).controls;
		for (const id of Object.keys(controls)) {
			if (id === response.id) continue;
			const group = this._customPlaceholderForm.get([fieldId, id]) as UntypedFormGroup;
			group.get('valid').setValue(false);
			if (group.get('data')) {
				group.get('data').removeValidators(Validators.required);
				group.get('data').updateValueAndValidity();
			}
		}
	}

	/**
	 * Returns if a customfield has a value in one of the responses
	 *
	 * @param {ExtractionResult[]} responses
	 * @return {boolean}
	 */
	hasValue(responses: ExtractionResult[]): boolean {
		return responses && responses.some((res) => res.value !== null && res.value !== undefined);
	}
	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	/**
	 * Initializes the data to the required structure
	 *
	 * @private
	 * @return {Promise<void>}
	 */
	private async _initData(): Promise<void> {
		this._customFieldObj = [];
		const customFieldExtractions = this._customFieldExtractions;
		// Sort the custom fields on label
		this.customFields = this.customFields.sort((a: any, b: any) => a.label.localeCompare(b.label));
		this.customFields.forEach((field) => {
			const extractions = customFieldExtractions.filter((res) => res.parent === field.id);
			const manual = extractions.find((res) => res.origin === 'Manual');
			if (!manual) {
				extractions.push({
					id: v4(),
					source: null,
					value: null,
					origin: 'Manual',
					created: new Date(),
					createdBy: this._authService.userId,
					parent: field.id,
					processed: false,
					valid: false,
				});
			}
			this._customFieldObj.push({
				field: field,
				responses: extractions,
			});
		});
		const documentPaths = this._customFieldExtractions.filter((res) => res.origin !== 'Manual').map((response) => response.source.path);
		this._fileList = await this._storageService.readFilesDataByPath(documentPaths);
	}

	/**
	 *
	 * Prepare the custom fields form
	 * @private
	 * @return {void}
	 */
	private _prepareCustomFieldsForm(): void {
		const controls = {};
		for (const fieldData of this._customFieldObj) {
			const customField = fieldData.field;
			if (!customField.enabled) continue;
			controls[customField.id] = this._formBuilder.group({});
			const customRes = fieldData.responses.filter((res) => res.origin !== 'Manual');
			const manualRes = fieldData.responses.find((res) => res.origin === 'Manual');
			// add validators and format value if necessary
			let fieldVal: any = manualRes.value;
			const validVal = customRes.length > 0 ? manualRes.valid : true;
			const validators = [];
			switch (customField.fieldType) {
				case CustomFieldTypes.textbox:
					validators.push(Validators.pattern('^(?!\\s*$).+'));
					break;
				case CustomFieldTypes.datePicker:
					fieldVal = fieldVal ? new Timestamp(fieldVal['seconds'], fieldVal['nanoseconds']).toDate() : null;
					break;
				case CustomFieldTypes.checkbox:
					fieldVal = fieldVal ? true : false;
					break;
			}
			// add controls for manual value
			controls[customField.id].addControl(
				manualRes.id,
				this._formBuilder.group({
					data: [fieldVal, validators],
					valid: [validVal],
				})
			);

			// Add controls for each response for validtoggle
			for (const res of customRes) controls[customField.id].addControl(res.id, this._formBuilder.group({ valid: [res.valid] }));
		}
		this._customPlaceholderForm = this._formBuilder.group(controls);
		return;
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * Getter for edit mode flag
	 * @type { boolean }
	 */
	get editMode(): boolean {
		return this._editMode;
	}

	/**
	 * Get custom field enum data
	 * @type { typeof CustomFieldTypes }
	 */
	get customFieldTypes(): typeof CustomFieldTypes {
		return CustomFieldTypes;
	}

	/**
	 * Get affected type enum data
	 * @type { typeof AffectedCategory }
	 */
	get affectedCategory(): typeof AffectedCategory {
		return AffectedCategory;
	}

	/**
	 *@type {{
	 * 		field: CustomField;
	 * 		responses: ExtractionResult[];
	 * 	}[]}
	 */
	get customFieldData(): {
		field: CustomField;
		responses: ExtractionResult[];
	}[] {
		return this._customFieldObj;
	}

	/**
	 * @type {string[]}
	 */
	get columnsToDisplay(): string[] {
		return ['label', 'value', 'source'];
	}

	/**
	 * @type {FormGroup}
	 */
	get customPlaceholderForm(): FormGroup {
		return this._customPlaceholderForm;
	}
	/**
	 * @type {boolean}
	 */
	get canEdit(): boolean {
		return this._authService.checkRolePermission(Collection.ENTITIES, Actions.edit);
	}
}
