<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 h-full">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.cases.dashboard.entityElement.customer') }}
			</div>
			<ng-container *ngIf="entity">
				<ng-container [ngSwitch]="entity.type">
					<ng-container *ngSwitchCase="'contact'">
						<ng-container *ngTemplateOutlet="contactValidation"></ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</div>
		<ng-container *ngIf="entity; else entityLoading">
			<ng-container [ngSwitch]="entity.type">
				<ng-container *ngSwitchCase="'contact'">
					<ng-container *ngTemplateOutlet="contact; context: { $implicit: entity }"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="'company'">
					<ng-container *ngTemplateOutlet="company; context: { $implicit: entity }"></ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>

	<!-- Templates -->
	<ng-template #entityLoading>
		<div class="animate-pulse flex space-x-4">
			<div class="flex-1 space-y-6 py-1">
				<div class="h-2 bg-slate-700 rounded"></div>
				<div class="space-y-3">
					<div class="grid grid-cols-3 gap-4">
						<div class="h-2 bg-slate-700 rounded col-span-2"></div>
						<div class="h-2 bg-slate-700 rounded col-span-1"></div>
					</div>
					<div class="h-2 bg-slate-700 rounded"></div>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #contactValidation>
		<div>
			<mat-icon
				*ngIf="!passportAvailable"
				class="icon-size-5 text-warn"
				[svgIcon]="'heroicons_solid:identification'"
				matTooltip="{{ t('platform.cases.dashboard.entityElement.noValidPassportInformationFound') }}"
			></mat-icon>
			<mat-icon
				*ngIf="passportAvailable"
				class="icon-size-5 text-green-500"
				[svgIcon]="'heroicons_solid:identification'"
				matTooltip="{{ t('platform.cases.dashboard.entityElement.passportInformationFound') }}"
			></mat-icon>
		</div>
	</ng-template>

	<ng-template #contact let-contact>
		<div class="flex flex-col">
			<div class="grid grid-flow-row auto-rows-max">
				<div class="grid gap-2">
					<div class="grid grid-cols-3">
						<div>
							<span class="font-medium">{{ t('platform.cases.dashboard.entityElement.firstName') }}: </span>
						</div>
						<div class="col-span-2 ...">
							<span>{{ contact.firstName }}</span>
						</div>
					</div>
					<div class="grid grid-cols-3">
						<div>
							<span class="font-medium">{{ t('platform.cases.dashboard.entityElement.lastName') }}: </span>
						</div>
						<div class="col-span-2 ...">
							<span>{{ contact.lastName }}</span>
						</div>
					</div>
					<div class="grid grid-cols-3">
						<div>
							<span class="font-medium">{{ t('platform.cases.dashboard.entityElement.dob') }}: </span>
						</div>
						<div class="col-span-2 ...">
							<span>{{ contact.dateOfBirth ? (contact.dateOfBirth.toDate() | date: 'mediumDate') : '' }}</span>
						</div>
					</div>
				</div>
				<div *ngIf="(address | json) != '{}'" class="mt-2">
					<div>
						<span class="font-medium">{{ t('platform.cases.dashboard.entityElement.address') }}: </span>
					</div>
					<div class="grid">
						<span>{{ address.line1 }}</span>
						<span class="mt-1">{{ address.line2 }}</span>
					</div>
					<div class="grid grid-cols-2 mt-1">
						<div class="space-x-2">
							<span *ngIf="address.zip_code && address.zip_code !== ''">{{ address.zip_code }}</span>
							<span>{{ address.city }}</span>
						</div>
					</div>
					<div class="grid grid-cols-2">
						<div class="space-x-2">
							<span *ngIf="address.state && address.state !== ''">{{ address.state }}</span>
							<span>{{ getCountryByIso(address.country) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<ng-template #company let-company>
		<div class="flex flex-col">
			<div class="font-medium">{{ company.name }}</div>
			<div class="grid">
				<span>{{ address.line1 }}</span>
				<span class="mt-1">{{ address.line2 }}</span>
			</div>
			<div class="grid grid-cols-2 mt-1">
				<div class="space-x-2">
					<span *ngIf="address.zip_code && address.zip_code !== ''">{{ address.zip_code }}</span>
					<span>{{ address.city }}</span>
				</div>
			</div>
			<div class="grid grid-cols-2">
				<div class="space-x-2">
					<span *ngIf="address.state && address.state !== ''">{{ address.state }}</span>
					<span> {{ getCountryByIso(address.country) }}</span>
				</div>
			</div>
		</div>
	</ng-template>
</ng-container>
