<ng-container *transloco="let t">
	<div class="flex flex-col w-40 h-40 m-2 p-4 shadow rounded-2xl cursor-pointer bg-card">
		<div class="aspect-w-9 aspect-h-6">
			<div class="flex items-center justify-center">
				<!-- Icons -->
				<div class="relative">
					<mat-icon class="icon-size-14 text-hint" [svgIcon]="'iconsmind:file'"></mat-icon>
					<div
						class="absolute left-0 bottom-0 px-1.5 rounded text-sm font-semibold leading-5 text-white"
						[class.bg-red-600]="file.type === 'application/pdf'"
						[class.bg-blue-600]="file.type === 'DOC'"
						[class.bg-green-600]="file.type === 'XLS'"
						[class.bg-gray-600]="file.type === 'TXT'"
						[class.bg-amber-600]="file.type === 'JPG'"
						[class.bg-gray-600]="!fileTypes[file.type]"
					>
						{{ fileTypes[file.type] ? fileTypes[file.type].shortName.toUpperCase() : 'OTHER' }}
					</div>
				</div>
			</div>
		</div>
		<div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
			<div class="truncate" [matTooltip]="file.name">
				{{ file.name }}
			</div>
			<div>
				<mat-progress-bar style="text-align: left" mode="determinate" [value]="percentage | async"></mat-progress-bar>
			</div>
			<div class="text-secondary truncate">{{ percentage | async }} %</div>
		</div>
	</div>
</ng-container>
