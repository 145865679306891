import { Injectable } from '@angular/core';
import { Notification } from 'app/shared/types/notification';
import { sortBy } from 'lodash-es';
import { Observable, Subject } from 'rxjs';
// services
import { DataService } from 'app/core/database/data.service';
import { AuthService } from '../auth/auth.service';
// enums
import { Collection } from 'app/shared/enums/collection';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(
		private _authService: AuthService,
		private _dataService: DataService
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------
	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------
	readNotifications(): Observable<DataObserver> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.USERS}/${this._authService.userId}/${Collection.NOTIFICATIONS}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Update the notification
	 *
	 * @param id
	 * @param notification
	 */
	update(id: string, notification: Notification): Promise<void> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.USERS}/${this._authService.userId}/${Collection.NOTIFICATIONS}`;
		return this._dataService.updateDocument(notification, path, id);
	}

	/**
	 * Delete the notification
	 *
	 * @param id
	 */
	delete(id: string): Promise<void> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.USERS}/${this._authService.userId}/${Collection.NOTIFICATIONS}`;
		return this._dataService.deleteData(path, id);
	}

	/**
	 * Mark all notifications as read
	 */
	async markAllAsRead(): Promise<void> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.USERS}/${this._authService.userId}/${Collection.NOTIFICATIONS}`;
		const notificationSnapshot = await this._dataService.loadAllEntries(path);
		const notifications: Notification[] = [];
		notificationSnapshot.docs.forEach((notification) => {
			const newNotification = notification.data() as Notification;
			newNotification.id = notification.id;
			newNotification.read = true;
			notifications.push(newNotification);
		});

		return this._dataService.updateDocuments(notifications, path);
	}

	/**
	 * Sort notifications by unread first
	 */
	sortByUnreadFirst(notifications: Notification[]) {
		let _unread = [];
		let _read = [];
		if (!notifications || notifications.length === 0) return [];
		notifications.reverse().forEach((notification) => {
			if (notification.read) {
				_read.push(notification);
			} else {
				_unread.push(notification);
			}
		});
		_unread = sortBy(_unread, 'time');
		_read = sortBy(_read, 'time');
		return _unread.reverse().concat(_read.reverse());
	}
}
