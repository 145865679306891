import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuditLogComponent } from './audit-log/audit-log.component';

import { MatIconModule } from '@angular/material/icon';
// Translation module
import { TranslocoModule } from '@jsverse/transloco';

@NgModule({
	declarations: [AuditLogComponent],
	imports: [CommonModule, MatIconModule, TranslocoModule],
	exports: [AuditLogComponent],
	providers: [DatePipe],
})
export class AuditModule {}
