import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DocumentReference } from 'firebase/firestore';
import { finalize, Subject, takeUntil } from 'rxjs';
// services
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
import { StorageService } from 'app/core/storage/storage.service';
// types and enums
import { FileData } from 'app/shared/types/fileData';
import { DataObserver } from 'app/shared/types/utilityTypes';

@Component({
	selector: 'report-documents-list',
	templateUrl: './documents-list.component.html',
	styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit, OnDestroy {
	@Input() linkedRecord: DocumentReference;
	columnsToDisplay = ['name', 'contents', 'type', 'size', 'created', 'createdBy'];
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _fileListObservable: DataObserver;
	private _fileList: FileData[] = [];
	constructor(
		private _platformInformationService: PlatformInformationService,
		private _storageService: StorageService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		this._storageService
			.readFileData(this.linkedRecord)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._fileListObservable.unsubscribe();
				})
			)
			.subscribe((filesObserver) => {
				this._fileListObservable = filesObserver;
				this._fileList = filesObserver.data;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns the full name of the user
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	/**
	 * Formats byte number to string
	 * @param {number} bytes
	 * @param {number} [decimals=2]
	 * @return {string}
	 */
	formatBytes(bytes: number, decimals = 2): string {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	/**
	 * @type {FileData[]}
	 */
	get fileList(): FileData[] {
		return this._fileList;
	}
}
