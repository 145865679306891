import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { DocumentData, DocumentReference } from 'firebase/firestore';
// rxjs
import { Observable, Subject } from 'rxjs';
//types & enums
import { Collection } from 'app/shared/enums/collection';
import { Case } from 'app/shared/types/case';
import { TaskAnswer } from 'app/shared/types/taskAnswer';
import { Task } from 'app/shared/types/taskboardTypes';
import { TrashBinElement } from 'app/shared/types/trash-bin-element';
import { DataObserver } from 'app/shared/types/utilityTypes';
// services
import { DataService } from 'app/core/database/data.service';
import { QueryCondition } from 'app/shared/types/query-condition';
import { AuthService } from '../auth/auth.service';
@Injectable({
	providedIn: 'root',
})
export class CaseService {
	unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _dataService: DataService,
		private _authService: AuthService,
		private _functions: Functions
	) {
		this._functions.region = 'europe-west1';
	}

	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------
	/**
	 * Loads all the accounts cases
	 * @return {Observable<DataObserver>}
	 */
	readCases(): Observable<DataObserver> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Loads a case
	 *
	 * @param {string} caseId
	 * @return {Observable<DataObserver>}
	 */
	readCase(caseId: string): Observable<DataObserver> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;
		return this._dataService.loadDataObservable(path, caseId);
	}

	/**
	 * Returns a promise to load an case
	 * @param { string } caseId
	 * @return { Promise<Case> }
	 */
	readCaseData(caseId: string): Promise<Case> {
		return this._dataService.loadData(`${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`, caseId);
	}

	/**
	 * Loads all cases to a entity
	 *
	 * @param {string} entityId
	 * @return {Observable<DataObserver>}
	 */
	readCasesToEntity(entityId: string): Observable<DataObserver> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;
		const conditions: QueryCondition[] = [
			{
				field: 'linkedRecord',
				operator: '==',
				value: entityId,
			},
		];
		return this._dataService.queryDataObservable(path, conditions);
	}

	/**
	 * Loads all tasks to specific case
	 *
	 * @param {string} caseId
	 * @return {Observable<DataObserver>}
	 */
	readTasks(caseId: string): Observable<DataObserver> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}/${caseId}/${Collection.TASKS}`;
		return this._dataService.loadAllEntriesObservable(path);
	}

	/**
	 * Loads taskAnswer to task and case
	 *
	 * @param {string} id
	 * @param {string} caseId
	 * @param {string} taskId
	 * @return {Promise<TaskAnswer>}
	 */
	readTaskAnswer(id: string, caseId: string, taskId: string): Promise<TaskAnswer> {
		const accountId = this._authService.accountId;
		const path = `${Collection.ACCOUNTS}/${accountId}/${Collection.CASES}/${caseId}/${Collection.TASKS}/${taskId}/${Collection.TASKANSWERS}`;
		return this._dataService.loadData(path, id);
	}

	/**
	 * Loads taskAnswer to task and case
	 *
	 * @param {string} caseId
	 * @param {Task[]} tasks
	 * @return {Promise<TaskAnswer[]>}
	 */
	async readTaskAnswersToCase(caseId: string, tasks: Task[]): Promise<TaskAnswer[]> {
		const promiseArr = [];
		for (const task of tasks) {
			if (!task.verifiedValue) continue;
			promiseArr.push(this.readTaskAnswer(task.verifiedValue.id, caseId, task.id));
		}
		return Promise.all(promiseArr);
	}

	/**
	 * Creates a new taskanswer object for the given task
	 *
	 * @param {TaskAnswer} taskAnswer
	 * @param {string} caseId
	 * @param {string} taskId
	 * @return {(Promise<DocumentReference<any> | void>)}
	 */
	createTaskAnswer(taskAnswer: TaskAnswer, caseId: string, taskId: string): Promise<DocumentReference<any> | void> {
		const accountId = this._authService.accountId;
		const path = `${Collection.ACCOUNTS}/${accountId}/${Collection.CASES}/${caseId}/${Collection.TASKS}/${taskId}/${Collection.TASKANSWERS}`;
		return this._dataService.storeDocument({ ...taskAnswer }, path);
	}

	/**
	 * Creates a case and returns it id
	 *
	 * @param {Case} caseData
	 * @return {Promise<string>}
	 */
	async createCase(caseData: Case): Promise<string> {
		caseData.created = new Date();
		caseData.createdBy = this._authService.userId;
		caseData.owner = caseData.owner ?? this._authService.userId;
		caseData.parent = this._authService.accountId;

		const createNewCase = httpsCallable(this._functions, 'createNewCase');
		const docRef = await createNewCase({ caseData: caseData }).catch((error) => {
			console.error(error);
		});
		if (!docRef) return;
		return docRef.data as string;
	}

	/**
	 * Updates the case
	 *
	 * @param {string} caseId
	 * @param {Partial<Case>} updateData
	 * @return {Promise<void>}
	 */
	updateCase(caseId, updateData: Partial<Case>): Promise<void> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;
		return this._dataService.updateDocument(updateData, path, caseId);
	}

	/**
	 * Moves the case to trashbin
	 *
	 * @return {Promise<void>}
	 */
	deleteCase(caseData: Case): Promise<void> {
		const casePath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}/${caseData.id}`;
		const trashBinElement: TrashBinElement = {
			deletedBy: this._authService.userId,
			deleted: new Date(),
			origin: `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`,
			object: caseData,
			subCollections: [
				`${casePath}/${Collection.TASKS}`,
				`${casePath}/${Collection.FILES}`,
				`${casePath}/${Collection.CUSTOMFIELDDATA}`,
				`${casePath}/${Collection.SANCTIONLISTRESULTS}`,
				`${casePath}/${Collection.NEWSCHECKRESULT}`,
			],
		};
		return this._dataService.moveToTrash(trashBinElement, this._authService.accountId);
	}

	/**
	 * Check for content type used in data room > file
	 * @param { string } definitionTypeId
	 * @return { Promise<boolean> }
	 */
	async checkForContentTypeUsedForFile(definitionTypeId: string): Promise<boolean> {
		const casePath = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;

		// Get case list
		const caseList = await this._dataService.loadAllEntries(casePath);

		for (const caseData of caseList.docs) {
			// Get files again case
			const fileList = await this._dataService.loadAllEntries(`${casePath}/${caseData.id}/${Collection.FILES}`);

			// Check for definition type exist in file
			const foundFile = fileList.docs.find((data) => data.data().contents.includes(definitionTypeId));

			if (foundFile) return true; // Exit the loop and return true if a file with the specified content type is found
		}

		return false;
	}

	/**
	 * Returns the firestore document reference with the complete path
	 * @param {string} caseId
	 * @return {DocumentReference<DocumentData>}
	 */
	getCaseReference(caseId: string): DocumentReference<DocumentData> {
		const path = `${Collection.ACCOUNTS}/${this._authService.accountId}/${Collection.CASES}`;
		return this._dataService.getFirestoreRef(path, caseId);
	}
	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
}
