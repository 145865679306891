<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden p-6 h-full">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.cases.dashboard.riskScoreElement.riskScore') }}
			</div>
		</div>
		<div>
			<ng-container *ngIf="riskLevel && riskLevelSettings">
				<apx-chart
					class="flex flex-auto items-center justify-center w-full h-full"
					[chart]="riskScoreChart.chart"
					[colors]="riskScoreChart.colors"
					[labels]="riskScoreChart.labels"
					[plotOptions]="riskScoreChart.plotOptions"
					[series]="riskScoreChart.series"
					[states]="riskScoreChart.states"
					[tooltip]="riskScoreChart.tooltip"
					[fill]="riskScoreChart.fill"
				></apx-chart>
			</ng-container>
			<ng-container>
				<span class="flex flex-auto items-center justify-center text-xl">{{ riskScore }}</span>
			</ng-container>
			<div class="flex flex-row space-x-2" *ngIf="tasksRemaining > 0">
				<div class="flex justify-center w-full text-secondary items-center">
					<mat-icon svgIcon="heroicons_outline:exclamation-circle" class="mr-1"></mat-icon>
					<div class="text-md font-medium truncate">
						{{ t('platform.cases.dashboard.riskScoreElement.valueIsNotFinalTasksRemaining') }}:
					</div>
					<div class="ml-1.5 text-lg font-semibold">
						{{ tasksRemaining }}
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>
