import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentReference } from '@angular/fire/firestore';
import { Subscribe, Unsubscribe } from '@firebase/util';
import { StorageService } from 'app/core/storage/storage.service';
// Types & Enums
import { FileTypes } from 'app/shared/enums/fileTypes';
import { UploadTask, UploadTaskSnapshot } from 'firebase/storage';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
	selector: 'file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
	@Input() file: File;
	@Input() linkedRecord: DocumentReference;
	@Output() fileReadyEvent: EventEmitter<void> = new EventEmitter<void>();

	fileTypes = FileTypes;
	private _uploadTask: UploadTask;
	private _percentage: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private _filePath: string;
	private _unsubscribe: Unsubscribe | Subscribe<UploadTaskSnapshot>;

	constructor(private _storageService: StorageService) {}

	ngOnInit(): void {
		this._uploadTask = this._storageService.uploadFileResumable(this.file, this.linkedRecord);

		this._unsubscribe = this._uploadTask.on(
			'state_changed',
			(snapshot) => this._onChange(snapshot),
			(error) => console.error(error),
			() => this._onComplete()
		);
	}

	/**
	 * Callback for when upload state changes - calculate progress and updates percentage subject
	 * @param {UploadTaskSnapshot} snapshot
	 * @return {void}
	 */
	private _onChange(snapshot: UploadTaskSnapshot): void {
		if (!this._filePath || this._filePath !== snapshot.ref.fullPath) this._filePath = snapshot.ref.fullPath;
		const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
		this._percentage.next(Math.ceil(percent));
		return;
	}

	/**
	 * Callback for when upload is complete
	 * @return {void}
	 */
	private _onComplete(): void {
		this._storeMeta();
		this._unsubscribe();
		this._emitComplete();
		return;
	}

	/**
	 * Store file Meta Info to Firestore files collection of linked record
	 * @return {Promise<void | DocumentReference>}
	 */
	private _storeMeta(): Promise<void | DocumentReference> {
		return this._storageService.storeFileMeta(this.file, this.linkedRecord, this._filePath);
	}

	/**
	 * Emit file ready event
	 * @return {void}
	 */
	private _emitComplete(): void {
		this.fileReadyEvent.emit();
		return;
	}

	/**
	 * @type {Observable<number>}
	 */
	get percentage(): Observable<number> {
		return this._percentage.asObservable();
	}
}
