<ng-container *transloco="let t">
	<div class="flex flex-col flex-auto bg-card shadow rounded-2xl overflow-x-scroll p-6">
		<div class="flex items-start justify-between mb-4">
			<div class="text-lg font-medium tracking-tight leading-6 truncate">
				{{ t('platform.cases.dashboard.taskPreviewElement.yourOpenTasks') }}
			</div>
		</div>
		<div class="overflow-x-auto mx-6">
			<table class="w-full bg-transparent" mat-table matSort [dataSource]="openTasks" [trackBy]="trackByFn" #recentTasksTable>
				<!-- Title -->
				<ng-container matColumnDef="title">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.dashboard.taskPreviewElement.title') }}
					</th>
					<td mat-cell *matCellDef="let task">
						<span class="pr-6 whitespace-nowrap">
							{{ task.title }}
						</span>
					</td>
				</ng-container>

				<!-- Date -->
				<ng-container matColumnDef="dueDate">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.dashboard.taskPreviewElement.dueDate') }}
					</th>
					<td mat-cell *matCellDef="let task">
						<span class="pr-6 whitespace-nowrap" *ngIf="task.dueDate">
							{{ task.dueDate.toDate() | date: 'MMM dd, y' }}
						</span>
					</td>
				</ng-container>

				<!-- Status -->
				<ng-container matColumnDef="status">
					<th mat-header-cell mat-sort-header *matHeaderCellDef>
						{{ t('platform.cases.dashboard.taskPreviewElement.status') }}
					</th>
					<td mat-cell *matCellDef="let task">
						<span
							class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
							[ngClass]="{
								'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': task.listId.includes('in-progress'),
								'bg-indigo-200 text-indigo-800 dark:bg-indigo-600 dark:text-indigo-50': task.listId.includes('todo'),
							}"
						>
							<span class="leading-relaxed whitespace-nowrap">{{
								task.listId.includes('todo')
									? t('platform.cases.dashboard.taskPreviewElement.todo')
									: t('platform.cases.dashboard.taskPreviewElement.inProgress')
							}}</span>
						</span>
					</td>
				</ng-container>

				<!-- Footer -->
				<ng-container matColumnDef="recentOrdersTableFooter">
					<td class="py-6 px-0 border-0" mat-footer-cell *matFooterCellDef colspan="6">
						<button mat-stroked-button (click)="goToTasks()">
							{{ t('platform.cases.dashboard.taskPreviewElement.seeAllTasks') }}
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="recentTasksTableColumns"></tr>
				<tr
					class="selection-row order-row h-16"
					mat-row
					*matRowDef="let row; columns: recentTasksTableColumns"
					(click)="openTask(row.id)"
				></tr>
				<tr class="h-16 border-0" mat-footer-row *matFooterRowDef="['recentOrdersTableFooter']"></tr>
			</table>
		</div>
	</div>
</ng-container>
