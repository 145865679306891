<ng-container *transloco="let t; read: 'platform.entity'">
	<div *ngIf="entity && dataSource.length > 0" class="flex flex-col rounded-2xl p-4">
		<div class="font-medium tracking-tight truncate">
			{{ t('sanctionList.sanctionListCheckResults') }}
		</div>
		<table class="w-full" mat-table [dataSource]="dataSource">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.date') }}</th>
				<td mat-cell *matCellDef="let listResult">
					{{ listResult.date ? (listResult.date.toDate() | date: 'short') : '-' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="title">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.title') }}</th>
				<td mat-cell *matCellDef="let listResult">
					{{ listResult.title }}
				</td>
			</ng-container>

			<ng-container matColumnDef="sanctionListCheckPassed">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.status') }}</th>
				<td mat-cell *matCellDef="let listResult">
					<span
						class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
						[ngClass]="{
							'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': listResult.sanctionListCheckPassed == false,
							'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': listResult.sanctionListCheckPassed == true,
						}"
						>{{
							listResult.sanctionListCheckStatus == 'pending'
								? '-'
								: listResult.sanctionListCheckPassed
									? t('common.passed')
									: t('common.failed')
						}}</span
					>
				</td>
			</ng-container>
			<ng-container matColumnDef="subType">
				<th mat-header-cell *matHeaderCellDef>{{ t('common.type') }}</th>
				<td mat-cell *matCellDef="let listResult">
					{{ listResult.subType }}
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
			<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
		</table>
	</div>
</ng-container>
