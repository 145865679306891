import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
// components
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@jsverse/transloco';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsViewerComponent } from './documents-viewer/documents-viewer.component';
import { IdentificationDocumentsListComponent } from './identification-documents-list/identification-documents-list.component';

@NgModule({
	declarations: [DocumentsViewerComponent, DocumentsListComponent, IdentificationDocumentsListComponent],
	imports: [
		CommonModule,
		NgxDocViewerModule,
		MatTableModule,
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		MatDialogModule,
		TranslocoModule,
		MatSelectModule,
		MatProgressSpinnerModule,
	],
	exports: [DocumentsListComponent, IdentificationDocumentsListComponent],
})
export class DocumentsModule {}
