import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { DeclineDialogComponent } from './decline-dialog/decline-dialog.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RequestChangesDialog } from './request-changes-dialog/request-changes-dialog.component';
import { RequestChangesList } from './request-changes-list/request-changes-list.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
// Transloco module
import { TranslocoModule } from '@jsverse/transloco';
@NgModule({
	declarations: [DeclineDialogComponent, RequestChangesDialog, RequestChangesList],
	imports: [
		CommonModule,
		MatIconModule,
		MatFormFieldModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonModule,
		MatInputModule,
		MatDialogModule,
		MatCheckboxModule,
		TranslocoModule,
	],
	exports: [DeclineDialogComponent, RequestChangesList],
})
export class ApprovalModule {}
