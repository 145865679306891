import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EChartsOption } from 'echarts';

@Component({
	selector: 'app-entity-relation-graph-viewer',
	templateUrl: './entity-relation-graph-viewer.component.html',
	styleUrls: ['./entity-relation-graph-viewer.component.scss'],
})
export class EntityRelationGraphViewerComponent implements OnInit {
	constructor(
		private _dialogRef: MatDialogRef<EntityRelationGraphViewerComponent>,
		@Inject(MAT_DIALOG_DATA) public options: EChartsOption
	) {}

	ngOnInit(): void {
		this.options.series[0].force.repulsion = 1000; // distance between 2 nodes increased to get larger view
		this.options.series[0].force.edgeLength = 500;
	}

	/** Exit full screen mode */
	close(): void {
		this._dialogRef.close();
	}
}
