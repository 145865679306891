import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	Auth,
	fetchSignInMethodsForEmail,
	getAuth,
	GoogleAuthProvider,
	IdTokenResult,
	OAuthProvider,
	SAMLAuthProvider,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
	User,
} from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { FirebaseError } from 'firebase/app';
import { AuthProvider, UserCredential } from 'firebase/auth';
// rxjs
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// services
import { DataService } from '../database/data.service';
// enums
import { Actions } from 'app/shared/enums/actions';
import { Collection } from 'app/shared/enums/collection';
import { Roles } from 'app/shared/enums/roles';
import { Account } from 'app/shared/types/account';
import { RoleData } from 'app/shared/types/roleData';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private _authenticated = false;
	private _user: User;
	private _idTokenResult: IdTokenResult | any;
	private _tenantId: string;
	private _authProviders: any = [];
	private _accountId: BehaviorSubject<string> = new BehaviorSubject(null);
	private _account: BehaviorSubject<Account> = new BehaviorSubject(null);

	unsubscribeAll: Subject<boolean> = new Subject<boolean>();
	/**
	 * Constructor
	 */
	constructor(
		private _httpClient: HttpClient,
		private _auth: Auth,
		private _functions: Functions,
		private _dataService: DataService
	) {
		this._functions.region = 'europe-west1';
	}

	init() {
		// necessary for app initialization, requires a promise
		return new Promise<void>((resolve) => {
			this._auth.onAuthStateChanged(async (user) => {
				if (!user) {
					return resolve();
				}
				this._user = user;
				const idToken = await this._user.getIdTokenResult(true).catch(() => {
					return resolve();
				});
				if (!idToken) return resolve();
				this._idTokenResult = idToken;

				// init account here for permissions
				this._auth.tenantId = user.tenantId;
				this._accountId.next(this._idTokenResult.claims.accountId);
				const account = await this._dataService.loadData(Collection.ACCOUNTS, this._accountId.value);
				this._account.next(account);
				return resolve();
			});
		});
	}
	// -------------------------------------------------------------------------
	// @ Public methods
	// -------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	forgotPassword(email: string): Promise<void> {
		return sendPasswordResetEmail(this._auth, email);
	}

	/**
	 * Reset password
	 *
	 * @param password
	 */
	resetPassword(password: string): Observable<any> {
		return this._httpClient.post('api/auth/reset-password', password);
	}

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	async signIn(credentials: { email: string; password: string }) {
		if (this._authenticated) return new Error('User is already signed in.');

		const userCredential = await signInWithEmailAndPassword(this._auth, credentials.email, credentials.password).catch((error: FirebaseError) => {
			throw error;
		});

		if (!userCredential) return new Error('Credentials not found');
		this._user = userCredential.user;

		const token = await userCredential.user.getIdToken();
		if (!token) return console.error('Token not found');

		this.accessToken = token;
		this._authenticated = true;
	}

	async signInWithMicrosoft(): Promise<void> {
		const provider = new OAuthProvider('microsoft.com');
		const auth = getAuth();

		const userCredential = await signInWithPopup(auth, provider).catch((error) => {
			return console.log(error);
		});
		if (!userCredential) return;

		const credential = OAuthProvider.credentialFromResult(userCredential);
		this.accessToken = credential.accessToken;

		const validateMSAuth = httpsCallable(this._functions, 'validateMSAuth');

		await validateMSAuth({ token: credential.accessToken }).catch((err) => {
			console.error(err);
			return err;
		});
		this._user = userCredential.user;
		this._authenticated = true;
		return this.refreshToken();
	}

	signInWithGoogle(): Promise<UserCredential> {
		const provider = new GoogleAuthProvider();
		const auth = getAuth();

		return signInWithPopup(auth, provider);
	}

	/**
	 * Sign out
	 */
	signOut(): Promise<any> {
		// Remove the access token from the local storage
		localStorage.removeItem('accessToken');

		// Set the authenticated flag to false
		this._authenticated = false;
		this.unsubscribeAll.next(true);
		this.unsubscribeAll.complete();
		return this._auth.signOut();
	}

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	unlockSession(credentials: { email: string; password: string }): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	resendVerificationEmail() {
		return sendEmailVerification(this._user);
	}

	async verifyUser(email: string): Promise<{ tenantId: string; providers: any[] }> {
		let verifyUser = httpsCallable(this._functions, 'verifyUser');

		const verifyResult = await verifyUser({ email: email });
		if (!verifyResult) return;

		this._tenantId = verifyResult.data['tenantId'] as string;
		this._auth.tenantId = this._tenantId;
		this._authProviders = verifyResult.data['providers'];

		let ret = {
			tenantId: this._tenantId,
			providers: [],
		};

		this._authProviders.forEach((provider) => {
			ret.providers.push(provider.providerId);
		});

		const methods = await fetchSignInMethodsForEmail(this._auth, email);
		methods.forEach((method) => {
			if (ret.providers.indexOf(method) === -1) {
				ret.providers.push(method);
			}
		});
		if (ret.providers.length > 0) {
			return ret;
		}
	}

	ssoSignIn(providerId): Promise<UserCredential> {
		let provider: AuthProvider;

		if (providerId.split('.')[0] === 'saml') {
			provider = new SAMLAuthProvider(providerId);
		} else if (providerId.split('.')[0] === 'oidc') {
			provider = new OAuthProvider(providerId);
		}

		return signInWithPopup(this._auth, provider);
	}

	refreshToken(): Promise<any> {
		return this._auth.currentUser.getIdToken(true);
	}

	/**
	 * Returns boolean if user has permission to perform given action in given module
	 *
	 * @param {string} module the module (see possible values in modules.ts)
	 * @param {Actions} action the action
	 * @return {boolean}
	 */
	checkRolePermission(module: string, action: Actions): boolean {
		if (this.userPermissions[module]) return this.userPermissions[module].includes(action);
		else return false;
	}

	/**
	 * Returns boolean if current user has permission to perform given action in any module
	 *
	 * @param {Actions} action the action
	 * @return {boolean}
	 */
	checkRolePermissionForAnyModule(action: Actions): boolean {
		return Object.values(this.userPermissions).some((permission) => permission.includes(action));
	}

	// -------------------------------------------------------------------------
	// @ Private methods
	// -------------------------------------------------------------------------

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------
	get userId(): string {
		return this._user ? this._user.uid : null;
	}

	get accountId(): string {
		return this._accountId.value;
	}

	set account(account: any) {
		this._account.next(account);
	}

	get account$(): Observable<Account> {
		return this._account.asObservable();
	}

	get tenantId(): string {
		return this._idTokenResult ? this._idTokenResult.claims.tenantId.toString() : null;
	}

	get role(): Roles {
		return this._idTokenResult ? this._idTokenResult.claims.role.toString() : null;
	}

	get displayName(): string {
		return this._user ? this._user.displayName : null;
	}

	get email(): string {
		return this._user ? this._user.email : null;
	}

	get avatarUrl(): string {
		return this._user ? this._user.photoURL : null;
	}

	get language(): string {
		return this._idTokenResult && this._idTokenResult.claims.language ? this._idTokenResult.claims.language.toString() : null;
	}

	get accountRoles(): { [key: string]: RoleData } {
		return this._account.value && this._account.value.settings && this._account.value.settings.roleSettings
			? this._account.value.settings.roleSettings
			: {};
	}

	get userPermissions(): { [key: string]: string[] } {
		return this.accountRoles[this.role] ? this.accountRoles[this.role].rights : {};
	}

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem('accessToken', token);
	}

	get accessToken(): string {
		return localStorage.getItem('accessToken') ?? '';
	}

	get token(): string {
		return this.accessToken;
	}
}
