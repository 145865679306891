import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { finalize, Subject, takeUntil } from 'rxjs';
// services
import { EntityHelperService } from 'app/core/entities/entity-helper.service';
import { PlatformInformationService } from 'app/core/platform-information/platform-information.service';
// types and enums
import { Contact } from 'app/shared/types/entityTypes';

@Component({
	selector: 'report-contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
	@Input() entity: Contact;
	private _entityObserver: any;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	private _companyName: string;

	constructor(
		private _platformInformationService: PlatformInformationService,
		private _entityHelperService: EntityHelperService
	) {}

	// -------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -------------------------------------------------------------------------

	ngOnInit(): void {
		if (!this.entity || !this.entity.company) return;
		this._entityHelperService
			.readEntity(this.entity.company)
			.pipe(
				takeUntil(this._unsubscribeAll),
				finalize(() => {
					this._entityObserver.unsubscribe();
				})
			)
			.subscribe((entityObserver) => {
				this._entityObserver = entityObserver;
				this._companyName = entityObserver.data.name;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
	// -------------------------------------------------------------------------
	// @ Public Methods
	// -------------------------------------------------------------------------

	/**
	 * Returns user displayname
	 *
	 * @param {string} userId
	 * @return {string}
	 */
	getUserName(userId: string): string {
		return this._platformInformationService.getUserNameById(userId);
	}

	// -------------------------------------------------------------------------
	// @ Accessors
	// -------------------------------------------------------------------------

	/**
	 * @type {string}
	 */
	get companyName(): string {
		return this._companyName;
	}
}
